import { ajxPromise } from 'services/appRequest';
import { validateParams } from 'utils/api.util';

export function getAllDisputes(
  limit,
  skip,
  from,
  to,
  businessId,
  channelId,
  filter
) {
  const errors = validateParams({ limit, skip, from, to, businessId }, [
    'limit',
    'skip',
    'from',
    'to',
    'businessId',
  ]);

  if (errors.length > 0) {
    return Promise.reject(
      `Disputes.getAllDisputes() is missing {${errors}} param(s)`
    );
  }

  const limitParam = `limit=${limit}`;
  const skipParam = `&skip=${skip}`;
  const fromParam = `&from=${from}`;
  const toParam = `&to=${to}`;
  const channelIdParam = channelId !== 0 ? `&channelId=${channelId}` : '';
  const filterParam = filter.param ? `&${filter.param}=${filter.value}` : '';

  return ajxPromise.get(
    `/businesses/${businessId}/disputes?${limitParam}${skipParam}${fromParam}${toParam}${channelIdParam}${filterParam}`
  );
}

export function getUnansweredDisputes(limit, skip, businessId, channelId) {
  const errors = validateParams({ limit, skip }, ['limit', 'skip']);

  if (errors.length > 0) {
    return Promise.reject(
      `Disputes.getUnansweredDisputes() is missing {${errors}} param(s)`
    );
  }

  const limitParam = `limit=${limit}`;
  const skipParam = `&skip=${skip}`;
  const statusesParam = '&statuses=evidenceRequired';
  const channelIdParam = channelId !== 0 ? `&channelId=${channelId}` : '';
  const orderParam = '&order=evidenceDueAscending';

  return ajxPromise.get(
    `/businesses/${businessId}/disputes?${limitParam}${skipParam}${statusesParam}${channelIdParam}${orderParam}`
  );
}

export function acceptDispute(businessId, disputeId) {
  return ajxPromise.post(
    `/businesses/${businessId}/disputes/${disputeId}/accept`
  );
}
