// @flow
type ActionOverview = {
  id: number,
  name: string,
  supportBatch?: boolean,
  requiresReadPermissionOnly?: boolean
};
export const ACTIONS_OVERVIEW: Array<ActionOverview> = [
  {
    id: 1,
    name: 'Capture',
    supportBatch: true
  },
  {
    id: 2,
    name: 'Void',
    supportBatch: true
  },
  {
    id: 3,
    name: 'Refund',
    supportBatch: true
  },
  {
    id: 4,
    name: 'Blacklist',
    requiresReadPermissionOnly: true
  }
];

export const ERROR_CODES = {
  TOKEN_EXPIRED: 83023,
  PASSWORD_DONT_MATCH: 83026,
  PASSWORD_RESET_REQUIRED: 83015,
  PASSWORD_EXPIRED: 83008,
  INVALID_LOGIN: 83011,
  PASSWORD_ALREADY_USED: 83017,
  EMAIL_NOT_REGISTERED: 83043
};

export const ERROR_CODE_TITLE = {
  83023: 'Token expired',
  83026: 'Password error',
  83015: 'Password reset required',
  83008: 'Password expired',
  83011: 'Invalid login',
  83017: 'Password already used',
  83043: 'Invaild email address',
  83016: 'Too many login attempts',
  83018: 'Too many login attempts',
  82001: 'Internal error',
  access_denied: 'Invalid login',
};

export const PASSWORD = {
  UPPER_CASE: /(?=(?:.*[A-Z]){1,})/,
  LOWER_CASE: /(?=(?:.*[a-z]){1,})/,
  SPECIAL_CHARACTERS: /(?=(?:.*[`~!<>€£@#$%^&*()_\-|'+=]){1,})/,
  LENGTH: /^.{9,15}$/,
  NUMBER: /(?=(?:.*[0-9]){1,})/
};

export const getAppBasePath = (): string => {
  // We have different urls for production servers.
  // For some of them app is served from v2 subfolder,
  // so url for them looks like [origin]/v2, and for the rest just [origin].
  // We have to set the basename accordingly to a server ('/' or '/v2').
  if (process.env.NODE_ENV === 'production') {
    const origin = window.location.origin;
    return origin.includes('hubb.checkout') || origin.includes('hubv2.checkout') ? '/' : '/v2';
  }
  return '/';
};

export const ENTITY_TYPE: {
  GATEWAY: number,
  ACCOUNT: number,
  PROPERTY: number,
  BUSINESS: number,
  CHANNEL: number
} = {
  GATEWAY: 1,
  ACCOUNT: 2,
  PROPERTY: 3,
  BUSINESS: 3,
  CHANNEL: 4
};

export const ACTIONS_ENUMS = {
  CAPTURE: 1,
  VOID: 2,
  BLACKLIST: 4,
  REFUND: 8
};

export const ACCOUNT_TYPE: {
  GOD: number,
  SUPER_ADMIN: number,
  MERCHANT_ADMIN: number,
  MERCHANT_USER: number,
  SUPER_USER: number
} = {
  GOD: 1,
  SUPER_ADMIN: 2,
  MERCHANT_ADMIN: 3,
  MERCHANT_USER: 4,
  SUPER_USER: 16
};

export const OPERATION = {
  CREATE: 1,
  READ: 2,
  UPDATE: 4,
  DELETE: 8
};

export const ACCOUNT_TYPE_NAMES = {
  SUPER_ADMIN: 'Super Admin',
  SUPER_USER: 'Super User',
  MERCHANT_USER: 'Merchant User',
  MERCHANT_ADMIN: 'Merchant Admin'
};
