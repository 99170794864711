import { colors } from 'styles/common.sc';
import styled from 'react-emotion';
export { Title } from 'components/ui/layout/CkoSignInLayout';

export const PasswordRequirements = styled('div')`
  p {
    font-size: 15px;
    color: ${colors.text.secondary};
    margin-bottom: 30px;
    text-align: left;
  }

  ul {
    text-align: left;
  }

  li {
    color: ${colors.text.adiacent};
    margin-bottom: 10px;
    font-size: 13px;
    transition: all 0.3s;

    svg * {
      fill: ${colors.icon.default};
    }

    .cko-icon {
      margin-right: 16px;
    }

    &.passed {
      color: ${colors.text.links};

      svg * {
        fill: ${colors.icon.active};
      }
    }
  }
`;
