// @flow
import * as PaymentsAPI from 'services/resources/payments.api';
import { dispatchResponse, getEntity } from 'utils/action.util';
import type { RequestStatus } from '../store.types';

/**
 * Get Single
 */
type GetSinglePaymentPendingType = 'PAYMENTS_SINGLE_PENDING';
type GetSinglePaymentSuccessType = 'PAYMENTS_SINGLE_SUCCESS';
type GetSinglePaymentErrorType = 'PAYMENTS_SINGLE_ERROR';
export const PAYMENT_SINGLE: {
  PENDING: GetSinglePaymentPendingType,
  SUCCESS: GetSinglePaymentSuccessType,
  ERROR: GetSinglePaymentErrorType
} = {
  PENDING: 'PAYMENTS_SINGLE_PENDING',
  SUCCESS: 'PAYMENTS_SINGLE_SUCCESS',
  ERROR: 'PAYMENTS_SINGLE_ERROR'
};

export type Dispute = {
  disputeId: string,
  initiatedOn: string,
  reason: string,
  reasonCode: string,
  status: string,
  submitEvidenceBy: string,
  actionId: string
};

export type Details = {
  arn: string,
  id: string,
  chargeId: string,
  actionDisplayId: string,
  apiVersion: string,
  authorizationCode: string,
  authorizationDate: string,
  billingDescriptor: string,
  paymentDisplayId: string,
  longResponseCode: string,
  shortResponseCode: string,
  trackId: string,
  transactionIndicator: string,
  dispute: null | Dispute,
  businessId: number,
  merchantId: number,
  channelId: number
};

export type Indicators = {
  currencySymbol: string,
  paymentMethodName: string,
  transactionDate: string,
  transactionStatus: string,
  transactionValue: number
};

export type Log = {
  currencySymbol: string,
  id: string,
  responseCode: string,
  status: string,
  timestamp: string,
  trackId: string,
  value: number
};

export type PaymentMethods = {
  avsCheck: string,
  avsDescription: string,
  bankCountry: string,
  cardHolder: string,
  cardId: string,
  cardProduct: string,
  cardWalletType: string,
  ccNumber: string,
  customerId: string,
  customerName: string,
  customerEmail: string,
  customerDisplayId: string,
  cvvCheck: string,
  cvvDescription: string,
  cvvPresent: boolean,
  expiryMonth: number,
  expiryYear: number,
  issuingBank: string,
  paymentMethod: string,
  paymentSource: {
    sourceId: string,
    responseData: ?{
      mandate_reference: string
    }
  }
};
export type Product = {
  productImage: string,
  productName: string,
  quantity: number,
  sku: string,
  totalPrice: number,
  unitPrice: string
};

export type Address = {
  addressLine1: string,
  addressLine2: string,
  city: string,
  country: string,
  phone: { number: string },
  postCode: string,
  recipientName: string,
  state: string,
  userDefined: string
};

export type Purchases = {
  captureTransactionId: string,
  currencyId: number,
  currencySymbol: string,
  customerIp: string,
  customerIpCountryIso3: string,
  description: string,
  maxRefundAmount: number,
  product: Array<Product>,
  shippingAddress: Address,
  userData: Object
};

export type PaymentPayload = {
  allowedActions: number,
  details: Details,
  indicators: Indicators,
  logs: Array<Log>,
  paymentMethods: PaymentMethods,
  purchases: Purchases,
  dispute?: Dispute
};

export type GetSinglePaymentPending = {
  type: GetSinglePaymentPendingType
};

export type GetSinglePaymentSuccess = {
  type: GetSinglePaymentSuccessType,
  payload: {
    data: PaymentPayload
  }
};

export type GetSinglePaymentError = {
  type: GetSinglePaymentErrorType,
  payload: {
    data: {
      message: ''
    }
  }
};

export type PaymentDetails = RequestStatus & PaymentPayload;

/**
 * Get Batch
 */
type GetBatchPaymentPendingType = 'PAYMENTS_BATCH_PENDING';
type GetBatchPaymentSuccessType = 'PAYMENTS_BATCH_SUCCESS';
type GetBatchPaymentErrorType = 'PAYMENTS_BATCH_ERROR';
type ClearBatchPaymentType = 'PAYMENTS_BATCH_CLEAR';
export const PAYMENT_BATCH: {
  PENDING: GetBatchPaymentPendingType,
  SUCCESS: GetBatchPaymentSuccessType,
  ERROR: GetBatchPaymentErrorType,
  CLEAR: ClearBatchPaymentType
} = {
  PENDING: 'PAYMENTS_BATCH_PENDING',
  SUCCESS: 'PAYMENTS_BATCH_SUCCESS',
  ERROR: 'PAYMENTS_BATCH_ERROR',
  CLEAR: 'PAYMENTS_BATCH_CLEAR'
};

export type GetBatchPaymentPending = {
  type: GetBatchPaymentPendingType
};

export type GetBatchPaymentSuccess = {
  type: GetBatchPaymentSuccessType,
  payload: {
    data: PaymentPayload
  }
};

export type GetBatchPaymentError = {
  type: GetBatchPaymentErrorType
};

export type ClearBatchPaymentAction = {
  type: ClearBatchPaymentType
};
export type PaymentBatch = RequestStatus & { list: Array<PaymentPayload> };

/**
 * Void
 */
type VoidPendingType = 'PAYMENT_ACTION_VOID_PENDING';
type VoidSuccessType = 'PAYMENT_ACTION_VOID_SUCCESS';
type VoidErrorType = 'PAYMENT_ACTION_VOID_ERROR';
export const VOID_ACTION: {
  PENDING: VoidPendingType,
  SUCCESS: VoidSuccessType,
  ERROR: VoidErrorType
} = {
  PENDING: 'PAYMENT_ACTION_VOID_PENDING',
  SUCCESS: 'PAYMENT_ACTION_VOID_SUCCESS',
  ERROR: 'PAYMENT_ACTION_VOID_ERROR'
};

export type VoidPending = {
  type: VoidPendingType
};

export type VoidSuccess = {
  type: VoidSuccessType,
  payload: {
    data: {
      id: string
    }
  }
};

export type VoidError = {
  type: VoidErrorType,
  payload: {
    data: {
      message: string
    }
  }
};

export type VoidAction = RequestStatus & {
  status: string
};

export type Void = VoidAction;

/**
 * Refund
 */
type RefundPendingType = 'PAYMENT_ACTION_REFUND_PENDING';
type RefundSuccessType = 'PAYMENT_ACTION_REFUND_SUCCESS';
type RefundErrorType = 'PAYMENT_ACTION_REFUND_ERROR';
export const REFUND_ACTION: {
  PENDING: RefundPendingType,
  SUCCESS: RefundSuccessType,
  ERROR: RefundErrorType
} = {
  PENDING: 'PAYMENT_ACTION_REFUND_PENDING',
  SUCCESS: 'PAYMENT_ACTION_REFUND_SUCCESS',
  ERROR: 'PAYMENT_ACTION_REFUND_ERROR'
};

export type RefundPending = {
  type: RefundPendingType
};

export type RefundSuccess = {
  type: RefundSuccessType,
  payload: {
    data: {
      status: string,
      id: string
    }
  }
};

export type RefundError = {
  type: RefundErrorType,
  payload: {
    data: {
      id: string,
      message: string
    }
  }
};

export type RefundAction = RequestStatus & {
  status: string
};

export type Refund = RefundAction;

/**
 * Capture
 */
type CapturePendingType = 'PAYMENT_ACTION_CAPTURE_PENDING';
type CaptureSuccessType = 'PAYMENT_ACTION_CAPTURE_SUCCESS';
type CaptureErrorType = 'PAYMENT_ACTION_CAPTURE_ERROR';
export const CAPTURE_ACTION: {
  PENDING: CapturePendingType,
  SUCCESS: CaptureSuccessType,
  ERROR: CaptureErrorType
} = {
  PENDING: 'PAYMENT_ACTION_CAPTURE_PENDING',
  SUCCESS: 'PAYMENT_ACTION_CAPTURE_SUCCESS',
  ERROR: 'PAYMENT_ACTION_CAPTURE_ERROR'
};

export type CapturePending = {
  type: CapturePendingType
};

export type CaptureSuccess = {
  type: CaptureSuccessType,
  payload: {
    data: {
      id: string
    }
  }
};

export type CaptureError = {
  type: CaptureErrorType,
  payload: {
    data: {
      message: string
    }
  }
};

export type CaptureAction = RequestStatus & {
  status: string
};

export type Capture = CaptureAction;

/**
 * Blacklist
 */
type BlacklistPendingType = 'PAYMENT_ACTION_BLACKLIST_PENDING';
type BlacklistSuccessType = 'PAYMENT_ACTION_BLACKLIST_SUCCESS';
type BlacklistErrorType = 'PAYMENT_ACTION_BLACKLIST_ERROR';
export const BLACKLIST: {
  PENDING: BlacklistPendingType,
  SUCCESS: BlacklistSuccessType,
  ERROR: BlacklistErrorType
} = {
  PENDING: 'PAYMENT_ACTION_BLACKLIST_PENDING',
  SUCCESS: 'PAYMENT_ACTION_BLACKLIST_SUCCESS',
  ERROR: 'PAYMENT_ACTION_BLACKLIST_ERROR'
};

export type BlacklistPending = {
  type: BlacklistPendingType
};

export type BlacklistSuccess = {
  type: BlacklistSuccessType
};

export type BlacklistError = {
  type: BlacklistErrorType,
  payload: {
    data: {
      message: string
    }
  }
};

export type BlackList = RequestStatus;

export type PaymentActions = {
  void: Void,
  refund: Refund,
  capture: Capture,
  blacklist: BlackList
};

/**
 * Blacklist Fields
 */
type GetBlacklistDataPendingType = 'GET_PAYMENT_BLACKLIST_PENDING';
type GetBlacklistDataSuccessType = 'GET_PAYMENT_BLACKLIST_SUCCESS';
type GetBlacklistDataErrorType = 'GET_PAYMENT_BLACKLIST_ERROR';
export const BLACKLIST_FIELDS: {
  PENDING: GetBlacklistDataPendingType,
  SUCCESS: GetBlacklistDataSuccessType,
  ERROR: GetBlacklistDataErrorType
} = {
  PENDING: 'GET_PAYMENT_BLACKLIST_PENDING',
  SUCCESS: 'GET_PAYMENT_BLACKLIST_SUCCESS',
  ERROR: 'GET_PAYMENT_BLACKLIST_ERROR'
};
export type BlacklistFieldsData = {
  isEmailActive: boolean,
  email: string,
  cardNumber: string,
  phoneNumber: string,
  isPhoneNumberActive: boolean,
  isCardNumberActive: boolean,
  ipAddress: string,
  isIpAddressActive: boolean,
  isLocalBlackList: boolean
};
export type BlackListFields = RequestStatus & {
  data: BlacklistFieldsData
};

type BlackListPayload = {
  email: boolean,
  emailValue: string,
  cardNumberValue: string,
  phoneNumberValue: string,
  phoneNumber: boolean,
  cardNumber: boolean,
  ipAddressValue: string,
  ipAddress: boolean,
  isLocalBlackList: boolean
};

export type GetBlacklistDataPending = {
  type: GetBlacklistDataPendingType
};

export type GetBlacklistDataSuccess = {
  type: GetBlacklistDataSuccessType,
  payload: {
    data: BlackListPayload
  }
};

export type GetBlacklistDataError = {
  type: GetBlacklistDataErrorType
};

// Actions
type Action =
  | GetSinglePaymentPending
  | GetSinglePaymentSuccess
  | GetSinglePaymentError
  | GetBatchPaymentPending
  | GetBatchPaymentSuccess
  | GetBatchPaymentError
  | ClearBatchPaymentAction
  | VoidPending
  | VoidSuccess
  | VoidError
  | CapturePending
  | CaptureSuccess
  | CaptureError
  | RefundPending
  | RefundSuccess
  | RefundError
  | BlacklistPending
  | BlacklistSuccess
  | BlacklistError
  | GetBlacklistDataPending
  | GetBlacklistDataSuccess
  | GetBlacklistDataError;

export type PaymentDetailsState = {
  batch: PaymentBatch,
  paymentDetails: PaymentDetails,
  actions: PaymentActions,
  blacklistFields: BlackListFields
};
export const DEFAULT_STATE: PaymentDetailsState = {
  batch: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    list: []
  },
  paymentDetails: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    allowedActions: 0,
    details: {
      arn: '',
      id: '',
      chargeId: '',
      actionDisplayId: '',
      apiVersion: '',
      authorizationCode: '',
      authorizationDate: '',
      billingDescriptor: '',
      paymentDisplayId: '',
      longResponseCode: '',
      shortResponseCode: '',
      trackId: '',
      transactionIndicator: '',
      dispute: null
    },
    indicators: {
      currencySymbol: '',
      paymentMethodName: '',
      transactionDate: '',
      transactionStatus: '',
      transactionValue: 0
    },
    logs: [],
    paymentMethods: {
      avsCheck: '',
      avsDescription: '',
      bankCountry: '',
      cardHolder: '',
      cardId: '',
      cardProduct: '',
      cardWalletType: '',
      ccNumber: '',
      customerId: '',
      customerEmail: '',
      customerDisplayId: '',
      customerName: '',
      cvvCheck: '',
      cvvDescription: '',
      cvvPresent: false,
      expiryMonth: 0,
      expiryYear: 0,
      issuingBank: '',
      paymentMethod: '',
      paymentSource: {
        sourceId: '',
        responseData: null
      }
    },
    purchases: {
      captureTransactionId: '',
      currencyId: 0,
      currencySymbol: '',
      customerIp: '',
      customerIpCountryIso3: '',
      description: '',
      maxRefundAmount: 0,
      product: [],
      shippingAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        phone: { number: '' },
        postCode: '',
        recipientName: '',
        state: '',
        userDefined: ''
      },
      userData: {}
    }
  },
  actions: {
    void: {
      loading: false,
      error: false,
      success: false,
      errorMessage: '',
      status: '',
      batch: {
        loading: false,
        error: false,
        success: false,
        errorMessage: ''
      }
    },
    capture: {
      loading: false,
      error: false,
      success: false,
      errorMessage: '',
      status: '',
      batch: {
        loading: false,
        error: false,
        success: false,
        errorMessage: ''
      }
    },
    refund: {
      loading: false,
      error: false,
      success: false,
      errorMessage: '',
      status: '',
      batch: {
        loading: false,
        error: false,
        success: false,
        errorMessage: ''
      }
    },
    blacklist: {
      loading: false,
      error: false,
      success: false,
      errorMessage: ''
    }
  },
  blacklistFields: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    data: {
      isEmailActive: false,
      email: '',
      cardNumber: '',
      phoneNumber: '',
      isPhoneNumberActive: false,
      isCardNumberActive: false,
      ipAddress: '',
      isIpAddressActive: false,
      isLocalBlackList: false
    }
  }
};

export default function reducer(
  state: PaymentDetailsState = DEFAULT_STATE,
  action: Action
): PaymentDetailsState {
  switch (action.type) {
    case PAYMENT_BATCH.PENDING:
      return {
        ...state,
        batch: {
          ...state.batch,
          loading: true,
          error: false,
          success: false
        }
      };
    case PAYMENT_BATCH.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        batch: {
          loading: false,
          error: false,
          success: true,
          errorMessage: '',
          list: [...state.batch.list, data]
        }
      };
    }
    case PAYMENT_BATCH.CLEAR:
      return {
        ...state,
        batch: DEFAULT_STATE.batch
      };
    case PAYMENT_SINGLE.PENDING:
      return {
        ...DEFAULT_STATE,
        batch: DEFAULT_STATE.batch,
        paymentDetails: {
          ...state.paymentDetails,
          loading: true
        }
      };
    case PAYMENT_SINGLE.SUCCESS: {
      const { data } = action.payload;
      const isPayout = data.logs.map(log => log.status.includes('Payout')).includes(true);
      return {
        ...state,
        paymentDetails: {
          ...data,
          isPayout,
          loading: false,
          success: true,
          error: false,
          errorMessage: ''
        }
      };
    }
    case PAYMENT_SINGLE.ERROR:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          loading: false,
          error: true,
          errorMessage: action.payload.data.message
        }
      };
    case VOID_ACTION.PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          void: {
            ...DEFAULT_STATE.actions.void,
            loading: true
          }
        }
      };
    case VOID_ACTION.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          void: {
            ...DEFAULT_STATE.actions.void,
            success: true,
            data: { chargeId: action.payload.data.id }
          }
        }
      };
    case VOID_ACTION.ERROR:
      return {
        ...state,
        actions: {
          ...state.actions,
          void: {
            ...DEFAULT_STATE.actions.void,
            error: true,
            errorMessage: action.payload.data.message
          }
        }
      };
    case CAPTURE_ACTION.PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          capture: {
            ...DEFAULT_STATE.actions.capture,
            loading: true
          }
        }
      };
    case CAPTURE_ACTION.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          capture: {
            ...state.actions.capture,
            loading: false,
            success: true,
            data: { chargeId: action.payload.data.id }
          }
        }
      };
    case CAPTURE_ACTION.ERROR:
      return {
        ...state,
        actions: {
          ...state.actions,
          capture: {
            ...state.actions.capture,
            loading: false,
            success: false,
            error: true,
            errorMessage: action.payload.data.message
          }
        }
      };
    case REFUND_ACTION.PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          refund: {
            ...DEFAULT_STATE.actions.refund,
            loading: true
          }
        }
      };
    case REFUND_ACTION.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          refund: {
            ...DEFAULT_STATE.actions.refund,
            loading: false,
            success: true,
            status: action.payload.data.status,
            data: { chargeId: action.payload.data.id }
          }
        }
      };
    case REFUND_ACTION.ERROR:
      return {
        ...state,
        actions: {
          ...state.actions,
          refund: {
            ...state.actions.refund,
            loading: false,
            error: true,
            success: false
          }
        }
      };
    case BLACKLIST.PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          blacklist: {
            loading: true,
            error: false,
            success: false,
            errorMessage: ''
          }
        }
      };
    case BLACKLIST.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          blacklist: {
            loading: false,
            error: false,
            success: true,
            errorMessage: ''
          }
        }
      };
    case BLACKLIST.ERROR:
      return {
        ...state,
        actions: {
          ...state.actions,
          blacklist: {
            loading: false,
            error: true,
            success: false,
            errorMessage: 'Error saving blacklist settings'
          }
        }
      };
    case BLACKLIST_FIELDS.PENDING:
      return {
        ...state,
        blacklistFields: {
          ...DEFAULT_STATE.blacklistFields,
          loading: true
        }
      };
    case BLACKLIST_FIELDS.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        blacklistFields: {
          ...state.blacklistFields,
          loading: false,
          success: true,
          error: false,
          errorMessage: '',
          data: {
            isEmailActive: data.email,
            email: data.emailValue,
            cardNumber: data.cardNumberValue,
            phoneNumber: data.phoneNumberValue,
            isPhoneNumberActive: data.phoneNumber,
            isCardNumberActive: data.cardNumber,
            ipAddress: data.ipAddressValue,
            isIpAddressActive: data.ipAddress,
            isLocalBlackList: data.isLocalBlackList
          }
        }
      };
    }
    case BLACKLIST_FIELDS.ERROR: 
      return {
        ...state,
        blacklistFields: {
          ...DEFAULT_STATE.blacklistFields,
          error: true,
          errorMessage: "Please send the the payment id and list of attributes (card, email, phone, ip) you'd like to block to support@checkout.com",
          loading: false
        }
      };
    default:
      return state;
  }
}

/**
 * Action to get the transaction details given a charge id
 */
type GetPaymentDetailParam = {
  accountId: number,
  channelId: number,
  businessId: number,
  paymentId: string,
  isBatch?: boolean
};
export type GetPaymentDetails = (value: GetPaymentDetailParam) => Function;
export function getPaymentDetails(dataObj: GetPaymentDetailParam): Function {
  const { PENDING, SUCCESS, ERROR } = dataObj.isBatch ? PAYMENT_BATCH : PAYMENT_SINGLE;
  const entity = getEntity(dataObj);
  const paramObj = {
    entityId: entity.id,
    entityType: entity.type,
    paymentId: dataObj.paymentId
  };
  const promiseReq = PaymentsAPI.getPaymentDetails(paramObj);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type GetPaymentBlacklistParam = {
  paymentId: string,
  accountId: number
};
/**
 * Action to get the transaction details given a payment id
 */
export type GetPaymentBlacklist = (dataObj: GetPaymentBlacklistParam) => Function;
export function getPaymentBlacklist(dataObj: GetPaymentBlacklistParam): Function {
  const { PENDING, SUCCESS, ERROR } = BLACKLIST_FIELDS;
  const promiseReq = PaymentsAPI.getPaymentBlacklist(dataObj);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to void the transaction corresponding to the charge id
 */
export type VoidPayment = (chargeId: string, params: Object) => Function;
export function voidPayment(chargeId: string, params: Object) {
  const { PENDING, SUCCESS, ERROR } = VOID_ACTION;
  const promiseReq = PaymentsAPI.actionPayment('void', chargeId, params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to capture the transaction corresponding to the charge id
 */
export type CapturePayment = (chargeId: string, params: Object) => Function;
export function capturePayment(chargeId: string, params: Object): Function {
  const { PENDING, SUCCESS, ERROR } = CAPTURE_ACTION;
  const promiseReq = PaymentsAPI.actionPayment('capture', chargeId, params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to refund the transaction corresponding to the charge id
 */
export type RefundPayment = (chargeId: string, params: Object) => Function;
export function refundPayment(chargeId: string, params: Object): Function {
  const { PENDING, SUCCESS, ERROR } = REFUND_ACTION;
  const promiseReq = PaymentsAPI.actionPayment('refund', chargeId, params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type ClearBatch = () => ClearBatchPaymentAction;
export function clearBatch(): ClearBatchPaymentAction {
  return {
    type: PAYMENT_BATCH.CLEAR
  };
}

/**
 * Blacklist the specified data related to the transaction corresponding to the payment id
 */
export type BlackListPaymentFields = {
  isCardNumberActive: boolean,
  isIpAddressActive: boolean,
  isEmailActive: boolean,
  isPhoneNumberActive: boolean
}
export type BlackListPaymentAttribute = 'card_number' | 'payment_ip' | 'email' | 'phone'
type BlacklistPaymentParams = BlackListPaymentFields & {
  paymentId: string,
  accountId: number,
};
export type BlacklistPayment = (dataObj: BlacklistPaymentParams) => Function;
export function blacklistPayment({
  paymentId,
  accountId,
  isCardNumberActive,
  isIpAddressActive,
  isEmailActive,
  isPhoneNumberActive
}: BlacklistPaymentParams): Function {
  const fields: BlackListPaymentFields = {
    isCardNumberActive,
    isIpAddressActive,
    isEmailActive,
    isPhoneNumberActive
  };

  const fieldAttributeMap = {
    isCardNumberActive: 'card_number',
    isIpAddressActive: 'payment_ip',
    isEmailActive: 'email',
    isPhoneNumberActive: 'phone'
  };

  const attributes: BlackListPaymentAttribute[] | [] = Object.entries(fields).filter(([_, value]) => value).map(([field]) => fieldAttributeMap[field]);

  const params = {
    payment_id: paymentId,
    attributes
  };

  const { PENDING, SUCCESS, ERROR } = BLACKLIST;
  const promiseReq = PaymentsAPI.blacklistPayment(accountId, params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}
