// @flow
import React from 'react';
import config from 'App-config';

export type Environment = 'qa' | 'sandbox' | 'production' | 'local';

type Props = {
  on: Environment[];
};

const FeatureSwitch = ({ children, on }: Props) => {
  return on.includes(config.env.toLowerCase()) ? <>{children}</> : null;
};

export const isFeatureFlagged = (environments?: string[]): boolean => {
  if (!environments) {
    return true;
  }

  return environments.includes(config.env.toLowerCase());
};

export default FeatureSwitch;
