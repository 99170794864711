// @flow
import React from 'react';
import SVG from 'react-inlinesvg';

import * as Styled from './CkoIcon.sc';
import { assetsPath } from 'utils/ui.util';
import { schemeJson, generalJson, schemes } from 'assets/compressed-svgs';
type Props = {
  name: string,
  type?: string,
  width?: number | string,
  className?: string,
  onClick?: Function
};

const CkoIcon = (props: Props) => {
  const { type } = props;
  let svgDir = '';

  const containerProps = {
    ...props,
    className: `cko-icon ${props.className || ''}`
  };

  // Show placeholder
  if (!props.name) {
    return <Styled.Container {...containerProps} isPlaceholder />;
  }


  // SVG path
  let iconName = props.name.toLowerCase();
  if (iconName.includes('dlocal')) {
    iconName = `latam${iconName.includes('small') ? '-small' : ''}`;
  }

  const iconNameCamelCase = iconName.replace(/-([a-z])/g, (g)=> {
    return g[1].toUpperCase();
  });
  // Card schemes Icon
  if (schemes[iconNameCamelCase] || schemeJson[iconName] || generalJson[iconName]) {
    const compressedSvg = type === 'scheme' ? schemeJson[iconName] : generalJson[iconName];
    return (
      <Styled.Container {...containerProps}>
        {schemes[iconNameCamelCase] ? (
          schemes[iconNameCamelCase]()
        ) : (
          <span dangerouslySetInnerHTML={{ __html: compressedSvg }} />
        )}
      </Styled.Container>
    );
  }

  // Flag Icons
  if (type === 'flag') {
    svgDir += 'flags/';
  }
  const svgSrc = `${assetsPath}/svgs/${svgDir}${iconName}.svg`;
  return (
    <Styled.Container {...containerProps}>
      {type === 'flag' ? (
        <img src={svgSrc} alt={`${iconName} flag`} className="flag-icon" />
      ) : (
        <SVG src={svgSrc} />
      )}
    </Styled.Container>
  );
};

export default CkoIcon;
