// @flow
import * as React from 'react';
import * as ReactRouterDOM from 'react-router-dom';
import { connect } from 'react-redux';

import feedback from 'components/ui/feedback/';
import type { StoreState } from 'store/reducers';
import * as passwordDuck from 'store/password-duck';

type OwnProps = {|
  match: ReactRouterDOM.Match,
  history: ReactRouterDOM.RouterHistory,
|};

type StateProps = {|
  verifyTokenState: passwordDuck.VerifyTokenState,
|};

type DispatchProps = {|
  verifyToken: passwordDuck.VerifyToken,
|};

type Props = OwnProps & StateProps & DispatchProps;

export class VerifyToken extends React.Component<Props> {
  componentDidMount() {
    this.verifyToken();
  }

  componentDidUpdate() {
    if (this.props.verifyTokenState.error) {
      feedback.error('Reset password token expired');
    }

    if (this.props.verifyTokenState.success) {
      const token = ((this.props.match.params.token: any): string);
      this.props.history.push(`/reset-password/${token}`);
    }
  }

  verifyToken = () => {
    const token = ((this.props.match.params.token: any): string);
    this.props.verifyToken(token);
  };

  render() {
    return null;
  }
}

const mapActionsToProps: DispatchProps = {
  verifyToken: passwordDuck.verifyToken,
};

function mapStateToProps(state: StoreState): StateProps {
  return {
    verifyTokenState: state.passwordState.verifyToken,
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(VerifyToken);
