// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import FeatureSwitch, { Environment, isFeatureFlagged } from 'utils/FeatureSwitch';
import type { StoreState } from 'store/reducers';
import * as globalDuck from 'store/global-duck';

type Props = {
  on: Environment[];
  selected: globalDuck.Selected,
}

const WithPaymentLinksPoc = ({ children, on, selected }: Props) => {
  return (selected.account.id === 239335 && selected.channel.id === 262276) ? <FeatureSwitch on={on}>{children}</FeatureSwitch> : null;
}

const mapStateToProps = (state: StoreState): StateProps => {
  const {
    globalState,
  } = state;
  return {
    selected: globalState.selected,
  };
};

export const arePaymentLinksAvailable = (on: Environment[], { accountId, channelId }) => {
  return (accountId === 239335 && channelId === 262276) ? isFeatureFlagged(on) : false;
}

export default connect(mapStateToProps)(WithPaymentLinksPoc);
