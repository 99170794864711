import {ajxPromise} from "../appRequest";
import appConfig from "App-config";

type IDPTypes = 'Credentials' | 'SSO';

type IDPResponse = {
  type: IDPTypes;
  ssoHref?: string;
};

interface Titles {
  und: string;
}
interface Properties {
  ['okta:idp:metadata']: string;
  ['okta:idp:type']: string;
  ['okta:idp:id']: string;
}
interface Link {
  rel: string;
  href: string;
  titles: Titles;
  properties: Properties;
}

type WebFingerResponse = {
  data: {
    subject: string;
    links: Link[];
  };
};

export const verify = async (username: string): Promise<IDPResponse> => {
  const {webFingerUri, serviceUrl, clientId, appEmbedLinkId} = appConfig.okta;

  const response: WebFingerResponse = await ajxPromise.get(
    `${webFingerUri}?resource=okta:acct:${encodeURIComponent(username)}`
  );

  const info = response.data.links[0];
  const idpType = info.properties['okta:idp:type'];
  const idpId = info.properties['okta:idp:id'];

  if (idpType === 'OKTA' || !username.endsWith('@checkout.com')) {
    return {
      type: 'Credentials',
    };
  }

  if (idpType.startsWith('SAML')) {
    return {
      type: 'SSO',
      ssoHref: `${serviceUrl}/sso/saml2/${idpId}/home/oidc_client/${clientId}/${appEmbedLinkId}?login_hint=${encodeURIComponent(username)}`,
    };
  }

  throw new Error('Unrecognized IDP Type');
};
