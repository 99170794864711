// @flow
import { combineReducers, type Dispatch } from 'redux';
import { unmarshallEvidence } from 'utils/api.util';

import * as EvidenceAPI from 'services/resources/evidence.api';
import { type GetState } from 'store/reducers';
import * as uiDuck from './ui';
import * as evidenceDuck from './evidence';
import * as disputeDuck from './dispute';

export type State = {
  ui: uiDuck.State,
  evidence: evidenceDuck.State,
  dispute: disputeDuck.State
};

export const reducer = combineReducers({
  ui: uiDuck.reducer,
  evidence: evidenceDuck.reducer,
  dispute: disputeDuck.reducer
});

type EvidenceType =
  | 'ProofOfDeliveryOrService'
  | 'InvoiceOrReceipt'
  | 'InvoiceShowingDistinctTransactions'
  | 'CustomerCommunication'
  | 'RefundOrCancellationPolicy'
  | 'RecurringTransactionAgreement'
  | 'AdditionalEvidence';

type EvidenceField = {
  type: EvidenceType,
  fileId: string,
  fileName: string,
  fileContent: string,
  description: string
};

type RawEvidenceField = {
  description: string,
  file: {
    loading: boolean,
    error: string,
    id: string,
    filename: string,
    content: string
  }
};

export type Evidence = EvidenceField[];

export type RawEvidence = {|
  additional?: RawEvidenceField,
  communication?: RawEvidenceField,
  invoice?: RawEvidenceField,
  matchingPayments?: RawEvidenceField,
  shipping?: RawEvidenceField,
  subscription?: RawEvidenceField,
  terms?: RawEvidenceField
|};

export type GetEvidencePendingAction = {
  type: 'GET_EVIDENCE_PENDING'
};
export function getEvidencePending(): GetEvidencePendingAction {
  return {
    type: 'GET_EVIDENCE_PENDING'
  };
}

export type GetEvidenceSuccessAction = {
  type: 'GET_EVIDENCE_SUCCESS',
  evidence: Evidence
};
export function getEvidenceSuccess(evidence: Evidence): GetEvidenceSuccessAction {
  return {
    evidence,
    type: 'GET_EVIDENCE_SUCCESS'
  };
}

export type GetEvidenceErrorAction = {
  type: 'GET_EVIDENCE_ERROR',
  error: string
};
export function getEvidenceError(error: string): GetEvidenceErrorAction {
  return {
    error,
    type: 'GET_EVIDENCE_ERROR'
  };
}

export function clear(): Function {
  return function clearThunk(dispatch: Dispatch<any>, getState: GetState) {
    for (const fieldKey of Object.keys(evidenceDuck.keyToClear)) {
      dispatch(evidenceDuck.keyToClear[fieldKey]());
    }
  };
}

export function getEvidence(businessId: string, disputeId: string): Function {
  return function getEvidenceThunk(dispatch: Dispatch<any>, getState: GetState) {
    dispatch(getEvidencePending());
    return EvidenceAPI.getEvidence({ businessId, disputeId })
      .then(function (response) {
        const evidence = response.data;
        const maybeState = unmarshallEvidence(evidence);
        Object.keys(maybeState).forEach(function (fieldKey) {
          dispatch(evidenceDuck.keyToHydrator[fieldKey]((maybeState[fieldKey]: any)));
        });
      })
      .catch(function (error) {
        dispatch(getEvidenceError(error));
      });
  };
}
