//@flow
import { produce } from 'utils/tree';
import * as formTypes from 'types/form';

export type State = {
  description: string,
  file: {
    loading: boolean,
    error: string,
    id: string,
    filename: string,
    content: string,
  },
};

export type HydrateAction = {
  type: 'EVIDENCE_HYDRATE_TERMS',
  state: State,
};
export function hydrate(state: State): HydrateAction {
  return {
    state,
    type: 'EVIDENCE_HYDRATE_TERMS',
  };
}

export type ClearAction = {
  type: 'EVIDENCE_CLEAR_TERMS',
};
export function clear(): ClearAction {
  return {
    type: 'EVIDENCE_CLEAR_TERMS',
  };
}

export type StreamTermsDescriptionAction = formTypes.StreamText<
  'EVIDENCE_STREAM_TERMS_DESCRIPTION'
>;
export function streamTermsDescription(
  value: string
): StreamTermsDescriptionAction {
  return {
    value,
    type: 'EVIDENCE_STREAM_TERMS_DESCRIPTION',
  };
}

export type UploadTermsEvidenceStartAction = {
  type: 'EVIDENCE_UPLOAD_TERMS_START',
};
export function uploadTermsEvidenceStart(): UploadTermsEvidenceStartAction {
  return {
    type: 'EVIDENCE_UPLOAD_TERMS_START',
  };
}

export type ClearTermsEvidenceAction = {
  type: 'EVIDENCE_CLEAR_TERMS_EVIDENCE',
};
export function clearTermsEvidence(): ClearTermsEvidenceAction {
  return {
    type: 'EVIDENCE_CLEAR_TERMS_EVIDENCE',
  };
}

export type UploadTermsEvidenceSuccessAction = formTypes.UploadSuccess<
  'EVIDENCE_UPLOAD_TERMS_SUCCESS'
>;
export function uploadTermsEvidenceSuccess(
  filename: string,
  content: string
): UploadTermsEvidenceSuccessAction {
  return {
    filename,
    content,
    type: 'EVIDENCE_UPLOAD_TERMS_SUCCESS',
  };
}

type UploadTermsEvidenceFailureAction = formTypes.UploadFailure<
  'EVIDENCE_UPLOAD_TERMS_FAILURE'
>;
export function uploadTermsEvidenceFailure(
  error: string
): UploadTermsEvidenceFailureAction {
  return {
    error,
    type: 'EVIDENCE_UPLOAD_TERMS_FAILURE',
  };
}

type Action =
  | HydrateAction
  | ClearAction
  | StreamTermsDescriptionAction
  | UploadTermsEvidenceStartAction
  | ClearTermsEvidenceAction
  | UploadTermsEvidenceSuccessAction
  | UploadTermsEvidenceFailureAction;

const DEFAULT_STATE: State = {
  description: '',
  file: {
    loading: false,
    error: '',
    id: '',
    filename: '',
    content: '',
  },
};

export function reducer(state: State = DEFAULT_STATE, action: Action): State {
  return produce(state, function(draft) {
    switch (action.type) {
      case 'EVIDENCE_HYDRATE_TERMS':
        return action.state;

      case 'EVIDENCE_CLEAR_TERMS':
        return DEFAULT_STATE;

      case 'EVIDENCE_STREAM_TERMS_DESCRIPTION':
        draft.description = action.value;
        break;

      case 'EVIDENCE_UPLOAD_TERMS_START':
        draft.file.loading = true;
        break;

      case 'EVIDENCE_CLEAR_TERMS_EVIDENCE':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: '',
          content: '',
        };
        break;

      case 'EVIDENCE_UPLOAD_TERMS_SUCCESS':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: action.filename,
          content: action.content,
        };
        break;

      case 'EVIDENCE_UPLOAD_TERMS_FAILURE':
        draft.file.loading = false;
        draft.file.error = action.error;
        break;
      default: break;
    }
  });
}
