// @flow
import { trackEvent, category } from './index';

export const eventNav = {
  accountSelectorToggle(toggle: boolean) {
    trackEvent({
      category: category.nav,
      label: 'account selector',
      action: toggle ? 'open' : 'close'
    });
  },
  accountSelected() {
    trackEvent({
      category: category.nav,
      label: 'account selector',
      action: 'account selected'
    });
  },
  businessToggle(toggle: boolean) {
    trackEvent({
      category: category.nav,
      label: 'account Selector',
      action: toggle ? 'closed business channels' : 'new business selected'
    });
  },
  chanelSelected() {
    trackEvent({
      category: category.nav,
      label: 'account selector',
      action: 'channel selected'
    });
  },
  logout() {
    trackEvent({
      category: category.nav,
      action: 'logout'
    });
  },
  collapseSidebar(isCollasped: boolean) {
    trackEvent({
      category: category.nav,
      label: 'collapse button',
      action: isCollasped ? 'expand' : 'collapse'
    });
  },
  showSettingMenu(showSettingsMenu: boolean) {
    trackEvent({
      category: category.nav,
      label: 'settings menu',
      action: showSettingsMenu ? 'inactive' : 'active'
    });
  }
};

export const eventSidebar = {
  payementDetailOpen() {
    trackEvent({
      category: category.sidebar,
      label: 'paymemnt details - payment',
      action: 'open'
    });
  },
  disputeDetailOpen() {
    trackEvent({
      category: category.sidebar,
      label: 'paymemnt details - dispute',
      action: 'open'
    });
  },
  customerDetailOpen() {
    trackEvent({
      category: category.sidebar,
      label: 'customer details',
      action: 'open'
    });
  },
  customerDetailsFromPayment() {
    trackEvent({
      category: category.sidebar,
      label: 'customer details - from payment details',
      action: 'open'
    });
  }
};

export const eventDatePicker = {
  refreshButton() {
    trackEvent({
      category: category.datePicker,
      action: 'refresh button click'
    });
  },
  dateChange(fromPreset: any, startDate: any, endDate: any) {
    trackEvent({
      category: category.datePicker,
      label: fromPreset ? 'preset option' : 'user defined',
      action: `${startDate} - ${endDate}`
    });
  },
  toggleVisibility(toggle: ?boolean) {
    trackEvent({
      category: category.datePicker,
      action: toggle ? 'close' : 'open'
    });
  },
  monthSelector(direction: any) {
    trackEvent({
      category: category.datePicker,
      label: 'month navigation',
      action: direction
    });
  }
};

export const eventTable = {
  dashboardTabs(activeTab: any) {
    trackEvent({
      category: category.table,
      label: 'dashboard tabs',
      action: activeTab
    });
  },
  filterSelected(col: any) {
    trackEvent({
      category: category.table,
      label: 'filter by selected',
      action: col
    });
  },
  paginationNav(key: any, index: any) {
    trackEvent({
      category: category.table,
      label: 'pagination',
      action: `${key === 'prev' ? 'previous' : 'next'} page selected - page no ${index}`
    });
  },
  paginationIndex(index: any) {
    trackEvent({
      category: category.table,
      label: 'pagination',
      action: `selected page - ${index}`
    });
  },
  filterTabToggle(toggle: boolean) {
    trackEvent({
      category: category.table,
      label: 'filter tab',
      action: toggle ? 'close' : 'open'
    });
  },
  manualPageChange(value: any) {
    trackEvent({
      category: category.table,
      label: 'manual page change',
      action: `got to page ${value}`
    });
  },
  changePageSize(value: any) {
    trackEvent({
      category: category.table,
      label: 'change page size',
      action: `selected ${value} rows`
    });
  },
  sortColumns(col: any, order: any) {
    trackEvent({
      category: category.table,
      label: 'sort columns',
      action: `${col} - ${order}`
    });
  },
  clearSeachInput() {
    trackEvent({
      category: category.table,
      label: 'search input',
      action: 'cleared'
    });
  },
  searchInput(value: any) {
    trackEvent({
      category: category.table,
      label: 'search input',
      action: value
    });
  }
};

export const eventFilter = {
  addMore() {
    trackEvent({
      category: category.filter,
      action: 'add more'
    });
  },
  removeFilter() {
    trackEvent({
      category: category.filter,
      action: 'remove filter'
    });
  },
  filterAction(action: any) {
    trackEvent({
      category: category.filter,
      label: 'type',
      action
    });
  },
  filterField(action: any) {
    trackEvent({
      category: category.filter,
      label: 'field',
      action
    });
  },
  filterParams(action: any) {
    trackEvent({
      category: category.filter,
      label: 'state',
      action
    });
  },
  keyword(action: any) {
    trackEvent({
      category: category.filter,
      label: 'keyword',
      action
    });
  }
};

export const eventModal = {
  contentModalOk(type: any) {
    trackEvent({
      category: category.modal,
      label: type,
      action: 'completed'
    });
  },
  contentModalCancel(type: any) {
    trackEvent({
      category: category.modal,
      label: type,
      action: 'cancelled'
    });
  },
  createPaymentSubmit() {
    trackEvent({
      category: category.modal,
      label: 'create payment',
      action: 'submit'
    });
  },
  createPaymentCancel() {
    trackEvent({
      category: category.modal,
      label: 'create payment',
      action: 'cancel'
    });
  },
  editCardToggle(toggle: boolean) {
    trackEvent({
      category: category.modal,
      label: 'edit card',
      action: toggle ? 'close' : 'open'
    });
  },
  removeCardToggle(toggle: boolean) {
    trackEvent({
      category: category.modal,
      label: 'remove card',
      action: toggle ? 'close' : 'open'
    });
  },
  addNewCard(state: any) {
    trackEvent({
      category: category.modal,
      label: 'Add new card',
      action: state
    });
  },
  editDetailsToggle(toggle: boolean) {
    trackEvent({
      category: category.modal,
      label: 'edit customer details',
      action: toggle ? 'close' : 'open'
    });
  },
  createPaymentOpen() {
    trackEvent({
      category: category.modal,
      label: 'create payment',
      action: 'open'
    });
  },
  paymentActionOpen(type: any) {
    trackEvent({
      category: category.modal,
      label: type,
      action: 'open'
    });
  },
  acceptDisputeToggle(toggle: boolean) {
    trackEvent({
      category: category.modal,
      label: 'accept disputes',
      action: toggle ? 'closed' : 'open'
    });
  },
  generateReport(report: any, isVisible: boolean) {
    trackEvent({
      category: category.modal,
      label: `new ${report} report`,
      action: isVisible ? 'close' : 'open'
    });
  }
};
