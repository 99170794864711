// @flow
import * as CustomersAPI from 'services/resources/customers.api';
import { dispatchResponse, getEntity } from 'utils/action.util';
import { SHARED_ACTION_TYPES } from 'store/sharedActions';

type Customer = [
  string,
  string,
  string,
  number,
  number,
  ?number,
  number,
  string,
];
type Headers = [
  'displayId',
  'id',
  'email',
  'orderHistory',
  'ltv',
  'lastPurchase',
  'lastOrderValue',
  'scheme',
];

export type CustomersPayload = {
  startIndex: number,
  totalRows: number,
  datas: Array<Customer>,
  headers: Headers,
};

export type CustomersData = {
  loading: boolean,
  success: boolean,
  error: boolean,
  errorMessage: any,
  startIndex?: number,
  totalRows?: number,
  list: Array<Customer>,
  headers: Headers,
};

export type CustomersState = {
  customers: CustomersData,
  refresh: boolean,
};

type CustomerAllPendingType = 'CUSTOMERS_ALL_PENDING';
type CustomerAllSuccessType = 'CUSTOMERS_ALL_SUCCESS';
type CustomerAllErrorType = 'CUSTOMERS_ALL_ERROR';

type CustomerAllPending = {
  type: CustomerAllPendingType,
};
type CustomerAllSuccess = {
  type: CustomerAllSuccessType,
  payload: {
    data: CustomersPayload,
  },
};
type CustomerAllError = {
  type: CustomerAllErrorType,
  payload: any,
};

type Action = CustomerAllPending | CustomerAllSuccess | CustomerAllError;
export const CUSTOMERS_ALL: {
  PENDING: CustomerAllPendingType,
  SUCCESS: CustomerAllSuccessType,
  ERROR: CustomerAllErrorType,
} = {
  PENDING: 'CUSTOMERS_ALL_PENDING',
  SUCCESS: 'CUSTOMERS_ALL_SUCCESS',
  ERROR: 'CUSTOMERS_ALL_ERROR',
};

type Filter = {
  action: string,
  changed: boolean,
  field: string,
  filterIndex: number,
  operator: string,
  type: string,
  value: string,
};

type GetAllActionParams = {
  accountId: number,
  businessId: number,
  channelId: number,
  fromDate: string,
  toDate: string,
  pageSize: number,
  sortColumn: string,
  sortOrder: string,
  startIndex: number,
  search?: string,
  filter?: Array<Filter>,
};
export type GetAll = (params: GetAllActionParams) => Function;

const DEFAULT_STATE: CustomersState = {
  customers: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    list: [],
    headers: [
      'displayId',
      'id',
      'email',
      'orderHistory',
      'ltv',
      'lastPurchase',
      'lastOrderValue',
      'scheme',
    ],
  },
  refresh: false,
};

export default function reducer(
  state: CustomersState = DEFAULT_STATE,
  action: Action
): CustomersState {
  switch (action.type) {
    case CUSTOMERS_ALL.PENDING:
      return {
        ...state,
        refresh: false,
        customers: {
          ...DEFAULT_STATE.customers,
          loading: true,
        },
      };
    case CUSTOMERS_ALL.SUCCESS:
      return {
        ...state,
        customers: {
          ...DEFAULT_STATE.customers,
          success: true,
          startIndex: action.payload.data.startIndex,
          totalRows: action.payload.data.totalRows,
          list: action.payload.data.datas,
          headers: action.payload.data.headers,
        },
      };
    case CUSTOMERS_ALL.ERROR:
      return {
        ...state,
        customers: {
          ...DEFAULT_STATE.customers,
          error: true,
          errorMessage: action.payload.data.message,
        },
      };
    case SHARED_ACTION_TYPES.REFRESH_DATA:
      return {
        ...state,
        refresh: true,
      };
    default:
      return state;
  }
}

/**
 * Action to get the customers table data
 */
export function getAll(params: GetAllActionParams): GetAll {
  const { id: entityId, typeId: entityTypeId } = getEntity(params);
  const { PENDING, SUCCESS, ERROR } = CUSTOMERS_ALL;
  const requestParams: CustomersAPI.GetAllParams = {
    entityId,
    entityTypeId,
    sortField: params.sortColumn || 'timestamp',
    sortOrder: params.sortOrder || 'desc',
    fromDate: params.fromDate,
    toDate: params.toDate,
    pageSize: params.pageSize,
    startIndex: params.startIndex,
  };

  if (params.search) {
    requestParams.search = params.search;
  }

  if (params.filter && params.filter.length > 0) {
    requestParams.filter = params.filter;
  }

  const promiseReq = CustomersAPI.getAll(requestParams);
  return dispatchResponse(
    promiseReq,
    PENDING,
    { type: SUCCESS },
    ERROR,
    true,
    true
  );
}
