// @flow
import * as React from 'react';
import CkoIcon from 'components/ui/icon/';
import { IconActionCross, IconActionCheckmark } from '@cko/icons';
import message from 'antd/lib/message';

// Hide after 4 sec
message.config({ duration: 4 });

export const hideFeedback = () => {
  message.destroy();
};

const createFeedback = (option: { type: string, message: string }) => {
  message[option.type](
    <div className="message-wrap clearfix">
      <div className="message-inner">
        <span className="status-icon">
          {option.type === 'success' ? <IconActionCheckmark /> : <CkoIcon name='no-entry' />}
        </span>
        <span>{option.message}</span>
      </div>
      <div className="close-btn" onClick={hideFeedback}>
        <IconActionCross />
      </div>
    </div>
  );
};

export default {
  info(message: string) {
    hideFeedback();
    createFeedback({ type: 'info', message });
  },
  success(message: string) {
    hideFeedback();
    createFeedback({ type: 'success', message });
  },
  error(message: string) {
    hideFeedback();
    createFeedback({ type: 'error', message });
  },
  hide() {
    hideFeedback();
  }
};
