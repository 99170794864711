export default {
  "limit": 10,
  "skip": 10,
  "statuses": "Active,Expired",
  "id": "pl_ELqQJXdXzabU",
  "reference": "ORD-123A",
  "payment_id": "pay_mbabizu24mvu3mela5njyhpit4",
  "total_count": 1,
  "data": [
    {
      "id": "pl_ELqQJXdXzabU1",
      "status": "Active",
      "payment_id": "pay_mbabizu24mvu3mela5njyhpit4",
      "amount": 100,
      "currency": "GBP",
      "reference": "ORD-123A",
      "description": "Payment for Gold Necklace",
      "created_on": "2021-08-19T20:25:28.727Z",
      "expires_on": "2021-08-20T20:25:28.727Z",
      "customer": {
        "email": "jokershere@email.com",
        "name": "Jack Napier"
      },
      "billing": {
        "address": {
          "address_line1": "Checkout.com",
          "address_line2": "90 Tottenham Court Road",
          "city": "London",
          "state": "London",
          "zip": "W1T 4TJ",
          "country": "GB"
        },
        "phone": {
          "country_code": "+1",
          "number": "4155552671"
        }
      },
      "products": [
        {
          "name": "Gold Necklace",
          "quantity": 1,
          "price": 200
        }
      ],
      "_links": {
        "self": {
          "href": "https://api.sandbox.checkout.com/payment-links/pl_ELqQJXdXzabU"
        },
        "redirect": {
          "href": "https://pay.sandbox.checkout.com/ELqQJXdXzabU"
        },
        "payment": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4"
        },
        "payment_actions": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4/actions"
        }
      }
    },
    {
      "id": "pl_ELqQJXdXzabU2",
      "status": "Payment Received",
      "payment_id": "pay_mbabizu24mvu3mela5njyhpit4",
      "amount": 100,
      "currency": "GBP",
      "reference": "ORD-123A",
      "description": "Payment for Gold Necklace",
      "created_on": "2021-08-19T20:25:28.727Z",
      "expires_on": "2021-08-20T20:25:28.727Z",
      "customer": {
        "email": "igorvolk@email.com",
        "name": "Igor Volk"
      },
      "billing": {
        "address": {
          "address_line1": "Checkout.com",
          "address_line2": "90 Tottenham Court Road",
          "city": "London",
          "state": "London",
          "zip": "W1T 4TJ",
          "country": "GB"
        },
        "phone": {
          "country_code": "+1",
          "number": "4155552671"
        }
      },
      "products": [
        {
          "name": "Gold Necklace",
          "quantity": 1,
          "price": 200
        },
        {
          "name": "Hammer",
          "quantity": 1,
          "price": 15
        },
        {
          "name": "Stradivarius",
          "quantity": 1,
          "price": 15000000
        }
      ],
      "_links": {
        "self": {
          "href": "https://api.sandbox.checkout.com/payment-links/pl_ELqQJXdXzabU"
        },
        "redirect": {
          "href": "https://pay.sandbox.checkout.com/ELqQJXdXzabU"
        },
        "payment": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4"
        },
        "payment_actions": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4/actions"
        }
      }
    },
    {
      "id": "pl_ELqQJXdXzabU3",
      "status": "Expired",
      "payment_id": "pay_mbabizu24mvu3mela5njyhpit4",
      "amount": 100,
      "currency": "GBP",
      "reference": "ORD-123A",
      "description": "Payment for Gold Necklace",
      "created_on": "2021-08-19T20:25:28.727Z",
      "expires_on": "2021-08-20T20:25:28.727Z",
      "customer": {
        "email": "philcollins@email.com",
        "name": "Phil Collins"
      },
      "billing": {
        "address": {
          "address_line1": "Checkout.com",
          "address_line2": "90 Tottenham Court Road",
          "city": "London",
          "state": "London",
          "zip": "W1T 4TJ",
          "country": "GB"
        },
        "phone": {
          "country_code": "+1",
          "number": "4155552671"
        }
      },
      "products": [
        {
          "name": "Gold Necklace",
          "quantity": 1,
          "price": 200
        }
      ],
      "_links": {
        "self": {
          "href": "https://api.sandbox.checkout.com/payment-links/pl_ELqQJXdXzabU"
        },
        "redirect": {
          "href": "https://pay.sandbox.checkout.com/ELqQJXdXzabU"
        },
        "payment": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4"
        },
        "payment_actions": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4/actions"
        }
      }
    },
    {
      "id": "pl_ELqQJXdXzabU4",
      "status": "Payment Received",
      "payment_id": "pay_mbabizu24mvu3mela5njyhpit45",
      "amount": 60,
      "currency": "GBP",
      "reference": "",
      "description": "",
      "created_on": "2021-05-19T20:25:28.727Z",
      "expires_on": "2021-07-20T18:15:28.727Z",
      "customer": {
        "email": "syd.barret@email.com",
        "name": "Syd Barret"
      },
      "billing": {
        "address": {
          "address_line1": "Checkout.com",
          "address_line2": "90 Tottenham Court Road",
          "city": "London",
          "state": "London",
          "zip": "W1T 4TJ",
          "country": "GB"
        },
        "phone": {
          "country_code": "+44",
          "number": "789567845"
        }
      },
      "products": [
        {
          "name": "Paint",
          "quantity": 1,
          "price": 30
        },
        {
          "name": "Brushes",
          "quantity": 1,
          "price": 30
        },
      ],
      "_links": {
        "self": {
          "href": "https://api.sandbox.checkout.com/payment-links/pl_ELqQJXdXzabU"
        },
        "redirect": {
          "href": "https://pay.sandbox.checkout.com/ELqQJXdXzabU"
        },
        "payment": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4"
        },
        "payment_actions": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4/actions"
        }
      }
    },
    {
      "id": "pl_ELqQJXdXzabU5",
      "status": "Payment Received",
      "payment_id": "pay_mbabizu24mvu3mela5njyhpit46",
      "amount": 3,
      "currency": "GBP",
      "reference": "",
      "description": "",
      "created_on": "2021-05-19T20:25:28.727Z",
      "expires_on": "2021-07-20T18:15:28.727Z",
      "customer": {
        "email": "",
        "name": ""
      },
      "billing": {
        "address": {
          "address_line1": "",
          "address_line2": "66 Kudikina Gora",
          "zip": "",
          "country": "Wonderland"
        },
        "phone": {
          "country_code": "",
          "number": ""
        }
      },
      "products": [
        {
          "name": "Apples",
          "quantity": 10,
          "price": 3
        },
      ],
      "_links": {
        "self": {
          "href": "https://api.sandbox.checkout.com/payment-links/pl_ELqQJXdXzabU"
        },
        "redirect": {
          "href": "https://pay.sandbox.checkout.com/ELqQJXdXzabU"
        },
        "payment": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4"
        },
        "payment_actions": {
          "href": "https://api.sandbox.checkout.com/payments/pay_mbabizu24mvu3mela5njyhpit4/actions"
        }
      }
    },
  ]
};
