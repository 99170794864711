// @flow
import nasMigrationMerchantIds from 'config/nasMigrationMerchantIds.json';
import * as globalDuck from 'store/global-duck';

// Set the localStorage keys namespace
const storageUserKeys = {
  loginStatus: 'cko.login',
  account: 'cko.account', // legacy, superceded by global
  global: 'cko.globalState',
  dateRange: 'cko.account.dateRange',
  user: 'cko.user',
  userSession: 'cko.user.session'
};

const storageKeys = {
  hasSeenV1Decommission: 'cko.hasSeenV1Decommission',
  hasSeenNasMigration: 'cko.hasSeenNasMigration'
};

// Local data api - stores and gets data
const localDataAPI = {
  storage: window.localStorage,
  /**
   * Gets the specified item
   * @param {string} key - item key
   * @returns {object}
   */
  getData(key: string): Object {
    const localData = this.storage.getItem(key);
    return JSON.parse(localData || '{}');
  },

  /**
   * Store the specified item
   * @param {string} key
   * @param {object} data
   */
  saveData(key: string, data: Object) {
    this.storage.setItem(key, JSON.stringify(data));
  },

  remove(key: string): void {
    this.storage.removeItem(key);
  },
};

/* -------------------------------------------------------------- EXPORTS */
/**
 * Login Session
 */
export const userSession = {
  get() {
    return localDataAPI.getData(storageUserKeys.userSession);
  },
  save() {
    localDataAPI.saveData(storageUserKeys.userSession, {
      lastDate: new Date().toISOString(),
    });
  },
};

/**
 * User
 */
export const user = {
  get() {
    return localDataAPI.getData(storageUserKeys.user);
  },
  save(data: Object) {
    localDataAPI.saveData(storageUserKeys.user, data);
  },
  remove() {
    localDataAPI.remove(storageUserKeys.user);
  },
};

/**
 * Global State
 */
export const globalState = {
  get(): globalDuck.GlobalState {
    return localDataAPI.getData(storageUserKeys.global);
  },
  save(data: globalDuck.GlobalState) {
    localDataAPI.saveData(storageUserKeys.global, data);
  },
};

/**
 * Date
 */
export const dateRange = {
  get() {
    return localDataAPI.getData(storageUserKeys.dateRange);
  },
  save(data: Object) {
    localDataAPI.saveData(storageUserKeys.dateRange, data);
  },
};

/**
 * Get the user token
 */
export function getToken(): string {
  const userData = user.get();
  return userData.user.account ? userData.user.account.token : '';
}

export const login = {
  get() {
    return localDataAPI.getData(storageUserKeys.loginStatus);
  },
  remove() {
    localDataAPI.remove(storageUserKeys.loginStatus);
  },
  passed() {
    localDataAPI.saveData(storageUserKeys.loginStatus, { data: true });
  },
};

/**
 * Checks if user is authenticated
 * @returns {boolean}
 */
export function isAuthenticated(): boolean {
  const cachedUserState = user.get();

  // must be old user format, may be removed a later time
  if (cachedUserState.data !== void 0) {
    clearUserData();
    return false;
  }

  const currentUser = cachedUserState.user && cachedUserState.user.account;
  const loginStatus = login.get().data;
  return (
    !!(currentUser && currentUser.email !== '' && currentUser.token !== '') ||
    !!loginStatus
  );
}

/**
 * Stores if user has seen modal for Hub v1 decommissioning
 */
export const hasSeenV1Decommission = {
  get() {
    return !!localDataAPI.getData(storageKeys.hasSeenV1Decommission).data;
  },
  set() {
    localDataAPI.saveData(storageKeys.hasSeenV1Decommission, { data: true, date: new Date().toISOString() });
  }
}

/**
 * Stores if user has seen modal for Nas migration
 */
export const hasSeenNasMigration = {
  getDate() {
    return localDataAPI.getData(storageKeys.hasSeenNasMigration).seenAt;
  },
  getSeen() {
    const cachedUserState = user.get();

    if (!cachedUserState.user.account.accountId) { // For internal users with no account ID
      return true;
    }

    const isNasMigrationUser = nasMigrationMerchantIds.includes(cachedUserState.user.account.accountId);

    if (isNasMigrationUser) { // If NAS Migration User, just show until they ack
      return !!localDataAPI.getData(storageKeys.hasSeenNasMigration).isSeen;
    }
    else { // If not NAS Migration User, don't show the modal
      return true;
    }
  },
  clear() {
    localDataAPI.remove(storageKeys.hasSeenNasMigration);
  },
  setSeen() {
    localDataAPI.saveData(storageKeys.hasSeenNasMigration, { isSeen: true, seenAt: new Date().toISOString() });
  }
}

/**
 * Clears all user data in the localStorage
 */
export function clearUserData() {
  for (let key in storageUserKeys) {
    localDataAPI.remove(storageUserKeys[key]);
  }
}
