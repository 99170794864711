// @flow
import * as PaymentsAPI from 'services/resources/payments.api';
import { dispatchResponse, getEntity } from 'utils/action.util';
import { type RefreshData, SHARED_ACTION_TYPES } from 'store/sharedActions';
import type { RequestStatus } from '../store.types';

/**
 * List
 */
type GetListPendingType = 'PAYMENTS_LIST_PENDING';
type GetListSuccessType = 'PAYMENTS_LIST_SUCCESS';
type GetListErrorType = 'PAYMENTS_LIST_ERROR';

export const LIST_ACTION_TYPES: {
  PENDING: GetListPendingType,
  ERROR: GetListErrorType,
  SUCCESS: GetListSuccessType
} = {
  PENDING: 'PAYMENTS_LIST_PENDING',
  ERROR: 'PAYMENTS_LIST_ERROR',
  SUCCESS: 'PAYMENTS_LIST_SUCCESS'
};

type HeadersType = [
  'transactionId',
  'id',
  'timestamp',
  'status',
  'product',
  'value',
  'scheme',
  'responseCode',
  'currencySymbol',
  'businessName',
  'allowedActions',
  'ccName',
  'ccNumber',
  'customerEmail',
  'trackId',
  '3dsVersion'
];
type DatasType = [
  string,
  string,
  string,
  string,
  string,
  number,
  string,
  string,
  string,
  string,
  number,
  string,
  string,
  string,
  string,
  string
];

type ListType = {
  id: string,
  timestamp: string,
  type: string,
  key: string,
  displayId: string,
  status: string,
  ccName: string,
  customerEmail: string,
  trackId: string,
  responseCode: string,
  value: string,
  scheme: string,
  actions: Array<Object>,
  currencySymbol: string,
  ccNumber: string,
  '3dsVersion': string
};
type ListDefaultData = {
  startIndex: number,
  totalRows: number,
  headers: HeadersType,
  list: Array<ListType>
};
export type PaymentList = RequestStatus & ListDefaultData;

export type GetListPending = {
  type: GetListPendingType
};

type GetListPayloadType = {
  startIndex: number,
  totalRows: number,
  headers: HeadersType,
  datas: Array<DatasType>
};
export type GetListSuccess = {
  type: GetListSuccessType,
  payload: {
    data: GetListPayloadType
  }
};

export type GetListError = {
  type: GetListErrorType
};

/**
 * Create Payment
 */
type CreatePaymentPendingType = 'CREATE_PAYMENT_PENDING';
type CreatePaymentSuccessType = 'CREATE_PAYMENT_SUCCESS';
type CreatePaymentErrorType = 'CREATE_PAYMENT_ERROR';
type ClearCreatePaymentType = 'CLEAR_CREATE_PAYMENT';

export const CREATE_PAYMENT_TYPES: {
  PENDING: CreatePaymentPendingType,
  ERROR: CreatePaymentErrorType,
  SUCCESS: CreatePaymentSuccessType,
  CLEAR: ClearCreatePaymentType
} = {
  PENDING: 'CREATE_PAYMENT_PENDING',
  ERROR: 'CREATE_PAYMENT_ERROR',
  SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CLEAR: 'CLEAR_CREATE_PAYMENT'
};

type PaymentChargeData = {
  +status: string,
  +responseMessage: string
};

export type PaymentCharge = RequestStatus & PaymentChargeData;

export type CreatePaymentPending = {
  type: CreatePaymentPendingType
};

export type CreatePaymentSuccess = {
  type: CreatePaymentSuccessType,
  payload: {
    data: PaymentChargeData
  }
};

export type CreatePaymentError = {
  type: CreatePaymentErrorType,
  payload: {
    data: {
      message: string
    }
  }
};

export type ClearCreatePayment = {
  type: ClearCreatePaymentType
};

/**
 * Active Action
 */
export type SetPaymentActionType = 'SET_ACTIVE_ACTION';

export const SET_ACTIVE_ACTION: SetPaymentActionType = 'SET_ACTIVE_ACTION';

type ActiveAction = {
  +blacklistId: string,
  +refundId: string,
  +voidId: string,
  +captureId: string
};

type PaymentActionDataType = {
  type: string,
  value: string | Array<Object>
};
export type SetPaymentActionData = {
  type: SetPaymentActionType,
  data: PaymentActionDataType
};

/**
 * Reducer State
 */
export type PaymentState = {
  +refresh: boolean,
  +payments: PaymentList,
  +paymentCharge: PaymentCharge,
  +activeAction: ActiveAction
};

// Actions
type Action =
  | GetListPending
  | GetListSuccess
  | GetListError
  | CreatePaymentPending
  | CreatePaymentError
  | CreatePaymentSuccess
  | ClearCreatePayment
  | SetPaymentActionData
  | RefreshData;

// State
export const DEFAULT_STATE: PaymentState = {
  refresh: false,
  payments: {
    loading: false,
    error: false,
    errorMessage: '',
    success: false,
    startIndex: 0,
    totalRows: 0,
    headers: [
      'transactionId',
      'id',
      'timestamp',
      'status',
      'product',
      'value',
      'scheme',
      'responseCode',
      'currencySymbol',
      'businessName',
      'allowedActions',
      'ccName',
      'ccNumber',
      'customerEmail',
      'trackId'
    ],
    list: []
  },
  paymentCharge: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    responseMessage: '',
    status: ''
  },
  activeAction: {
    blacklistId: '',
    refundId: '',
    voidId: '',
    captureId: ''
  }
};

export default function reducer(state: PaymentState = DEFAULT_STATE, action: Action): PaymentState {
  switch (action.type) {
    case LIST_ACTION_TYPES.PENDING:
      return {
        ...state,
        refresh: false,
        payments: {
          ...DEFAULT_STATE.payments,
          loading: true
        }
      };
    case LIST_ACTION_TYPES.ERROR:
      return {
        ...state,
        payments: {
          ...DEFAULT_STATE.payments,
          error: true
        }
      };
    case LIST_ACTION_TYPES.SUCCESS: {
      const { datas, ...data } = action.payload.data;
      return {
        ...state,
        refresh: false,
        payments: {
          ...DEFAULT_STATE.payments,
          ...data,
          list: datas,
          success: true
        }
      };
    }
    case CREATE_PAYMENT_TYPES.PENDING:
      return {
        ...state,
        refresh: false,
        paymentCharge: {
          ...DEFAULT_STATE.paymentCharge,
          loading: true
        }
      };
    case CREATE_PAYMENT_TYPES.ERROR:
      return {
        ...state,
        paymentCharge: {
          ...DEFAULT_STATE.paymentCharge,
          errorMessage: action.payload.data.message,
          error: true
        }
      };
    case CREATE_PAYMENT_TYPES.SUCCESS:
      return {
        ...state,
        paymentCharge: {
          ...DEFAULT_STATE.paymentCharge,
          ...action.payload.data,
          success: true
        }
      };
    case CREATE_PAYMENT_TYPES.CLEAR:
      return {
        ...state,
        paymentCharge: {
          ...DEFAULT_STATE.paymentCharge
        }
      };
    case SET_ACTIVE_ACTION: {
      const activeAction = { ...state.activeAction };
      activeAction[action.data.type] = action.data.value;
      return {
        ...state,
        activeAction
      };
    }
    case SHARED_ACTION_TYPES.REFRESH_DATA:
      return {
        ...state,
        refresh: true
      };
    default:
      return state;
  }
}

/**
 * Action to get all payments list data
 */
type Filter = {
  action: string,
  changed: boolean,
  field: string,
  filterIndex: number,
  operator: string,
  type: string,
  value: string
};
type ListDefaultParamsType = {
  fromDate: string,
  toDate: string,
  pageSize: number,
  sortColumn: string,
  sortOrder: string,
  startIndex: number,
  search?: string,
  filter?: Array<Filter>
};

type ListAccountParamsType = {
  accountId: number,
  businessId: number,
  channelId: number
};

type ListParams = ListAccountParamsType & ListDefaultParamsType;

type EntityType = {
  entityType: string,
  entityId: string
};
type ListRequestParams = EntityType & ListAccountParamsType & ListDefaultParamsType;

export type GetList = (listParams: ListParams) => Function;
export function getList(listParams: ListParams): Function {
  const entity = getEntity(listParams);
  const { PENDING, SUCCESS, ERROR } = LIST_ACTION_TYPES;
  const requestParams: ListRequestParams = {
    entityId: entity.id,
    entityType: entity.type,
    accountId: listParams.accountId,
    businessId: listParams.businessId,
    channelId: listParams.channelId,
    fromDate: listParams.fromDate,
    toDate: listParams.toDate,
    search: listParams.search,
    sortColumn: listParams.sortColumn || 'timestamp',
    sortOrder: listParams.sortOrder || 'desc',
    pageSize: listParams.pageSize || 10,
    startIndex: listParams.startIndex || 0,
    filter: listParams.filter
  };

  if (listParams.filter !== undefined && requestParams.filter !== undefined) {
    requestParams.filter = requestParams.filter.map(f => {
      if (f.value === 'Latam local card schemes') {
        return {
          ...f,
          value: 'DLOCAL'
        };
      }
      return f;
    });
  }

  const promiseReq = PaymentsAPI.getAll(requestParams);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR, true, true);
}

/**
 * Action to create charges request
 */
export type CreatePaymentParams = {
  amount: number,
  autoCapTime: number,
  autoCapture: string,
  billingDetails: {
    addressLine1: string,
    addressLine2: string,
    city: string,
    country: string,
    phone: { number: string, countryCode: string },
    postcode: string,
    state: string
  },
  card: {
    cvv2: string,
    expiryMonth: string,
    expiryYear: string,
    name: string,
    number: string
  },
  channelId: string,
  currency: string,
  description: string,
  email: string,
  shippingDetails: {
    addressLine1: string,
    addressLine2: string,
    city: string,
    country: string,
    postcode: string,
    state: string
  },
  trackId: string,
  type: string,
  udf1: string
};
export type CreatePayment = (params: CreatePaymentParams) => Function;
export function createPayment(params: CreatePaymentParams, channelAPIVersion: string) {
  const { PENDING, SUCCESS, ERROR } = CREATE_PAYMENT_TYPES;
  const promiseReq = PaymentsAPI.createCharges(params, channelAPIVersion);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to clear all the payments charges
 */
export type ClearCharges = Function;
export function clearCharges(): ClearCreatePayment {
  return {
    type: CREATE_PAYMENT_TYPES.CLEAR
  };
}

/**
 * Action to set payment action type
 */
export type SetPaymentAction = (params: PaymentActionDataType) => SetPaymentActionData;
export function setPaymentAction(params: PaymentActionDataType): SetPaymentActionData {
  return {
    type: SET_ACTIVE_ACTION,
    data: {
      type: params.type,
      value: params.value
    }
  };
}
