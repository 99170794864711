import * as React from 'react';
import { connect } from 'react-redux';
import type { StoreState } from 'store/reducers';
import { PASSWORD } from 'config';
import CkoSignInLayout from 'components/ui/layout/CkoSignInLayout';
import CkoIcon from 'components/ui/icon/';
import { IconActionCross, IconActionCheckmark } from '@cko/icons';
import { FlexColumn, FlexRow, FlexItem } from 'components/ui/flex/';
import * as passwordDuck from 'store/password-duck';
import ResetForm from './reset-form/';
import * as Styled from './ResetPassword.sc';

// XXX: these are all injected by react-router, and will be properly typed later
type OwnProps = {
  location: {
    search: string,
  },
  history: {
    push: Function,
  },
  match: Object,
};

type StateProps = {
  newPassword: passwordDuck.NewPasswordState,
  verifyToken: passwordDuck.VerifyTokenState,
};

type DispatchProps = {
  changePassword: passwordDuck.ChangePassword,
  verifyToken: passwordDuck.VerifyToken,
};

type Props = OwnProps & StateProps & DispatchProps;

type Requirement = {
  label: string,
  expression: RegExp,
  isValid: boolean,
};

type State = {
  passwordRequirements: Array<Requirement>,
};

export class ResetPassword extends React.Component<Props, State> {
  state = {
    passwordRequirements: [
      {
        label: 'At least one uppercase letter',
        expression: PASSWORD.UPPER_CASE,
        isValid: false,
      },
      {
        label: 'At least one lowercase letter',
        expression: PASSWORD.LOWER_CASE,
        isValid: false,
      },
      {
        label: 'At least one number',
        expression: PASSWORD.NUMBER,
        isValid: false,
      },
      {
        label: 'At least one special character (£, %, !, etc.)',
        expression: PASSWORD.SPECIAL_CHARACTERS,
        isValid: false,
      },
      {
        label: 'Between 9 and 15 characters',
        expression: PASSWORD.LENGTH,
        isValid: false,
      },
    ],
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.token) {
      this.props.verifyToken(match.params.token);
    }
  }

  componentDidUpdate() {
    if (this.props.newPassword.success || this.props.verifyToken.error) {
      this.props.history.push('/login');
    }
  }

  getErrorMsgs() {
    const { newPassword } = this.props;
    if (newPassword.error) {
      return {
        type: 'error',
        message: newPassword.errorData.message,
      };
    }
    return {};
  }

  handleCheckList = (value: string) => {
    const results = [];
    const { passwordRequirements } = this.state;

    passwordRequirements.forEach((obj, indexVal) => {
      const pattern = new RegExp(obj.expression);
      if (pattern.test(value)) {
        obj.isValid = true;
        this.setState({ passwordRequirements: [...passwordRequirements] });
      } else {
        obj.isValid = false;
        results.push(1);
      }
    });

    return !!(results.length === 0);
  };

  handleSubmit = (formData: Object) => {
    const { match } = this.props;

    if (match.params.token) {
      this.props.changePassword({
        token: match.params.token,
        ...formData,
      });
    } else {
      this.props.changePassword({
        ...formData,
        email: this.props.newPassword.credentials.email,
      });
    }
  };

  renderCheckList() {
    return this.state.passwordRequirements.map((data, key) => (
      <li key={`pr_${key}`} className={data.isValid ? 'passed' : ''}>
        {data.isValid ? <IconActionCheckmark /> : <IconActionCross />}
        {data.label}
      </li>
    ));
  }

  render() {
    return (
      <CkoSignInLayout loading={this.props.newPassword.loading}>
        <FlexColumn width="100%">
          <FlexItem margin="0 0 40px 0">
            <CkoIcon name="logo" className="logo" width="30px" />
            <Styled.Title>Reset Password</Styled.Title>
          </FlexItem>
          <FlexRow width="100%">
            <FlexItem className="flex-item" margin="0 0 20px 0">
              <ResetForm
                hasToken={this.props.match.params.token !== void 0}
                tempPassword={this.props.newPassword.credentials.password}
                onSubmit={this.handleSubmit}
                onChangeCheckList={this.handleCheckList}
                error={this.getErrorMsgs()}
              />
            </FlexItem>
            <FlexItem className="flex-item" margin="0 0 20px 0">
              <Styled.PasswordRequirements>
                <p>Your new password should match the following requirements.</p>
                <ul>{this.renderCheckList()}</ul>
              </Styled.PasswordRequirements>
            </FlexItem>
          </FlexRow>
        </FlexColumn>
      </CkoSignInLayout>
    );
  }
}

const mapActionsToProps: DispatchProps = {
  changePassword: passwordDuck.changePassword,
  verifyToken: passwordDuck.verifyToken,
};

function mapStateToProps(state: StoreState): StateProps {
  return {
    newPassword: state.passwordState.newPassword,
    verifyToken: state.passwordState.verifyToken,
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ResetPassword);
