import axios from 'axios';
import { isProductionServer } from 'utils';
import config from 'App-config';

export const baseURL = window.location.origin.includes('hub-staging')
  ? 'https://hub-staging.checkout.com/'
  : config.baseURL;

const basePath = isProductionServer() ? 'api' : 'hub';

export default axios.create({
  baseURL: baseURL + basePath,
  headers: { 'x-hub-version': config.appVersion }
});
