// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as Styled from './styled/CkoLoading.sc';
export { default as CkoLoadingBar } from './CkoLoadingBar';

const Dots = ({ size, ...props }: { size?: string }) => (
  <Styled.DotsLoader className="cko-app-loading" size={size} {...props}>
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </Styled.DotsLoader>
);

const Loading = ({
  withInfo,
  show = true,
  full = false,
  opacity = 100,
  marginTop = '',
  size = '',
  ...props
} : {
  show?: boolean,
  opacity?: number,
  withInfo?: boolean,
  className?: string,
  size?: string,
  marginTop?: string,
  full?: boolean,
}) => {
  if (withInfo) {
    return (
      <Styled.InfoLoader className={classNames({
        'cko-app-loading': true,
        'show-spin': true,
        'hide-spin': !show
      })}>
        <div className="loader-inner">
          <Styled.SpinLoader className="spinner" />
          <p>We are loading new data.</p>
          <p>Please be patient</p>
        </div>
      </Styled.InfoLoader>
    );
  }
  if (full) {
    return (
      <Styled.LoadingWrap className="cko-app-loading-full" opacity={opacity}>
        <Dots size={size} marginTop={marginTop} {...props} />
      </Styled.LoadingWrap>
    );
  }
  return <Dots size={size} marginTop={marginTop} {...props} />;
};

export default Loading;
