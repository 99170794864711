// @flow
import axios from 'services/axios';
import * as localApi from 'services/localDataApi';

const appData: {
  currencyId: string,
} = {
  currencyId: ''
};

/**
 * Sets the current user's currencyId
 * @param {string} value - currency ID
 */
export function setCurrencyId(value: string): void {
  appData.currencyId = value;
}

/** Gets the current user's currencyId */
export function getCurrencyId(): string {
  if (appData.currencyId) {
    return appData.currencyId;
  }

  const user = localApi.user.get();
  // presumes user is guaranteed to have a data property
  return user && user.user ? user.user.account.displayCurrencyId : '';
}

/**
 * Maps RESTful actions.
 * All actions accepts the following arugments
 * (path = String, data = Object, reqHeaders = Object)
 * @returns {object}
 * - get()
 * - post()
 * - put()
 * - delete()
 */

export const ajxPromise = {
  get(path: string, otherOptions?: ?Object) {
    return axios.get(path, otherOptions);
  },
  post(
    path: string,
    data: any,
    otherOptions?: ?Object,
  ) {
    return axios.post(
      path,
      data,
      otherOptions
    );
  },
  put(path: string, data: any, otherOptions?: Object) {
    return axios.put(
      path,
      data,
      otherOptions
    );
  },
  delete(
    path: string,
    data?: any,
    otherOptions?: Object,
  ) {
    return axios.delete(
      path,
      data,
      otherOptions
    );
  }
};
