//@flow
import { produce } from 'utils/tree';
import * as formTypes from 'types/form';

export type State = {
  description: string,
  file: {
    loading: boolean,
    error: string,
    id: string,
    filename: string,
    content: string,
  },
};

export type HydrateInvoiceAction = {
  type: 'EVIDENCE_HYDRATE_INVOICE',
  state: State,
};
export function hydrate(state: State): HydrateInvoiceAction {
  return {
    state,
    type: 'EVIDENCE_HYDRATE_INVOICE',
  };
}

export type ClearAction = {
  type: 'EVIDENCE_CLEAR_INVOICE',
};
export function clear(): ClearAction {
  return {
    type: 'EVIDENCE_CLEAR_INVOICE',
  };
}

export type StreamInvoiceDescriptionAction = formTypes.StreamText<
  'EVIDENCE_STREAM_INVOICE_DESCRIPTION'
>;
export function streamInvoiceDescription(
  value: string
): StreamInvoiceDescriptionAction {
  return {
    value,
    type: 'EVIDENCE_STREAM_INVOICE_DESCRIPTION',
  };
}

export type UploadInvoiceEvidenceStartAction = {
  type: 'EVIDENCE_UPLOAD_INVOICE_START',
};
export function uploadInvoiceEvidenceStart(): UploadInvoiceEvidenceStartAction {
  return {
    type: 'EVIDENCE_UPLOAD_INVOICE_START',
  };
}

export type ClearInvoiceEvidenceAction = {
  type: 'EVIDENCE_CLEAR_INVOICE_EVIDENCE',
};
export function clearInvoiceEvidence(): ClearInvoiceEvidenceAction {
  return {
    type: 'EVIDENCE_CLEAR_INVOICE_EVIDENCE',
  };
}

export type UploadInvoiceEvidenceSuccessAction = formTypes.UploadSuccess<
  'EVIDENCE_UPLOAD_INVOICE_SUCCESS'
>;
export function uploadInvoiceEvidenceSuccess(
  filename: string,
  content: string
): UploadInvoiceEvidenceSuccessAction {
  return {
    filename,
    content,
    type: 'EVIDENCE_UPLOAD_INVOICE_SUCCESS',
  };
}

type UploadInvoiceEvidenceFailureAction = formTypes.UploadFailure<
  'EVIDENCE_UPLOAD_INVOICE_FAILURE'
>;
export function uploadInvoiceEvidenceFailure(
  error: string
): UploadInvoiceEvidenceFailureAction {
  return {
    error,
    type: 'EVIDENCE_UPLOAD_INVOICE_FAILURE',
  };
}

type Action =
  | HydrateInvoiceAction
  | ClearAction
  | StreamInvoiceDescriptionAction
  | UploadInvoiceEvidenceStartAction
  | ClearInvoiceEvidenceAction
  | UploadInvoiceEvidenceSuccessAction
  | UploadInvoiceEvidenceFailureAction;

const DEFAULT_STATE: State = {
  description: '',
  file: {
    loading: false,
    error: '',
    id: '',
    filename: '',
    content: '',
  },
};

export function reducer(state: State = DEFAULT_STATE, action: Action): State {
  return produce(state, function(draft) {
    switch (action.type) {
      case 'EVIDENCE_HYDRATE_INVOICE':
        return action.state;

      case 'EVIDENCE_CLEAR_INVOICE':
        return DEFAULT_STATE;

      case 'EVIDENCE_STREAM_INVOICE_DESCRIPTION':
        draft.description = action.value;
        break;

      case 'EVIDENCE_UPLOAD_INVOICE_START':
        draft.file.loading = true;
        break;

      case 'EVIDENCE_CLEAR_INVOICE_EVIDENCE':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: '',
          content: '',
        };
        break;

      case 'EVIDENCE_UPLOAD_INVOICE_SUCCESS':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: action.filename,
          content: action.content,
        };
        break;

      case 'EVIDENCE_UPLOAD_INVOICE_FAILURE':
        draft.file.loading = false;
        draft.file.error = action.error;
        break;
      default: break;
    }
  });
}
