// @flow
import sortBy from 'lodash/sortBy';
import * as PaymentPlanAPI from 'services/resources/payment-plans.api';
import { dispatchResponse, getEntity } from 'utils/action.util';
import { type RequestStatus } from '../store.types';
import { convertCentsWorth } from 'services/subscription/subscriptionService';

type GetPaymentPlanListPendingType = 'PAYMENT_PLAN_ALL_PENDING';
type GetPaymentPlanListSuccessType = 'PAYMENT_PLAN_ALL_SUCCESS';
type GetPaymentPlanListErrorType = 'PAYMENT_PLAN_ALL_ERROR';

type UpdatePaymentPlanPendingType = 'PAYMENT_PLAN_UPDATE_PENDING';
type UpdatePaymentPlanSuccessType = 'PAYMENT_PLAN_UPDATE_SUCCESS';
type UpdatePaymentPlanErrorType = 'PAYMENT_PLAN_UPDATE_ERROR';

type GetPaymentPlanOptionsPendingType = 'PAYMENT_TYPE_OPTIONS_PENDING';
type GetPaymentPlanOptionsSuccessType = 'PAYMENT_TYPE_OPTIONS_SUCCESS';
type GetPaymentPlanOptionsErrorType = 'PAYMENT_TYPE_OPTIONS_ERROR';

/*
 * PAYMENT_PLAN_LIST
 */
export const PAYMENT_PLAN_LIST: {
  PENDING: GetPaymentPlanListPendingType,
  SUCCESS: GetPaymentPlanListSuccessType,
  ERROR: GetPaymentPlanListErrorType,
} = {
  PENDING: 'PAYMENT_PLAN_ALL_PENDING',
  SUCCESS: 'PAYMENT_PLAN_ALL_SUCCESS',
  ERROR: 'PAYMENT_PLAN_ALL_ERROR',
};

type GetPaymentPlanListPending = {
  type: GetPaymentPlanListPendingType,
};

type GetPaymentPlanListSuccess = {
  type: GetPaymentPlanListSuccessType,
  payload: {
    data: Object,
  },
};

type GetPaymentPlanListError = {
  type: GetPaymentPlanListErrorType,
};

/*
 * PAYMENT_PLAN_UPDATE
 */
export const PAYMENT_PLAN_UPDATE: {
  PENDING: UpdatePaymentPlanPendingType,
  SUCCESS: UpdatePaymentPlanSuccessType,
  ERROR: UpdatePaymentPlanErrorType,
} = {
  PENDING: 'PAYMENT_PLAN_UPDATE_PENDING',
  SUCCESS: 'PAYMENT_PLAN_UPDATE_SUCCESS',
  ERROR: 'PAYMENT_PLAN_UPDATE_ERROR',
};

type UpdatePaymentPlanPending = {
  type: UpdatePaymentPlanPendingType,
};

type UpdatePaymentPlanSuccess = {
  type: UpdatePaymentPlanSuccessType,
  payload: {
    data: Object,
  },
};

type UpdatePaymentPlanError = {
  type: UpdatePaymentPlanErrorType,
};

/*
 * PAYMENT_OPTIONS
 */
export const PAYMENT_OPTIONS: {
  PENDING: GetPaymentPlanOptionsPendingType,
  SUCCESS: GetPaymentPlanOptionsSuccessType,
  ERROR: GetPaymentPlanOptionsErrorType,
} = {
  PENDING: 'PAYMENT_TYPE_OPTIONS_PENDING',
  SUCCESS: 'PAYMENT_TYPE_OPTIONS_SUCCESS',
  ERROR: 'PAYMENT_TYPE_OPTIONS_ERROR',
};

type GetPaymentPlanOptionsPending = {
  type: GetPaymentPlanOptionsPendingType,
};

export type PaymentOptions = {
  currency: string,
  cycle: string,
  name: string,
  planId: string,
  value: number,
};
type GetPaymentPlanOptionsSuccess = {
  type: GetPaymentPlanOptionsSuccessType,
  payload: {
    data: Array<PaymentOptions>,
  },
};

type GetPaymentPlanOptionsError = {
  type: GetPaymentPlanOptionsErrorType,
};

type Action =
  | GetPaymentPlanListPending
  | GetPaymentPlanListSuccess
  | GetPaymentPlanListError
  | UpdatePaymentPlanPending
  | UpdatePaymentPlanSuccess
  | UpdatePaymentPlanError
  | GetPaymentPlanOptionsPending
  | GetPaymentPlanOptionsSuccess
  | GetPaymentPlanOptionsError;

export type PaymentPlanState = {
  list: RequestStatus & { data: Array<Object> },
  options: RequestStatus & { data: Array<PaymentOptions> },
  update: RequestStatus,
};

const DEFAULT_STATE: PaymentPlanState = {
  list: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    data: [],
  },
  options: {
    data: [],
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
  },
  update: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
  },
};

export default function reducer(
  state: PaymentPlanState = DEFAULT_STATE,
  action: Action
) {
  switch (action.type) {
    case PAYMENT_PLAN_LIST.PENDING:
      return {
        ...state,
        list: {
          ...DEFAULT_STATE.list,
          loading: true,
        },
      };
    case PAYMENT_PLAN_LIST.ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success: false,
          error: true,
        },
      };
    case PAYMENT_PLAN_LIST.SUCCESS:
      return {
        ...state,
        list: {
          ...DEFAULT_STATE.list,
          success: true,
          data: [...action.payload.data],
        },
      };
    case PAYMENT_PLAN_UPDATE.PENDING:
      return {
        ...state,
        update: {
          ...DEFAULT_STATE.update,
          loading: true,
        },
      };
    case PAYMENT_PLAN_UPDATE.ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: false,
          error: true,
        },
      };
    case PAYMENT_PLAN_UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...DEFAULT_STATE.update,
          success: true,
        },
      };
    case PAYMENT_OPTIONS.PENDING:
      return {
        ...state,
        options: {
          ...DEFAULT_STATE.options,
          loading: true,
        },
      };
    case PAYMENT_OPTIONS.ERROR:
      return {
        ...state,
        options: {
          ...state.options,
          loading: false,
          success: false,
          error: true,
        },
      };
    case PAYMENT_OPTIONS.SUCCESS: {
      const data =
        action.payload.data.length > 1
          ? sortBy(action.payload.data, p =>
              (p.name + p.value).toLowerCase()
            ).map(p => convertCentsWorth(false, p))
          : action.payload.data;

      return {
        ...state,
        options: {
          ...DEFAULT_STATE.options,
          data,
          success: true,
        },
      };
    }
    default:
      return state;
  }
}

type AccountParams = {
  accountId: number,
  channelId: number,
  businessId: number,
};
/**
 * Action to get the payment plan options
 */
export type GetPaymentPlanOptions = (param: AccountParams) => Function;
export function getPaymentPlanOptions(param: AccountParams): Function {
  const entity = getEntity(param);
  const promiseReq = PaymentPlanAPI.getPaymentPlanOptions(
    entity.id,
    entity.typeId
  );
  const { PENDING, SUCCESS, ERROR } = PAYMENT_OPTIONS;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to get the payment plan list
 */
export type GetPaymentPlanList = (
  accountsData: AccountParams,
  pageSize: number,
  startIndex: number
) => Function;
export function getPaymentPlanList(
  accountsData: AccountParams,
  pageSize: number = 10,
  startIndex: number = 0
) {
  const entity = getEntity(accountsData);
  const promiseReq = PaymentPlanAPI.getPaymentPlanList(
    entity.id,
    entity.typeId,
    pageSize,
    startIndex
  );
  const { PENDING, SUCCESS, ERROR } = PAYMENT_PLAN_LIST;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to update payment plan
 */
type UpdateParams = {
  autoCapTime: string,
  name: string,
  planTrackId: string,
  status: string,
  value: string,
};
export type UpdatePaymentPlan = (
  payplanId: string,
  param: UpdateParams
) => Function;
export function updatePaymentPlan(payplanId: string, data: UpdateParams) {
  const promiseReq = PaymentPlanAPI.updatePaymentPlan(payplanId, data);
  const { PENDING, SUCCESS, ERROR } = PAYMENT_PLAN_UPDATE;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}
