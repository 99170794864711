import { colors } from 'styles/common.sc';
import styled from 'react-emotion';

export const Container = styled('div')`
  margin-top: 36px;

  .login-password {
    .ant-input-suffix {
      padding: 0 20px;

      &:before {
        content: '';
        position: absolute;
        top: 27%;
        left: 0;
        width: 1px;
        height: 50%;
        background: ${colors.athensGray};
      }
    }

    .ant-input-affix-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #ffffff;
      border: solid 1px ${colors.border.default};
      box-shadow: 0 1px 3px 0 rgba(123, 141, 147, 0.15);
      border-radius: 3px;

      .ant-input {
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0px;
        flex: 1;

        &:not(:last-child) {
          padding-right: 16px;
        }

        &:not(:first-child) {
          padding-left: 16px;
        }
      }

      .ant-input-prefix,
      .ant-input-suffix {
        position: relative;
      }
    }

    .has-error {
      .ant-input-affix-wrapper {
        border-color: ${colors.border.error};
        box-shadow: 0 1px 3px 0 rgba(233, 109, 109, 0.45);

        .ant-input,
        .ant-input:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
`;
