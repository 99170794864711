// @flow
import * as React from 'react';
import { render } from 'react-dom';
import { unregister } from './registerServiceWorker';
import { setupGoogleAnalytics } from 'services/analytics';
import consoleWarning from './utils/consoleWarning';
import './polyfills';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// App
import App from './App';

const doc: any = document;
const rootNode: HTMLElement = doc.getElementById('root');

// Setup Google Analytics
if (process.env.NODE_ENV !== 'test') {
  setupGoogleAnalytics();
}

// Remove service worker
unregister();

// Render App
render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootNode
);

const globalModule: any = module;
if (globalModule.hot) {
  globalModule.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(
      <Provider store={store}>
        <NextApp />
      </Provider>,
      rootNode
    );
  });
}

// Show Warning message when user opens console in production app
if (process.env.NODE_ENV === 'production') {
  consoleWarning();
}
