// @flow
import { ajxPromise } from 'services/appRequest';
import { validateParams } from 'utils/api.util';
import axios from 'services/axios';
import { saveAs } from 'browser-filesaver';

/**
 * Get the list of certificates
 */
export const getCertificates = (accountId: number, businessId: number) => {
  const errorList = validateParams({ accountId, businessId }, ['accountId', 'businessId']);
  if (errorList.length > 0) {
    return Promise.reject(`ApplePay.getCertificates() is missing {${errorList}} param(s)`);
  }
  return ajxPromise.get(
    `/accounts/${accountId}/businesses/${businessId}/business-settings/apple-pay/certificates`
  );
};

/**
 * Upload new certificate
 */
export const uploadCertificate = (
  accountId: number,
  businessId: number,
  certificate: Object, // type later
  onUploadProgress: (progressEvent: ProgressEvent) => void
) => {
  const errorList = validateParams({ accountId, businessId, certificate }, [
    'accountId',
    'businessId',
    'certificate'
  ]);

  if (errorList.length > 0) {
    return Promise.reject(`ApplePay.uploadCertificate() is missing {${errorList}} param(s)`);
  }

  const data = new FormData();
  data.append('file', certificate);

  return axios.post(
    `accounts/${accountId}/businesses/${businessId}/business-settings/apple-pay/upload-certificate`,
    data,
    {
      onUploadProgress: progressEvent => {
        onUploadProgress(progressEvent);
      }
    }
  );
};

export const downloadCertificateRequest = (
  accountId: number,
  businessId: number,
  businessName: string
) => {
  const errorList = validateParams({ accountId, businessId, businessName }, [
    'accountId',
    'businessId',
    'businessName'
  ]);

  if (errorList.length > 0) {
    return Promise.reject(
      `ApplePay.downloadCertificateRequest() is missing {${errorList}} param(s)`
    );
  }

  ajxPromise
    .get(
      `/accounts/${accountId}/businesses/${businessId}/business-settings/apple-pay/signing-request`
    )
    .then(response => {
      const blob = new Blob([response.data], {
        type: 'application/octet-stream'
      });
      saveAs(blob, `${businessName}.csr`);
    });
};
