// @flow
type AppSetActiveRowType = 'SET_ACTIVE_ROW';
type AppPushRowDetailsStackType = 'PUSH_ROW_DETAILS_STACK';
type AppClearRowDetailsStackType = 'CLEAR_ROW_DETAILS_STACK';
type AppSliceRowDetailsType = 'SLICE_ROW_DETAILS';
type AppCollapseSidebarType = 'COLLAPSE_SIDEBAR';

type TableSelectedRowsType = 'TABLE_SELECTED_ROWS';
type TableSearchType = 'TABLE_SEARCH';
type TableSortType = 'TABLE_SORT';
type TablePaginationType = 'TABLE_PAGINATION';
type TableFilterType = 'TABLE_FILTER';
type TableClearFilterType = 'TABLE_CLEAR_FILTER';
type TableResetSelectionType = 'RESET_SELECTION';
type TableResetType = 'TABLE_RESET';

export type Sort = {
  sortColumn: string,
  sortOrder: string,
};

export type Pagination = {
  pageSize: number,
  startIndex: number,
};

export type Filter = {
  action: string,
  changed: boolean,
  field: string,
  filterIndex: number,
  operator: string,
  type: string,
  value: string,
};

export type TableOptions = {
  search: string,
  sort: Sort,
  filter: Array<Filter>,
  pagination: Pagination,
};

type AppContentWrapScrollAction = {
  type: AppContentWrapScrollType,
};
type AppSetActiveRowAction = {
  type: AppSetActiveRowType,
  activeRow: any,
};
type AppPushRowDetailsStackAction = {
  type: AppPushRowDetailsStackType,
  rowData: any,
};
type AppClearRowDetailsStackAction = {
  type: AppClearRowDetailsStackType,
};
type AppSliceRowDetailsAction = {
  type: AppSliceRowDetailsType,
  rowData: any,
};
type AppCollapseSidebarAction = {
  type: AppCollapseSidebarType,
  collapse: boolean,
};
type TableSelectedRowsAction = {
  type: TableSelectedRowsType,
  options: Array<any>,
};
type TableSearchAction = {
  type: TableSearchType,
  search: string,
};
type TableSortAction = {
  type: TableSortType,
  options: Sort | {},
};
type TablePaginationAction = {
  type: TablePaginationType,
  pagination: Pagination,
};
type TableFilterAction = {
  type: TableFilterType,
  filter: Array<Filter>,
};
type TableClearFilterAction = {
  type: TableClearFilterType,
};
type TableResetSelectionAction = {
  type: TableResetSelectionType,
  options: TableOptions | {},
};
type TableResetAction = {
  type: TableResetType,
  options: TableOptions | {},
};

export const APP: {
  SET_ACTIVE_ROW: AppSetActiveRowType,
  PUSH_ROW_DETAILS_STACK: AppPushRowDetailsStackType,
  CLEAR_ROW_DETAILS_STACK: AppClearRowDetailsStackType,
  SLICE_ROW_DETAILS: AppSliceRowDetailsType,
  COLLAPSE_SIDEBAR: AppCollapseSidebarType,
} = {
  SET_ACTIVE_ROW: 'SET_ACTIVE_ROW',
  PUSH_ROW_DETAILS_STACK: 'PUSH_ROW_DETAILS_STACK',
  CLEAR_ROW_DETAILS_STACK: 'CLEAR_ROW_DETAILS_STACK',
  SLICE_ROW_DETAILS: 'SLICE_ROW_DETAILS',
  COLLAPSE_SIDEBAR: 'COLLAPSE_SIDEBAR',
};

export const TABLE: {
  SELECTED_ROWS: TableSelectedRowsType,
  SEARCH: TableSearchType,
  SORT: TableSortType,
  PAGINATION: TablePaginationType,
  FILTER: TableFilterType,
  CLEAR_FILTER: TableClearFilterType,
  RESET_SELECTION: TableResetSelectionType,
  RESET: TableResetType,
} = {
  SELECTED_ROWS: 'TABLE_SELECTED_ROWS',
  SEARCH: 'TABLE_SEARCH',
  SORT: 'TABLE_SORT',
  PAGINATION: 'TABLE_PAGINATION',
  FILTER: 'TABLE_FILTER',
  CLEAR_FILTER: 'TABLE_CLEAR_FILTER',
  RESET_OPTIONS: 'RESET_OPTIONS',
  RESET_FILTER: 'RESET_FILTER',
  RESET_SELECTION: 'RESET_SELECTION',
  RESET: 'TABLE_RESET',
};

export type UIState = {
  isSidebarCollapsed: boolean,
  tableOptions: TableOptions,
  selectedRows: Array<Object>,
  rowDetailStack: Array<Object>,
  activeRow: Object,
};

const DEFAULT_STATE: UIState = {
  isSidebarCollapsed: false,
  tableOptions: {
    search: '',
    sort: {
      sortColumn: 'timestamp',
      sortOrder: 'desc',
    },
    filter: [],
    pagination: {
      pageSize: 25,
      startIndex: 0,
    },
  },
  selectedRows: [],
  rowDetailStack: [],
  activeRow: {},
};

type Action =
  | AppSetActiveRowAction
  | AppPushRowDetailsStackAction
  | AppClearRowDetailsStackAction
  | AppSliceRowDetailsAction
  | AppCollapseSidebarAction
  | TableSelectedRowsAction
  | TableSearchAction
  | TableSortAction
  | TablePaginationAction
  | TableFilterAction
  | TableClearFilterAction
  | TableResetSelectionAction
  | TableResetAction
  | AppContentWrapScrollAction;
/**
 * Reducer
 */
export default function reducer(
  state: UIState = DEFAULT_STATE,
  action: Action
) {
  switch (action.type) {
    /**
     * Set Table active row
     */
    case APP.COLLAPSE_SIDEBAR:
      return {
        ...state,
        isSidebarCollapsed: action.collapse,
      };
    /**
     * Set Table active row
     */
    case APP.SET_ACTIVE_ROW:
      return {
        ...state,
        activeRow: action.activeRow,
      };
    /**
     * Set Row Details
     */
    case APP.PUSH_ROW_DETAILS_STACK: {
      const rowData = action.rowData;
      let activeRow = { ...state.activeRow };
      let rowDetailStack = [...state.rowDetailStack];
      const rowIndex = rowDetailStack.findIndex(data => {
        return data.id === rowData.id && data.type === rowData.type;
      });

      if (
        rowDetailStack.length >= 2 &&
        matchesPrevRow(rowDetailStack, rowData)
      ) {
        rowDetailStack.splice(rowDetailStack.length - 1, 1);
      } else if (rowIndex === -1) {
        rowDetailStack.push(rowData);
      } else if (rowDetailStack.length >= 2 && rowIndex === 0) {
        rowDetailStack = [rowData];
      }

      return {
        ...state,
        rowDetailStack,
        activeRow,
      };
    }
    /**
     * Clear Single Row Details
     */
    case APP.SLICE_ROW_DETAILS: {
      const rowData = action.rowData;
      const rowDetailStack: Array<Object> = [...state.rowDetailStack].filter(
        data => data.id !== rowData.id
      );
      return {
        ...state,
        rowDetailStack,
      };
    }
    /**
     * Clear All Row Details
     */
    case APP.CLEAR_ROW_DETAILS_STACK:
      return {
        ...state,
        rowDetailStack: [],
      };
    /**
     * Set Selected rows
     */
    case TABLE.SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [...action.options],
      };
    /**
     * Set pagination
     */
    case TABLE.PAGINATION:
      return {
        ...state,
        tableOptions: {
          ...state.tableOptions,
          pagination: { ...action.pagination },
        },
      };
    /**
     * Set table search
     */
    case TABLE.SEARCH:
      return {
        ...state,
        tableOptions: {
          ...state.tableOptions,
          pagination: {
            ...state.tableOptions.pagination,
            startIndex: 0,
          },
          search: action.search,
        },
      };
    /**
     * Set table filter
     */
    case TABLE.FILTER:
      return {
        ...state,
        tableOptions: {
          ...state.tableOptions,
          filter: [...action.filter],
        },
      };
    /**
     * Clear table filter
     */
    case TABLE.CLEAR_FILTER:
      return {
        ...state,
        tableOptions: {
          ...state.tableOptions,
          filter: [],
        },
      };
    /**
     * Set table sort
     */
    case TABLE.SORT:
      return {
        ...state,
        tableOptions: {
          ...state.tableOptions,
          sort: { ...action.options },
        },
      };
    /**
     * Reset Table Row Selection
     */
    case TABLE.RESET_SELECTION:
      return {
        ...state,
        selectedRows: [],
        activeRow: {},
      };
    /**
     * Reset table
     */
    case TABLE.RESET:
      return {
        ...state,
        selectedRows: [],
        activeRow: {},
        tableOptions: {
          ...DEFAULT_STATE.tableOptions,
          ...action.options,
        },
      };
    default:
      return state;
  }
}

export type SetTableRow = (options?: Array<Object>) => TableSelectedRowsAction;
export function setTableRow(
  options?: Array<Object> = []
): TableSelectedRowsAction {
  return {
    type: TABLE.SELECTED_ROWS,
    options,
  };
}

export type SetTableSearch = (search: string) => TableSearchAction;
export function setTableSearch(search: string = ''): TableSearchAction {
  return {
    type: TABLE.SEARCH,
    search,
  };
}

export type SetTableFilter = (filter: Array<Filter>) => TableFilterAction;
export function setTableFilter(filter: Array<Filter> = []): TableFilterAction {
  return {
    type: TABLE.FILTER,
    filter,
  };
}

export type SetTableSort = (options?: Sort | {}) => TableSortAction;
export function setTableSort(options?: Sort | {} = {}): TableSortAction {
  return {
    type: TABLE.SORT,
    options,
  };
}

export type SetTablePagination = (
  pagination: Pagination
) => TablePaginationAction;
export function setTablePagination(
  pagination: Pagination
): TablePaginationAction {
  return {
    type: TABLE.PAGINATION,
    pagination: {
      pageSize: pagination.pageSize > 250 ? 250 : pagination.pageSize,
      startIndex: pagination.startIndex,
    },
  };
}

export type ClearTableFilter = () => TableClearFilterAction;
export function clearTableFilter(): TableClearFilterAction {
  return {
    type: TABLE.CLEAR_FILTER,
  };
}

export type ResetTable = (options?: TableOptions | {}) => TableResetAction;
export function resetTable(options?: TableOptions | {} = {}): TableResetAction {
  return {
    type: TABLE.RESET,
    options,
  };
}

export type ResetTableSelections = (
  options?: TableOptions | {}
) => TableResetSelectionAction;
export function resetTableSelections(
  options?: TableOptions | {} = {}
): TableResetSelectionAction {
  return {
    type: TABLE.RESET_SELECTION,
    options,
  };
}
export type SetActiveRow = (activeRow?: Object) => AppSetActiveRowAction;
export function setActiveRow(activeRow?: Object = {}): AppSetActiveRowAction {
  return {
    type: APP.SET_ACTIVE_ROW,
    activeRow,
  };
}

export type PushToRowDetailStack = (
  rowData: Object
) => AppPushRowDetailsStackAction;
export function pushToRowDetailStack(
  rowData: Object
): AppPushRowDetailsStackAction {
  return {
    type: APP.PUSH_ROW_DETAILS_STACK,
    rowData,
  };
}

export type ClearRowDetailStack = () => AppClearRowDetailsStackAction;
export function clearRowDetailStack(): AppClearRowDetailsStackAction {
  return {
    type: APP.CLEAR_ROW_DETAILS_STACK,
  };
}

export type SliceRowDetailStack = (rowData: Object) => AppSliceRowDetailsAction;
export function sliceRowDetailStack(rowData: Object): AppSliceRowDetailsAction {
  return {
    type: APP.SLICE_ROW_DETAILS,
    rowData,
  };
}

export type CollapseSidebar = (collapse: boolean) => AppCollapseSidebarAction;
export function collapseSidebar(
  collapse: boolean = false
): AppCollapseSidebarAction {
  return {
    type: APP.COLLAPSE_SIDEBAR,
    collapse,
  };
}

function matchesPrevRow(activeRows: Array<Object>, rowData: Object) {
  const lastActiveRow = activeRows[activeRows.length - 2];
  return !!(
    lastActiveRow.id === rowData.id && lastActiveRow.type === rowData.type
  );
}
