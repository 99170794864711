// @flow
export type RefreshDataType = 'REFRESH_DATA';

export type RefreshDataAction = {
  type: RefreshDataType,
};

export const SHARED_ACTION_TYPES: {
  REFRESH_DATA: RefreshDataType,
} = {
  REFRESH_DATA: 'REFRESH_DATA',
};

export type RefreshData = () => RefreshDataAction;
export function refreshData(): RefreshDataAction {
  return {
    type: SHARED_ACTION_TYPES.REFRESH_DATA,
  };
}
