// @flow
import * as DisputesAPI from 'services/resources/disputes.api';
import { dispatchResponse } from 'utils/action.util';
import { SHARED_ACTION_TYPES } from 'store/sharedActions';

type AcceptDisputePendingAction = {
  type: AcceptDisputePendingType
};
type AcceptDisputeErrorAction = {
  type: AcceptDisputeErrorType
};
type AcceptDisputeSuccessAction = {
  type: AcceptDisputeSuccessType,
  payload: any
};

const ACCEPT_DISPUTE_TYPES: {
  PENDING: AcceptDisputePendingType,
  ERROR: AcceptDisputeErrorType,
  SUCCESS: AcceptDisputeSuccessType
} = {
  PENDING: 'ACCEPT_DISPUTE_PENDING',
  ERROR: 'ACCEPT_DISPUTE_ERROR',
  SUCCESS: 'ACCEPT_DISPUTE_SUCCESS'
};

export const GET_ALL_DISPUTES = {
  PENDING: 'GET_ALL_DISPUTES_PENDING',
  ERROR: 'GET_ALL_DISPUTES_ERROR',
  SUCCESS: 'GET_ALL_DISPUTES_SUCCESS'
};

export const GET_UNANSWERED_DISPUTES = {
  PENDING: 'GET_UNANSWERED_DISPUTES_PENDING',
  ERROR: 'GET_UNANSWERED_DISPUTES_ERROR',
  SUCCESS: 'GET_UNANSWERED_DISPUTES_SUCCESS'
};

export type Dispute = Object; // TODO add type later

export type AllDisputesState = {
  loading: boolean,
  error: boolean,
  data: Array,
  totalCount: number
};

type AllDisputesAction = {
  type: GET_ALL_DISPUTES | GET_UNANSWERED_DISPUTES,
  payload: {
    data: Array,
    totalCount: number
  }
};

const DEFAULT_STATE = {
  refresh: false,
  acceptDisputeState: {
    loading: false,
    error: false,
    success: false
  },
  allDisputeState: {
    loading: false,
    error: false,
    data: [],
    totalCount: 0
  },
  unansweredDisputeState: {
    loading: false,
    error: false,
    data: [],
    totalCount: 0
  }
};

type Action =
  | AcceptDisputePendingAction
  | AcceptDisputeErrorAction
  | AcceptDisputeSuccessAction
  | AllDisputesAction;

export default function reducer(state: DisputesState = DEFAULT_STATE, action: Action) {
  switch (action.type) {
    case ACCEPT_DISPUTE_TYPES.PENDING:
      return {
        ...state,
        acceptDisputeState: {
          ...DEFAULT_STATE.acceptDisputeState,
          loading: true
        }
      };
    case ACCEPT_DISPUTE_TYPES.ERROR:
      return {
        ...state,
        acceptDisputeState: {
          ...DEFAULT_STATE.acceptDisputeState,
          error: true
        }
      };
    case ACCEPT_DISPUTE_TYPES.SUCCESS:
      return {
        ...state,
        acceptDisputeState: {
          ...DEFAULT_STATE.acceptDisputeState,
          success: true
        }
      };
    case GET_ALL_DISPUTES.PENDING:
      return {
        ...state,
        refresh: false,
        allDisputeState: {
          ...state.allDisputeState,
          loading: true
        }
      };
    case GET_ALL_DISPUTES.SUCCESS:
      return {
        ...state,
        refresh: false,
        allDisputeState: {
          loading: false,
          error: false,
          data: action.payload.data,
          totalCount: action.payload.totalCount
        }
      };
    case GET_ALL_DISPUTES.ERROR:
      return {
        ...state,
        refresh: false,
        allDisputeState: {
          ...state.allDisputeState,
          loading: false,
          error: true
        }
      };
    case GET_UNANSWERED_DISPUTES.PENDING:
      return {
        ...state,
        refresh: false,
        unansweredDisputeState: {
          ...state.unansweredDisputeState,
          loading: true
        }
      };
    case GET_UNANSWERED_DISPUTES.SUCCESS:
      return {
        ...state,
        refresh: false,
        unansweredDisputeState: {
          loading: false,
          error: false,
          data: action.payload.data,
          totalCount: action.payload.totalCount
        }
      };
    case GET_UNANSWERED_DISPUTES.ERROR:
      return {
        ...state,
        refresh: false,
        unansweredDisputeState: {
          ...state.unansweredDisputeState,
          loading: false,
          error: true
        }
      };
    case SHARED_ACTION_TYPES.REFRESH_DATA:
      return {
        ...state,
        refresh: true
      };

    default:
      return state;
  }
}

export type AcceptDispute = (number, string) => Function;
export function acceptDispute(businessId: number, disputeId: string) {
  const { PENDING, ERROR, SUCCESS } = ACCEPT_DISPUTE_TYPES;
  const request = DisputesAPI.acceptDispute(businessId, disputeId);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, true, true);
}

export const getDisputes = (action: AllDisputesAction) => {
  const { type, payload } = action;
  return {
    type,
    payload
  };
};