import * as React from 'react';
import { isUndefined } from 'utils';

import CkoIcon from 'components/ui/icon/';
import CkoTooltip from 'components/ui/tooltip';
import * as Styled from './CkoButton.sc';

type Props = {|
  id?: string,
  htmlType?: string,
  type?: string,
  dataIndex?: any,
  size?: string,
  link?: string,
  icon?: string,
  iconRight?: string,
  state?: string,
  disabled?: boolean,
  tooltip?: string,
  tooltipPlacement?: string,
  tooltipWidth?: string,
  className?: string,
  block?: boolean,
  value?: string,
  children?: React.Node,
  onClick: Function,
  target?: string,
  trackOptions?: {
    category: string,
    label: string
  },
  prefix?: any,
  suffix?: any
|};

export default class CkoButton extends React.PureComponent<Props> {
  static defaultProps = {
    onClick: () => {}
  };

  handleClick = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onClick(this.props.dataIndex);
  };

  goToLink = () => {
    window.open(this.props.link, this.props.target || '_blank');
  };

  renderButton() {
    const {
      value,
      children,
      icon,
      iconRight,
      prefix = false,
      suffix = false,
      className = ''
    } = this.props;

    const btnProps = {
      ...this.props,
      iconButton: !!(!value && icon),
      iconRight: !!iconRight,
      className: `cko-button ${className}`
    };

    if (!isUndefined(this.props.link)) {
      btnProps.onClick = this.goToLink;
    } else if (!isUndefined(this.props.dataIndex) && !this.props.disabled) {
      btnProps.onClick = this.handleClick;
    }

    if (prefix || suffix) {
      return (
        <Styled.Container {...btnProps}>
          {prefix}
          {value && <span className="cko-button-value">{value}</span>}
          {children && children}
          {suffix}
        </Styled.Container>
      );
    }

    return (
      <Styled.Container {...btnProps}>
        {icon && <CkoIcon name={icon} width="15px" />}
        {value && <span className="cko-button-value">{value}</span>}
        {children && children}
        {iconRight && <CkoIcon name={iconRight} width="15px" />}
      </Styled.Container>
    );
  }

  render() {
    if (this.props.disabled && this.props.tooltip) {
      return (
        <CkoTooltip
          title={this.props.tooltip}
          primary
          placement={this.props.tooltipPlacement || 'right'}
          width={this.props.tooltipWidth || '300px'}
        >
          {this.renderButton()}
        </CkoTooltip>
      );
    }

    return this.renderButton();
  }
}
