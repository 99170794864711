// @flow
import * as SettingsAPI from 'services/resources/settings.api';
import { dispatchResponse } from 'utils/action.util';
import type { RequestStatus } from '../store.types';

export type Webhooks = {
  id?: string,
  isActive: boolean,
  isLive: boolean,
  events: Array<string>,
  url: string,
  authorisationKey: string,
};

/**
 * Create Channel urls
 */
type CreateChannelUrlsPendingType = 'CREATE_CHANNEL_PENDING';
type CreateChannelUrlsErrorType = 'CREATE_CHANNEL_ERROR';
type CreateChannelUrlsSuccessType = 'CREATE_CHANNEL_SUCCESS';
export const CREATE_CHANNEL: {
  PENDING: CreateChannelUrlsPendingType,
  SUCCESS: CreateChannelUrlsSuccessType,
  ERROR: CreateChannelUrlsErrorType,
} = {
  PENDING: 'CREATE_CHANNEL_PENDING',
  SUCCESS: 'CREATE_CHANNEL_SUCCESS',
  ERROR: 'CREATE_CHANNEL_ERROR',
};
export type CreateChannelUrlsPending = {
  type: CreateChannelUrlsPendingType,
};

export type CreateChannelUrlsSuccess = {
  type: CreateChannelUrlsSuccessType,
  payload: {
    data: Object,
  },
};

export type CreateChannelUrlsError = {
  type: CreateChannelUrlsErrorType,
  payload: {
    data: Object,
  },
};

export type CreateUrlStatus = RequestStatus;

/**
 * Get Channel urls
 */
type GetChannelUrlsPendingType = 'GET_CHANNEL_URLS_PENDING';
type GetChannelUrlsErrorType = 'GET_CHANNEL_URLS_ERROR';
type GetChannelUrlsSuccessType = 'GET_CHANNEL_URLS_SUCCESS';
export const CHANNEL_URLS: {
  PENDING: GetChannelUrlsPendingType,
  SUCCESS: GetChannelUrlsSuccessType,
  ERROR: GetChannelUrlsErrorType,
} = {
  PENDING: 'GET_CHANNEL_URLS_PENDING',
  SUCCESS: 'GET_CHANNEL_URLS_SUCCESS',
  ERROR: 'GET_CHANNEL_URLS_ERROR',
};
export type GetChannelUrlsPending = {
  type: GetChannelUrlsPendingType,
};

export type GetChannelUrlsSuccess = {
  type: GetChannelUrlsSuccessType,
  payload: {
    data: {
      channelUrl: string,
      channelUrlId: number,
      feedUrl: string,
      successfulTransactionUrl: string,
      unsuccessfulTransactionUrl: string,
      uwReviewed: boolean,
      webhooks: Array<Webhooks>,
    },
  },
};

export type GetChannelUrlsError = {
  type: GetChannelUrlsErrorType,
};

/**
 * Business and ChannelID
 */
type BusinessIdActionType = 'SET_CHANNEL_SETTING_BUSINESS_ID';
export const SET_BUSINESS_ID: BusinessIdActionType =
  'SET_CHANNEL_SETTING_BUSINESS_ID';

export type SetBusinessIdType = {
  type: BusinessIdActionType,
  payload: {
    data: number,
  },
};

/**
 * Set channel
 */
type SetChannelActionType = 'SET_CHANNEL_SETTINGS_CHANNEL';
const SET_CHANNEL: SetChannelActionType = 'SET_CHANNEL_SETTINGS_CHANNEL';
export type Channel = {
  businessId: number,
  id: number,
  name: string,
  creationDate: string,
  apiVersion: string,
};
export type SetChannelType = {
  type: SetChannelActionType,
  payload: {
    data: Channel,
  },
};

/**
 * Get API keys
 */
type GetApiKeysPendingType = 'CHANNEL_SETTINGS_API_KEYS_PENDING';
type GetApiKeysErrorType = 'CHANNEL_SETTINGS_API_KEYS_ERROR';
type GetApiKeysSuccessType = 'CHANNEL_SETTINGS_API_KEYS_SUCCESS';
export const API_KEYS: {
  PENDING: GetApiKeysPendingType,
  SUCCESS: GetApiKeysSuccessType,
  ERROR: GetApiKeysErrorType,
} = {
  PENDING: 'CHANNEL_SETTINGS_API_KEYS_PENDING',
  SUCCESS: 'CHANNEL_SETTINGS_API_KEYS_SUCCESS',
  ERROR: 'CHANNEL_SETTINGS_API_KEYS_ERROR',
};

type ApiKeysPayloadData = {
  isLive: boolean,
  isVerified: boolean,
  lastUpdate: string,
  liveKeys: {
    publishableKey: string,
    secretKey: string,
  },
  testKeys: {
    publishableKey: string,
    secretKey: string,
  },
};
export type ApiKeysData = {
  +isLive: boolean,
  +isVerified: boolean,
  +lastUpdate: string,
  +publishableKey: string,
  +secretKey: string,
};
export type ApiKeys = RequestStatus & ApiKeysData;
export type GetApiKeysPending = {
  type: GetApiKeysPendingType,
};

export type GetApiKeysSuccess = {
  type: GetApiKeysSuccessType,
  payload: {
    data: ApiKeysPayloadData,
  },
};
export type GetApiKeysError = {
  type: GetApiKeysErrorType,
};

/**
 * Refresh API keys
 */
type RefreshApiKeyPendingType = 'SETTINGS_REFRESH_API_KEY_PENDING';
type RefreshApiKeyErrorType = 'SETTINGS_REFRESH_API_KEY_ERROR';
type RefreshApiKeySuccessType = 'SETTINGS_REFRESH_API_KEY_SUCCESS';
export const REFRESH_API_KEY: {
  PENDING: RefreshApiKeyPendingType,
  SUCCESS: RefreshApiKeySuccessType,
  ERROR: RefreshApiKeyErrorType,
} = {
  PENDING: 'SETTINGS_REFRESH_API_KEY_PENDING',
  SUCCESS: 'SETTINGS_REFRESH_API_KEY_SUCCESS',
  ERROR: 'SETTINGS_REFRESH_API_KEY_ERROR',
};
export type PublishableKeyType = 'PUBLISHABLEKEY';
export type SecretKeyType = 'SECRETKEY';
export const KEYS: {
  PUBLISHABLEKEY: PublishableKeyType,
  SECRETKEY: SecretKeyType,
} = {
  PUBLISHABLEKEY: 'PUBLISHABLEKEY',
  SECRETKEY: 'SECRETKEY',
};

export type RefreshApiKeyPending = {
  type: RefreshApiKeyPendingType,
};

export type RefreshApiKeySuccess = {
  type: RefreshApiKeySuccessType,
};
export type RefreshApiKeyError = {
  type: RefreshApiKeyErrorType,
  payload: {
    data: {
      message: string,
    },
  },
};

export type ChannelUrls = RequestStatus & {
  mainUrl: string,
  successUrl: string,
  errorUrl: string,
  feedUrl: string,
  id: number,
  umReviewed: boolean,
};

/**
 * Get Events
 */
type GetAllEventsPendingType = 'SETTINGS_WEBHOOK_EVENTS_PENDING';
type GetAllEventsErrorType = 'SETTINGS_WEBHOOK_EVENTS_ERROR';
type GetAllEventsSuccessType = 'SETTINGS_WEBHOOK_EVENTS_SUCCESS';
export const WEBHOOK_EVENTS: {
  PENDING: GetAllEventsPendingType,
  SUCCESS: GetAllEventsSuccessType,
  ERROR: GetAllEventsErrorType,
} = {
  PENDING: 'SETTINGS_WEBHOOK_EVENTS_PENDING',
  SUCCESS: 'SETTINGS_WEBHOOK_EVENTS_SUCCESS',
  ERROR: 'SETTINGS_WEBHOOK_EVENTS_ERROR',
};

export type EventsData = {
  description: string,
  isActive: boolean,
  name: string,
  version: string,
};

export type GetAllEventsPending = {
  type: GetAllEventsPendingType,
};

export type GetAllEventsSuccess = {
  type: GetAllEventsSuccessType,
  payload: {
    data: Array<EventsData>,
  },
};
export type GetAllEventsError = {
  type: GetAllEventsErrorType,
};

export type Events = RequestStatus & { list: Array<EventsData> };

/**
 * Update channel
 */
type UpdateChannelUrlPendingType = 'UPDATE_CHANNEL_URL_PENDING';
type UpdateChannelUrlErrorType = 'UPDATE_CHANNEL_URL_ERROR';
type UpdateChannelUrlSuccessType = 'UPDATE_CHANNEL_URL_SUCCESS';
export const UPDATE_CHANNEL_URL: {
  PENDING: UpdateChannelUrlPendingType,
  SUCCESS: UpdateChannelUrlSuccessType,
  ERROR: UpdateChannelUrlErrorType,
} = {
  PENDING: 'UPDATE_CHANNEL_URL_PENDING',
  SUCCESS: 'UPDATE_CHANNEL_URL_SUCCESS',
  ERROR: 'UPDATE_CHANNEL_URL_ERROR',
};

export type UpdateChannelUrlPending = {
  type: UpdateChannelUrlPendingType,
};

export type UpdateChannelUrlSuccess = {
  type: UpdateChannelUrlSuccessType,
  payload: {
    data: {
      channelUrl: string,
      channelUrlId: number,
      feedUrl: string,
      successfulTransactionUrl: string,
      unsuccessfulTransactionUrl: string,
      uwReviewed: boolean,
      webhooks: Array<Webhooks>,
    },
  },
};

export type UpdateChannelUrlError = {
  type: UpdateChannelUrlErrorType,
};

// Actions
type Action =
  | SetBusinessIdType
  | SetChannelType
  | GetApiKeysPending
  | GetApiKeysError
  | GetApiKeysSuccess
  | RefreshApiKeyPending
  | RefreshApiKeySuccess
  | RefreshApiKeyError
  | GetAllEventsPending
  | GetAllEventsError
  | GetAllEventsSuccess
  | UpdateChannelUrlPending
  | UpdateChannelUrlSuccess
  | UpdateChannelUrlError
  | GetChannelUrlsPending
  | GetChannelUrlsSuccess
  | GetChannelUrlsError
  | CreateChannelUrlsPending
  | CreateChannelUrlsSuccess
  | CreateChannelUrlsError;

export type ChannelSettingState = {
  +createUrlStatus: CreateUrlStatus,
  +channel: Channel,
  +apiKeys: ApiKeys,
  +refreshKey: RequestStatus,
  +channelUrls: ChannelUrls,
  +webhooks: Array<Webhooks>,
  +events: Events,
  +updateUrl: RequestStatus,
};

// State
export const DEFAULT_STATE: ChannelSettingState = {
  createUrlStatus: {
    loading: false,
    error: false,
    success: false,
    errorMessage: ''
  },
  channel: {
    businessId: 0,
    id: 0,
    name: '',
    creationDate: '',
    apiVersion: ''
  },
  apiKeys: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    isLive: false,
    isVerified: false,
    lastUpdate: '',
    publishableKey: '',
    secretKey: ''
  },
  refreshKey: {
    loading: false,
    error: false,
    success: false,
    errorMessage: ''
  },
  channelUrls: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    mainUrl: '',
    successUrl: '',
    errorUrl: '',
    feedUrl: '',
    id: 0,
    umReviewed: false
  },
  webhooks: [],
  events: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    list: []
  },
  updateUrl: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    status: null
  }
};

export default function reducer(
  state: ChannelSettingState = DEFAULT_STATE,
  action: Action
): ChannelSettingState {
  switch (action.type) {
    case SET_BUSINESS_ID:
      return {
        ...state,
        channel: {
          ...state.channel,
          businessId: action.payload.data
        },
        updateUrl: { ...DEFAULT_STATE.updateUrl }
      };
    case SET_CHANNEL:
      return {
        ...state,
        channel: {
          ...state.channel,
          ...action.payload.data
        }
      };
    case CHANNEL_URLS.PENDING:
      return {
        ...state,
        channelUrls: {
          ...DEFAULT_STATE.channelUrls,
          pending: true
        }
      };
    case CHANNEL_URLS.SUCCESS: {
      const channel = action.payload.data;
      return {
        ...state,
        channelUrls: {
          ...state.channelUrls,
          success: true,
          mainUrl: channel.channelUrl,
          successUrl: channel.successfulTransactionUrl,
          errorUrl: channel.unsuccessfulTransactionUrl,
          feedUrl: channel.feedUrl,
          id: channel.channelUrlId,
          umReviewed: channel.uwReviewed
        },
        webhooks: channel.webhooks
      };
    }
    case API_KEYS.PENDING:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          loading: true,
          error: false,
          success: false
        }
      };
    case API_KEYS.ERROR:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          loading: false,
          error: true,
          success: false
        }
      };
    case API_KEYS.SUCCESS: {
      const { liveKeys, testKeys, ...keysData } = action.payload.data;
      const apiKeyData = { ...keysData, publishableKey: '', secretKey: '' };
      apiKeyData.publishableKey = keysData.isLive
        ? liveKeys.publishableKey
        : testKeys.publishableKey;
      apiKeyData.secretKey = keysData.isLive ? liveKeys.secretKey : testKeys.secretKey;
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          ...apiKeyData,
          loading: false,
          error: false,
          success: true
        }
      };
    }
    case REFRESH_API_KEY.PENDING:
      return {
        ...state,
        refreshKey: {
          ...state.refreshKey,
          loading: true,
          error: false,
          success: false
        }
      };
    case REFRESH_API_KEY.ERROR:
      return {
        ...state,
        refreshKey: {
          ...state.refreshKey,
          loading: false,
          error: true,
          success: false,
          errorMessage: action.payload.data.message
        }
      };
    case REFRESH_API_KEY.SUCCESS:
      return {
        ...state,
        refreshKey: {
          ...state.refreshKey,
          loading: false,
          error: false,
          success: true
        }
      };
    case WEBHOOK_EVENTS.PENDING:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
          error: false,
          success: false
        }
      };
    case WEBHOOK_EVENTS.ERROR:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          error: true,
          success: false
        }
      };
    case WEBHOOK_EVENTS.SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          list: [...action.payload.data],
          loading: false,
          error: false,
          success: true
        }
      };
    case UPDATE_CHANNEL_URL.PENDING:
      return {
        ...state,
        updateUrl: {
          ...state.updateUrl,
          loading: true,
          error: false,
          success: false,
          status: null
        }
      };
    case UPDATE_CHANNEL_URL.SUCCESS: {
      const { data, status } = action.payload;
      return {
        ...state,
        updateUrl: {
          ...state.updateUrl,
          loading: false,
          error: false,
          success: true,
          status
        },
        channelUrls: {
          ...state.channelUrls,
          mainUrl: data.channelUrl,
          successUrl: data.successfulTransactionUrl,
          errorUrl: data.unsuccessfulTransactionUrl
        },
        webhooks: [...data.webhooks]
      };
    }
    case UPDATE_CHANNEL_URL.ERROR:
      const { data, status } = action.payload;
      return {
        ...state,
        updateUrl: {
          ...state.updateUrl,
          loading: false,
          error: true,
          success: false,
          status
        }
      };
    case CREATE_CHANNEL.PENDING:
      return {
        ...state,
        createUrlStatus: {
          ...DEFAULT_STATE.createUrlStatus,
          loading: true
        }
      };
    case CREATE_CHANNEL.SUCCESS:
      return {
        ...state,
        createUrlStatus: {
          ...state.createUrlStatus,
          loading: false,
          success: true
        }
      };
    case CREATE_CHANNEL.ERROR:
      return {
        ...state,
        createUrlStatus: {
          ...DEFAULT_STATE.createUrlStatus,
          error: true,
          errorMessage: action.payload.data.message
        }
      };
    default:
      return state;
  }
}

type SelectorParams = {
  accountId: number,
  businessId: number,
  channelId: number
};

/**
 * Action to set channel
 */
export type SetBusinessId = (businessId: number) => SetBusinessIdType;
export function setBusinessId(businessId: number): SetBusinessIdType {
  return {
    type: SET_BUSINESS_ID,
    payload: {
      data: businessId
    }
  };
}

/**
 * Action to set channel
 */
export const setChannelData = (data: Channel): Function => {
  return (dispatch: Function) => {
    dispatch({ type: SET_CHANNEL, payload: { data } });
  };
};

export type GetChannelUrls = (params: SelectorParams) => Function;
export function getChannelUrls(params: SelectorParams): Function {
  const { PENDING, SUCCESS, ERROR } = CHANNEL_URLS;
  const promiseReq = SettingsAPI.getChannelUrls(params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to get the channel api keys
 */
export type GetApiKeys = (params: SelectorParams) => Function;
export function getApiKeys(params: SelectorParams): Function {
  const { PENDING, SUCCESS, ERROR } = API_KEYS;
  const promiseReq = SettingsAPI.getApiKeys(params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to refresh the channel api key
 */
type RefreshApiKeyParams = SelectorParams & {
  keyType: {
    testKey?: PublishableKeyType | SecretKeyType,
    liveKey?: PublishableKeyType | SecretKeyType
  },
  password: string
};
export type RefreshApiKey = (params: RefreshApiKeyParams) => Function;
export function refreshApiKey(params: RefreshApiKeyParams): Function {
  const { PENDING, SUCCESS, ERROR } = REFRESH_API_KEY;
  const promiseReq = SettingsAPI.refreshApiKey(params);
  return dispatchResponse(promiseReq, PENDING, SUCCESS, ERROR);
}

/**
 * Action to refresh the channel api key
 */
type GetAllEventsParam = SelectorParams & {
  apiVersion: string
};
export type GetAllEvents = (params: GetAllEventsParam) => Function;
export function getAllEvents(params: GetAllEventsParam): Function {
  const { PENDING, SUCCESS, ERROR } = WEBHOOK_EVENTS;
  const promiseReq = SettingsAPI.getAllEvents(params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS, params }, ERROR);
}

/**
 * Action to update the channel api key
 */
export type UpdateChannelUrlParam = SelectorParams & {
  urlData: {
    channelUrl: string,
    channelUrlId: number,
    feedUrl: string,
    successfulTransactionUrl: string,
    unsuccessfulTransactionUrl: string,
    uwReviewed: boolean,
    webhooks: Array<Webhooks>
  }
};
export type UpdateChannelUrl = (params: UpdateChannelUrlParam) => Function;
export function updateChannelUrl(params: UpdateChannelUrlParam): Function {
  const { PENDING, SUCCESS, ERROR } = UPDATE_CHANNEL_URL;
  const promiseReq = SettingsAPI.updateChannelUrl(params);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type SaveChannelUrl = (params: UpdateChannelUrlParam, webhookId: string, apiVersion: string, values: Object) => Function;
export function saveChannelUrl(params: UpdateChannelUrlParam, webhookId: string, apiVersion: string, values: Object) {
  const { events } = values;
  const url = `${values.protocol}${values.url}`;
  if (webhookId) {
    params.urlData.webhooks = [...params.urlData.webhooks].map(webhook => {
      if (webhook.id !== webhookId) {
        return webhook;
      }
      return { ...webhook, url, events };
    });
  } else {
    params.urlData.webhooks = [
      {
        isActive: true,
        authorisationKey: '',
        isValid: true,
        isLive: false,
        url,
        events,
        apiVersion
      },
      ...params.urlData.webhooks
    ];
  }
  return updateChannelUrl(params);
}

export type DeleteChannelUrl = (params: Object, url?: string) => Function;
export function deleteChannelUrl(params: UpdateChannelUrlParam, url: string) {
  params.urlData.webhooks = params.urlData.webhooks.filter(webhook => webhook.url !== url);
  return updateChannelUrl(params);
}
/**
 * Action to Create the channel url
 */

export type CreateChannelParam = {
  accountId: number,
  businessId: number,
  channelUrl: string,
  successfulTransactionUrl: string,
  unsuccessfulTransactionUrl: string,
  data: {
    channelAPIVersion: string,
    channelName: string,
    channelType: number,
  },
};
export type CreateChannel = (params: CreateChannelParam) => Function;
export function createChannel(params: CreateChannelParam): Function {
  const { PENDING, SUCCESS, ERROR } = CREATE_CHANNEL;
  const urlData = {
    channelName: params.data.channelName,
    channelUrl: params.channelUrl,
    successfulTransactionUrl: params.successfulTransactionUrl,
    unsuccessfulTransactionUrl: params.unsuccessfulTransactionUrl,
  };
  return async (dispatch: Function) => {
    dispatch({ type: PENDING });
    try {
      const { data, error } = await SettingsAPI.createChannel(params);
      if (error) {
        throw error;
      }
      const {
        data: dataURL,
        error: errorURL,
      } = await SettingsAPI.createChannelUrl({
        accountId: data.accountId,
        businessId: data.businessId,
        channelId: data.channelId,
        urlData,
      });

      if (errorURL) {
        throw errorURL;
      }
      // Redirect the user to the right channel after creating it.
      dispatch({ type: SUCCESS });
      dispatch({
        type: SET_CHANNEL,
        payload: {
          data: {
            apiVersion: data.channelAPIVersion,
            businessId: data.businessId,
            creationDate: data.creationDate,
            id: data.channelId,
            name: data.channelName,
          },
        },
      });
      dispatch({
        type: CHANNEL_URLS.SUCCESS,
        payload: { data: { ...dataURL, webhooks: [] } },
      });
    } catch (e) {
      dispatch({ type: ERROR, payload: { data: e.response.data } });
    }
  };
}
