// @flow
import * as StatementsAPI from 'services/resources/statements.api';
import { dispatchResponse } from 'utils/action.util';
import type { RequestStatus, RequestParams } from 'store/store.types';

export type Deposit = {
  amount: number,
  currencySymbol: string,
  status: string,
};

export type Statement = {
  deposits: Deposit[],
  displayId: string,
  displayStatementId: string,
  endDate: string,
  entityName: string,
  isLegacyStatement: boolean,
  isValidated: boolean,
  settlementDate: string,
  startDate: string,
  statementId: string,
  status: string,
  url: string,
};

export type StatementsList = RequestStatus & {
  startIndex: number,
  totalRows: number,
  statements: Statement[],
};

export type MonthSection = {
  inView: boolean,
  offsetTop: number,
  rect: ?(ClientRect | DOMRect), // optional becuase it can only be retrieved once we have a valid ref
};

// key should be `${year}${month}`
export type MonthSectionMap = { [key: string]: MonthSection };

export type StatementsState = {
  list: StatementsList,
  monthSectionMap: MonthSectionMap,
};

type GetStatementsPendingType = 'GET_STATEMENTS_PENDING';
type GetStatementsErrorType = 'GET_STATEMENTS_ERROR';
type GetStatementsSuccessType = 'GET_STATEMENTS_SUCCESS';
type StreamMonthSectionMapType = 'STATEMENTS_STREAT_MONTH_SECTION_MAP';

export const STATEMENTS_ACTION_TYPES: {
  PENDING: GetStatementsPendingType,
  ERROR: GetStatementsErrorType,
  SUCCESS: GetStatementsSuccessType,
  STREAM_MONTH_SECTION_MAP: StreamMonthSectionMapType,
} = {
  PENDING: 'GET_STATEMENTS_PENDING',
  ERROR: 'GET_STATEMENTS_ERROR',
  SUCCESS: 'GET_STATEMENTS_SUCCESS',
  STREAM_MONTH_SECTION_MAP: 'STATEMENTS_STREAT_MONTH_SECTION_MAP',
};

type GetStatementsPending = {
  type: GetStatementsPendingType,
};

type GetStatementsError = {
  type: GetStatementsErrorType,
};

type GetStatementsSuccess = {
  type: GetStatementsSuccessType,
  payload: {
    data: {
      statements: Array<Statement>,
      startIndex: number,
      totalRows: number,
    },
  },
};

type StreamMonthSectionMapAction = {
  type: StreamMonthSectionMapType,
  monthSectionMap: MonthSectionMap,
};

type Action =
  | GetStatementsPending
  | GetStatementsError
  | GetStatementsSuccess
  | StreamMonthSectionMapAction;

const DEFAULT_STATE: StatementsState = {
  list: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    startIndex: 0,
    totalRows: 0,
    statements: [],
  },
  monthSectionMap: {},
};

export default function reducer(
  state: StatementsState = DEFAULT_STATE,
  action: Action
): StatementsState {
  switch (action.type) {
    case STATEMENTS_ACTION_TYPES.PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case STATEMENTS_ACTION_TYPES.ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          error: true,
          loading: false,
        },
      };
    case STATEMENTS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success: true,
          ...action.payload.data,
        },
      };
    case STATEMENTS_ACTION_TYPES.STREAM_MONTH_SECTION_MAP:
      return {
        ...state,
        monthSectionMap: action.monthSectionMap,
      };
    default:
      return state;
  }
}

export type StreamMonthSectionMap = (
  monthSectionMap: MonthSectionMap
) => StreamMonthSectionMapAction;
export function streamMonthSectionMap(monthSectionMap: MonthSectionMap) {
  return {
    monthSectionMap,
    type: STATEMENTS_ACTION_TYPES.STREAM_MONTH_SECTION_MAP,
  };
}

export type GetStatements = (params: RequestParams) => Function;
/**
 * Action to get a list of statements
 */
export function getStatements(params: RequestParams) {
  const { PENDING, SUCCESS, ERROR } = STATEMENTS_ACTION_TYPES;
  const promiseReq = StatementsAPI.getAll(params);
  return dispatchResponse(
    promiseReq,
    PENDING,
    { type: SUCCESS },
    ERROR,
    true,
    true
  );
}
