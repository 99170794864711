// @flow
import * as React from 'react';
import Carousel from 'antd/lib/carousel';
// import { IconActionArrowRight, IconActionArrowLeft } from '@cko/icons';
import * as Styled from './NewsUpdate.sc';

type Props = {};

type State = {
  currentSlide: number,
  totalSlides: number
};

export default class NewsUpdate extends React.Component<Props, State> {
  state = {
    currentSlide: 0,
    totalSlides: 2
  };
  /*
  slider: any;

  nextSlide = () => {
    this.slider.next();
  };

  prevSlide = () => {
    this.slider.prev();
  };

  goToSlide = (e: SyntheticEvent<HTMLDivElement>) => {
    let slideNum = e.currentTarget.getAttribute('data-num');
    this.slider.goTo(slideNum);
  };

  beforeChange = (from: number, to: number) => {
    this.setState({ currentSlide: to });
  };

  getSlideLinks(): Array<React.Element<'span'>> {
    const slides: Array<React.Element<'span'>> = [];
    for (let i = 0; i < this.state.totalSlides; i + 1) {
      let className = classNames({
        'slide-link': true,
        active: !!(this.state.currentSlide === i)
      });
      slides.push(
        <span
          key={i}
          className={className}
          data-num={i}
          onClick={this.goToSlide}
        />
      );
    }
    return slides;
  } */

  render() {
    const carouselProps = {
      autoplay: true,
      dots: false,
      effect: 'scrollx',
      beforeChange: this.beforeChange
    };

    return (
      <Styled.Container>
        <Styled.Title>Did you know?</Styled.Title>
        {/* <Styled.Nav>
         <IconActionArrowLeft onClick={this.prevSlide} />
          <span className="slideWrap">{this.getSlideLinks()}</span>
          <IconActionArrowRight onClick={this.nextSlide} />
        </Styled.Nav> */}
        {
          // this was in Carousel:
          // ref={c => this.slider = c}
        }
        <Styled.Inner>
          <Carousel {...carouselProps}>
            <Styled.Slide>
              You can configure webhooks from the webhook management section to receive
              notifications about events that occur on your account.
            </Styled.Slide>
          </Carousel>
        </Styled.Inner>
      </Styled.Container>
    );
  }
}
