// @flow
import { type Dispatch } from 'redux';
import * as loginAPI from 'services/basics/login.api';
import { ajxPromise } from 'services/appRequest';

import {
  dispatchResponse,
  dispatchSuccess,
  dispatchError
} from 'utils/action.util';
import { ACCOUNT_TYPE, ERROR_CODES } from 'config';
import { type RequestStatus } from 'store/store.types';
import type { StoreState } from 'store/reducers';
import * as currencyService from 'services/currency/currencyService';
import { initAuthorisation } from 'services/security/authorisation';
import { setCurrencyId } from 'services/appRequest';
import * as passwordDuck from 'store/password-duck';

export type Permission = {
  name: string,
  permission: number,
};

export type PhoneNumber = {
  countryCode: string,
  number: string,
};

export type Country = {
  countryId: number,
  countryIso2Code: string,
  countryPhoneCode: string,
  name: string,
};

export type Currency = {
  coefficient: number,
  currencyId: number,
  isCommon: boolean,
  isSettlement: boolean,
  name: string,
  symbol: string,
};

export type PaymentMethod = {
  name: string,
  paymentMethodId: number,
};

export type UserPayload = {
  accountId: number,
  accountTypeId: number,
  accountTypeName: string,
  displayCurrencyId: number,
  displayCurrencyName: string,
  displayCurrencySymbol: string,
  email: string,
  isActive: boolean,
  isAccountOwner: boolean,
  lastLoginDate: string,
  name: string,
  permissions: Array<Permission>,
  phone: PhoneNumber,
  timezone: string,
  token: string,
  userId: number,
  isReadOnly: boolean,
  isMerchantAdmin: boolean,
  isSuperAdmin: boolean,
  isSuperUser: Boolean,
  isGodUser: boolean,
  isSso: boolean,
};

export function mapOktaPayload(payload): UserPayload {
  if (!payload.cko_inline_hook_succeeded) {
    throw new Error();
  }

  const {
    hub_account_id,
    hub_user_id,
    hub_user_name,
    hub_user_email,
    hub_phone_number,
    hub_phone_country_code,
    hub_account_type_id,
    hub_account_type_name,
    hub_version,
    hub_is_active,
    hub_is_account_owner,
    hub_token,
    hub_last_login_date,
    hub_display_currency_id,
    hub_permissions,
    hub_timezone,
    hub_gdpr_activated,
  } = payload;
  return {
    accountId: hub_account_id,
    accountTypeId: hub_account_type_id,
    accountTypeName: hub_account_type_name,
    displayCurrencyId: hub_display_currency_id,
    email: hub_user_email,
    gdprActivated: hub_gdpr_activated,
    hubVersion: hub_version,
    isAccountOwner: hub_is_account_owner,
    isActive: hub_is_active,
    isSso: true,
    lastLoginDate: hub_last_login_date,
    name: hub_user_name,
    permissions: hub_permissions,
    phone: {
      countryCode: hub_phone_country_code,
      number: hub_phone_number,
    },
    token: hub_token,
    timezone: hub_timezone,
    userId: hub_user_id,
  };
}

export type LookupsPayload = {
  countries: Array<Country>,
  currencies: Array<Currency>,
  paymentMethods: Array<PaymentMethod>,
};

// XXX: should eventually handle errors in a standardized manner
export type User = RequestStatus & {
  errorData: any,
  account: UserPayload,
};

export type Lookups = RequestStatus & LookupsPayload;

export type UpdateDisplayCurrencyState = RequestStatus;

export type UserState = {
  user: User,
  lookups: Lookups,
  updateDisplayCurrency: UpdateDisplayCurrencyState,
};

type LoadCachedDataType = 'LOAD_USER_CACHED_DATA';
type ClearType = 'CLEAR_USER';
type LoginPendingType = 'LOGIN_PENDING';
type LoginErrorType = 'LOGIN_ERROR';
type LoginSuccessType = 'LOGIN_SUCCESS';
type LoginResetRequiredType = 'LOGIN_RESET_REQUIRED';
type LookupsPendingType = 'LOOKUPS_PENDING';
type LookupsErrorType = 'LOOKUPS_ERROR';
type LookupsSuccessType = 'LOOKUPS_SUCCESS';
type ProfileUpdatePendingType = 'PROFILE_UPDATE_PENDING';
type ProfileUpdateErrorType = 'PROFILE_UPDATE_ERROR';
type ProfileUpdateSuccessType = 'PROFILE_UPDATE_SUCCESS';
type SignOutType = 'SIGN_OUT';
type UpdateUserCurrencyType = 'USER_UPDATE_CURRENCY';
type UpdateDisplayCurrencyPendingType = 'DISPLAY_CURRENCY_UPDATE_PENDING';
type UpdateDisplayCurrencyErrorType = 'DISPLAY_CURRENCY_UPDATE_ERROR';
type UpdateDisplayCurrencySuccessType = 'DISPLAY_CURRENCY_UPDATE_SUCCESS';
type SetGDPRConfirmationType = 'GDPR_CONFIRMATION';

type LoginPendingAction = {
  type: LoginPendingType,
};

type LoginErrorAction = {
  type: LoginErrorType,
  payload: {
    data: any,
  },
};

type LoginSuccessAction = {
  type: LoginSuccessType,
  payload: {
    data: UserPayload,
  },
};

type LoginResetRequiredAction = {
  type: LoginResetRequiredType,
};

type LookupsPendingAction = {
  type: LookupsPendingType,
};

type LookupsErrorAction = {
  type: LookupsErrorType,
};

type LookupsSuccessAction = {
  type: LookupsSuccessType,
  payload: {
    data: LookupsPayload,
  },
};

type ProfileUpdatePendingAction = {
  type: ProfileUpdatePendingType,
};

type ProfileUpdateErrorAction = {
  type: ProfileUpdateErrorType,
  payload: {
    data: any,
  },
};

type ProfileUpdateSuccessAction = {
  type: ProfileUpdateSuccessType,
  payload: {
    data: {
      name: string,
      email: string,
      timeZone: string,
    },
  },
};

type LoadCachedDataAction = {
  type: LoadCachedDataType,
  payload: {
    data: UserState,
  },
};

type ClearAction = {
  type: ClearType,
};

type LogoutAction = {
  type: SignOutType,
  sessionEnd: boolean,
};

type UpdateUserCurrencyAction = {
  type: UpdateUserCurrencyType,
  displayCurrencyName: string,
  displayCurrencySymbol: string,
};

type UpdateDisplayCurrencyPendingAction = {
  type: UpdateDisplayCurrencyPendingType,
};

type UpdateDisplayCurrencyErrorAction = {
  type: UpdateDisplayCurrencyErrorType,
};

// This is a special success action where the payload is
// irrelavant because the update only takes affect after
// a new session is created
type UpdateDisplayCurrencySuccessAction = {
  type: UpdateDisplayCurrencySuccessType,
};

type SetGDPRConfirmationAction = {
  type: SetGDPRConfirmationType,
};

type Action =
  | LoginPendingAction
  | LoginErrorAction
  | LoginSuccessAction
  | LoginResetRequiredAction
  | LookupsPendingAction
  | LookupsErrorAction
  | LookupsSuccessAction
  | ProfileUpdatePendingAction
  | ProfileUpdateErrorAction
  | ProfileUpdateSuccessAction
  | LoadCachedDataAction
  | UpdateDisplayCurrencyPendingAction
  | UpdateDisplayCurrencyErrorAction
  | UpdateDisplayCurrencySuccessAction
  | ClearAction
  | LogoutAction
  | UpdateUserCurrencyAction
  | SetGDPRConfirmationAction;

export const LOGIN_ACTION_TYPES: {
  PENDING: LoginPendingType,
  ERROR: LoginErrorType,
  SUCCESS: LoginSuccessType,
  RESET_REQUIRED: LoginResetRequiredType,
} = {
  PENDING: 'LOGIN_PENDING',
  ERROR: 'LOGIN_ERROR',
  SUCCESS: 'LOGIN_SUCCESS',
  RESET_REQUIRED: 'LOGIN_RESET_REQUIRED'
};

export const LOOKUPS_ACTION_TYPES: {
  PENDING: LookupsPendingType,
  ERROR: LookupsErrorType,
  SUCCESS: LookupsSuccessType,
} = {
  PENDING: 'LOOKUPS_PENDING',
  ERROR: 'LOOKUPS_ERROR',
  SUCCESS: 'LOOKUPS_SUCCESS'
};

export const PROFILE_UPDATE_ACTION_TYPES: {
  PENDING: ProfileUpdatePendingType,
  ERROR: ProfileUpdateErrorType,
  SUCCESS: ProfileUpdateSuccessType,
} = {
  PENDING: 'PROFILE_UPDATE_PENDING',
  ERROR: 'PROFILE_UPDATE_ERROR',
  SUCCESS: 'PROFILE_UPDATE_SUCCESS'
};

export const USER_ACTION_TYPES: {
  LOAD_CACHED_DATA: LoadCachedDataType,
  CLEAR: ClearType,
  SIGN_OUT: SignOutType,
  UPDATE_CURRENCY: UpdateUserCurrencyType,
  GDPR_CONFIRMATION: SetGDPRConfirmationType
} = {
  LOAD_CACHED_DATA: 'LOAD_USER_CACHED_DATA',
  CLEAR: 'CLEAR_USER',
  SIGN_OUT: 'SIGN_OUT',
  UPDATE_CURRENCY: 'USER_UPDATE_CURRENCY',
  GDPR_CONFIRMATION: 'GDPR_CONFIRMATION'
};

export const UPDATE_DISPLAY_CURRENCY_ACTION_TYPES: {
  PENDING: UpdateDisplayCurrencyPendingType,
  ERROR: UpdateDisplayCurrencyErrorType,
  SUCCESS: UpdateDisplayCurrencySuccessType,
} = {
  PENDING: 'DISPLAY_CURRENCY_UPDATE_PENDING',
  ERROR: 'DISPLAY_CURRENCY_UPDATE_ERROR',
  SUCCESS: 'DISPLAY_CURRENCY_UPDATE_SUCCESS'
};

export const DEFAULT_STATE: UserState = {
  user: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    errorData: null,
    account: {
      accountId: 0,
      accountTypeId: 0,
      accountTypeName: '',
      displayCurrencyId: 0,
      displayCurrencyName: '',
      displayCurrencySymbol: '',
      email: '',
      isActive: false,
      isAccountOwner: false,
      lastLoginDate: '',
      name: '',
      permissions: [],
      phone: {
        countryCode: '',
        number: ''
      },
      timezone: '',
      token: '',
      userId: 0,
      isReadOnly: false,
      isMerchantAdmin: false,
      isSuperAdmin: false,
      isSuperUser: false,
      isGodUser: false
    }
  },
  lookups: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    countries: [],
    currencies: [],
    paymentMethods: []
  },
  updateDisplayCurrency: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
};

export default function reducer(
  state: UserState = DEFAULT_STATE,
  action: Action
): UserState {
  switch (action.type) {
    case LOGIN_ACTION_TYPES.PENDING:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      };
    case LOGIN_ACTION_TYPES.ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: true,
          errorData: { ...action.payload.data }
        }
      };
    case LOGIN_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          success: true,
          account: {
            ...action.payload.data,
            isReadOnly:
              action.payload.data.accountTypeId === ACCOUNT_TYPE.MERCHANT_USER,
            isMerchantAdmin:
              action.payload.data.accountTypeId === ACCOUNT_TYPE.MERCHANT_ADMIN,
            isSuperAdmin:
              action.payload.data.accountTypeId === ACCOUNT_TYPE.SUPER_ADMIN,
            isSuperUser:
              action.payload.data.accountTypeId === ACCOUNT_TYPE.SUPER_USER,
            isGodUser: action.payload.data.accountTypeId === ACCOUNT_TYPE.GOD,
          },
        },
      };
    case LOGIN_ACTION_TYPES.RESET_REQUIRED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          success: false,
        },
      };
    case LOOKUPS_ACTION_TYPES.PENDING:
      return {
        ...state,
        lookups: {
          ...state.lookups,
          loading: true,
        },
      };
    case LOOKUPS_ACTION_TYPES.ERROR:
      return {
        ...state,
        lookups: {
          ...state.lookups,
          loading: false,
          error: true,
        },
      };
    case LOOKUPS_ACTION_TYPES.SUCCESS:
      /* eslint-disable no-case-declarations */
      const { data } = action.payload;
      const { paymentMethods, ...rest } = data;
      const filterPM = paymentMethods.map((p) => {
        if (p.name === 'DLOCAL') {
          return {
            ...p,
            name: 'Latam local card schemes'
          };
        }
        return p;
      });
      return {
        ...state,
        lookups: {
          ...rest,
          paymentMethods: filterPM,
          loading: false,
          success: true,
        },
      };
    case PROFILE_UPDATE_ACTION_TYPES.PENDING:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          success: false,
        },
      };
    case PROFILE_UPDATE_ACTION_TYPES.ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          success: false,
          error: true,
          errorData: { ...action.payload.data },
        },
      };
    case PROFILE_UPDATE_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          account: {
            ...state.user.account,
            name: action.payload.data.name,
            email: action.payload.data.email,
            timeZone: action.payload.data.timeZone,
          },
        },
      };
    case UPDATE_DISPLAY_CURRENCY_ACTION_TYPES.PENDING:
      return {
        ...state,
        updateDisplayCurrency: {
          ...state.updateDisplayCurrency,
          loading: true,
        },
      };
    case UPDATE_DISPLAY_CURRENCY_ACTION_TYPES.ERROR:
      return {
        ...state,
        updateDisplayCurrency: {
          ...state.updateDisplayCurrency,
          loading: false,
          error: true,
        },
      };
    case UPDATE_DISPLAY_CURRENCY_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        updateDisplayCurrency: {
          ...state.updateDisplayCurrency,
          loading: false,
          success: true,
        },
      };
    case USER_ACTION_TYPES.UPDATE_CURRENCY:
      return {
        ...state,
        user: {
          ...state.user,
          account: {
            ...state.user.account,
            displayCurrencyName: action.displayCurrencyName,
            displayCurrencySymbol: action.displayCurrencySymbol,
          },
        },
      };
    case USER_ACTION_TYPES.LOAD_CACHED_DATA:
      return action.payload.data;
    case USER_ACTION_TYPES.CLEAR:
      return { ...DEFAULT_STATE };
    case USER_ACTION_TYPES.GDPR_CONFIRMATION:
      return {
        ...state,
        user: {
          ...state.user,
          account: {
            ...state.user.account,
            gdprActivated: true,
          },
        },
      };
  default:
      return state;
  }
}

// XXX: the error types varies in an unspecified way
// not clear how to type
export function loginFailure(error: any): LoginErrorAction {
  return {
    type: LOGIN_ACTION_TYPES.ERROR,
    payload: {
      data: error,
    },
  };
}

export function setGDPRConfirmation() {
  return {
    type: USER_ACTION_TYPES.GDPR_CONFIRMATION,
  };
}


function loginSuccess(data: UserPayload): LoginSuccessAction {
  return {
    type: LOGIN_ACTION_TYPES.SUCCESS,
    payload: { data },
  };
}

function loginResetRequired(): LoginResetRequiredAction {
  return {
    type: LOGIN_ACTION_TYPES.RESET_REQUIRED,
  };
}

function handleLoginError(
  dispatch: Dispatch<any>,
  error: any,
  credentials: passwordDuck.Credentials,
  oktaAuth
): void {
  const response = error.response;
  const serverError = response && response.data && response.data.errorCode;

  if (serverError) {
    const errorCode = parseInt(response.data.errorCode, 10);
    switch (errorCode) {
      case ERROR_CODES.PASSWORD_RESET_REQUIRED:
      case ERROR_CODES.PASSWORD_EXPIRED:
        dispatch(passwordDuck.cacheCredentials(credentials));
        dispatch(loginResetRequired());
        return;

      case ERROR_CODES.TOKEN_EXPIRED:
        dispatch(logout(oktaAuth, false));
        return;

      default:
        dispatch(loginFailure(response.data));
    }
  } else {
    dispatch(loginFailure(error));
  }
}

export type Login = (credentials: passwordDuck.Credentials, token: String, oktaAuth) => Function;
export function login(credentials: passwordDuck.Credentials, token: String, oktaAuth) {
  const request = (token)
    ? ajxPromise.get('/change-hub-version', { headers: { 'X-AuthToken': token } })
    : loginAPI.login(credentials.email, credentials.password);

  return (dispatch: Dispatch<any>) => {
    dispatch({ type: LOGIN_ACTION_TYPES.PENDING });
    return request
      .then((response: any) => {
        if (response.data.errorCode) {
          dispatch(loginFailure(response.data));
        } else {
          const user = (response.data: UserPayload);
          dispatch(authorise(user));
        }
      })
      .catch((error: any) => {
        handleLoginError(dispatch, error, credentials, oktaAuth);
      });
  };
}

export type Authorise = (user: UserPayload) => void;
export function authorise(user: UserPayload) {
  return (dispatch: Dispatch<any>) => {
    user.isReadOnly = user.accountTypeId === ACCOUNT_TYPE.MERCHANT_USER;
    user.isMerchantAdmin = user.accountTypeId === ACCOUNT_TYPE.MERCHANT_ADMIN;
    user.isSuperAdmin = user.accountTypeId === ACCOUNT_TYPE.SUPER_ADMIN;
    user.isGodUser = user.accountTypeId === ACCOUNT_TYPE.GOD;
    user.isSuperUser = user.accountTypeId === ACCOUNT_TYPE.SUPER_USER;

    initAuthorisation(user);
    setCurrencyId(user.displayCurrencyId.toString());
    dispatch(loginSuccess(user));
    const userComingFromV1 = sessionStorage.getItem('userComingFromV1');
    if (user.hubVersion === 1 && !userComingFromV1) {
      // TODO: alarm
      // window.location.href = `${window.location.origin}/v1/redirect-user/`;
    }
  };
}

export type Logout = (sessionEnd?: boolean) => LogoutAction;
/**
 * Action to logout user
 * @returns {function}
 */
export function logout(oktaAuth, sessionEnd: boolean = false): LogoutAction {
  return dispatch => {
    return oktaAuth.signOut().then(() => {
      return dispatch({
        type: USER_ACTION_TYPES.SIGN_OUT,
        sessionEnd,
      });
    });
  }
}

export type Clear = () => ClearAction;
export function clear(): ClearAction {
  return {
    type: USER_ACTION_TYPES.CLEAR,
  };
}

function setupCurrency(
  lookups: LookupsPayload,
  displayCurrencyId: number
): ?Currency {
  const currency = currencyService.setCurrency(
    lookups.currencies,
    displayCurrencyId
  );

  if (currency) {
    currencyService.setPaymentMethod(lookups.paymentMethods);
  }

  return currency;
}

function updateCurrency(
  displayCurrencyName: string,
  displayCurrencySymbol: string
): UpdateUserCurrencyAction {
  return {
    displayCurrencyName,
    displayCurrencySymbol,
    type: USER_ACTION_TYPES.UPDATE_CURRENCY,
  };
}

export type GetLookups = () => Function;
/**
 * Action to get the global permissions and settings
 */
export function getLookups(): Function {
  return (dispatch: Dispatch<any>, getState: () => StoreState) => {
    dispatch({
      type: LOOKUPS_ACTION_TYPES.PENDING,
    });
    loginAPI
      .getLookups()
      .then((response: { data: LookupsPayload }) => {
        dispatchSuccess(
          {
            type: LOOKUPS_ACTION_TYPES.SUCCESS,
          },
          dispatch,
          false
        )(response);

        const displayCurrencyId = getState().userState.user.account
          .displayCurrencyId;
        const currency = setupCurrency(response.data, displayCurrencyId);
        if (currency) {
          dispatch(updateCurrency(currency.name, currency.symbol));
        }
      })
      .catch((err: any) => {
        dispatchError(LOOKUPS_ACTION_TYPES.ERROR, dispatch, false);
      });
  };
}

function loadCachedLogin(userState: UserState): LoadCachedDataAction {
  return {
    type: USER_ACTION_TYPES.LOAD_CACHED_DATA,
    payload: {
      data: userState
    }
  };
}

export type HandleCachedLogin = (userState: UserState) => Function;
export function handleCachedLogin(userState: UserState): Function {
  return (dispatch: Dispatch<any>) => {
    dispatch(loadCachedLogin(userState));
    let currency;
    try {
      currency = setupCurrency(
        userState.lookups,
        userState.user.account.displayCurrencyId
      );
    } catch (error) {
      return clear();
    }
    initAuthorisation(userState.user.account);
    if (currency) {
      dispatch(updateCurrency(currency.name, currency.symbol));
    }
  };
}

type UpdateCompleteProfileSpec = {
  newName?: string,
  currentPassword?: string,
  newPassword?: string,
  confirmPassword?: string,
  newTimezone?: string,
};
export type UpdateCompleteProfile = (
  spec: UpdateCompleteProfileSpec
) => Function;
/**
 * handles updating profile in setting
 * must update email after password change if both are changed
 * as updating password requires the email
 */
export function updateCompleteProfile(
  spec: UpdateCompleteProfileSpec,
  oktaAuth
): Function {
  return (dispatch: Dispatch<any>, getState: () => StoreState) => {
    const {
      newName, currentPassword, newPassword, confirmPassword, newTimezone
    } = spec;
    const { name, email, timezone } = getState().userState.user.account;
    const shouldUpdateName = newName && name !== newName;
    const shouldUpdatePassword = currentPassword && newPassword && confirmPassword;
    const shouldUpdateTimezone = newTimezone && newTimezone !== timezone;

    function updatePassword() {
      if (!(currentPassword && newPassword && confirmPassword)) {
        return;
      }

      dispatch({ type: passwordDuck.CHANGE_PASSWORD_ACTION_TYPES.PENDING });
      // XXX: type the API responses
      return loginAPI
        .changePassword(email, currentPassword, newPassword, confirmPassword)
        .then((response: { data: any }) => {
          dispatchSuccess(
            {
              type: passwordDuck.CHANGE_PASSWORD_ACTION_TYPES.SUCCESS,
            },
            dispatch,
            false
          )(response);

          // Sign out as the token will be invalid after password change
          setTimeout(() => dispatch(logout(oktaAuth)), 2000);
        })
        .catch(err => {
          dispatchError(
            passwordDuck.CHANGE_PASSWORD_ACTION_TYPES.ERROR,
            dispatch,
            false
          )(err);
        });
    }

    // must only be called if newName is defined
    function updateName() {
      dispatch({ type: PROFILE_UPDATE_ACTION_TYPES.PENDING });
      return loginAPI
        .updateProfile(((newName: any): string), email, timezone)
        .then((response: { data: any }) => {
          dispatchSuccess(
            { type: PROFILE_UPDATE_ACTION_TYPES.SUCCESS },
            dispatch,
            false
          )(response);
          if (shouldUpdatePassword) {
            updatePassword();
          }
        })
        .catch(err => {
          dispatchError(PROFILE_UPDATE_ACTION_TYPES.ERROR, dispatch, true)(err);
        });
    }

    function updateTimezone() {
      dispatch({ type: PROFILE_UPDATE_ACTION_TYPES.PENDING });
      return loginAPI
        .updateProfile(name, email, ((newTimezone: any): string))
        .then((response: { data: any }) => {
          dispatchSuccess(
            { type: PROFILE_UPDATE_ACTION_TYPES.SUCCESS },
            dispatch,
            false
          )(response);
        })
        .catch(err => {
          dispatchError(PROFILE_UPDATE_ACTION_TYPES.ERROR, dispatch, true)(err);
        });
    }

    if (shouldUpdateName) {
      return updateName();
    } else if (shouldUpdatePassword) {
      return updatePassword();
    } else if(shouldUpdateTimezone) {
      return updateTimezone()
    } else {
      return Promise.reject('invalid call to updateCompleteProfile');
    }
  };
}

export type UpdateDisplayCurrency = (
  accountId: number,
  currencyId: number
) => Function;
export function updateDisplayCurrency(
  accountId: number,
  currencyId: number
): Function {
  const promiseReq = loginAPI.updateDisplayCurrency(accountId, currencyId);
  const { PENDING, SUCCESS, ERROR } = UPDATE_DISPLAY_CURRENCY_ACTION_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}
