// @flow
import cloneDeep from 'lodash/cloneDeep';
import * as AccountAPI from 'services/basics/account.api';
import { dispatchResponse } from 'utils/action.util';
import * as StoreTypes from 'store/store.types';
import currencyIds from 'config/currencyIds.json';
import * as PayoutsDuck from 'store/payouts-duck';
import { SHARED_ACTION_TYPES } from 'store/sharedActions';

export type Account = {
  accountId: number,
  name: string,
};

export type Accounts = StoreTypes.RequestStatus & {
  list: Array<Account>,
};

export type Webhook = {
  id: string,
  authorisationKey: string,
  isActive: boolean,
  url: string,
  events: Array<string>,
};

type ChannelUrl = {
  channelUrlId: number,
  channelUrl: string,
  successfulTransactionUrl: string,
  unsuccessfulTransactionUrl: string,
  feedUrl: string,
  uwReviewed: boolean,
  webhooks: Array<Webhook>,
};

export type Channel = {
  creationDate: string,
  referenceId: string,
  channelUrls: Array<ChannelUrl>,
  isActive: boolean,
  isPayoutActivated: boolean,
  isVerified: boolean,
  accountId: number,
  businessId: number,
  channelId: number,
  channelType: number,
  channelAPIVersion: string,
  channelName: string,
  gaTrackingId: string,
};

type PaymentMethod = {
  propertyPaymentMethodId: number,
  propertyId: number,
  paymentMethodId: number,
  payoutCylceId: number,
  isActive: boolean,
};

type VatSettings = {
  hasVat: boolean,
  vatNumber: string,
  vatRate: number,
};

export type Business = {
  accountId: number,
  propertyId: number,
  merchantCategoryCodeId: number,
  businessServiceSettingId: number,
  propertyName: string,
  managerFirstName: string,
  managerLastName: string,
  managerEmail: string,
  defaultDescriptorName: ?string,
  defaultDescriptorCity: ?string,
  billingDescriptor: string,
  allowMoto: boolean,
  enableAccountUpdater: boolean,
  enableFullApi: boolean,
  force3D: boolean,
  isCvvOptional: boolean,
  allowRiskFlag: boolean,
  isDynamicDescriptor: boolean,
  riskSettingsRequireApproval: boolean,
  isApplePayEnabled: boolean,
  channels: Array<Channel>,
  paymentMethods: Array<PaymentMethod>,
  bankAccounts: Array<PayoutsDuck.BankDescription>,
  supportPhone: StoreTypes.PhoneNumber,
  vatSettings: VatSettings,
  migratedToGwc: boolean,
};

export type BusinessMap = { [accountId: string]: Array<Business> };

export type Businesses = StoreTypes.RequestStatus & {
  map: BusinessMap,
};

type Dates = {
  fromDate: string,
  toDate: string,
};

type SelectionEntity = {
  id: number,
  title: string,
};

type SelectionSpec = {
  account: SelectionEntity,
  business?: SelectionEntity,
  channel?: SelectionEntity,
};

export type Selected = {
  account: SelectionEntity,
  business: SelectionEntity,
  channel: SelectionEntity,
};

export type GlobalState = {
  isWelcomeBannerVisible: boolean,
  isGWCore: boolean,
  channelAPIVersion: string,
  fromDate: string,
  toDate: string,
  selected: Selected,
  businesses: Businesses,
  accounts: Accounts,
  channelCurrencies: Array<number>,
  prismEnabled: boolean
};

type LoadCachedDataType = 'GLOBAL_LOAD_CACHED_DATA';
type SetDatesType = 'GLOBAL_SET_DATES';
type SetSelectionType = 'GLOBAL_SET_SELECTION';
type RefreshType = 'GLOBAL_REFERSH';
type GetAccountsPendingType = 'GLOBAL_GET_ACCOUNTS_PENDING';
type GetAccountsErrorType = 'GLOBAL_GET_ACCOUNT_ERROR';
type GetAccountsSuccessType = 'GLOBAL_GET_ACCOUNTS_SUCCESS';
type GetBusinessesPendingType = 'GLOBAL_GET_BUSINESSES_PENDING';
type GetBusinessesErrorType = 'GLOBAL_GET_BUSINESSES_ERROR';
type GetBusinessesSuccessType = 'GLOBAL_GET_BUSINESSES_SUCCESS';
type SetWelcomeBannerType = 'GLOBAL_WELCOME_BANNER';

type SetWelcomeBannerAction = {
  type: SetWelcomeBannerType,
  active: boolean,
};

type SetDatesAction = {
  type: SetDatesType,
  dates: Dates,
};

type SetSelectionAction = {
  type: SetSelectionType,
  selection: SelectionSpec,
};

type RefreshAction = {
  type: RefreshType,
};

type LoadCachedDataAction = {
  type: LoadCachedDataType,
  globalState: GlobalState,
};

type GetAccountsPendingAction = {
  type: GetAccountsPendingType,
};

type GetAccountsErrorAction = {
  type: GetAccountsErrorType,
};

type GetAccountsSuccessAction = {
  type: GetAccountsSuccessType,
  payload: {
    data: Array<Account>,
  },
};

type GetBusinessesPendingAction = {
  type: GetBusinessesPendingType,
};

type GetBusinessesErrorAction = {
  type: GetBusinessesErrorType,
};

type GetBusinessesSuccessAction = {
  type: GetBusinessesSuccessType,
  accountId: string,
  payload: {
    data: {
      businesses: Array<Business>,
    },
  },
};

type Action =
  | SetDatesAction
  | SetSelectionAction
  | RefreshAction
  | LoadCachedDataAction
  | GetAccountsPendingAction
  | GetAccountsErrorAction
  | GetAccountsSuccessAction
  | GetBusinessesPendingAction
  | GetBusinessesErrorAction
  | GetBusinessesSuccessAction
  | SetWelcomeBannerAction
  | SHARED_ACTION_TYPES;

const GLOBAL_WELCOME_BANNER: SetWelcomeBannerType = 'GLOBAL_WELCOME_BANNER';

export const GLOBAL_ACTION_TYPES: {
  SET_DATES: SetDatesType,
  SET_SELECTION: SetSelectionType,
  LOAD_CACHED_DATA: LoadCachedDataType,
} = {
  SET_DATES: 'GLOBAL_SET_DATES',
  SET_SELECTION: 'GLOBAL_SET_SELECTION',
  LOAD_CACHED_DATA: 'GLOBAL_LOAD_CACHED_DATA',
};

export const GET_ACCOUNTS_ACTION_TYPES: {
  PENDING: GetAccountsPendingType,
  ERROR: GetAccountsErrorType,
  SUCCESS: GetAccountsSuccessType,
} = {
  PENDING: 'GLOBAL_GET_ACCOUNTS_PENDING',
  ERROR: 'GLOBAL_GET_ACCOUNT_ERROR',
  SUCCESS: 'GLOBAL_GET_ACCOUNTS_SUCCESS',
};

export const GET_BUSINESSES_ACTION_TYPES: {
  PENDING: GetBusinessesPendingType,
  ERROR: GetBusinessesErrorType,
  SUCCESS: GetBusinessesSuccessType,
} = {
  PENDING: 'GLOBAL_GET_BUSINESSES_PENDING',
  ERROR: 'GLOBAL_GET_BUSINESSES_ERROR',
  SUCCESS: 'GLOBAL_GET_BUSINESSES_SUCCESS',
};

export const GLOBAL_REFERSH: RefreshType = 'GLOBAL_REFERSH';

const DEFAULT_STATE: GlobalState = {
  isWelcomeBannerVisible: false,
  fromDate: '',
  toDate: '',
  isGWCore: false,
  selected: {
    account: {
      id: 0,
      title: '',
    },
    business: {
      id: 0,
      title: '',
    },
    channel: {
      id: 0,
      title: '',
    },
  },
  businesses: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    map: {},
  },
  accounts: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    list: [],
  },
  channelCurrencies: currencyIds,
  refreshing: false
};

function handleSetSelection(
  state: GlobalState,
  selection: SelectionSpec
): GlobalState {
  let nextSelection = cloneDeep(state.selected);
  const { account, business, channel } = selection;

  if (account && account.id) {
    nextSelection.account = account;
    nextSelection.business = { id: 0, title: '' };
    nextSelection.channel = { id: 0, title: '' };
  }

  if (business && business.id) {
    nextSelection.business = business;
    nextSelection.channel = { id: 0, title: '' };
  }

  if (channel && channel.id) {
    nextSelection.channel = channel;
  }

  let channelAPIVersion = '';
  let isGWCore = false;
  if (nextSelection.account.id !== null && Object.keys(state.businesses.map).length > 0) {
    const businesses = state.businesses.map[nextSelection.account.id.toString()];
    isGWCore = businesses.length > 0 && businesses.filter(i => i.migratedToGwc === true).length >= 1;

    if (channel && channel.id) {
      Object.values(businesses).forEach((business) => {
        business.channels.forEach((c) => {
          if (c.channelId === channel.id) {
            channelAPIVersion = c.channelAPIVersion;
          }
        });
      });
    }
  }


  return {
    ...state,
    channelAPIVersion,
    isGWCore,
    selected: nextSelection
  };
}

export default function reducer(
  state: GlobalState = DEFAULT_STATE,
  action: Action
): GlobalState {
  switch (action.type) {
    case GLOBAL_WELCOME_BANNER:
      return {
        ...state,
        isWelcomeBannerVisible: action.active,
      };

    case GLOBAL_ACTION_TYPES.LOAD_CACHED_DATA:
      return action.globalState;

    case GLOBAL_ACTION_TYPES.SET_DATES:
      return {
        ...state,
        fromDate: action.dates.fromDate,
        toDate: action.dates.toDate,
      };

    case GLOBAL_ACTION_TYPES.SET_SELECTION:
      return handleSetSelection(state, action.selection);

    case GET_ACCOUNTS_ACTION_TYPES.PENDING:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: true,
        },
      };

    case GET_ACCOUNTS_ACTION_TYPES.ERROR:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          error: true,
        },
      };

    case GET_ACCOUNTS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          success: true,
          list: action.payload.data,
        },
      };

    case GET_BUSINESSES_ACTION_TYPES.PENDING:
      return {
        ...state,
        businesses: {
          ...state.businesses,
          loading: true,
        },
      };

    case GET_BUSINESSES_ACTION_TYPES.ERROR:
      return {
        ...state,
        businesses: {
          ...state.businesses,
          loading: false,
          error: true,
        },
      };

    case GET_BUSINESSES_ACTION_TYPES.SUCCESS:
      // SET GWCORE
      /* eslint-disable no-case-declarations */
      let isGWCore = false;
      let { businesses: businessesMap } = action.payload.data;
      if (action.accountId !== null && businessesMap.length > 0) {
        if (businessesMap.length > 0) {
          isGWCore = businessesMap
            .filter(i => i.accountId === action.accountId)
            .filter(i => i.migratedToGwc === true).length >= 1;
        }
      }
      return {
        ...state,
        cardPayoutsEnabled: action.payload.data.cardPayoutsEnabled,
        isGWCore,
        prismEnabled: action.payload.data.prismEnabled,
        businesses: {
          ...state.businesses,
          loading: false,
          success: true,
          map: {
            ...state.businesses.map,
            [action.accountId]: action.payload.data.businesses,
          },
        },
      };
    case SHARED_ACTION_TYPES.REFRESH_DATA:
      return {
        ...state,
        refersh: true
      };
    case GLOBAL_REFERSH:
      return {
        ...state,
        refersh: false
      };
    default:
      return state;
  }
}

export type GetAccounts = () => Function;
export function getAccounts() {
  const promiseReq = AccountAPI.getAccounts();
  let { PENDING, SUCCESS, ERROR } = GET_ACCOUNTS_ACTION_TYPES;

  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type GetBusinesses = (accountId: number) => Function;
export function getBusinesses(accountId: number) {
  const promiseReq = AccountAPI.getAccountAssets(accountId);
  let { PENDING, SUCCESS, ERROR } = GET_BUSINESSES_ACTION_TYPES;

  return dispatchResponse(
    promiseReq,
    PENDING,
    { type: SUCCESS, accountId },
    ERROR
  );
}

export type SetSelection = (selection: SelectionSpec) => SetSelectionAction;
export function setSelection(selection: SelectionSpec): SetSelectionAction {
  return {
    selection,
    type: GLOBAL_ACTION_TYPES.SET_SELECTION,
  };
}

export type SetDates = (dates: Dates) => SetDatesAction;
export function setDates(dates: Dates): SetDatesAction {
  return {
    dates,
    type: GLOBAL_ACTION_TYPES.SET_DATES,
  };
}

export type LoadCachedGlobal = (
  globalState: GlobalState
) => LoadCachedDataAction;
export function loadCachedGlobal(
  globalState: GlobalState
): LoadCachedDataAction {
  return {
    globalState,
    type: GLOBAL_ACTION_TYPES.LOAD_CACHED_DATA,
  };
}

export type SetWelcomeBanner = (
  active: boolean
) => { active: boolean, type: SetWelcomeBannerType };
export function setWelcomeBanner(active: boolean) {
  return {
    active,
    type: GLOBAL_WELCOME_BANNER,
  };
}

export type ResetRefreshing = (
  active: boolean
) => { active: boolean, type: SetWelcomeBannerType };
export function resetRefreshing() {
  return {
    type: GLOBAL_REFERSH
  };
}
