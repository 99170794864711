// @flow
import * as applePayAPI from 'services/resources/apple-pay.api';
import { dispatchResponse } from 'utils/action.util';
import { type Dispatch } from 'redux';
import type { StoreState } from 'store/reducers';

export type Certificate = {
  merchantIdentifier: string,
  validFrom: string,
  validUntil: string
};

type ApplePayCertificatesPendingType = 'APPLE_PAY_CERTIFICATES_PENDING';
type ApplePayCertificatesSuccessType = 'APPLE_PAY_CERTIFICATES_SUCCESS';
type ApplePayCertificatesErrorType = 'APPLE_PAY_CERTIFICATES_ERROR';

type ApplePayCertificatesPendingAction = {
  type: ApplePayCertificatesPendingType
};
type ApplePayCertificatesSuccessAction = {
  type: ApplePayCertificatesSuccessType,
  payload: {
    data: Array<Certificate>
  }
};
type ApplePayCertificatesErrorAction = {
  type: ApplePayCertificatesErrorType,
  payload: {
    data: string
  }
};

export const APPLE_PAY_CERTIFICATES_TYPES: {
  PENDING: ApplePayCertificatesPendingType,
  SUCCESS: ApplePayCertificatesSuccessType,
  ERROR: ApplePayCertificatesErrorType
} = {
  PENDING: 'APPLE_PAY_CERTIFICATES_PENDING',
  SUCCESS: 'APPLE_PAY_CERTIFICATES_SUCCESS',
  ERROR: 'APPLE_PAY_CERTIFICATES_ERROR'
};

type ApplePayUploadCertificatePendingType = 'APPLE_PAY_UPLOAD_CERTIFICATE_PENDING';
type ApplePayUploadCertificateSuccessType = 'APPLE_PAY_UPLOAD_CERTIFICATE_SUCCESS';
type ApplePayUploadCertificateErrorType = 'APPLE_PAY_UPLOAD_CERTIFICATE_ERROR';

type ApplePayUploadCertificatePendingAction = {
  type: ApplePayUploadCertificatePendingType,
  progress?: number
};
type ApplePayUploadCertificateSuccessAction = {
  type: ApplePayUploadCertificateSuccessType
};
type ApplePayUploadCertificateErrorAction = {
  type: ApplePayUploadCertificateErrorType,
  payload: {
    data: string
  }
};

export const APPLE_PAY_UPDATE_CERTIFICATE_TYPES: {
  PENDING: ApplePayUploadCertificatePendingType,
  SUCCESS: ApplePayUploadCertificateSuccessType,
  ERROR: ApplePayUploadCertificateErrorType
} = {
  PENDING: 'APPLE_PAY_UPLOAD_CERTIFICATE_PENDING',
  SUCCESS: 'APPLE_PAY_UPLOAD_CERTIFICATE_SUCCESS',
  ERROR: 'APPLE_PAY_UPLOAD_CERTIFICATE_ERROR'
};

export type CertificatesState = {
  loading: boolean,
  success: boolean,
  error: boolean,
  errorMessage: string,
  list: Array<Certificate>
};

export type UploadCertificateState = {
  loading: boolean,
  success: boolean,
  error: boolean,
  progress: ?number,
  errorMessage: string
};

export type ApplePayState = {
  certificates: CertificatesState,
  uploadCertificate: UploadCertificateState
};

type Action =
  | ApplePayCertificatesPendingAction
  | ApplePayCertificatesSuccessAction
  | ApplePayCertificatesErrorAction
  | ApplePayUploadCertificatePendingAction
  | ApplePayUploadCertificateSuccessAction
  | ApplePayUploadCertificateErrorAction;

export const DEFAULT_STATE: ApplePayState = {
  certificates: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    list: []
  },
  uploadCertificate: {
    loading: false,
    success: false,
    error: false,
    progress: null,
    errorMessage: ''
  }
};

export default function reducer(state: ApplePayState = DEFAULT_STATE, action: Action) {
  switch (action.type) {
    case APPLE_PAY_CERTIFICATES_TYPES.PENDING:
      return {
        ...state,
        certificates: {
          ...DEFAULT_STATE.certificates,
          loading: true
        }
      };
    case APPLE_PAY_CERTIFICATES_TYPES.SUCCESS:
      return {
        ...state,
        certificates: {
          ...DEFAULT_STATE.certificates,
          success: true,
          list: action.payload.data
        }
      };
    case APPLE_PAY_CERTIFICATES_TYPES.ERROR:
      return {
        ...state,
        certificates: {
          error: true,
          errorMessage: action.payload.data
        }
      };
    case APPLE_PAY_UPDATE_CERTIFICATE_TYPES.PENDING:
      return {
        ...state,
        uploadCertificate: {
          ...DEFAULT_STATE.uploadCertificate,
          loading: true,
          progress: action.progress || null
        }
      };
    case APPLE_PAY_UPDATE_CERTIFICATE_TYPES.SUCCESS:
      return {
        ...state,
        uploadCertificate: {
          ...DEFAULT_STATE.uploadCertificate,
          success: true
        }
      };
    case APPLE_PAY_UPDATE_CERTIFICATE_TYPES.ERROR:
      return {
        ...state,
        uploadCertificate: {
          ...DEFAULT_STATE.uploadCertificate,
          error: true,
          errorMessage: action.payload.data
        }
      };
    default:
      return state;
  }
}

export type GetCertificates = (accountId: number, businessId: number) => Function;

export function getCertificates(accountId: number, businessId: number) {
  const { PENDING, SUCCESS, ERROR } = APPLE_PAY_CERTIFICATES_TYPES;
  const request = applePayAPI.getCertificates(accountId, businessId);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, true, true);
}

export type UploadCertificate = (
  accountId: number,
  businessId: number,
  certificate: File,
  updateProgress: (processedSize: number) => void,
  errorHandler: (errorMessage: string) => void,
  load: (fileId: string) => void
) => Function;

export function uploadCertificate(
  accountId: number,
  businessId: number,
  certificate: File,
  updateProgress: (processedSize: number) => void,
  errorHandler: (errorMessage: string) => void,
  load: (fileId: string) => void
) {
  return (dispatch: Dispatch<any>, getState: () => StoreState) => {
    const { PENDING, SUCCESS, ERROR } = APPLE_PAY_UPDATE_CERTIFICATE_TYPES;
    const extention = certificate.name.slice(-3, certificate.name.length);
    if (extention !== 'cer') {
      // dispatch PENDING event before ERROR so that we can
      // show error notification every time when user reuploads a file
      dispatch({
        type: PENDING,
        progress: 0
      });

      dispatch({
        type: ERROR,
        payload: {
          data: 'Incorrect certificate extension'
        }
      });
      errorHandler();
      return;
    }

    const request = applePayAPI.uploadCertificate(
      accountId,
      businessId,
      certificate,
      (progressEvent: ProgressEvent) => {
        dispatch({
          type: PENDING,
          progress: progressEvent.loaded
        });
        updateProgress(progressEvent.loaded);
      }
    );

    request
      .then(() => load())
      .catch(() => {
        errorHandler();
      });

    const req = dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, true, true)(dispatch);

    return req;
  };
}
