// @flow
import { produce } from 'utils/tree';
import * as formTypes from 'types/form';

export type State = {
  description: string,
  file: {
    loading: boolean,
    error: string,
    id: string,
    filename: string,
    content: string,
  },
};

export type HydrateAction = {
  type: 'EVIDENCE_HYDRATE_ADDITIONAL',
  state: State,
};
export function hydrate(state: State): HydrateAction {
  return {
    state,
    type: 'EVIDENCE_HYDRATE_ADDITIONAL',
  };
}

export type ClearAction = {
  type: 'EVIDENCE_CLEAR_ADDITIONAL',
};
export function clear(): ClearAction {
  return {
    type: 'EVIDENCE_CLEAR_ADDITIONAL',
  };
}

export type StreamAdditionalDescriptionAction = formTypes.StreamText<
  'EVIDENCE_STREAM_ADDITIONAL_DESCRIPTION'
>;
export function streamAdditionalDescription(
  value: string
): StreamAdditionalDescriptionAction {
  return {
    value,
    type: 'EVIDENCE_STREAM_ADDITIONAL_DESCRIPTION',
  };
}

export type UploadAdditionalEvidenceStartAction = {
  type: 'EVIDENCE_UPLOAD_ADDITIONAL_START',
};
export function uploadAdditionalEvidenceStart(): UploadAdditionalEvidenceStartAction {
  return {
    type: 'EVIDENCE_UPLOAD_ADDITIONAL_START',
  };
}

export type ClearAdditionalEvidenceAction = {
  type: 'EVIDENCE_CLEAR_ADDITIONAL_EVIDENCE',
};
export function clearAdditionalEvidence(): ClearAdditionalEvidenceAction {
  return {
    type: 'EVIDENCE_CLEAR_ADDITIONAL_EVIDENCE',
  };
}

export type UploadAdditionalEvidenceSuccessAction = formTypes.UploadSuccess<
  'EVIDENCE_UPLOAD_ADDITIONAL_SUCCESS'
>;
export function uploadAdditionalEvidenceSuccess(
  filename: string,
  content: string
): UploadAdditionalEvidenceSuccessAction {
  return {
    filename,
    content,
    type: 'EVIDENCE_UPLOAD_ADDITIONAL_SUCCESS',
  };
}

type UploadAdditionalEvidenceFailureAction = formTypes.UploadFailure<
  'EVIDENCE_UPLOAD_ADDITIONAL_FAILURE'
>;
export type UploadAdditionalEvidenceFailure = (
  filename: string
) => UploadAdditionalEvidenceFailureAction;
export function uploadAdditionalEvidenceFailure(
  error: string
): UploadAdditionalEvidenceFailureAction {
  return {
    error,
    type: 'EVIDENCE_UPLOAD_ADDITIONAL_FAILURE',
  };
}

type Action =
  | HydrateAction
  | ClearAction
  | StreamAdditionalDescriptionAction
  | UploadAdditionalEvidenceStartAction
  | ClearAdditionalEvidenceAction
  | UploadAdditionalEvidenceSuccessAction
  | UploadAdditionalEvidenceFailureAction;

const DEFAULT_STATE: State = {
  description: '',
  file: {
    loading: false,
    error: '',
    id: '',
    filename: '',
    content: '',
  },
};

export function reducer(state: State = DEFAULT_STATE, action: Action): State {
  return produce(state, function(draft) {
    switch (action.type) {
      case 'EVIDENCE_HYDRATE_ADDITIONAL':
        return action.state;

      case 'EVIDENCE_CLEAR_ADDITONAL':
        return DEFAULT_STATE;

      case 'EVIDENCE_STREAM_ADDITIONAL_DESCRIPTION':
        draft.description = action.value;
        break;

      case 'EVIDENCE_UPLOAD_ADDITIONAL_START':
        draft.file.loading = true;
        break;

      case 'EVIDENCE_CLEAR_ADDITIONAL_EVIDENCE':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: '',
          content: '',
        };
        break;

      case 'EVIDENCE_UPLOAD_ADDITIONAL_SUCCESS':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: action.filename,
          content: action.content,
        };
        break;

      case 'EVIDENCE_UPLOAD_ADDITIONAL_FAILURE':
        draft.file.loading = false;
        draft.file.error = action.error;
        break;
      default: break;
    }
  });
}
