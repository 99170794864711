// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { BrowserRouter } from 'react-router-dom';
import * as localApi from 'services/localDataApi';
import * as globalDuck from 'store/global-duck';
import type { StoreState } from 'store/reducers';
import RoutesConfig from './routes/';
import WelcomeModal from 'components/ui/welcome-modal/';
import * as userDuck from 'store/user-duck';
import moment from 'moment-timezone';
import './styles/app.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import appConfig from "./App-config";
import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";

type StateProps = {|
  isWelcomeBannerVisible: boolean,
  userSuccess: boolean
|};

type DispatchProps = {|
  logout: userDuck.Logout,
  handleCachedLogin: userDuck.HandleCachedLogin,
  loadCachedGlobal: globalDuck.LoadCachedGlobal,
  setWelcomeBanner: globalDuck.SetWelcomeBanner
|};

type Props = StateProps & DispatchProps;

const oktaAuth = new OktaAuth(appConfig.okta);

export const App = (props: Props) => {
  const defaults = {
    isAuthenticated: localApi.isAuthenticated(),
    cachedGlobalState: localApi.globalState.get(),
    userData: localApi.user.get()
  };
  const [{ isAuthenticated, cachedGlobalState, userData }] = useState(defaults);
  useEffect(() => {
    const { user: { account = false } = {} } = localApi.user.get();
    if (account && props.userSuccess) {
      moment.tz.setDefault(account.timezone || 'Europe/London');
    }
  });

  if (isAuthenticated) {
    // when user logged in successfully
    // we need to check the local-storage about when they seen the nas-migration-popup
    // if the difference is greater or equal than 24 hours, we should clear and show popup again
    var hourDifference = Math.abs(new Date() - new Date(localApi.hasSeenNasMigration.getDate())) / (1000 * 60 * 60);

    if (hourDifference >= 24) {
      localApi.hasSeenNasMigration.clear();
    }

    if (isEmpty(cachedGlobalState) || !userData.user) {
      props.logout(oktaAuth);
      return null;
    }
    props.handleCachedLogin(userData);
    props.loadCachedGlobal(cachedGlobalState);
  }

  return (
    <>
      {props.isWelcomeBannerVisible && <WelcomeModal />}
      <Security oktaAuth={oktaAuth} restoreOriginalUri={() => undefined}>
        <BrowserRouter basename="/">
          <RoutesConfig />
        </BrowserRouter>
      </Security>
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  isWelcomeBannerVisible: state.globalState.isWelcomeBannerVisible,
  userSuccess: state.userState.user.success
});

const mapActionsToProps: DispatchProps = {
  logout: userDuck.logout,
  handleCachedLogin: userDuck.handleCachedLogin,
  loadCachedGlobal: globalDuck.loadCachedGlobal,
  setWelcomeBanner: globalDuck.setWelcomeBanner
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
