// @flow
import { produce } from 'utils/tree';

export type ToggleSubmissionModeAction = {
  type: 'EVIDENCE_TOGGLE_SUBMISSION_MODE',
};
export type ToggleSubmissionMode = () => ToggleSubmissionModeAction;
export function toggleSubmissionMode(): ToggleSubmissionModeAction {
  return {
    type: 'EVIDENCE_TOGGLE_SUBMISSION_MODE'
  };
}

export type State = {
  generalSubmission: boolean,
};

export type Action = ToggleSubmissionModeAction;

const DEFAULT_STATE: State = {
  generalSubmission: true
};

export function reducer(state: State = DEFAULT_STATE, action: Action): State {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'EVIDENCE_TOGGLE_SUBMISSION_MODE':
        draft.generalSubmission = !draft.generalSubmission;
        break;
      default: break;
    }
  });
}
