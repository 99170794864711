import { ajxPromise } from 'services/appRequest';
import { validateParams } from 'utils/api.util';
import axios, { baseURL } from 'services/axios';
import { isProductionServer } from 'utils';
import { getToken } from 'services/localDataApi';

const reportPath = isProductionServer() ? 'reporting.api' : 'query';

/**
 * Get the list of reports via an api call
 */
export const getReports = (requestParams) => {
  const requiredParams = ['entityId', 'entityTypeId', 'migratedToGwc'];
  const errorList = validateParams(requestParams, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(`Reports.getReports() is missing {${errorList}} param(s)`);
  }
  const { entityId, entityTypeId, migratedToGwc } = requestParams;
  return axios.get(
    `${baseURL}${reportPath}/reports/v2?entityId=${entityId}&entityTypeId=${entityTypeId}&migratedToGwc=${migratedToGwc}`
  );
};

// There is no way to download csv file using direct link to the file,
// because token should be passed as a header but not as an url parameter.
// Workaround is to make get request and get row csv text,
// create a blob from this text and save it using browser-filesaver library.

// UPD: changed download method: browser-filesaver library was removed to avoid Blob as It has limitation up to 500Mb
// used direct download link with x-authtoken as an url parameter.
export const getReportCSV = (url: string, name: string) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = `${url}&x-authtoken=${getToken()}`;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

/**
 * Generate reports via an api call
 * @param {object} params payload to the request (e.g. date, reports list)
 */
export const generateReports = (params) => {
  const requiredParams = [
    'entityTypeId',
    'entityId',
    'fromDate',
    'toDate',
    'reports',
    'migratedToGwc'
  ];

  const errorList = validateParams(params, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(`Reports.generateReports() is missing {${errorList}} param(s)`);
  }
  return ajxPromise.post(`${baseURL}${reportPath}/reports/v2/generate`, params);
};
