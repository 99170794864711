// Store
import { dispatchResponse } from 'utils/action.util';
import { Action } from './Action';
import * as RiskTypes from './Types';
import type { RiskState } from './Types';
import { DEFAULT_STATE } from './State';

// API
import * as RiskAPI from 'services/resources/risks.api';

export default function reducer(
  state: RiskState = DEFAULT_STATE,
  action: Action
) {
  switch (action.type) {
    case RiskTypes.RISK_VELOCITY_TYPES.PENDING:
      return {
        ...state,
        velocity: {
          ...state.velocity,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.RISK_VELOCITY_TYPES.ERROR:
      return {
        ...state,
        velocity: {
          ...state.velocity,
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.RISK_VELOCITY_TYPES.SUCCESS:
      return {
        ...state,
        velocity: {
          loading: false,
          error: false,
          success: true,
          data: action.payload.data
        }
      };
    case RiskTypes.RISK_ACTIONS_TYPES.PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.RISK_ACTIONS_TYPES.ERROR:
      return {
        ...state,
        actions: {
          ...state.actions,
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.RISK_ACTIONS_TYPES.SUCCESS:
      return {
        ...state,
        actions: {
          loading: false,
          error: false,
          success: true,
          list: action.payload
        }
      };
    case RiskTypes.SAVE_VELOCITY.PENDING:
      return {
        ...state,
        saveVelocity: {
          ...state.saveVelocity,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.SAVE_VELOCITY.ERROR:
      return {
        ...state,
        saveVelocity: {
          loading: false,
          error: true,
          success: false,
          errorMessage: action.payload.data?.message
        }
      };
    case RiskTypes.SAVE_VELOCITY.SUCCESS:
      return {
        ...state,
        saveVelocity: {
          errorMessage: null,
          loading: false,
          error: false,
          success: true
        },
        modalConfirmation: {
          ...state.modalConfirmation,
          open: false
        },
        feedback: {
          show: true
        }
      };
    case RiskTypes.CONFIRMATION_MODAL_TYPES.OPEN:
      return {
        ...state,
        modalConfirmation: {
          ...state.modalConfirmation,
          open: true
        }
      };
    case RiskTypes.CONFIRMATION_MODAL_TYPES.CLOSE:
      return {
        ...state,
        modalConfirmation: {
          ...state.modalConfirmation,
          open: false
        }
      };
    case RiskTypes.RISK_FEEDBACK_TYPES.SHOW:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          show: true
        }
      };
    case RiskTypes.RISK_FEEDBACK_TYPES.CLOSE:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          show: false
        }
      };
    case RiskTypes.CANCEL_VELOCITY_PENDING.PENDING:
      return {
        ...state,
        cancelVelocityPending: {
          ...state.cancelVelocityPending,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.CANCEL_VELOCITY_PENDING.ERROR:
      return {
        ...state,
        cancelVelocityPending: {
          loading: false,
          error: true,
          success: false,
          errorMessage: action.payload.data?.message
        }
      };
    case RiskTypes.CANCEL_VELOCITY_PENDING.SUCCESS:
      return {
        ...state,
        cancelVelocityPending: {
          errorMessage: null,
          loading: false,
          error: false,
          success: true
        },
        modalConfirmation: {
          ...state.modalConfirmation,
          open: false
        },
        feedback: {
          show: true
        }
      };
    case RiskTypes.CANCEL_VERIFIED_INFO_PENDING.PENDING:
      return {
        ...state,
        cancelVerifiedInfoPending: {
          ...state.cancelVelocityPending,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.CANCEL_VERIFIED_INFO_PENDING.ERROR:
      return {
        ...state,
        cancelVerifiedInfoPending: {
          loading: false,
          error: true,
          success: false,
          errorMessage: action.payload.data?.message
        }
      };
    case RiskTypes.CANCEL_VERIFIED_INFO_PENDING.SUCCESS:
      return {
        ...state,
        cancelVerifiedInfoPending: {
          errorMessage: null,
          loading: false,
          error: false,
          success: true
        },
        modalConfirmation: {
          ...state.modalConfirmation,
          open: false
        },
        feedback: {
          show: true
        }
      };
    case RiskTypes.CANCEL_HIGH_RISK_COUNTRY_PENDING.PENDING:
      return {
        ...state,
        cancelHighRiskCountryPending: {
          ...state.cancelHighRiskCountryPending,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.CANCEL_HIGH_RISK_COUNTRY_PENDING.ERROR:
      return {
        ...state,
        cancelHighRiskCountryPending: {
          loading: false,
          error: true,
          success: false,
          errorMessage: action.payload.data?.message
        }
      };
    case RiskTypes.CANCEL_HIGH_RISK_COUNTRY_PENDING.SUCCESS:
      return {
        ...state,
        cancelHighRiskCountryPending: {
          errorMessage: null,
          loading: false,
          error: false,
          success: true
        },
        modalConfirmation: {
          ...state.modalConfirmation,
          open: false
        },
        feedback: {
          show: true
        }
      };
    case RiskTypes.VELOCITY_PROPERTY_TYPES.PENDING:
      return {
        ...state,
        property: {
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.VELOCITY_PROPERTY_TYPES.ERROR:
      return {
        ...state,
        property: {
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.VELOCITY_PROPERTY_TYPES.SUCCESS:
      return {
        ...state,
        property: {
          loading: false,
          error: false,
          success: true
        }
      };
    case RiskTypes.GET_VERIFIED_INFO_TYPES.PENDING:
      return {
        ...state,
        verifiedInfo: {
          ...state.verifiedInfo,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.GET_VERIFIED_INFO_TYPES.ERROR:
      return {
        ...state,
        verifiedInfo: {
          ...state.verifiedInfo,
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.GET_VERIFIED_INFO_TYPES.SUCCESS:
      return {
        ...state,
        verifiedInfo: {
          loading: false,
          error: false,
          success: true,
          data: action.payload.data
        }
      };
    case RiskTypes.GET_HIGH_RISK_COUNTRIES_TYPES.PENDING:
      return {
        ...state,
        highRiskCountries: {
          ...state.highRiskCountries,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.GET_HIGH_RISK_COUNTRIES_TYPES.ERROR:
      return {
        ...state,
        highRiskCountries: {
          ...state.highRiskCountries,
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.GET_HIGH_RISK_COUNTRIES_TYPES.SUCCESS:
      return {
        ...state,
        highRiskCountries: {
          ...state.highRiskCountries,
          loading: false,
          error: false,
          success: true,
          data: action.payload.data
        }
      };
    case RiskTypes.SAVE_VERIFIED_INFO_RULES_TYPES.PENDING:
      return {
        ...state,
        saveVerifiedInfoRules: {
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.SAVE_VERIFIED_INFO_RULES_TYPES.ERROR:
      return {
        ...state,
        saveVerifiedInfoRules: {
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.SAVE_VERIFIED_INFO_RULES_TYPES.SUCCESS:
      return {
        ...state,
        saveVerifiedInfoRules: {
          loading: false,
          error: false,
          success: true
        },
        modalConfirmation: {
          ...state.modalConfirmation,
          open: false
        },
        feedback: {
          show: true
        }
      };
    case RiskTypes.SAVE_HIGH_RISK_COUNTRY_TYPES.PENDING:
      return {
        ...state,
        saveHighRiskCountry: {
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.SAVE_HIGH_RISK_COUNTRY_TYPES.ERROR:
      return {
        ...state,
        saveHighRiskCountry: {
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.SAVE_HIGH_RISK_COUNTRY_TYPES.SUCCESS:
      return {
        ...state,
        saveHighRiskCountry: {
          loading: false,
          error: false,
          success: true
        },
        modalConfirmation: {
          ...state.modalConfirmation,
          open: false
        }
      };
    case RiskTypes.RISK_BLACKLIST_ATTRIBUTES_TYPES.PENDING:
      return {
        ...state,
        blacklistAttributes: {
          ...state.blacklistAttributes,
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.RISK_BLACKLIST_ATTRIBUTES_TYPES.ERROR:
      return {
        ...state,
        blacklistAttributes: {
          ...state.blacklistAttributes,
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.RISK_BLACKLIST_ATTRIBUTES_TYPES.SUCCESS:
      return {
        ...state,
        blacklistAttributes: {
          loading: false,
          error: false,
          success: true,
          data: action.payload.data
        }
      };
    case RiskTypes.RISK_BLACKLIST_TYPES.PENDING:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          loading: true,
          error: false,
          success: false,
        }
      };
    case RiskTypes.RISK_BLACKLIST_TYPES.ERROR:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.RISK_BLACKLIST_TYPES.SUCCESS:
      return {
        ...state,
        blacklist: {
          loading: false,
          error: false,
          success: true,
          data: action.payload.data
        }
      };
    case RiskTypes.ADD_BLACKLIST_RULE_TYPES.PENDING:
      return {
        ...state,
        addBlackListRuleState: {
          loading: true,
          error: false,
          success: false,
          errorMessage: null
        }
      };
    case RiskTypes.ADD_BLACKLIST_RULE_TYPES.ERROR:
      return {
        ...state,
        addBlackListRuleState: {
          loading: false,
          error: true,
          success: false,
          errorMessage: action.payload.data?.message
        }
      };
    case RiskTypes.ADD_BLACKLIST_RULE_TYPES.SUCCESS:
      return {
        ...state,
        addBlackListRuleState: {
          loading: false,
          error: false,
          success: true,
          errorMessage: null
        },
        feedback: {
          show: true
        }
      };
    case RiskTypes.RISK_BLACKLIST_DELETE_TYPES.PENDING:
      return {
        ...state,
        blacklistDelete: {
          loading: true,
          error: false,
          success: false
        }
      };
    case RiskTypes.RISK_BLACKLIST_DELETE_TYPES.ERROR:
      return {
        ...state,
        blacklistDelete: {
          loading: false,
          error: true,
          success: false
        }
      };
    case RiskTypes.RISK_BLACKLIST_DELETE_TYPES.SUCCESS:
      return {
        ...state,
        blacklistDelete: {
          loading: false,
          error: false,
          success: true
        }
      };
    default:
      return state;
  }
}

export type SaveVerifiedInfoRules = (
  accountId: string | number,
  businessId: string | number,
  verifiedInfoRules: any,
  password: string
) => Function;
export function saveVerifiedInfoRules(
  accountId: string | number,
  businessId: string | number,
  verifiedInfoRules,
  password: string
) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.SAVE_VERIFIED_INFO_RULES_TYPES;
  const request = RiskAPI.saveVerifiedInfoRules(accountId, businessId, verifiedInfoRules, password);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type SaveHighRiskCountries = (
  accountId: string | number,
  businessId: string | number,
  countries: string[],
  password: string
) => Function;
export function saveHighRiskCountries(
  accountId: string | number,
  businessId: string | number,
  countries: string[],
  password: string
) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.SAVE_HIGH_RISK_COUNTRY_TYPES;
  const request = RiskAPI.saveHighRiskCountries(accountId, businessId, { countries }, password);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type GetVerifiedInfo = (string | number, string | number) => Function;
export function getVerifiedInfo(accountId: string | number, businessId: string | number) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.GET_VERIFIED_INFO_TYPES;
  const request = RiskAPI.getVerifiedInfoRules(accountId, businessId);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type GetHighRiskCountries = (string | number, string | number) => Function;
export function getHighRiskCountries(accountId: string | number, businessId: string | number) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.GET_HIGH_RISK_COUNTRIES_TYPES;
  const request = RiskAPI.getHighRiskCountries(accountId, businessId);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type GetVelocityRules = (string | number, string | number) => Function;
export function getVelocityRules(accountId: string | number, businessId: string | number) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.RISK_VELOCITY_TYPES;
  const request = RiskAPI.getVelocityRules(accountId, businessId);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type GetVelocityProperties = (string | number, string | number) => Function;
export function getVelocityProperties(accountId: string | number, propertyId: string | number) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.VELOCITY_PROPERTY_TYPES;
  const request = RiskAPI.getVelocityProperties(accountId, propertyId);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type GetVelocityActions = (string | number, string | number) => Function;
export function getVelocityActions(accountId: string | number, businessId: string | number) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.RISK_ACTIONS_TYPES;
  const request = RiskAPI.getVelocityActions(accountId, businessId);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type SaveVelocityRules = (
  accountId: string | number,
  businessId: string | number,
  velocityRules: any,
  password: string
) => Function;
export function saveVelocityRules(
  accountId: string | number,
  businessId: string | number,
  velocityRules,
  password: string
) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.SAVE_VELOCITY;
  const request = RiskAPI.saveVelocityRules(accountId, businessId, velocityRules, password);
  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type CancelVelocityPending = (
  accountId: string | number,
  businessId: string | number,
  password: string
) => Function;
export function cancelVelocityPending(
  accountId: string | number,
  businessId: string | number,
  password: string
) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.CANCEL_VELOCITY_PENDING;
  const request = RiskAPI.cancelVelocityPending(accountId, businessId, password);

  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type CancelVerifiedInfoPending = (
  accountId: string | number,
  businessId: string | number,
  password: string
) => Function;
export function cancelVerifiedInfoPending(
  accountId: string | number,
  businessId: string | number,
  password: string
) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.CANCEL_VERIFIED_INFO_PENDING;
  const request = RiskAPI.cancelVerifiedInfoPending(accountId, businessId, password);

  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type CancelHighRiskCountryPending = (
  accountId: string | number,
  businessId: string | number,
  password: string
) => Function;
export function cancelHighRiskCountryPending(
  accountId: string | number,
  businessId: string | number,
  password: string
) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.CANCEL_HIGH_RISK_COUNTRY_PENDING;
  const request = RiskAPI.cancelHighRiskCountryPending(accountId, businessId, password);

  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type OpenConfirmationModal = () => ({ type: RiskTypes.CONFIRMATION_MODAL_TYPES.OPEN });
export const openConfirmationModal = () => {
  return { type: RiskTypes.CONFIRMATION_MODAL_TYPES.OPEN };
};

export type CloseConfirmationModal = () => ({ type: RiskTypes.CONFIRMATION_MODAL_TYPES.CLOSE });
export const closeConfirmationModal = () => {
  return { type: RiskTypes.CONFIRMATION_MODAL_TYPES.CLOSE };
};

export type ShowFeedbackModal = () => ({ type: RiskTypes.RISK_FEEDBACK_TYPES.SHOW });
export const showFeedbackModal = () => {
  return { type: RiskTypes.RISK_FEEDBACK_TYPES.SHOW };
};

export type CloseFeedbackModal = () => ({ type: RiskTypes.RISK_FEEDBACK_TYPES.CLOSE });
export const closeFeedbackModal = () => {
  return { type: RiskTypes.RISK_FEEDBACK_TYPES.CLOSE };
};

export type GetBlacklistAttributes = (accountId: string | number) => Function;
export function getBlacklistAttributes(accountId: string | number) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.RISK_BLACKLIST_ATTRIBUTES_TYPES;
  const request = RiskAPI.getBlacklistAttributes(accountId);

  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type GetAllBlacklist = ({
  accountId: string | number, pageSize: string | number, search: string, startIndex: string | number
}) => Function;
export function getAllBlacklist(params : {
  accountId: string | number, pageSize: string | number, search: string, startIndex: string | number
}) {
  const {
    accountId,
    pageSize = 10,
    search = '',
    startIndex = 0
  } = params;
  const { PENDING, ERROR, SUCCESS } = RiskTypes.RISK_BLACKLIST_TYPES;
  const request = RiskAPI.getAllBlacklist({
    accountId,
    pageSize,
    search,
    startIndex
  });

  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type AddBlackListRule = (
  accountId: string | number,
  attributeType: string,
  attributeValue: string
) => Function;
export function addBlackListRule(accountId, attributeType, attributeValue): AddBlackListRule {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.ADD_BLACKLIST_RULE_TYPES;
  const request = RiskAPI.addBlacklistRule(accountId, attributeType, attributeValue);

  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type DeleteBlacklistRules = (accountId: string | number, ruleIds: string[]) => Function;
export function deleteBlacklistRules(accountId: string | number, ruleIds: string[]) {
  const { PENDING, ERROR, SUCCESS } = RiskTypes.RISK_BLACKLIST_DELETE_TYPES;
  const request = RiskAPI.deleteBlacklistRules(accountId, ruleIds);

  return dispatchResponse(request, PENDING, { type: SUCCESS }, ERROR, false, true);
}

export type { RiskState };
