import { colors } from 'styles/common.sc';
import styled, { css } from 'react-emotion';

const warningColour = '#DE9A4B';

const getColour = (props, defaultColor) => {
  if (props.danger) {
    return colors.bg.alert;
  }

  if (props.warning) {
    return warningColour;
  }

  if (props.primary) {
    return colors.bg.green;
  }

  if (props.white) {
    return colors.white;
  }

  return defaultColor || colors.white;
};

const theme = props => {
  let border;

  if (props.solid) {
    return css`
      background-color: ${getColour(props, colors.riverBed)};
      border: none;
      color: ${props.white ? colors.riverBed : colors.white};
      padding: 5px 10px;
      border-radius: 2px;

      svg * {
        fill: ${props.white ? colors.riverBed : colors.white};
      }
    `;
  }

  if (props.placement === 'bottom') {
    border = `border-bottom: solid 2px ${getColour(props, colors.riverBed)}`;
  } else {
    border = `border-top: solid 2px ${getColour(props, colors.riverBed)}`;
  }

  return css`
    background-color: ${colors.white};
    color: ${colors.riverBed};
    padding: 8px 15px;
    border-radius: 3px;
    ${border};
  `;
};

const getTipColor = (props, defaultColor) => {
  if (!props.solid) {
    return colors.white;
  }

  if (props.danger) {
    return colors.bg.alert;
  } if (props.warning) {
    return warningColour;
  } if (props.primary) {
    return colors.bg.green;
  } if (props.white) {
    return colors.white;
  }
  return colors.riverBed;
};

export const Container = styled('div')`
  ${containerStyle};
`;

function containerStyle(props) {
  return css`
    display: inline-block;
    width: ${props.containerWidth || 'auto'};

    .inner-container {
      position: relative;
      text-align: center;

      .ant-tooltip {
        line-height: 1.5;
        color: ${colors.riverBed};
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        z-index: 1060;
        display: block;
        visibility: visible;
        width: ${props.width || 'auto'};
      }
      .ant-tooltip-hidden {
        display: none;
      }

      .ant-tooltip-placement-top,
      .ant-tooltip-placement-topLeft,
      .ant-tooltip-placement-topRight {
        padding-bottom: 8px;
      }
      .ant-tooltip-placement-right,
      .ant-tooltip-placement-rightTop,
      .ant-tooltip-placement-rightBottom {
        padding-left: 8px;
      }
      .ant-tooltip-placement-bottom,
      .ant-tooltip-placement-bottomLeft,
      .ant-tooltip-placement-bottomRight {
        padding-top: 8px;
      }
      .ant-tooltip-placement-left,
      .ant-tooltip-placement-leftTop,
      .ant-tooltip-placement-leftBottom {
        padding-right: 8px;
      }

      .ant-tooltip-inner {
        position: relative;
        font-size: 12px;
        max-width: 300px;
        text-align: center;
        text-decoration: none;
        ${theme(props)};

        &:after,
        &:before {
          display: block;
          content: '';
          position: absolute;
          z-index: -100;
          top: 0;
          bottom: 0;
          width: 50%;
          box-shadow: 0 2px 5px 0 rgba(121, 131, 149, 0.3);
        }

        &:after {
          right: 0;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        &:before {
          left: 0;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }

      .ant-tooltip-placement-top {
        .ant-tooltip-inner {
          &:after,
          &:before {
            width: 45%;
          }
        }
      }

      .ant-tooltip-arrow {
        position: absolute;
        z-index: -100;
        display: block;
        width: 1rem;
        height: 1rem;
        left: auto;
        bottom: -0.5rem;
        transform: translateX(-50%) rotate(45deg);
        box-shadow: 0 2px 5px 0 rgba(121, 131, 149, 0.3);
        background: ${getTipColor(props)};
      }

      .ant-tooltip-placement-topLeft {
        .ant-tooltip-inner {
          &:before {
            width: 15%;
          }
          &:after {
            width: 75%;
          }
        }
      }

      .ant-tooltip-placement-topRight {
        .ant-tooltip-inner {
          &:before {
            width: 75%;
          }
          &:after {
            width: 15%;
          }
        }
      }

      .ant-tooltip-placement-left {
        .ant-tooltip-arrow {
          box-shadow: 2px -1px 5px 0 rgba(121, 131, 149, 0.3);
          -webkit-box-shadow: 2px -1px 5px 0 rgba(121, 131, 149, 0.3);
        }

        .ant-tooltip-inner {
          &:before {
            right: 0;
            width: 100%;
            border-radius: 0px 3px 3px 0px;
            left: auto;
            height: 30%;
            box-shadow: 0px -2px 5px 0 rgba(121, 131, 149, 0.3);
            -webkit-box-shadow: 0px -2px 5px 0 rgba(121, 131, 149, 0.3);
          }
          &:after {
            right: 0;
            width: 100%;
            border-radius: 3px 0px 0px 3px;
            left: auto;
            height: 30%;
            bottom: 0;
            top: auto;
            box-shadow: 0px 2px 5px 0 rgba(121, 131, 149, 0.3);
            -webkit-box-shadow: 0px 2px 5px 0 rgba(121, 131, 149, 0.3);
          }
        }
      }

      .ant-tooltip-placement-right {
        .ant-tooltip-arrow {
          box-shadow: 0 0 5px 0 rgba(121, 131, 149, 0.3);
          -webkit-box-shadow: 0 0 5px 0 rgba(121, 131, 149, 0.3);
        }

        .ant-tooltip-inner {
          &:before {
            left: 0;
            width: 100%;
            border-radius: 0px 3px 3px 0px;
            right: auto;
            height: 30%;
            box-shadow: 0px -2px 5px 0 rgba(121, 131, 149, 0.3);
            -webkit-box-shadow: 0px -2px 5px 0 rgba(121, 131, 149, 0.3);
          }
          &:after {
            right: auto;
            width: 100%;
            border-radius: 3px 0px 0px 3px;
            left: 0;
            height: 30%;
            bottom: 0;
            top: auto;
            box-shadow: 0px 2px 5px 0 rgba(121, 131, 149, 0.3);
            -webkit-box-shadow: 0px 2px 5px 0 rgba(121, 131, 149, 0.3);
          }
        }
      }

      .ant-tooltip-placement-top .ant-tooltip-arrow,
      .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
      .ant-tooltip-placement-topRight .ant-tooltip-arrow {
        bottom: 6px;
      }

      .ant-tooltip-placement-top .ant-tooltip-arrow {
        left: 50%;
      }

      .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
        left: 16px;
      }
      .ant-tooltip-placement-topRight .ant-tooltip-arrow {
        right: 0;
        left: auto;
      }
      .ant-tooltip-placement-right .ant-tooltip-arrow,
      .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
      .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
        left: 10px;
        right: auto;
      }
      .ant-tooltip-placement-right .ant-tooltip-arrow {
        top: 50%;
        margin-top: -5px;
      }
      .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
        top: 8px;
      }
      .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
        bottom: 8px;
      }
      .ant-tooltip-placement-left .ant-tooltip-arrow,
      .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
      .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
        right: -3px;
        left: auto;
      }
      .ant-tooltip-placement-left .ant-tooltip-arrow {
        top: 50%;
        margin-top: -5px;
      }
      .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
        top: 38%;
      }
      .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
        bottom: 8px;
      }
      .ant-tooltip-placement-bottom .ant-tooltip-arrow,
      .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
      .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
        top: 6px;
      }
      .ant-tooltip-placement-bottom .ant-tooltip-arrow {
        left: 50%;
        margin-left: -5px;
      }
      .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
        left: 16px;
      }
      .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
        right: 16px;
      }
    }
  `;
}
