import type { RiskState } from './Types';

export const DEFAULT_STATE: RiskState = {
  velocity: {
    loading: false,
    error: false,
    success: false,
    data: []
  },
  actions: {
    loading: false,
    error: false,
    success: false,
    list: {
      data: []
    }
  },
  saveVelocity: {
    loading: false,
    error: false,
    success: false,
    errorMessage: null
  },
  modalConfirmation: {
    open: false
  },
  feedback: {
    show: false
  },
  cancelVelocityPending: {
    loading: false,
    error: false,
    success: false,
    errorMessage: null
  },
  verifiedInfo: {
    loading: true,
    error: false,
    success: false,
    data: null
  },
  highRiskCountries: {
    loading: true,
    error: false,
    success: false,
    data: null
  },
  saveVerifiedInfoRules: {
    loading: false,
    error: false,
    success: false
  },
  saveHighRiskCountry: {
    loading: false,
    error: false,
    success: false
  },
  cancelVerifiedInfoPending: {
    loading: false,
    error: false,
    success: false,
    errorMessage: null
  },
  cancelHighRiskCountryPending: {
    loading: false,
    error: false,
    success: false,
    errorMessage: null
  },
  blacklistAttributes: {
    loading: false,
    error: false,
    success: false,
    data: null
  },
  blacklist: {
    loading: false,
    error: false,
    success: false,
    data: null
  },
  addBlackListRuleState: {
    loading: false,
    error: false,
    success: false,
    errorMessage: null
  },
  blacklistDelete: {
    loading: false,
    error: false,
    success: false
  }
};
