import { text, colors, clearfix } from 'styles/common.sc';
import styled, { css } from 'react-emotion';
import Checkbox from 'antd/lib/checkbox';
import Radio from 'antd/lib/radio';

/**
 * Form wrap
 */
export const FormWrap = styled('div')`
  width: 300px;

  & .ant-form-item {
    margin-bottom: 14px;
  }

  & .ant-input-group-wrapper {
    background-color: ${colors.white};
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.athensGray};
    box-shadow: 0px 1px 3px 0px rgba(121, 131, 149, 0.15);
    overflow: hidden;
  }

  & .ant-input {
    border: none;
    background-color: ${colors.white};
    width: 100%;
    height: 42px;
    box-shadow: none;
  }

  & .ant-input-group-addon {
    border: none;
    background: transparent;

    & .anticon {
      color: ${colors.black};
      font-size: 13px;
    }
  }
`;

/**
 * Form Item
 */
export const FormItemWrap = styled('div')`
  .ant-row {
    margin-bottom: 15px;
  }

  .ant-form-item-label {
    color: ${colors.text.secondary};
    font-weight: ${text.medium};
    margin-bottom: 10px;
  }

  .has-error {
    .cko-field,
    .ant-select,
    .ant-input,
    .ant-input:focus {
      border-color: ${colors.bg.alert};
      box-shadow: 0 1px 3px 0 rgba(233, 109, 109, 0.45);
    }
  }

  .ant-form-explain {
    font-size: 12px;
    color: ${colors.bg.alert};
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const selectWrapStyle = props => {
  return css`
    width: 100%;
    position: relative;

    .placeholder-value {
      color: ${colors.text.adiacent};
    }

    .cko-icon {
      width: 17px;
      height: 12px;
      border-radius: 3px;
      box-shadow: 0 2px 5px 0 rgba(121, 131, 149, 0.2);
      overflow: hidden;
      margin-right: 10px;

      &.placeholder {
        box-shadow: none;
      }
    }

    .ant-select {
      ${fieldCommonStyles()};
      ${fieldTheme(props)};
      overflow: hidden;

      cursor: pointer;
      padding-right: 0;

      .ant-select-selection-selected-value,
      .ant-select-selection__placeholder {
        padding: 0 16px;
        width: 92%;

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .ant-select-selection__placeholder {
        color: ${colors.text.adiacent};
      }

      &.ant-select-open {
        border-top-color: #d0d3db;
        border-right-color: #d0d3db;
        border-left-color: #d0d3db;
        border-bottom-color: #eff0f4;
        box-shadow: 0 2px 5px 0 rgba(121, 131, 149, 0.25);
        border-radius: 3px 3px 0 0;
      }

      &.ant-select-disabled {
        ${disabledStyle()};
      }

      & .ant-select-search {
        position: absolute;
        top: 0;
        z-index: 888;
        width: 100%;
        overflow: hidden;
        border-bottom: solid 1px #eff0f4;
        ${fieldTheme(props)};

        & .ant-select-search__field__wrap {
          height: inherit;
          line-height: inherit;
        }

        & .ant-select-search__field {
          width: 100%;
          padding: 0 16px;
          color: ${colors.riverBed};
          height: inherit;
          line-height: inherit;
          border: none;

          &:focus,
          &:active {
            outline: none;
          }
        }
      }
    }

    & .ant-select-arrow {
      text-align: center;
      color: ${colors.text.tertiary};
      border: solid 1px transparent;
      margin: 4px 5px 0 0;

      ${selectArrowTheme};
      border-radius: 3px 3px 0 0;

      &:before {
        font-size: 4px;
        margin-top: -3px;
        margin-right: -1px;
      }
    }

    & .ant-select-open {
      & .ant-select-arrow {
        color: ${colors.icon.active};
        border-color: #eff0f4;
        border-bottom-color: ${colors.white};
      }
    }

    .listContainer {
      width: 100%;
      background: white;
      position: absolute;
      top: 0px;
      left: 0;

      .ant-select-dropdown {
        border: solid 1px #d0d3db;
        border-top: none;
        border-radius: 0 0 3px 3px;
        box-shadow: 0 2px 5px 0 rgba(121, 131, 149, 0.25);
        ${dropDownTheme};
      }
    }

    .ant-select-dropdown-menu {
      li {
        padding: 0 16px;
        ${selectListTheme};
        &:hover {
          background-color: #fafbfc;
          color: ${colors.text.links};
        }

        &.ant-select-dropdown-menu-item-selected {
          background-color: ${colors.bg.green};
          color: ${colors.white};
        }
      }
    }
  `;
};
/**
 * Select field
 */
export const SelectWrap = styled('div')`
  ${selectWrapStyle};
`;

/**
 * Input field
 */
export const InputWrap = styled('div')`
  ${inputTheme};
`;

const textareaWrapStyle = props => {
  return css`
    ${inputTheme(props)};
    min-height: 84px;
    max-width: 100%;
    line-height: auto;

    .ant-input,
    textarea {
      resize: none;
      padding-top: 12px;
    }
  `;
};
/**
 * Textarea field
 */
export const TextareaWrap = styled('div')`
  ${textareaWrapStyle};
`;

/**
 * Checkbox field
 */
export const CheckboxStyled = styled(Checkbox)``;

/**
 * Radio field
 */
export const RadioStyled = styled(Radio)`
  display: block;
  height: 32px;
  line-height: 32px;
`;

/**
 * Form Group
 */
export const CkoFormGroup = styled('div')`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  zoom: 1;
  display: block;
  ${clearfix};

  .cko-column {
    padding: 0;

    .cko-input,
    .ant-input,
    .ant-select {
      border-left-color: transparent;
      border-radius: 0px 3px 3px 0px;
    }

    .has-error {
      .cko-input,
      .ant-input,
      .ant-select {
        border-left-color: #cf5858;
      }
    }

    &:first-child {
      .ant-input,
      .ant-select {
        border-left-color: ${colors.athensGray};
        border-radius: 3px 0px 0px 3px;
      }
    }

    .ant-form-explain {
      display: none;
    }
  }
`;

/**
 * Helpers
 */
function fieldTheme(props) {
  if (props.size === 'large') {
    return `
      height: 42px;
      line-height: 42px;
    `;
  } else {
    return `
      height: 32px;
      line-height: 32px;
    `;
  }
}

function selectArrowTheme(props) {
  if (props.size === 'large') {
    return css`
      width: 32px;
      height: 37px;
      line-height: 37px;
    `;
  } else {
    return css`
      width: 32px;
      height: 27px;
      line-height: 27px;
    `;
  }
}

function selectListTheme(props) {
  if (props.size === 'large') {
    return css`
      height: 42px;
      line-height: 42px;
    `;
  } else {
    return css`
      line-height: 32px;
      height: 32px;
    `;
  }
}

function dropDownTheme(props) {
  if (props.size === 'large') {
    return `
      top: 42px !important;
    `;
  } else {
    return `
      top: 32px !important;
    `;
  }
}

function fieldCommonStyles() {
  return `
    width: 100%;
    background-color: ${colors.white};
    color: ${colors.text.secondary};
    border: solid 1px ${colors.border.default};
    font-size: 13px;
    font-weight: 400;
    box-shadow: 0 1px 3px 0 rgba(123, 141, 147, 0.15);
    border-radius: 3px;

    &:hover,
    &:focus {
      outline: none;
      border-color: ${colors.border.hover};
    }
  `;
}

function disabledStyle() {
  return `
    box-shadow: none;
    background-color: ${colors.form.disabled};
    border-color: rgba(223, 231, 235, 0.5);
    cursor: not-allowed;
    color: rgba(121, 131, 149, 0.5);

    ::-webkit-input-placeholder {
      color: rgba(121, 131, 149, 0.5);
    }
  `;
}

export function inputTheme(props) {
  let inputLeftPad = 33;
  let prefixPad = 10;

  if (props.size === 'large') {
    inputLeftPad = 43;
    prefixPad = 15;
  }

  return `
    width: 100%;
    ${fieldTheme(props)};

    .ant-input {
      padding: 0 16px;
      ${fieldCommonStyles()};
      height: 100%;

      &.ant-input-disabled {
        ${disabledStyle()};
      }
    }

    .ant-input-affix-wrapper {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      display: inline-block;
      width: inherit;
      height: inherit;

      .ant-input:not(:first-child) {
        padding-left: ${inputLeftPad}px;
      }

      .ant-input:not(:last-child) {
        padding-right: ${inputLeftPad}px;
      }

      .ant-input-prefix,
      .ant-input-suffix {
        position: absolute;
        top: 0;
        z-index: 2;
        height: inherit;
        line-height: inherit;
        color: #798395;
      }

      .ant-input-prefix {
        left: 0;
        padding-left: ${prefixPad}px;
      }

      .ant-input-suffix {
        right: 0;
        padding-right: ${prefixPad}px;
      }
    }
  `;
}
