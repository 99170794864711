// @flow
import * as React from 'react';
import Form from 'antd/lib/form';

import CkoAlert from 'components/ui/alert/';
import { IconActionEmail } from '@cko/icons';
import CkoInput from 'components/ui/form/CkoInput';
import CkoButton from 'components/ui/button';
import { ButtonGroup } from './ForgotPasswordForm.sc';
import { withRouter } from 'react-router-dom';

type Props = {|
  form: Object,
  onSubmit: Function,
  history: Object,
  error: {
    type: string,
    message: string | React.Node,
    description: string | React.Node,
  },
|};

export class ForgotPasswordForm extends React.Component<Props> {
  disableBtn(): boolean {
    const emailValue = this.props.form.getFieldValue('email');
    const hasErrors = this.props.form.getFieldError('email') || [];
    return !emailValue || (hasErrors && hasErrors.length > 0);
  }

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values.email);
      }
    });
  };

  render() {
    const { error, form, history } = this.props;

    return (
        <Form onSubmit={this.handleSubmit}>
          <CkoInput
            required
            id="email"
            type="email"
            form={form}
            placeholder="Email address"
            message="Please enter your email"
            size="large"
            prefix={<IconActionEmail style={{ margin: '12px 0 0 0' }}/>}
          />
          {error && error.type && <CkoAlert {...error} />}
          <ButtonGroup>
            <CkoButton
              disabled={this.disableBtn()}
              size="large"
              type="primary"
              htmlType="submit"
              value="Send me help"
            />
            <CkoButton
              size="large"
              type="secondary"
              value="Cancel"
              onClick={() => history.push('/')}
            />
          </ButtonGroup>

        </Form>
    );
  }
}

export default Form.create()(withRouter(ForgotPasswordForm));
