import { colors } from 'styles/common.sc';
import styled from 'react-emotion';

// Button Styled Component
export const Container = styled('div')`
         width: 100%;
         color: ${colors.white};
         text-align: left;
         padding: 10px;
         font-size: 12px;
         background-color: ${(props) =>
           props.type === 'success' ? colors.bg.green : colors.bg.alert};
         border-radius: 3px;
         box-shadow: 0 2px 5px 0 rgba(207, 88, 88, 0.45);

         .ant-alert {
           width: 100%;
           height: 100%;
         }

         .ant-alert-message,
         .ant-alert-description {
           display: block;
         }

         .ant-alert-message {
           font-size: 14px;
         }
       `;
