// @flow
import * as React from 'react';
import Form from 'antd/lib/form';
import { Link } from 'react-router-dom';
import { IconActionEmail, IconActionPassword } from '@cko/icons';
import CkoAlert from 'components/ui/alert/';
import CkoInput from 'components/ui/form/CkoInput';
import CkoButton from 'components/ui/button';
import * as Styled from './LoginForm.sc';
import { verify } from "../../../../services/security/webfinger";
import debounce from "lodash/debounce";

type Props = {|
  form: Object,
  onSubmit: Function,
  user: Object,
  alert?: {
    type: string,
    message: string | React.Node,
    description: string | React.Node,
  },
|};

class LoginForm extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isSso: false,
      isLoading: true,
      ssoUrl: '',
    };
  }

  static defaultProps = {
    alert: {
      type: 'info',
      message: null,
      description: null
    }
  };

  checkLoginType = async () => {
    this.setState({isLoading: true});
    const {form} = this.props;
    const email = form.getFieldValue('email');
    const idpResponse = await verify(email);

    if (idpResponse.type === 'SSO') {
      this.setState({
        isLoading: false,
        isSso: true,
        ssoUrl: idpResponse.ssoHref,
      });
    } else if (idpResponse.type === 'Credentials') {
      this.setState({
        isLoading: false,
        isSso: false,
        ssoUrl: '',
      });
    }
    return idpResponse.type === 'SSO';
  };

  handleEmailChange = debounce(this.checkLoginType, 1000);

  handleSubmit = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    const { form, onSubmit } = this.props;

    this.checkLoginType().then(isSso => {
      form.validateFields((err, {email, password}) => {
        if (!err) {
          onSubmit({email, password}, isSso, this.state.ssoUrl);
        }
      });
    });
  };

  render() {
    const { alert, form, user: { errorData } } = this.props;
    const attemptsLeft = errorData && parseInt(errorData.remainingLoginAttempts, 10);
    return (
      <Styled.Container>
        <Form onSubmit={this.handleSubmit} className="loginform" noValidate="novalidate">
          <CkoInput
            required
            id="email"
            data-cko-id="login-email"
            type="email"
            form={form}
            focususeronload="true"
            placeholder="Email address"
            message="Please enter your email"
            size="large"
            validateTrigger="onChange"
            prefix={<IconActionEmail style={{ margin: '12px 0 0 0' }}/>}
            onEnter={this.handleSubmit}
            onBlur={this.checkLoginType}
            onChange={this.handleEmailChange}
          />
          {!this.state.isSso && <CkoInput
            required
            id="password"
            data-cko-id="login-password"
            form={form}
            type="password"
            placeholder="Password"
            message="Please enter your password"
            size="large"
            className="login-password"
            prefix={<IconActionPassword style={{ margin: '12px 0 0 0' }}/>}
            onEnter={this.handleSubmit}
            validateTrigger="onChange"
            suffix={
              <Link to="/forgot-password">
                {attemptsLeft === 0 ? 'Unblock account' : 'Forgot?'}
              </Link>
            }
          />}
          {alert && alert.message && <CkoAlert {...alert} data-cko-id="error-msg" />}
          <CkoButton
            block={true}
            size="large"
            type="primary"
            htmlType="submit"
            className="login-btn"
            value={this.state.isSso ? "Log in with SSO" : "Log in"}
            id="login-btn"
            data-cko-id="login-btn"
          />
        </Form>
      </Styled.Container>
    );
  }
}

export default Form.create()(LoginForm);
