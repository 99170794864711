// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReactRouterDOM from 'react-router-dom';

import CkoIcon from 'components/ui/icon/';
import CkoSignInLayout from 'components/ui/layout/CkoSignInLayout';
import type { StoreState } from 'store/reducers';
import * as passwordDuck from 'store/password-duck';
import { Column, Title } from 'components/ui/layout/CkoSignInLayout';
import ForgotPasswordForm from './forgot-password-form/';

type OwnProps = {|
  history: ReactRouterDOM.RouterHistory,
|};

type StateProps = {|
  requestPassword: passwordDuck.RequestPasswordState,
|};

type DispatchProps = {|
  clearPassword: passwordDuck.ClearPassword,
  requestResetPassword: passwordDuck.RequestResetPassword,
|};

type Props = OwnProps & StateProps & DispatchProps;

export class ForgotPassword extends React.Component<Props> {
  componentDidMount() {
    this.props.clearPassword();
  }

  componentDidUpdate(prevProps: Props) {
    const isPasswordLoaded =
      prevProps.requestPassword.loading && this.props.requestPassword.success;
    if (isPasswordLoaded) {
      this.props.history.push('/login');
    }
  }

  // where do we get message from
  render() {
    const { requestPassword } = this.props;
    const errorData: {
      type: string,
      message: string,
      description: string,
    } = {
      type: 'error',
      message: 'Error',
      description: requestPassword.errorData.message,
    };

    return (
      <CkoSignInLayout loading={requestPassword.loading}>
        <Column align="center">
          <CkoIcon name="logo" className="logo" width="30px" />
          <Title>Locked yourself out?</Title>
          <div className="forgot-password-container">
            <p className="form-info">
              Don&apos;t worry, we will send you help. Just enter your e-mail
              below to receive password reset instructions.
            </p>
            <ForgotPasswordForm
              onSubmit={this.props.requestResetPassword}
              error={requestPassword.error && errorData}
            />
          </div>
        </Column>
      </CkoSignInLayout>
    );
  }
}

const mapActionsToProps: DispatchProps = {
  clearPassword: passwordDuck.clearPassword,
  requestResetPassword: passwordDuck.requestResetPassword,
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    requestPassword: state.passwordState.requestPassword,
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ForgotPassword);
