// @flow
import * as ReportsAPI from 'services/resources/reports.api';
import { dispatchResponse, getEntity } from 'utils/action.util';

type Report = {
  code: string,
  creationDate: string,
  description: string,
  isFailed: boolean,
  isReady: boolean,
  name: string,
  size: number,
  fromDate: string,
  toDate: string,
  url: string,
};

type ReportCategoty = {
  category: string,
  code: string,
  description: string,
  generatedReports: Array<Report>,
};

type ReportsMap = {
  rpdt?: ReportCategoty,
  rpst?: ReportCategoty,
  rptf?: ReportCategoty,
  rpcb?: ReportCategoty,
  rra?: ReportCategoty,
  rpr?: ReportCategoty,
};

export type ReportsData = {
  loading: boolean,
  error: boolean,
  reports: ReportsMap | {},
};

export type Generate = {
  loading: boolean,
  error: boolean,
  success: boolean,
};

export type ReportsState = {
  data: ReportsData,
  generate: Generate,
};

type ReportsAllPendingType = 'REPORTS_ALL_PENDING';
type ReportsAllSuccessType = 'REPORTS_ALL_SUCCESS';
type ReportsAllErrorType = 'REPORTS_ALL_ERROR';
type ReportsGeneratePendingType = 'REPORTS_GENERATE_PENDING';
type ReportsGenerateSuccessType = 'REPORTS_GENERATE_SUCCESS';
type ReportsGenerateErrorType = 'REPORTS_GENERATE_ERROR';

type ReportsAllPending = {
  type: ReportsAllPendingType,
};
type ReportsAllSuccess = {
  type: ReportsAllSuccessType,
  payload: {
    data: {
      reports: Object,
    },
  },
};
type ReportsAllError = {
  type: ReportsAllErrorType,
};
type ReportsGeneratePending = {
  type: ReportsGeneratePendingType,
};
type ReportsGenerateSuccess = {
  type: ReportsGenerateSuccessType,
};
type ReportsGenerateError = {
  type: ReportsGenerateErrorType,
};

type Action =
  | ReportsAllPending
  | ReportsAllSuccess
  | ReportsAllError
  | ReportsGeneratePending
  | ReportsGenerateSuccess
  | ReportsGenerateError;

const DEFAULT_STATE: ReportsState = {
  data: {
    loading: false,
    error: false,
    success: false,
    reports: {},
  },
  generate: {
    loading: false,
    error: false,
    success: false,
  },
};

export const REPORTS_ALL: {
  PENDING: ReportsAllPendingType,
  SUCCESS: ReportsAllSuccessType,
  ERROR: ReportsAllErrorType,
} = {
  PENDING: 'REPORTS_ALL_PENDING',
  SUCCESS: 'REPORTS_ALL_SUCCESS',
  ERROR: 'REPORTS_ALL_ERROR',
};

export const REPORTS_GENERATE: {
  PENDING: ReportsGeneratePendingType,
  SUCCESS: ReportsGenerateSuccessType,
  ERROR: ReportsGenerateErrorType,
} = {
  PENDING: 'REPORTS_GENERATE_PENDING',
  SUCCESS: 'REPORTS_GENERATE_SUCCESS',
  ERROR: 'REPORTS_GENERATE_ERROR',
};

/**
 * Reducer
 */
export default function reducer(
  state: ReportsState = DEFAULT_STATE,
  action: Action
) {
  switch (action.type) {
    case REPORTS_ALL.PENDING:
      return {
        ...state,
        data: {
          ...state.data,
          loading: true,
          error: false,
          success: false,
        },
      };
    case REPORTS_ALL.SUCCESS:
      return {
        ...state,
        data: {
          loading: false,
          error: false,
          success: true,
          reports: action.payload.data.reports,
        },
      };
    case REPORTS_ALL.ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          loading: false,
          success: false,
          error: true,
        },
      };
    case REPORTS_GENERATE.PENDING:
      return {
        ...state,
        generate: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case REPORTS_GENERATE.SUCCESS:
      return {
        ...state,
        generate: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case REPORTS_GENERATE.ERROR:
      return {
        ...state,
        generate: {
          ...state.generate,
          loading: false,
          error: true,
          success: false,
        },
      };
    default:
      return state;
  }
}

export type Selected = {
  accountId: number,
  businessId: number,
  channelId: number,
};

export type ReportsGenerateParams = {
  reports: Array<string>,
  fromDate: string,
  toDate: string,
};

export type GetReports = (selected: Selected, migratedToGwc: Boolean) => Function;
export type GenerateReports = (
  selected: Selected,
  reportsData: ReportsGenerateParams
) => Function;

/**
 * Action to get the list of reports
 */
export function getReports(selected: Selected, migratedToGwc: Boolean) {
  const { id: entityId, typeId: entityTypeId } = getEntity(selected);
  const requestParams = {
    entityId,
    entityTypeId,
    migratedToGwc
  };
  const promiseReq = ReportsAPI.getReports(requestParams);
  const { PENDING, SUCCESS, ERROR } = REPORTS_ALL;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to generate reports
 */
export function generateReports(
  selected: Selected,
  reportsData: ReportsGenerateParams
) {
  const { id: entityId, typeId: entityTypeId } = getEntity(selected);
  const requestParams = {
    entityId,
    entityTypeId,
    ...reportsData,
  };

  const promiseReq = ReportsAPI.generateReports(requestParams);
  const { PENDING, SUCCESS, ERROR } = REPORTS_GENERATE;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}
