// @flow
import { combineReducers } from 'redux';

import * as shipping from './shipping';
import * as invoice from './invoice';
import * as matchingPayments from './matching-payments';
import * as subscription from './subscription';
import * as terms from './terms';
import * as communication from './communication';
import * as additional from './additional';

export type State = {
  shipping: shipping.State,
  invoice: invoice.State,
  matchingPayments: matchingPayments.State,
  subscription: subscription.State,
  terms: terms.State,
  communication: communication.State,
  additional: additional.State,
};

export type MaybeState = {
  shipping?: shipping.State,
  invoice?: invoice.State,
  matchingPayments?: matchingPayments.State,
  subscription?: subscription.State,
  terms?: terms.State,
  communication?: communication.State,
  additional?: additional.State,
};

export const keyToHydrator = {
  shipping: shipping.hydrate,
  invoice: invoice.hydrate,
  matchingPayments: matchingPayments.hydrate,
  subscription: subscription.hydrate,
  terms: terms.hydrate,
  communication: communication.hydrate,
  additional: additional.hydrate,
};

export const keyToClear = {
  shipping: shipping.clear,
  invoice: invoice.clear,
  matchingPayments: matchingPayments.clear,
  subscription: subscription.clear,
  terms: terms.clear,
  communication: communication.clear,
  additional: additional.clear,
};

export const reducer = combineReducers({
  shipping: shipping.reducer,
  invoice: invoice.reducer,
  matchingPayments: matchingPayments.reducer,
  subscription: subscription.reducer,
  terms: terms.reducer,
  communication: communication.reducer,
  additional: additional.reducer,
});
