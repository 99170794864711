// @flow
import { USER_ACTION_TYPES } from 'store/user-duck';
import { ENTITY_TYPE } from 'config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { userSession, clearUserData } from 'services/localDataApi';

type dispatchAlias = (Object | Function) => {};

/**
 * Action to delete an associated card
 * @param {Promise} promiseReq Ajax request promise
 * @param {String} pendingType action type
 * @param {Object} success action type
 * @param {String} error action type
 * @param {Boolean} signoutOn401 should signout if 401 response
 * @return {Function}
 */
export function dispatchResponse(
  promiseReq: Promise<Object>,
  pendingType?: string | null | Object = {},
  success: string | Object = {},
  error: string = '',
  signoutOn401: boolean = true,
  showProgress: boolean = false
): Function {
  return function(dispatch: dispatchAlias) {
    if (hasSessionExpired()) {
      clearUserData();
      return dispatch({ type: USER_ACTION_TYPES.SIGN_OUT, sessionEnd: true });
    }

    if (showProgress) {
      dispatch(hideLoading());
      dispatch(showLoading());
    }

    if (pendingType) {
      dispatch(typeof pendingType === 'string' ? { type: pendingType } : pendingType);
    }

    return promiseReq
      .then(checkStatus)
      .then(dispatchSuccess(success, dispatch, showProgress))
      .catch(dispatchError(error, dispatch, signoutOn401));
  };
}

/**
 * Handles the request status code.
 * If > 300 then throw error
 * @param {Object|String} response request response data
 * @returns {Object}
 */
export function checkStatus(response: {
  status: number,
  statusText: string,
}): Object {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error: Object = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Handles the request success response
 * @param {Object|String} success action type
 * @param {Function} dispatch callback function to dispatch the data
 * @returns {Function}
 */
export function dispatchSuccess(
  success: string | Object,
  dispatch: dispatchAlias,
  showProgress: boolean
): Function {
  return ({ data, status }) => {
    if (showProgress) dispatch(hideLoading());

    // Save last request date and time
    userSession.save();

    if (typeof success === 'string') {
      dispatch({ type: success });
    } else {
      dispatch({
        ...success,
        payload: { data, status }
      });
    }

    return data;
  };
}

/**
 * Handles the request error response
 * @param {object|string} error action type
 * @param {function} dispatch callback function to dispatch the data
 * @param {boolean} signoutOn401 should signout if 401 response
 * @returns {function}
 */
export function dispatchError(
  error: string,
  dispatch: dispatchAlias,
  signoutOn401: boolean = true
): Function {
  return (errorData: Object) => {
    const responseCode = errorData.response && errorData.response.status.toString();

    if (responseCode === '503') {
      window.location.reload();
      return;
    }

    if (signoutOn401 && responseCode === '401') {
      return dispatch({ type: USER_ACTION_TYPES.SIGN_OUT, sessionEnd: true });
    }

    return dispatch({
      type: error,
      payload: {
        data: errorData.response ? errorData.response.data : errorData,
        status: errorData.response && errorData.response.status
      }
    });
  };
}

export type Entity = {
  id: string,
  typeId: string,
  type: string,
};

/**
 * Creates an entity from entityIds
 */
export function getEntity(entityIds: {
  channelId: number,
  businessId: number,
  accountId: number,
}): Entity {
  const entity: Entity | Object = {};

  if (!entityIds) {
    return entity;
  }

  const { channelId, businessId, accountId } = entityIds;

  if (channelId) {
    entity.id = channelId.toString(10);
    entity.typeId = ENTITY_TYPE.CHANNEL.toString(10);
    entity.type = 'channels';
  } else if (businessId) {
    entity.id = businessId.toString(10);
    entity.typeId = ENTITY_TYPE.BUSINESS.toString(10);
    entity.type = 'businesses';
  } else if (accountId) {
    entity.id = accountId.toString(10);
    entity.typeId = ENTITY_TYPE.ACCOUNT.toString(10);
    entity.type = 'accounts';
  }
  return entity;
}

export function dispatchBatchResponse(
  promiseReq: Function,
  batchParams: Array<any>,
  config: Object
): Function {
  return function(dispatch: dispatchAlias) {
    let requestIndex = 0;
    const batchLength = batchParams.length - 1;

    dispatch({ type: config.pending });

    const promiseRecursion = () => {
      const promiseParams = batchParams[requestIndex];
      if (requestIndex <= batchLength) {
        promiseReq(...promiseParams)
          .then(data => {
            requestIndex++;
            promiseRecursion();
          })
          .catch(errorData => {
            return dispatch({ type: config.error });
          });
      } else {
        return dispatch({ type: config.success });
      }
    };

    return promiseRecursion();
  };
}

function hasSessionExpired(): boolean {
  const ONE_HOUR = 60 * 60 * 1000;
  const currentSession = userSession.get();

  // Check user last date session exist
  if (currentSession.lastDate) {
    const currentDate = new Date();
    const lastSessionDate = new Date(currentSession.lastDate);

    // Check last session is > an hour
    return currentDate - lastSessionDate > ONE_HOUR;
  }
  return false;
}
