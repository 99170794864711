// @flow
import ReactGA from 'react-ga';
import Analytics from './Analytics';
import {
  eventNav,
  eventSidebar,
  eventModal,
  eventDatePicker,
  eventTable,
  eventFilter
} from './events';

const category = {
  nav: 'navigation', // Left hand side navigation
  sidebar: 'details sidebar',
  datePicker: 'date picker',
  table: 'table',
  filter: 'filter',
  modal: 'modal'
};

function formatPathName(pathname: string): string {
  let page = pathname;
  if (page.includes('details')) {
    page = page.split('/').reduce((newString, splitValue) => {
      // If the new string contains details ignore the anything after it
      if (!newString.includes('details') && newString != null) {
        return `${newString}/${splitValue}`;
      }
      return newString;
    });
  }
  return page;
}

function setupGoogleAnalytics(): void {
  let trackerId;
  switch (window.location.hostname) {
    case 'localhost':
    case 'dev.ckotech.co':
    case 'test.ckotech.co':
    case 'qa.ckotech.co':
    case 'uat.ckotech.co':
      trackerId = 'UA-83886670-3';
      break;
    case 'sandbox.checkout.com':
      trackerId = 'UA-83886670-6';
      break;
    default:
      trackerId = 'UA-83886670-7';
  }

  ReactGA.initialize(trackerId);
  ReactGA.pageview(formatPathName(window.location.pathname));
}

function trackEvent(params: {
  category: string,
  action: string,
  label?: string,
}): void {
  ReactGA.event({ ...params });
}

export {
  eventNav,
  eventSidebar,
  eventModal,
  eventDatePicker,
  eventTable,
  eventFilter,
  category,
  setupGoogleAnalytics,
  formatPathName,
  trackEvent
};

export default Analytics;
