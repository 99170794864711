//@flow
import { produce } from 'utils/tree';
import * as formTypes from 'types/form';

export type State = {
  description: string,
  file: {
    loading: boolean,
    error: string,
    id: string,
    filename: string,
    content: string,
  },
};

export type HydrateShippingAction = {
  type: 'EVIDENCE_HYDRATE_SHIPPING',
  state: State,
};
export function hydrate(state: State): HydrateShippingAction {
  return {
    state,
    type: 'EVIDENCE_HYDRATE_SHIPPING',
  };
}

export type ClearAction = {
  type: 'EVIDENCE_CLEAR_SHIPPING',
};
export function clear(): ClearAction {
  return {
    type: 'EVIDENCE_CLEAR_SHIPPING',
  };
}

export type StreamShippingDescriptionAction = formTypes.StreamText<
  'EVIDENCE_STREAM_SHIPPING_DESCRIPTION'
>;
export function streamShippingDescription(
  value: string
): StreamShippingDescriptionAction {
  return {
    value,
    type: 'EVIDENCE_STREAM_SHIPPING_DESCRIPTION',
  };
}

export type UploadShippingEvidenceStartAction = {
  type: 'EVIDENCE_UPLOAD_SHIPPING_START',
};
export function uploadShippingEvidenceStart(): UploadShippingEvidenceStartAction {
  return {
    type: 'EVIDENCE_UPLOAD_SHIPPING_START',
  };
}

export type ClearShippingEvidenceAction = {
  type: 'EVIDENCE_CLEAR_SHIPPING_EVIDENCE',
};
export function clearShippingEvidence(): ClearShippingEvidenceAction {
  return {
    type: 'EVIDENCE_CLEAR_SHIPPING_EVIDENCE',
  };
}

export type UploadShippingEvidenceSuccessAction = formTypes.UploadSuccess<
  'EVIDENCE_UPLOAD_SHIPPING_SUCCESS'
>;
export function uploadShippingEvidenceSuccess(
  filename: string,
  content: string
): UploadShippingEvidenceSuccessAction {
  return {
    filename,
    content,
    type: 'EVIDENCE_UPLOAD_SHIPPING_SUCCESS',
  };
}

type UploadShippingEvidenceFailureAction = formTypes.UploadFailure<
  'EVIDENCE_UPLOAD_SHIPPING_FAILURE'
>;
export function uploadShippingEvidenceFailure(
  error: string
): UploadShippingEvidenceFailureAction {
  return {
    error,
    type: 'EVIDENCE_UPLOAD_SHIPPING_FAILURE',
  };
}

type Action =
  | HydrateShippingAction
  | ClearAction
  | StreamShippingDescriptionAction
  | UploadShippingEvidenceStartAction
  | ClearShippingEvidenceAction
  | UploadShippingEvidenceSuccessAction
  | UploadShippingEvidenceFailureAction;

const DEFAULT_STATE: State = {
  description: '',
  file: {
    loading: false,
    error: '',
    id: '',
    filename: '',
    content: '',
  },
};

export function reducer(state: State = DEFAULT_STATE, action: Action): State {
  return produce(state, function(draft) {
    switch (action.type) {
      case 'EVIDENCE_HYDRATE_SHIPPING':
        return action.state;

      case 'EVIDENCE_CLEAR_SHIPPING':
        return DEFAULT_STATE;

      case 'EVIDENCE_STREAM_SHIPPING_DESCRIPTION':
        draft.description = action.value;
        break;

      case 'EVIDENCE_UPLOAD_SHIPPING_START':
        draft.file.loading = true;
        break;

      case 'EVIDENCE_CLEAR_SHIPPING_EVIDENCE':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: '',
          content: '',
        };
        break;

      case 'EVIDENCE_UPLOAD_SHIPPING_SUCCESS':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: action.filename,
          content: action.content,
        };
        break;

      case 'EVIDENCE_UPLOAD_SHIPPING_FAILURE':
        draft.file.loading = false;
        draft.file.error = action.error;
        break;
      default: break;
    }
  });
}
