// @flow
import { createSelector } from 'reselect';
import * as UserManagementAPI from 'services/resources/user-management.api';
import { dispatchResponse } from 'utils/action.util';
import { type RequestStatus } from 'store/store.types';
import type { StoreState } from 'store/reducers';

export type User = {
  active: boolean,
  allowedBusinesses: Array<number>,
  email: string,
  id: number,
  readOnly: boolean,
  merchantAccountId: number
};

type PhoneNumber = {
  countryCode: string,
  number: string
};

export type Account = {
  accountId: number,
  gatewayId: number,
  accountName: string,
  countryId: number,
  timezoneId: number,
  currencyId: number,
  companyName: string,
  companyIndustryId: number,
  supportPhone: PhoneNumber,
  supportEmail: string,
  companyStreet: string,
  companyCity: ?string,
  companyCityId: number,
  companyState: string,
  companyPostalCode: string,
  companyCountryId: number,
  mailingStreet: string,
  mailingCity: ?string,
  mailingCityId: number,
  mailingState: string,
  mailingPostalCode: string,
  mailingCountryId: number,
  contactPhone: ?PhoneNumber,
  contractName: ?string,
  contactEmail: ?string,
  merchantType: ?string,
  allowConsolidateWire: ?boolean,
  allowConsolidateReport: ?boolean,
  hasVat: boolean,
  vatRate: number,
  useMailingAddress: boolean
};

export type Admin = {
  userId: number,
  accountId: number,
  profileGroupCode: ?number,
  accountName: ?string,
  email: string,
  name: string,
  phone: PhoneNumber,
  requiredPasswordReset: boolean,
  isDefault: boolean
};

/**
 * LIST USERS
 */
type UserManagementListUsersPendingType = 'USER_MANAGEMENT_LIST_USERS_PENDING';
type UserManagementListUsersSuccessType = 'USER_MANAGEMENT_LIST_USERS_SUCCESS';
type UserManagementListUsersErrorType = 'USER_MANAGEMENT_LIST_USERS_ERROR';
type UserManagementListUsersPending = {
  type: UserManagementListUsersPendingType
};
type UserManagementListUsersSuccess = {
  type: UserManagementListUsersSuccessType,
  payload: {
    data: {
      users: Array<User>
    }
  }
};
type UserManagementListUsersError = {
  type: UserManagementListUsersErrorType
};

/**
 * CREATE USER
 */
type UserManagementCreateUserPendingType = 'USER_MANAGEMENT_CREATE_USER_PENDING';
type UserManagementCreateUserSuccessType = 'USER_MANAGEMENT_CREATE_USER_SUCCESS';
type UserManagementCreateUserErrorType = 'USER_MANAGEMENT_CREATE_USER_ERROR';

type UserManagementCreateUserPending = {
  type: UserManagementCreateUserPendingType
};
type UserManagementCreateUserSuccess = {
  type: UserManagementCreateUserSuccessType
};
type UserManagementCreateUserError = {
  type: UserManagementCreateUserErrorType
};

/**
 * UPDATE USER
 */
type UserManagementUpdateUserPendingType = 'USER_MANAGEMENT_UPDATE_USER_PENDING';
type UserManagementUpdateUserSuccessType = 'USER_MANAGEMENT_UPDATE_USER_SUCCESS';
type UserManagementUpdateUserErrorType = 'USER_MANAGEMENT_UPDATE_USER_ERROR';

type UserManagementUpdateUserPending = {
  type: UserManagementUpdateUserPendingType
};
type UserManagementUpdateUserSuccess = {
  type: UserManagementUpdateUserSuccessType
};
type UserManagementUpdateUserError = {
  type: UserManagementUpdateUserErrorType
};

/**
 * DELETE USER
 */
type UserManagementDeleteUserPendingType = 'USER_MANAGEMENT_DELETE_USER_PENDING';
type UserManagementDeleteUserSuccessType = 'USER_MANAGEMENT_DELETE_USER_SUCCESS';
type UserManagementDeleteUserErrorType = 'USER_MANAGEMENT_DELETE_USER_ERROR';

type UserManagementDeleteUserPending = {
  type: UserManagementDeleteUserPendingType
};
type UserManagementDeleteUserSuccess = {
  type: UserManagementDeleteUserSuccessType
};
type UserManagementDeleteUserError = {
  type: UserManagementDeleteUserErrorType
};

/**
 * GET ACCOUNT DETAILS
 */
type UserManagementGetAccountDetailsPendingType = 'USER_MANAGEMENT_GET_ACCOUNT_DETAILS_PENDING';
type UserManagementGetAccountDetailsErrorType = 'USER_MANAGEMENT_GET_ACCOUNT_DETAILS_ERROR';
type UserManagementGetAccountDetailsSuccessType = 'USER_MANAGEMENT_GET_ACCOUNT_DETAILS_SUCCESS';

type UserManagementGetAccountDetailsPendingAction = {
  type: UserManagementGetAccountDetailsPendingType
};
type UserManagementGetAccountDetailsErrorAction = {
  type: UserManagementGetAccountDetailsErrorType
};
type UserManagementGetAccountDetailsSuccessAction = {
  type: UserManagementGetAccountDetailsSuccessType,
  payload: {
    data: {
      account: Account,
      user: Admin
    }
  }
};

export const USER_MANAGEMENT_LIST_USERS_TYPES: {
  PENDING: UserManagementListUsersPendingType,
  SUCCESS: UserManagementListUsersSuccessType,
  ERROR: UserManagementListUsersErrorType
} = {
  PENDING: 'USER_MANAGEMENT_LIST_USERS_PENDING',
  SUCCESS: 'USER_MANAGEMENT_LIST_USERS_SUCCESS',
  ERROR: 'USER_MANAGEMENT_LIST_USERS_ERROR'
};

export const USER_MANAGEMENT_CREATE_USER_TYPES: {
  PENDING: UserManagementCreateUserPendingType,
  SUCCESS: UserManagementCreateUserSuccessType,
  ERROR: UserManagementCreateUserErrorType
} = {
  PENDING: 'USER_MANAGEMENT_CREATE_USER_PENDING',
  SUCCESS: 'USER_MANAGEMENT_CREATE_USER_SUCCESS',
  ERROR: 'USER_MANAGEMENT_CREATE_USER_ERROR'
};

export const USER_MANAGEMENT_UPDATE_USER_TYPES: {
  PENDING: UserManagementUpdateUserPendingType,
  SUCCESS: UserManagementUpdateUserSuccessType,
  ERROR: UserManagementUpdateUserErrorType
} = {
  PENDING: 'USER_MANAGEMENT_UPDATE_USER_PENDING',
  SUCCESS: 'USER_MANAGEMENT_UPDATE_USER_SUCCESS',
  ERROR: 'USER_MANAGEMENT_UPDATE_USER_ERROR'
};

export const USER_MANAGEMENT_DELETE_USER_TYPES: {
  PENDING: UserManagementDeleteUserPendingType,
  SUCCESS: UserManagementDeleteUserSuccessType,
  ERROR: UserManagementDeleteUserErrorType
} = {
  PENDING: 'USER_MANAGEMENT_DELETE_USER_PENDING',
  SUCCESS: 'USER_MANAGEMENT_DELETE_USER_SUCCESS',
  ERROR: 'USER_MANAGEMENT_DELETE_USER_ERROR'
};

export const USER_MANAGEMENT_GET_ACCOUNT_DETAILS: {
  PENDING: UserManagementGetAccountDetailsPendingType,
  ERROR: UserManagementGetAccountDetailsErrorType,
  SUCCESS: UserManagementGetAccountDetailsSuccessType
} = {
  PENDING: 'USER_MANAGEMENT_GET_ACCOUNT_DETAILS_PENDING',
  ERROR: 'USER_MANAGEMENT_GET_ACCOUNT_DETAILS_ERROR',
  SUCCESS: 'USER_MANAGEMENT_GET_ACCOUNT_DETAILS_SUCCESS'
};

type Action =
  | UserManagementListUsersPending
  | UserManagementListUsersSuccess
  | UserManagementListUsersError
  | UserManagementCreateUserPending
  | UserManagementCreateUserSuccess
  | UserManagementCreateUserError
  | UserManagementUpdateUserPending
  | UserManagementUpdateUserSuccess
  | UserManagementUpdateUserError
  | UserManagementDeleteUserPending
  | UserManagementDeleteUserSuccess
  | UserManagementDeleteUserError
  | UserManagementGetAccountDetailsPendingAction
  | UserManagementGetAccountDetailsErrorAction
  | UserManagementGetAccountDetailsSuccessAction;

export type ListUsersState = {
  data: Array<User>,
  loading: boolean,
  success: boolean,
  error: boolean
};

export type CreateUserState = {
  loading: boolean,
  success: boolean,
  error: boolean,
  errorMessage: string
};

export type UpdateUserState = {
  loading: boolean,
  success: boolean,
  error: boolean
};

export type DeleteUserState = {
  loading: boolean,
  success: boolean,
  error: boolean
};

// XXX: not sure what else is here yet
export type AccountDetailsState = RequestStatus & {
  account: Account,
  user: Admin
};

export type UserManagementState = {
  listUsers: ListUsersState,
  createUser: CreateUserState,
  updateUser: UpdateUserState,
  deleteUser: DeleteUserState,
  accountDetails: AccountDetailsState
};

export const DEFAULT_STATE: UserManagementState = {
  listUsers: {
    loading: false,
    error: false,
    success: false,
    data: []
  },
  createUser: {
    loading: false,
    error: false,
    success: false,
    errorMessage: ''
  },
  updateUser: {
    loading: false,
    error: false,
    success: false
  },
  deleteUser: {
    loading: false,
    error: false,
    success: false
  },
  accountDetails: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    user: {
      userId: 0,
      accountId: 0,
      profileGroupCode: null,
      accountName: null,
      email: '',
      name: '',
      phone: {
        countryCode: '',
        number: ''
      },
      requiredPasswordReset: false,
      isDefault: false
    },
    account: {
      accountId: 0,
      gatewayId: 0,
      accountName: '',
      countryId: 0,
      timezoneId: 0,
      currencyId: 0,
      companyName: '',
      companyIndustryId: 0,
      supportPhone: {
        countryCode: '',
        number: ''
      },
      supportEmail: '',
      companyStreet: '',
      companyCity: null,
      companyCityId: 0,
      companyState: '',
      companyPostalCode: '',
      companyCountryId: 0,
      mailingStreet: '',
      mailingCity: null,
      mailingCityId: 0,
      mailingState: '',
      mailingPostalCode: '',
      mailingCountryId: 0,
      contactPhone: null,
      contractName: null,
      contactEmail: null,
      merchantType: null,
      allowConsolidateWire: null,
      allowConsolidateReport: null,
      hasVat: false,
      vatRate: 0,
      useMailingAddress: false
    }
  }
};

export default function reducer(state: UserManagementState = DEFAULT_STATE, action: Action) {
  switch (action.type) {
    /**
     * List Users - Pending
     */
    case USER_MANAGEMENT_LIST_USERS_TYPES.PENDING:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          loading: true,
          error: false,
          success: false
        }
      };
    /**
     * List Users - Success
     */
    case USER_MANAGEMENT_LIST_USERS_TYPES.SUCCESS:
      return {
        ...state,
        listUsers: {
          loading: false,
          error: false,
          success: true,
          data: action.payload.data.users
        }
      };
    /**
     * List Users - Error
     */
    case USER_MANAGEMENT_LIST_USERS_TYPES.ERROR:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          loading: false,
          error: true,
          success: false
        }
      };

    case USER_MANAGEMENT_CREATE_USER_TYPES.PENDING:
      /**
       * Create User - Pending
       */
      return {
        ...state,
        createUser: {
          loading: true,
          error: false,
          success: false
        }
      };
    /**
     * Create User - Success
     */
    case USER_MANAGEMENT_CREATE_USER_TYPES.SUCCESS:
      return {
        ...state,
        createUser: {
          loading: false,
          error: false,
          success: true
        }
      };
    /**
     * Create User - Error
     */
    case USER_MANAGEMENT_CREATE_USER_TYPES.ERROR:
      return {
        ...state,
        createUser: {
          loading: false,
          error: true,
          success: false,
          errorMessage: action.payload.data.message
        }
      };
    /**
     * Update User - Pending
     */
    case USER_MANAGEMENT_UPDATE_USER_TYPES.PENDING:
      return {
        ...state,
        updateUser: {
          loading: true,
          error: false,
          success: false
        }
      };
    /**
     * Update User - Success
     */
    case USER_MANAGEMENT_UPDATE_USER_TYPES.SUCCESS:
      return {
        ...state,
        updateUser: {
          loading: false,
          error: false,
          success: true
        }
      };
    /**
     * Update User - Error
     */
    case USER_MANAGEMENT_UPDATE_USER_TYPES.ERROR:
      return {
        ...state,
        updateUser: {
          loading: false,
          error: true,
          success: false
        }
      };
    /**
     * Delete User - Pending
     */
    case USER_MANAGEMENT_DELETE_USER_TYPES.PENDING:
      return {
        ...state,
        deleteUser: {
          loading: true,
          error: false,
          success: false
        }
      };
    /**
     * Delete User - Success
     */
    case USER_MANAGEMENT_DELETE_USER_TYPES.SUCCESS:
      return {
        ...state,
        deleteUser: {
          loading: false,
          error: false,
          success: true
        }
      };
    /**
     * Delete User - Error
     */
    case USER_MANAGEMENT_DELETE_USER_TYPES.ERROR:
      return {
        ...state,
        deleteUser: {
          loading: false,
          error: true,
          success: false
        }
      };

    case USER_MANAGEMENT_GET_ACCOUNT_DETAILS.PENDING:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          loading: true
        }
      };

    case USER_MANAGEMENT_GET_ACCOUNT_DETAILS.ERROR:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          loading: false,
          error: true
        }
      };

    case USER_MANAGEMENT_GET_ACCOUNT_DETAILS.SUCCESS:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          loading: false,
          success: true,
          ...action.payload.data
        }
      };

    default:
      return state;
  }
}

type AccountId = string | number;
export type GetListOfUsers = (accountId: AccountId) => Function;
export function getListOfUsers(accountId: AccountId): Function {
  const promiseReq = UserManagementAPI.getListOfUsers(accountId);
  const { PENDING, SUCCESS, ERROR } = USER_MANAGEMENT_LIST_USERS_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

type UserId = string | number;
// TODO type data
export type NewUserInfo = {
  allowedBusinesses: Array<number>,
  readOnly: boolean,
  email: string
};

export type EditUserInfo = {
         allowedBusinesses: Array<number>,
         readOnly: boolean,
         active: boolean,
         email: string
       };

export type UpdateUserInfo = (
  accountId: AccountId,
  userId: UserId,
  userInfo: EditUserInfo
) => Function;
export function updateUserInfo(
  accountId: string | number,
  userId: string | number,
  userData: Object
) {
  const promiseReq = UserManagementAPI.updateUserInfo(accountId, userId, userData);
  const { PENDING, SUCCESS, ERROR } = USER_MANAGEMENT_UPDATE_USER_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type CreateUser = (accountId: AccountId, userInfo: NewUserInfo) => Function;
export function createUser(accountId: AccountId, userInfo: NewUserInfo) {
  const promiseReq = UserManagementAPI.createUser(accountId, userInfo);
  const { PENDING, SUCCESS, ERROR } = USER_MANAGEMENT_CREATE_USER_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type DeleteUser = (accountId: AccountId, userId: UserId) => Function;
export function deleteUser(accountId: AccountId, userId: UserId) {
  const promiseReq = UserManagementAPI.deleteUser(accountId, userId);
  const { PENDING, SUCCESS, ERROR } = USER_MANAGEMENT_DELETE_USER_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type GetAccountDetails = (accountId: number) => Function;
/**
 * Action to get the account details
 */
export function getAccountDetails(accountId: number) {
  const promiseReq = UserManagementAPI.getAccountDetails(accountId);
  const { PENDING, SUCCESS, ERROR } = USER_MANAGEMENT_GET_ACCOUNT_DETAILS;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Selectors
 */
export const accountDetailsSelector = (state: StoreState) => state.userManagementState.accountDetails;

export const getAccountSupportEmail = createSelector(
  [accountDetailsSelector],
  accountDetails => {
    return accountDetails.account.supportEmail;
  }
);

export const getAccountOwnerId = createSelector(
  [accountDetailsSelector],
  accountDetails => {
    return accountDetails.user.userId;
  }
);
