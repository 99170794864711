// @flow
import { type Dispatch } from 'redux';
import * as SettingsAPI from 'services/resources/settings.api';
import {
  dispatchResponse,
  dispatchSuccess,
  dispatchError,
} from 'utils/action.util';
import type { StoreState } from 'store/reducers';
import type { RequestStatus } from 'store/store.types';

export type BankDescription = {
  bankAccountId: number,
  accountId: number,
  name: string,
  bankName: string,
  accountHolderName: string,
  bankAccountNumber: string,
  iban: ?string,
  swift: ?string,
  settlementCurrencyId: number,
  bankCountryId: number,
  bankAddress: string,
  sortCode: ?string,
  routingCode: ?string,
  abaNumber: ?string,
  isFSCCode: ?boolean, //XXX
  isThreshold: boolean,
  paymentThreshold: number,
  wireFee: number,
  pricingCurrencyId: number,
  businessName: Array<string>,
  isSeparatedFees: boolean,
  feeCycleId: ?string,
};

export type PayoutConfiguration = {
  bankAccountId: number,
  accountId: number,
  name: string,
  bankName: string,
  accountHolderName: string,
  bankAccountNumber: string,
  iban: ?string,
  swift: ?string,
  settlementCurrencyId: number,
  processingCurrencies: Array<number>,
  bankCountryId: number,
  bankAddress: string,
  sortCode: ?string,
  routingCode: ?string,
  abaNumber: ?string,
  isFSCCode: ?boolean,
  isDefault: boolean,
  linkedBankAccountId: number,
  isConsolidated: boolean,
  depositScheduleId: number,
  isThresholdEnabled: boolean,
  paymentThreshold: number,
  wireFee: number,
  pricingCurrencyId: number,
  isSeparatedFees: boolean,
};

export type PayoutConfigurationMap = {
  [businessName: string]: Array<PayoutConfiguration>,
};

export type Frequency = {
  payoutCycleId: number,
  frequency: string,
  isActive: boolean,
};

export type FrequencyMap = {
  [id: number]: Frequency,
};

type BankAccounts = RequestStatus & {
  accounts: Array<BankDescription>,
};

export type UpdateBankAccountState = RequestStatus;

type PaymentConfigurations = RequestStatus & {
  payoutConfigurationMap: PayoutConfigurationMap,
};

type Frequencies = RequestStatus & {
  frequencyMap: FrequencyMap,
};

type GetBankAccountsPendingType = 'BANK_ACCOUNTS_LIST_PENDING';
type GetBankAccountsErrorType = 'BANK_ACCOUNTS_LIST_ERROR';
type GetBankAccountsSuccessType = 'BANK_ACCOUNTS_LIST_SUCCESS';
type UpdateBankAccountPendingType = 'UPDATE_BANK_ACCOUNT_PENDING';
type UpdateBankAccountErrorType = 'UPDATE_BANK_ACCOUNT_ERROR';
type UpdateBankAccountSuccessType = 'UPDATE_BANK_ACCOUNT_SUCCESS';
type GetPayoutConfigurationsPendingType = 'PAYOUT_CONFIGURATIONS_PENDING';
type GetPayoutConfigurationsErrorType = 'PAYOUT_CONFIGURATIONS_ERROR';
type GetPayoutConfigurationsSuccessType = 'PAYOUT_CONFIGURATIONS_SUCCESS';
type GetFrequenciesPendingType = 'FREQUENCIES_PENDING';
type GetFrequenciesErrorType = 'FREQUENCIES_ERROR';
type GetFrequenciesSuccessType = 'FREQUENCIES_SUCCESS';

type GetBankAccountsPending = {
  type: GetBankAccountsPendingType,
};

type GetBankAccountsError = {
  type: GetBankAccountsErrorType,
};

type GetBankAccountsSuccess = {
  type: GetBankAccountsSuccessType,
  payload: {
    data: Array<BankDescription>,
  },
};

type UpdateBankAccountPending = {
  type: UpdateBankAccountPendingType,
};

type UpdateBankAccountError = {
  type: UpdateBankAccountErrorType,
};

type UpdateBankAccountSuccess = {
  type: UpdateBankAccountSuccessType,
  payload: {
    data: BankDescription,
  },
};

type GetPaymentConfigurationsPending = {
  type: GetPayoutConfigurationsPendingType,
};

type GetPaymentConfigurationsError = {
  type: GetPayoutConfigurationsErrorType,
};

type GetPaymentConfigurationsSuccess = {
  type: GetPayoutConfigurationsSuccessType,
  payload: {
    data: any,
  },
};

type GetFreqenciesPending = {
  type: GetFrequenciesPendingType,
};

type GetFrequenciesError = {
  type: GetFrequenciesErrorType,
};

type GetFrequenciesSuccess = {
  type: GetFrequenciesSuccessType,
  payload: {
    data: Array<Frequency>,
  },
};

type Action =
  | GetBankAccountsPending
  | GetBankAccountsError
  | GetBankAccountsSuccess
  | UpdateBankAccountPending
  | UpdateBankAccountError
  | UpdateBankAccountSuccess
  | GetPaymentConfigurationsPending
  | GetPaymentConfigurationsError
  | GetPaymentConfigurationsSuccess
  | GetFreqenciesPending
  | GetFrequenciesError
  | GetFrequenciesSuccess;

export type PayoutsState = {|
  bankAccounts: BankAccounts,
  updateBankAccount: UpdateBankAccountState,
  payoutConfigurations: PaymentConfigurations,
  frequencies: Frequencies,
|};

const BANK_ACCOUNTS_ACTION_TYPES: {
  PENDING: GetBankAccountsPendingType,
  ERROR: GetBankAccountsErrorType,
  SUCCESS: GetBankAccountsSuccessType,
} = {
  PENDING: 'BANK_ACCOUNTS_LIST_PENDING',
  ERROR: 'BANK_ACCOUNTS_LIST_ERROR',
  SUCCESS: 'BANK_ACCOUNTS_LIST_SUCCESS',
};

const UPDATE_BANK_ACCOUNT_ACTION_TYPES: {
  PENDING: UpdateBankAccountPendingType,
  ERROR: UpdateBankAccountErrorType,
  SUCCESS: UpdateBankAccountSuccessType,
} = {
  PENDING: 'UPDATE_BANK_ACCOUNT_PENDING',
  ERROR: 'UPDATE_BANK_ACCOUNT_ERROR',
  SUCCESS: 'UPDATE_BANK_ACCOUNT_SUCCESS',
};

const PAYMENT_CONFIGURATIONS_ACTION_TYPES: {
  PENDING: GetPayoutConfigurationsPendingType,
  ERROR: GetPayoutConfigurationsErrorType,
  SUCCESS: GetPayoutConfigurationsSuccessType,
} = {
  PENDING: 'PAYOUT_CONFIGURATIONS_PENDING',
  ERROR: 'PAYOUT_CONFIGURATIONS_ERROR',
  SUCCESS: 'PAYOUT_CONFIGURATIONS_SUCCESS',
};

const FREQUENCIES_ACTION_TYPES: {
  PENDING: GetFrequenciesPendingType,
  ERROR: GetFrequenciesErrorType,
  SUCCESS: GetFrequenciesSuccessType,
} = {
  PENDING: 'FREQUENCIES_PENDING',
  ERROR: 'FREQUENCIES_ERROR',
  SUCCESS: 'FREQUENCIES_SUCCESS',
};

export const DEFAULT_STATE: PayoutsState = {
  bankAccounts: {
    accounts: [],
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
  },
  updateBankAccount: {
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
    msg: ''
  },
  payoutConfigurations: {
    payoutConfigurationMap: {},
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
  },
  frequencies: {
    frequencyMap: {},
    loading: false,
    error: false,
    success: false,
    errorMessage: '',
  },
};

function getUpdatedBankAccounts(
  accounts: Array<BankDescription>,
  updatedAccount: BankDescription
): Array<BankDescription> {
  return accounts.reduce((updatedAccounts, account) => {
    // replace with the updated bankAccount
    if (updatedAccount.bankAccountId === account.bankAccountId) {
      return [...updatedAccounts, updatedAccount];
    } else {
      return [...updatedAccounts, account];
    }
  }, []);
}

export default function reducer(
  state: PayoutsState = DEFAULT_STATE,
  action: Action
): PayoutsState {
  switch (action.type) {
    case BANK_ACCOUNTS_ACTION_TYPES.PENDING:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          loading: true,
        },
      };
    case BANK_ACCOUNTS_ACTION_TYPES.ERROR:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          loading: false,
          error: true,
        },
      };
    case BANK_ACCOUNTS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          accounts: action.payload.data,
          loading: false,
          error: false,
          success: true,
        },
      };
    case UPDATE_BANK_ACCOUNT_ACTION_TYPES.PENDING:
      return {
        ...state,
        updateBankAccount: {
          ...state.updateBankAccount,
          loading: true,
        },
      };
    case UPDATE_BANK_ACCOUNT_ACTION_TYPES.ERROR:
      const { message } = action.payload.data
      return {
        ...state,
        updateBankAccount: {
          ...state.updateBankAccount,
          loading: false,
          success: false,
          error: true,
          msg: message === 'Internal error' ? 'Unable to process your request' : message
        }
      };
    case UPDATE_BANK_ACCOUNT_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          accounts: getUpdatedBankAccounts(
            state.bankAccounts.accounts,
            action.payload.data
          ),
        },
        updateBankAccount: {
          ...state.updateBankAccount,
          loading: false,
          error: false,
          success: true,
        },
      };
    case PAYMENT_CONFIGURATIONS_ACTION_TYPES.PENDING:
      return {
        ...state,
        payoutConfigurations: {
          ...state.payoutConfigurations,
          loading: true,
        },
      };
    case PAYMENT_CONFIGURATIONS_ACTION_TYPES.ERROR:
      return {
        ...state,
        payoutConfigurations: {
          ...state.payoutConfigurations,
          loading: false,
          error: true,
        },
      };
    case PAYMENT_CONFIGURATIONS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        payoutConfigurations: {
          ...state.payoutConfigurations,
          payoutConfigurationMap: action.payload.data,
          loading: false,
        },
      };
    case FREQUENCIES_ACTION_TYPES.PENDING:
      return {
        ...state,
        frequencies: {
          ...state.frequencies,
          loading: true,
        },
      };
    case FREQUENCIES_ACTION_TYPES.ERROR:
      return {
        ...state,
        frequencies: {
          ...state.frequencies,
          loading: false,
          success: false,
          error: true,
        },
      };
    case FREQUENCIES_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        frequencies: {
          ...state.frequencies,
          frequencyMap: action.payload.data.reduce(
            (frequencyMap, frequency) => {
              return { ...frequencyMap, [frequency.payoutCycleId]: frequency };
            },
            {}
          ),
          loading: false,
          error: false,
          success: true,
        },
      };
    default:
      return state;
  }
}

export type GetBankAccounts = (accountId: number) => Function;
/**
 * Action to get the bank accounts details
 */
export function getBankAccounts(accountId: number): Function {
  const promiseReq = SettingsAPI.getBankAccounts(accountId);
  const { PENDING, SUCCESS, ERROR } = BANK_ACCOUNTS_ACTION_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

export type GetPayoutConfigurations = (accountId: number) => Function;
/**
 * Action to get payment configuration for all businesses
 */
export function getPayoutConfigurations(accountId: number) {
  return (dispatch: Dispatch<any>, getState: () => StoreState) => {
    const businesses = getState().globalState.businesses.map[
      accountId.toString()
    ];
    const businessIdToName = businesses.reduce(
      (result, { propertyId: businessId, propertyName: businessName }) => {
        return {
          ...result,
          [businessId]: businessName,
        };
      },
      {}
    );
    const businessIds = Object.keys(businessIdToName);
    const { PENDING, SUCCESS, ERROR } = PAYMENT_CONFIGURATIONS_ACTION_TYPES;
    const paymentConfigurationReqs = businessIds.map(businessId =>
      SettingsAPI.getBusinessBankDetails(accountId, businessId)
    );
    dispatch({ type: PENDING });
    return Promise.all(paymentConfigurationReqs)
      .then((responses: Array<{ data: any }>) => {
        const response = {
          data: businessIds.reduce((result, businessId, index) => {
            const paymentConfig = responses[index].data;
            const businessName = businessIdToName[businessId];
            return {
              ...result,
              [businessName]: paymentConfig,
            };
          }, {}),
        };
        dispatchSuccess({ type: SUCCESS }, dispatch, false)(response);
      })
      .catch(err => {
        dispatchError(ERROR, dispatch, false);
      });
  };
}

export type GetFrequencies = () => Function;
/**
 * Action to get the payout cycle frequencies
 */
export function getFrequencies() {
  const promiseReq = SettingsAPI.getFrequencies();
  const { PENDING, SUCCESS, ERROR } = FREQUENCIES_ACTION_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

// should only be called if the user isAccountOwner
export type UpdateBankAccount = (
  accountId: number,
  bankAccount: BankDescription
) => Function;
export function updateBankAccount(
  accountId: number,
  bankAccount: BankDescription
): Function {
  const promiseReq = SettingsAPI.updateBankAccount(accountId, bankAccount);
  const { PENDING, SUCCESS, ERROR } = UPDATE_BANK_ACCOUNT_ACTION_TYPES;
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}
