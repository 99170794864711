type RISK_VELOCITY_PENDING = 'RISK_VELOCITY_PENDING';
type RISK_VELOCITY_SUCCESS = 'RISK_VELOCITY_SUCCESS';
type RISK_VELOCITY_ERROR = 'RISK_VELOCITY_ERROR';

type SAVE_VELOCITY_PENDING = 'SAVE_VELOCITY_PENDING';
type SAVE_VELOCITY_SUCCESS = 'SAVE_VELOCITY_SUCCESS';
type SAVE_VELOCITY_ERROR = 'SAVE_VELOCITY_ERROR';

type RISK_BLACKLIST_ATTRIBUTES_PENDING = 'RISK_BLACKLIST_ATTRIBUTES_PENDING';
type RISK_BLACKLIST_ATTRIBUTES_SUCCESS = 'RISK_BLACKLIST_ATTRIBUTES_SUCCESS';
type RISK_BLACKLIST_ATTRIBUTES_ERROR = 'RISK_BLACKLIST_ATTRIBUTES_ERROR';

type RISK_BLACKLIST_PENDING = 'RISK_BLACKLIST_PENDING';
type RISK_BLACKLIST_SUCCESS = 'RISK_BLACKLIST_SUCCESS';
type RISK_BLACKLIST_ERROR = 'RISK_BLACKLIST_ERROR';

type RISK_BLACKLIST_DELETE_PENDING = 'RISK_BLACKLIST_DELETE_PENDING';
type RISK_BLACKLIST_DELETE_SUCCESS = 'RISK_BLACKLIST_DELETE_SUCCESS';
type RISK_BLACKLIST_DELETE_ERROR = 'RISK_BLACKLIST_DELETE_ERROR';

type ADD_BLACKLIST_RULE_PENDING = 'ADD_BLACKLIST_RULE_PENDING';
type ADD_BLACKLIST_RULE_SUCCESS = 'ADD_BLACKLIST_RULE_SUCCESS';
type ADD_BLACKLIST_RULE_ERROR = 'ADD_BLACKLIST_RULE_ERROR';

type SAVE_VERIFIED_INFO_RULES_PENDING = 'SAVE_VERIFIED_INFO_RULES_PENDING';
type SAVE_VERIFIED_INFO_RULES_SUCCESS = 'SAVE_VERIFIED_INFO_RULES_SUCCESS';
type SAVE_VERIFIED_INFO_RULES_ERROR = 'SAVE_VERIFIED_INFO_RULES_ERROR';

type SAVE_HIGH_RISK_COUNTRY_PENDING = 'SAVE_HIGH_RISK_COUNTRY_PENDING';
type SAVE_HIGH_RISK_COUNTRY_SUCCESS = 'SAVE_HIGH_RISK_COUNTRY_SUCCESS';
type SAVE_HIGH_RISK_COUNTRY_ERROR = 'SAVE_HIGH_RISK_COUNTRY_ERROR';

type RISK_ACTIONS_PENDING = 'RISK_ACTIONS_PENDING';
type RISK_ACTIONS_SUCCESS = 'RISK_ACTIONS_SUCCESS';
type RISK_ACTIONS_ERROR = 'RISK_ACTIONS_ERROR';

type CANCEL_VELOCITY_PENDING_START = 'CANCEL_VELOCITY_PENDING_START';
type CANCEL_VELOCITY_PENDING_SUCCESS = 'CANCEL_VELOCITY_PENDING_SUCCESS';
type CANCEL_VELOCITY_PENDING_ERROR = 'CANCEL_VELOCITY_PENDING_ERROR';

type CANCEL_VERIFIED_INFO_PENDING_START = 'CANCEL_VERIFIED_INFO_PENDING_START';
type CANCEL_VERIFIED_INFO_PENDING_SUCCESS = 'CANCEL_VERIFIED_INFO_PENDING_SUCCESS';
type CANCEL_VERIFIED_INFO_PENDING_ERROR = 'CANCEL_VERIFIED_INFO_PENDING_ERROR';

type CANCEL_HIGH_RISK_COUNTRY_PENDING_START = 'CANCEL_HIGH_RISK_COUNTRY_PENDING_START';
type CANCEL_HIGH_RISK_COUNTRY_PENDING_SUCCESS = 'CANCEL_HIGH_RISK_COUNTRY_PENDING_SUCCESS';
type CANCEL_HIGH_RISK_COUNTRY_PENDING_ERROR = 'CANCEL_HIGH_RISK_COUNTRY_PENDING_ERROR';

type VELOCITY_PROPERTY_START = 'VELOCITY_PROPERTY_START';
type VELOCITY_PROPERTY_SUCCESS = 'VELOCITY_PROPERTY_SUCCESS';
type VELOCITY_PROPERTY_ERROR = 'VELOCITY_PROPERTY_ERROR';

type GET_HIGH_RISK_COUNTRIES_START = 'GET_HIGH_RISK_COUNTRIES_START';
type GET_HIGH_RISK_COUNTRIES_SUCCESS = 'GET_HIGH_RISK_COUNTRIES_SUCCESS';
type GET_HIGH_RISK_COUNTRIES_ERROR = 'GET_HIGH_RISK_COUNTRIES_ERROR';

type GET_VERIFIED_INFO_START = 'GET_VERIFIED_INFO_START';
type GET_VERIFIED_INFO_SUCCESS = 'GET_VERIFIED_INFO_SUCCESS';
type GET_VERIFIED_INFO_ERROR = 'GET_VERIFIED_INFO_ERROR';

type OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
type CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';

type RISK_FEEDBACK_SHOW = 'RISK_FEEDBACK_SHOW';
type RISK_FEEDBACK_CLOSE = 'RISK_FEEDBACK_CLOSE';

export type TypeOfAction = 'DECLINE' | 'DECLINE_BLACKLIST' | 'FLAG' | 'PROCESS' | 'PROCESS3D';

export type Velocity = {
  actionId: number,
  description: string,
  frequency: string,
  id: number,
  isActive: boolean,
  pendingApproval: boolean,
  reasonCode: string,
  transactionType: string,
  triggerCount: number,
  velocityRuleType: string,
};

export type VerifiedInfo = {
  actionId: number,
  description: string,
  id: number,
  isActive: boolean,
  pendingApproval: boolean,
  reasonCode: string
};

export type Action = {
  id: number,
  action: TypeOfAction
}

export const SAVE_VERIFIED_INFO_RULES_TYPES: {
  PENDING: SAVE_VERIFIED_INFO_RULES_PENDING,
  SUCCESS: SAVE_VERIFIED_INFO_RULES_SUCCESS,
  ERROR: SAVE_VERIFIED_INFO_RULES_ERROR
} = {
  PENDING: 'SAVE_VERIFIED_INFO_RULES_PENDING',
  SUCCESS: 'SAVE_VERIFIED_INFO_RULES_SUCCESS',
  ERROR: 'SAVE_VERIFIED_INFO_RULES_ERROR'
};

export const RISK_BLACKLIST_ATTRIBUTES_TYPES: {
  PENDING: RISK_BLACKLIST_ATTRIBUTES_PENDING,
  SUCCESS: RISK_BLACKLIST_ATTRIBUTES_SUCCESS,
  ERROR: RISK_BLACKLIST_ATTRIBUTES_ERROR
} = {
  PENDING: 'RISK_BLACKLIST_ATTRIBUTES_PENDING',
  SUCCESS: 'RISK_BLACKLIST_ATTRIBUTES_SUCCESS',
  ERROR: 'RISK_BLACKLIST_ATTRIBUTES_ERROR'
};

export const RISK_BLACKLIST_TYPES: {
  PENDING: RISK_BLACKLIST_PENDING,
  SUCCESS: RISK_BLACKLIST_SUCCESS,
  ERROR: RISK_BLACKLIST_ERROR
} = {
  PENDING: 'RISK_BLACKLIST_PENDING',
  SUCCESS: 'RISK_BLACKLIST_SUCCESS',
  ERROR: 'RISK_BLACKLIST_ERROR'
};

export const RISK_BLACKLIST_DELETE_TYPES: {
  PENDING: RISK_BLACKLIST_DELETE_PENDING,
  SUCCESS: RISK_BLACKLIST_DELETE_SUCCESS,
  ERROR: RISK_BLACKLIST_DELETE_ERROR
} = {
  PENDING: 'RISK_BLACKLIST_DELETE_PENDING',
  SUCCESS: 'RISK_BLACKLIST_DELETE_SUCCESS',
  ERROR: 'RISK_BLACKLIST_DELETE_ERROR'
};

export const ADD_BLACKLIST_RULE_TYPES: {
  PENDING: ADD_BLACKLIST_RULE_PENDING,
  SUCCESS: ADD_BLACKLIST_RULE_SUCCESS,
  ERROR: ADD_BLACKLIST_RULE_ERROR
} = {
  PENDING: 'ADD_BLACKLIST_RULE_PENDING',
  SUCCESS: 'ADD_BLACKLIST_RULE_SUCCESS',
  ERROR: 'ADD_BLACKLIST_RULE_ERROR'
};

export const SAVE_HIGH_RISK_COUNTRY_TYPES: {
  PENDING: SAVE_HIGH_RISK_COUNTRY_PENDING,
  SUCCESS: SAVE_HIGH_RISK_COUNTRY_SUCCESS,
  ERROR: SAVE_HIGH_RISK_COUNTRY_ERROR
} = {
  PENDING: 'SAVE_HIGH_RISK_COUNTRY_PENDING',
  SUCCESS: 'SAVE_HIGH_RISK_COUNTRY_SUCCESS',
  ERROR: 'SAVE_HIGH_RISK_COUNTRY_ERROR'
};

export const GET_HIGH_RISK_COUNTRIES_TYPES: {
  PENDING: GET_HIGH_RISK_COUNTRIES_START,
  SUCCESS: GET_HIGH_RISK_COUNTRIES_SUCCESS,
  ERROR: GET_HIGH_RISK_COUNTRIES_ERROR
} = {
  PENDING: 'GET_HIGH_RISK_COUNTRIES_START',
  SUCCESS: 'GET_HIGH_RISK_COUNTRIES_SUCCESS',
  ERROR: 'GET_HIGH_RISK_COUNTRIES_ERROR'
};

export const GET_VERIFIED_INFO_TYPES: {
  PENDING: GET_VERIFIED_INFO_START,
  SUCCESS: GET_VERIFIED_INFO_SUCCESS,
  ERROR: GET_VERIFIED_INFO_ERROR
} = {
  PENDING: 'GET_VERIFIED_INFO_START',
  SUCCESS: 'GET_VERIFIED_INFO_SUCCESS',
  ERROR: 'GET_VERIFIED_INFO_ERROR'
};

export const RISK_VELOCITY_TYPES: {
  PENDING: RISK_VELOCITY_PENDING,
  SUCCESS: RISK_VELOCITY_SUCCESS,
  ERROR: RISK_VELOCITY_ERROR
} = {
  PENDING: 'RISK_VELOCITY_PENDING',
  SUCCESS: 'RISK_VELOCITY_SUCCESS',
  ERROR: 'RISK_VELOCITY_ERROR'
};

export const RISK_FEEDBACK_TYPES: {
  SHOW: RISK_FEEDBACK_SHOW,
  CLOSE: RISK_FEEDBACK_CLOSE
} = {
  SHOW: 'RISK_FEEDBACK_SHOW',
  CLOSE: 'RISK_FEEDBACK_CLOSE'
};

export const CANCEL_VELOCITY_PENDING: {
  PENDING: CANCEL_VELOCITY_PENDING_START,
  SUCCESS: CANCEL_VELOCITY_PENDING_SUCCESS,
  ERROR: CANCEL_VELOCITY_PENDING_ERROR
} = {
  PENDING: 'CANCEL_VELOCITY_PENDING_START',
  SUCCESS: 'CANCEL_VELOCITY_PENDING_SUCCESS',
  ERROR: 'CANCEL_VELOCITY_PENDING_ERROR'
};

export const CANCEL_VERIFIED_INFO_PENDING: {
  PENDING: CANCEL_VERIFIED_INFO_PENDING_START,
  SUCCESS: CANCEL_VERIFIED_INFO_PENDING_SUCCESS,
  ERROR: CANCEL_VERIFIED_INFO_PENDING_ERROR
} = {
  PENDING: 'CANCEL_VERIFIED_INFO_PENDING_START',
  SUCCESS: 'CANCEL_VERIFIED_INFO_PENDING_SUCCESS',
  ERROR: 'CANCEL_VERIFIED_INFO_PENDING_ERROR'
};

export const CANCEL_HIGH_RISK_COUNTRY_PENDING: {
  PENDING: CANCEL_HIGH_RISK_COUNTRY_PENDING_START,
  SUCCESS: CANCEL_HIGH_RISK_COUNTRY_PENDING_SUCCESS,
  ERROR: CANCEL_HIGH_RISK_COUNTRY_PENDING_ERROR
} = {
  PENDING: 'CANCEL_HIGH_RISK_COUNTRY_PENDING_START',
  SUCCESS: 'CANCEL_HIGH_RISK_COUNTRY_PENDING_SUCCESS',
  ERROR: 'CANCEL_HIGH_RISK_COUNTRY_PENDING_ERROR'
};

export const VELOCITY_PROPERTY_TYPES: {
  PENDING: VELOCITY_PROPERTY_START,
  SUCCESS: VELOCITY_PROPERTY_SUCCESS,
  ERROR: VELOCITY_PROPERTY_ERROR
} = {
  PENDING: 'VELOCITY_PROPERTY_START',
  SUCCESS: 'VELOCITY_PROPERTY_SUCCESS',
  ERROR: 'VELOCITY_PROPERTY_ERROR'
};

export const CONFIRMATION_MODAL_TYPES: {
  OPEN: OPEN_CONFIRMATION_MODAL,
  CLOSE: CLOSE_CONFIRMATION_MODAL,
} = {
  OPEN: 'OPEN_CONFIRMATION_MODAL',
  CLOSE: 'CLOSE_CONFIRMATION_MODAL'
};

export const RISK_ACTIONS_TYPES: {
  PENDING: RISK_ACTIONS_PENDING,
  SUCCESS: RISK_ACTIONS_SUCCESS,
  ERROR: RISK_ACTIONS_ERROR
} = {
  PENDING: 'RISK_ACTIONS_PENDING',
  SUCCESS: 'RISK_ACTIONS_SUCCESS',
  ERROR: 'RISK_ACTIONS_ERROR'
};

export const SAVE_VELOCITY: {
  PENDING: SAVE_VELOCITY_PENDING,
  SUCCESS: SAVE_VELOCITY_SUCCESS,
  ERROR: SAVE_VELOCITY_ERROR
} = {
  PENDING: 'SAVE_VELOCITY_PENDING',
  SUCCESS: 'SAVE_VELOCITY_SUCCESS',
  ERROR: 'SAVE_VELOCITY_ERROR'
};

export type RiskSaveVerifiedInfoRules = {
  loading: boolean,
  success: boolean,
  error: boolean,
}

export type RiskHighRiskCountries = {
  loading: boolean,
  success: boolean,
  error: boolean,
  data: {
    countries: number[],
    pendingApproval: boolean
  }
}

export type RiskCancelVelocityPending = {
  loading: boolean,
  success: boolean,
  error: boolean,
  errorMessage: any
}

export type RiskCancelHighRiskCountryPending = {
  loading: boolean,
  success: boolean,
  error: boolean,
  errorMessage: any
}

export type RiskCancelVerifiedInfoPending = {
  loading: boolean,
  success: boolean,
  error: boolean,
  errorMessage: any
}

export type RiskSaveHighRiskCountry = {
  loading: boolean,
  success: boolean,
  error: boolean
}

export type RiskVelocity = {
  loading: boolean,
  success: boolean,
  error: boolean,
  data: Velocity[]
}

export type RiskAction = {
  loading: boolean,
  success: boolean,
  error: boolean,
  list: Action[]
}

export type RiskSaveVelocity = {
  loading: boolean,
  error: boolean,
  success: boolean,
  errorMessage: string
}

export type RiskModalConfirmation = {
  open: boolean
}

export type RiskFeedback = {
  open: boolean
}

export type VelocityRulesSortedByType = {
  [key: string]: Velocity[]
}

export type RiskVerifiedInfo = {
  loading: boolean,
  success: boolean,
  error: boolean,
  data: VerifiedInfo[]
}

export type RiskBlacklistAttribute = {
  attributeType: string,
  description: string
}

export type RiskBlacklistAttributes = {
  loading: boolean,
  success: boolean,
  error: boolean,
  data: RiskBlacklistAttribute[]
}

export type RiskBlacklistItem = {
  attributeType: string,
  attributeValue: string,
  createdDate: string,
  id: string
}

export type RiskBlacklist = {
  loading: boolean,
  success: boolean,
  error: boolean,
  data: {
    startIndex: number,
    totalRows: number,
    blacklists: RiskBlacklistItem[]
  }
}

export type AddBlackListRuleState = {
  loading: boolean,
  success: boolean,
  error: boolean,
  errorMessage: string
}

export type RiskBlacklistDelete = {
  loading: boolean,
  success: boolean,
  error: boolean
}

export type RiskState = {
  velocity: RiskVelocity,
  actions: RiskAction,
  saveVelocity: RiskSaveVelocity,
  modalConfirmation: RiskModalConfirmation,
  feedback: RiskFeedback,
  cancelVelocityPending: RiskCancelVelocityPending,
  verifiedInfo: RiskVerifiedInfo,
  highRiskCountries: RiskHighRiskCountries,
  saveVerifiedInfoRules: RiskSaveVerifiedInfoRules,
  saveHighRiskCountry: RiskSaveHighRiskCountry,
  cancelHighRiskCountryPending: RiskCancelHighRiskCountryPending,
  cancelVerifiedInfoPending: RiskCancelVerifiedInfoPending,
  blacklistAttributes: RiskBlacklistAttributes,
  blacklist: RiskBlacklist,
  addBlackListRuleState: AddBlackListRuleState,
  blacklistDelete: RiskBlacklistDelete
};
