// @flow
import * as React from 'react';
import LoadingBar from 'react-redux-loading-bar';

import * as Styled from './styled/CkoLoadingBar.sc';

export default class CkoLoadingBar extends React.Component<{}> {
  render() {
    return (
      <Styled.Container className="cko-loading-bar">
        <LoadingBar className="loading-bar" />
      </Styled.Container>
    );
  }
}
