// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';
import { handleLogout, cacheStates, redirectToHubV1Middleware } from './middlewares';

const middlewares = [thunk, handleLogout, redirectToHubV1Middleware, cacheStates];
// compose
let composeEnhancers = process.env.NODE_ENV !== 'development'
  ? compose
  : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);
