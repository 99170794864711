import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { ACTIONS_ENUMS } from 'config';
import { BASE } from 'styles/common.sc';
import { auth } from 'services/security/authorisation';
import { currencyService } from 'services/currency/currencyService';
import moment from 'moment-timezone';
import { arePaymentLinksAvailable } from 'components/ui/payment-links/with-payment-links-poc';

// Retuns the app assets folder path
export const assetsPath = `${process.env.PUBLIC_URL}/assets`;

// Converts PX to em
export function toEm(value: number, baseValue: number = BASE) {
  return `${value / baseValue}em`;
}

/**
 * Side Menu Links
 */
export function getMenuArray(user?: Object) {
  return [
    {
      label: 'Dashboard',
      link: '/dashboard',
      icon: 'sidebar-dashboard',
      id: 'dashboard-link',
      isAuthorised: auth.canView('dashboard')
    },
    {
      label: 'Payments',
      link: '/payments',
      icon: 'sidebar-transactions',
      id: 'payments-link',
      isAuthorised: auth.canView('transactions')
    },
    // {
    //   label: 'Payment Links',
    //   link: '/payment-links',
    //   icon: 'sidebar-transactions',
    //   id: 'payment-links-link',
    //   isAuthorised: auth.canView('transactions') && arePaymentLinksAvailable(['qa', 'local', 'sandbox'], user)
    // },
    {
      label: 'Disputes',
      link: '/disputes',
      icon: 'sidebar-disputes',
      id: 'disputes-link',
      isAuthorised: auth.canView('disputes')
    },
    {
      label: 'Customers',
      link: '/customers',
      icon: 'sidebar-customers',
      id: 'customers-link',
      isAuthorised: auth.canView('customers')
    },
    // {
    //   label: 'Payment plans',
    //   link: '/payment-plans',
    //   icon: 'sidebar-plans',
    //   id: 'payment-link',
    // },
    {
      label: 'Statements',
      link: '/statements',
      icon: 'sidebar-statements',
      id: 'statements-link',
      isAuthorised: auth.canView('Deposits::Viewing')
    },
    // {
    //   label: 'Blacklist cards',
    //   link: '/risk/blacklist',
    //   icon: 'sidebar-blacklisted',
    //   id: 'blacklist-link',
    // },
    {
      label: 'Reports',
      link: '/reports',
      icon: 'sidebar-reports',
      id: 'reports-link',
      isAuthorised: auth.canView('reports')
    },
    {
      label: 'Risk',
      link: '/risk',
      icon: 'sidebar-risk',
      id: 'risk-link',
      isAuthorised: true
    },
    {
      isAuthorised: true,
      sideInfo: true
    },
    {
      settings: true,
      label: 'Settings',
      icon: 'sidebar-settings',
      id: 'settings-link',
      isAuthorised: true
    },
    {
      isAuthorised: true,
      logout: true,
      label: 'Log out',
      icon: 'sidebar-logout',
      id: 'logout-link'
    }
  ];
}

/**
 * Remove empty props in the object
 * @returns {object}
 */
export const removeEmptyObjects = (objData: Object) => {
  return pickBy(objData, identity);
};

/**
 * Common form fields error message
 * @returns {object}
 */
export const formErrorMsg = {
  creditCard: 'Please enter a valid card details',
  email: 'Please enter a valid email',
  cardholder: 'Please enter cardholder name',
  amount: 'Please enter valid amount',
  cardExpDate: 'Please enter valid date',
  cvv: 'Please enter valid CVV',
  channel: 'Please select a channel',
  currency: 'Please select a currency',
  paymentPlan: 'Please select payment plan',
  address: 'Please enter the address',
  city: 'Please enter the city',
  postcode: 'Please enter the postcode',
  country: 'Please select country'
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
};

export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 24 },
    sm: { span: 14, offset: 6 }
  }
};

export const ColWithOutLabel = {
  xs: { span: 24, offset: 24 },
  sm: { span: 14, offset: 6 }
};

export const isActionAllowed = (
  allowedAction: number,
  action: Object,
  canEdit: boolean
): boolean => {
  if (canEdit || action.id === 4) {
    const actionName = action.name.toUpperCase();
    const parseAllowedActions = Object.values(ACTIONS_ENUMS).map(
      actionEnumValue => !!(actionEnumValue & allowedAction)
    );
    return parseAllowedActions[actionName];
  }
  return false;
};

export function getFormattedPhoneNumber(phone: Object): string {
  if (!phone.countryCode) return '';
  return `+${phone.countryCode} ${phone.number}`;
}

export const FILTER_ACTIONS = {
  CONTAINS: 'CONTAINS',
  EQUALS: 'EQUALS',
  BEGINS: 'BEGINS',
  ENDS: 'ENDS',
  GT: 'GT',
  GTE: 'GTE',
  LT: 'LT',
  LTE: 'LTE',
  SHOW: 'INCLUDE',
  HIDE: 'EXCLUDE'
};

export function numberShortner(num: number): string {
  const shortenOptionsList = [
    { power: 1e9, symbol: 'B' },
    { power: 1e6, symbol: 'M' },
    { power: 1e3, symbol: 'K' }
  ];
  const shortenOption = shortenOptionsList.find(option => num >= option.power);

  if (!shortenOption) {
    return String(num);
  }
  const zeroSuffixPattern = /\.0+$/;
  const value = (num / shortenOption.power).toFixed(2).replace(zeroSuffixPattern, '');
  const formattedValue = `${value}${shortenOption.symbol}`;

  return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getDevice = {
  width: window.innerWidth,
  height: window.innerHeight
};

export function getBrowserType(): Object {
  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari = /constructor/i.test(window.HTMLElement)
    || (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    }(!window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification)));

  // Edge 20+
  const isEdge = !window.ie && !!window.StyleMedia;

  // Chrome 1+
  const isChrome = !!window.chrome && !!window.chrome.webstore;

  return {
    isFirefox,
    isSafari,
    isEdge,
    isChrome
  };
}

export function formatNumber(
  value: number,
  currencyName: ?string,
  shorten?: boolean = false,
  useCommas?: boolean = true,
  dropDecimals?: boolean = false
): string {
  if (!currencyName && !value) return '0';
  if (shorten && value >= 1e4) return numberShortner(value);

  if (useCommas && !currencyName) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return currencyService.wrap(currencyName).format(value, useCommas, dropDecimals);
}

export function formatToCents(currencyId: number | string, value: number): number {
  const currency = currencyService.wrap(currencyId);
  if (currency === null) return 0;
  return currency.convertToCents(value);
}

export function elemInView(
  el: ?HTMLElement,
  rect: ?(ClientRect | DOMRect),
  offset: number = 0,
  parentHeight: number
): boolean {
  // if we don't have the ref, then it can't be in view
  // we will have the rect if we have the el, but flow doesn't know that
  if (!el || !rect) {
    return false;
  }

  // the element is completely before the section in view
  if (rect.top - offset + rect.height < 0) {
    return false;
  }

  // the element is completely after the section in view
  if (rect.top + offset > parentHeight) {
    return false;
  }

  // if the element is not completely before or after the sectionInView
  // then it must be inView
  return true;
}

export const blankValue = 'Unknown';

export function isUrlValid(value: string): boolean {
  const URL_VALIDATOR = /^(?:(?:https?|ftp):\/\/)?(?:(?:(?:[^\s/<>()$?#.]+)(?:\.(?:[^\s$?#().])+)+)|localhost(?::[0-9]+)?)(?:\/[^\s]*)*$/i;

  const pattern = new RegExp(URL_VALIDATOR);

  if (pattern.test(value)) {
    return true;
  }
  return false;
}

export function getAllTimezone(): Array<any> {
  let timezones = moment.tz
    .names()
    .filter(name => {
      function isExplicitOffset() {
        return name.includes('-') || name.includes('+') || name.includes('Etc/');
      }

      function isAcronym() {
        return name.split('').every(c => c.toUpperCase() === c);
      }

      return name.toUpperCase() === 'GMT' || (!isExplicitOffset() && !isAcronym());
    })
    .map(name => {
      const timezone = moment.tz.zone(name);

      var utcOffset = -timezone.utcOffset(moment.utc());
      var offsetStringRep = () => {
        var prefix = utcOffset >= 0 ? 'UTC+' : 'UTC-';

        var absOffset = Math.abs(utcOffset);
        var hours = Math.floor(absOffset / 60);
        var minutes = absOffset % 60;
        hours = (hours < 10 ? '0' : '') + hours;
        minutes = (minutes < 10 ? '0' : '') + minutes;

        return prefix + hours + ':' + minutes;
      };

      return {
        name: timezone.name,
        label: '(' + offsetStringRep() + ') ' + timezone.name.replace(/_/g, ' '),
        offset: utcOffset
      };
    });

  timezones.forEach(timezone => {
    timezones[timezone.name] = timezone;
  });

  return timezones;
}

export function dateRangePresets() {
  return [
    {
      text: 'Today',
      id: 'today',
      start: moment().startOf('day'),
      end: moment().endOf('day')
    },
    {
      text: 'Yesterday',
      id: 'yesterday',
      start: moment()
        .subtract(1, 'day')
        .startOf('day'),
      end: moment()
        .subtract(1, 'day')
        .endOf('day')
    },
    {
      text: 'This Week',
      id: 'this-week',
      start: moment().startOf('week'),
      end: moment().endOf('week')
    },
    {
      text: 'Past Week',
      id: 'past-week',
      start: moment()
        .subtract(1, 'week')
        .startOf('week'),
      end: moment()
        .subtract(1, 'week')
        .endOf('week')
    },
    {
      text: 'This Month',
      id: 'this-month',
      start: moment().startOf('month'),
      end: moment().endOf('month')
    },
    {
      text: 'Past Month',
      id: 'past-month',
      start: moment()
        .subtract(1, 'month')
        .startOf('month'),
      end: moment()
        .subtract(1, 'month')
        .endOf('month')
    }
  ];
}

export function compareDates(a, b, comparionFunc) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return comparionFunc(a.date(), b.date());
  if (isSameYear) return comparionFunc(aMonth, bMonth);
  return comparionFunc(aYear, bYear);
}

export function isSameDate(a, b) {
  return compareDates(a, b, (x, y) => x === y);
}

export function isBeforeDate(a, b) {
  return compareDates(a, b, (x, y) => x < y);
}
