// @flow
import { combineReducers } from 'redux';
import userState, { type UserState } from 'store/user-duck';
import globalState, { type GlobalState } from 'store/global-duck';
import passwordState, { type PasswordState } from 'store/password-duck';
import channelSettingState, {
  type ChannelSettingState,
} from 'store/channels-settings-duck';
import paymentState, { type PaymentState } from 'store/payments-duck';
import paymentDetailsState, {
  type PaymentDetailsState,
} from 'store/payment-details-duck';
import paymentLinksState, { type PaymentLinksState} from 'store/payment-links-duck';
import paymentPlanState, {
  type PaymentPlanState,
} from 'store/payment-plan-duck';
import customersState, { type CustomersState } from 'store/customers-duck';
import customerDetailsState, {
  type CustomerDetailsState,
} from 'store/customer-details-duck';
import statementsState, { type StatementsState } from 'store/statements-duck';
import userManagementState, {
  type UserManagementState,
} from 'store/user-management-duck';
import payoutsState, { type PayoutsState } from 'store/payouts-duck';
import reportsState, { type ReportsState } from 'store/reports-duck';
import uiState, { type UIState } from 'store/ui-duck';
import applePayState, { type ApplePayState } from 'store/apple-pay-duck';
import disputesState, { type DisputesState } from 'store/disputes-duck';
import riskState, { type RiskState } from 'store/risk-duck';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import * as submitEvidenceDuck from 'store/ducks/submit-evidence';

export type StoreState = {
  userState: UserState,
  passwordState: PasswordState,
  globalState: GlobalState,
  uiState: UIState,
  statementsState: StatementsState,
  payoutsState: PayoutsState,
  paymentState: PaymentState,
  paymentDetailsState: PaymentDetailsState,
  paymentLinksState: PaymentLinksState,
  paymentPlanState: PaymentPlanState,
  customersState: CustomersState,
  customerDetailsState: CustomerDetailsState,
  reportsState: ReportsState,
  loadingBar: Object,
  userManagementState: UserManagementState,
  channelSettingState: ChannelSettingState,
  applePayState: ApplePayState,
  disputesState: DisputesState,
  submitEvidence: submitEvidenceDuck.State,
  risk: RiskState
};

// used to type thunks
export type GetState = () => StoreState;

export default combineReducers({
  userState,
  passwordState,
  globalState,
  uiState,
  paymentState,
  paymentDetailsState,
  paymentLinksState,
  paymentPlanState,
  customersState,
  customerDetailsState,
  statementsState,
  reportsState,
  loadingBar,
  userManagementState,
  channelSettingState,
  payoutsState,
  applePayState,
  disputesState,
  submitEvidence: submitEvidenceDuck.reducer,
  risk: riskState
});
