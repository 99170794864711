import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import * as Styled from './CkoTooltip.sc';

type Props = {
  visible?: boolean,
  primary?: boolean,
  warning?: boolean,
  white?: boolean,
  danger?: boolean,
  width?: string,
  title: React.Node,
  placement?: string,
  className?: string,
  solid?: boolean,
  children: React.Node,
};

const CkoTooltip = (props:Props)=> {
  const tipContainer = useRef(false);

  const { placement, title, ...propsRest } = {
    ...props,
    className: `cko-tooltip ${props.className || ''}`
  };
  return (
    <Styled.Container {...propsRest}>
      <Tooltip {...props} getPopupContainer={() => tipContainer.current}>
          <span ref={tipContainer} className="inner-container" />
          {props.children}
      </Tooltip>
    </Styled.Container>
  );
};


export default CkoTooltip;