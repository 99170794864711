export default {
  okta: {
    issuer: process.env.REACT_APP_OKTA_URL + '/oauth2/' + process.env.REACT_APP_OKTA_ISSUER || '',
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID || '',
    redirectUri: window.location.origin + '/implicit/callback',
    webFingerUri: process.env.REACT_APP_OKTA_URL + '/.well-known/webfinger',
    serviceUrl: process.env.REACT_APP_OKTA_URL || '',
    appEmbedLinkId: process.env.REACT_APP_OKTA_EMBED_LINK_ID || '',
  },
  baseURL: process.env.REACT_APP_BASE_URL || '',
  appVersion: process.env.REACT_APP_VERSION || '',
  env: process.env.REACT_APP_ENV || '',
  flags: {},
  prism: {
    baseURL: process.env.REACT_APP_PRISM_BASE_URL || '',
    apiBaseUrl: process.env.REACT_APP_PRISM_API_BASE_URL || ''
  }
};
