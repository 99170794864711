// @flow
import * as LoginAPI from 'services/basics/login.api';
import { dispatchResponse } from 'utils/action.util';
import { type RequestStatus } from 'store/store.types';

// XXX: in the future, should be processed in a standard way, and not
// placed directly in the state tree.
type ErrorData = {
  errorCode?: string,
  eventId?: string,
  message: string,
};

export type Credentials = {
  email: string,
  password: string,
} | false;

// The credentials are cached here so they can be preserved
// during a password reset
export type NewPasswordState = RequestStatus & {
  credentials: Credentials,
  errorData: ErrorData,
};

export type RequestPasswordState = RequestStatus & {
  errorData: ErrorData,
};

export type VerifyTokenState = RequestStatus;

export type PasswordState = {
  newPassword: NewPasswordState,
  requestPassword: RequestPasswordState,
  verifyToken: VerifyTokenState,
};

type ChangePasswordPendingType = 'CHANGE_PASSWORD_PENDING';
type ChangePasswordErrorType = 'CHANGE_PASSWORD_ERROR';
type ChangePasswordSuccessType = 'CHANGE_PASSWORD_SUCCESS';
type RequestPasswordPendingType = 'REQUEST_PASSWORD_PENDING';
type RequestPasswordErrorType = 'REQUEST_PASSWORD_ERROR';
type RequestPasswordSuccessType = 'REQUEST_PASSWORD_SUCCESS';
type VerifyTokenPendingType = 'VERIFY_TOKEN_PENDING';
type VerifyTokenErrorType = 'VERIFY_TOKEN_ERROR';
type VerifyTokenSuccessType = 'VERIFY_TOKEN_SUCCESS';
type ClearPasswordType = 'PASSWORD_CLEAR';
type CacheCredentialsType = 'PASSWORD_CREDENTIALS_CACHE';

type ChangePasswordPendingAction = {
  type: ChangePasswordPendingType,
};

type ChangePasswordErrorAction = {
  type: ChangePasswordErrorType,
  payload: {
    data: ErrorData,
  },
};

type ChangePasswordSuccessAction = {
  type: ChangePasswordSuccessType,
};

type RequestPasswordPendingAction = {
  type: RequestPasswordPendingType,
};

type RequestPasswordErrorAction = {
  type: RequestPasswordErrorType,
  payload: {
    data: ErrorData,
  },
};

type RequestPasswordSuccessAction = {
  type: RequestPasswordSuccessType,
};

type VerifyTokenPendingAction = {
  type: VerifyTokenPendingType,
};

type VerifyTokenErrorAction = {
  type: VerifyTokenErrorType,
};

type VerifyTokenSuccessAction = {
  type: VerifyTokenSuccessType,
};

type ClearPasswordAction = {
  type: ClearPasswordType,
};

type CacheCredentialsAction = {
  type: CacheCredentialsType,
  credentials: Credentials,
};

type Action =
  | ChangePasswordPendingAction
  | ChangePasswordErrorAction
  | ChangePasswordSuccessAction
  | RequestPasswordPendingAction
  | RequestPasswordErrorAction
  | RequestPasswordSuccessAction
  | VerifyTokenPendingAction
  | VerifyTokenErrorAction
  | VerifyTokenSuccessAction
  | ClearPasswordAction
  | CacheCredentialsAction;

export const CHANGE_PASSWORD_ACTION_TYPES: {
  PENDING: ChangePasswordPendingType,
  ERROR: ChangePasswordErrorType,
  SUCCESS: ChangePasswordSuccessType,
} = {
  PENDING: 'CHANGE_PASSWORD_PENDING',
  ERROR: 'CHANGE_PASSWORD_ERROR',
  SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
};

const REQUEST_PASSWORD_ACTION_TYPES: {
  PENDING: RequestPasswordPendingType,
  ERROR: RequestPasswordErrorType,
  SUCCESS: RequestPasswordSuccessType,
} = {
  PENDING: 'REQUEST_PASSWORD_PENDING',
  ERROR: 'REQUEST_PASSWORD_ERROR',
  SUCCESS: 'REQUEST_PASSWORD_SUCCESS',
};

const VERIFY_TOKEN_ACTION_TYPES: {
  PENDING: VerifyTokenPendingType,
  ERROR: VerifyTokenErrorType,
  SUCCESS: VerifyTokenSuccessType,
} = {
  PENDING: 'VERIFY_TOKEN_PENDING',
  ERROR: 'VERIFY_TOKEN_ERROR',
  SUCCESS: 'VERIFY_TOKEN_SUCCESS',
};

const PASSWORD_ACTION_TYPES: {
  CLEAR: ClearPasswordType,
  CACHE_CREDENTIALS: CacheCredentialsType,
} = {
  CLEAR: 'PASSWORD_CLEAR',
  CACHE_CREDENTIALS: 'PASSWORD_CREDENTIALS_CACHE',
};

// exported for testing purposes
export const DEFAULT_STATE: PasswordState = {
  newPassword: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    credentials: {
      email: '',
      password: '',
    },
    errorData: {
      errorCode: '',
      eventId: '',
      message: '',
    },
  },
  requestPassword: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    errorData: {
      errorCode: '',
      eventId: '',
      message: '',
    },
  },
  verifyToken: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    errorData: {
      errorCode: '',
      eventId: '',
      message: '',
    },
  },
};

function getErrorMessage(errorData: ErrorData): string {
  if (errorData.message) {
    return errorData.message;
  }

  return 'Unable to process you request';
}

export default function reducer(
  state: PasswordState = DEFAULT_STATE,
  action: Action
): PasswordState {
  switch (action.type) {
    case CHANGE_PASSWORD_ACTION_TYPES.PENDING:
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          loading: true,
        },
      };

    case CHANGE_PASSWORD_ACTION_TYPES.ERROR:
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          loading: false,
          error: true,
          errorMessage: getErrorMessage(action.payload.data),
          errorData: action.payload.data,
        },
      };

    case CHANGE_PASSWORD_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          credentials: {
            email: '',
            password: '',
          },
          loading: false,
          success: true,
        },
      };

    case REQUEST_PASSWORD_ACTION_TYPES.PENDING:
      return {
        ...state,
        requestPassword: {
          ...state.requestPassword,
          loading: true,
        },
      };

    case REQUEST_PASSWORD_ACTION_TYPES.ERROR:
      return {
        ...state,
        requestPassword: {
          ...state.requestPassword,
          loading: false,
          error: true,
          errorData: action.payload.data,
        },
      };

    case REQUEST_PASSWORD_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        requestPassword: {
          ...state.requestPassword,
          loading: false,
          success: true,
        },
      };

    case VERIFY_TOKEN_ACTION_TYPES.PENDING:
      return {
        ...state,
        verifyToken: {
          ...state.verifyToken,
          loading: true,
        },
      };

    case VERIFY_TOKEN_ACTION_TYPES.ERROR:
      return {
        ...state,
        verifyToken: {
          ...state.verifyToken,
          loading: false,
          error: true,
        },
      };

    case VERIFY_TOKEN_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        verifyToken: {
          ...state.verifyToken,
          loading: false,
          success: true,
        },
      };

    case PASSWORD_ACTION_TYPES.CACHE_CREDENTIALS:
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          credentials: action.credentials,
        },
      };

    case PASSWORD_ACTION_TYPES.CLEAR:
      return DEFAULT_STATE;
    default:
      return state;
  }
}

type PasswordChangeSpec = {
  token?: string,
  email: string,
  newPassword: string,
  currentPassword: string,
  confirmPassword: string,
};

export type ChangePassword = (spec: PasswordChangeSpec) => Function;
// Update Password
export function changePassword(spec: PasswordChangeSpec): Function {
  let promiseReq;

  if (spec.token) {
    // If Token exist, then make request to /verify-reset-password
    const { token, email, newPassword, confirmPassword } = spec;
    promiseReq = LoginAPI.verifyResetPassword(
      token,
      email,
      newPassword,
      confirmPassword
    );
  } else {
    // Make request to /change-password
    const { email, currentPassword, newPassword, confirmPassword } = spec;
    promiseReq = LoginAPI.changePassword(
      email,
      currentPassword,
      newPassword,
      confirmPassword
    );
  }
  const { PENDING, SUCCESS, ERROR } = CHANGE_PASSWORD_ACTION_TYPES;
  return dispatchResponse(promiseReq, PENDING, SUCCESS, ERROR);
}

export type RequestResetPassword = (email: string) => Function;
// Request New Password
export function requestResetPassword(email: string) {
  const promiseReq = LoginAPI.requestResetPassword(email);
  const { PENDING, SUCCESS, ERROR } = REQUEST_PASSWORD_ACTION_TYPES;
  return dispatchResponse(promiseReq, PENDING, SUCCESS, ERROR);
}

export type VerifyToken = (token: string) => Function;
// Verify Token
export function verifyToken(token: string) {
  // Make request to /verify-token
  const promiseReq = LoginAPI.verifyToken(token);
  const { PENDING, SUCCESS, ERROR } = VERIFY_TOKEN_ACTION_TYPES;
  return dispatchResponse(promiseReq, PENDING, SUCCESS, ERROR);
}

export type ClearPassword = () => ClearPasswordAction;
export function clearPassword(): ClearPasswordAction {
  return {
    type: PASSWORD_ACTION_TYPES.CLEAR,
  };
}

export type CacheCredentials = (
  credentials: Credentials
) => CacheCredentialsAction;
export function cacheCredentials(
  credentials: Credentials
): CacheCredentialsAction {
  return {
    credentials,
    type: PASSWORD_ACTION_TYPES.CACHE_CREDENTIALS,
  };
}
