import { colors, animation } from 'styles/common.sc';
import styled, { css } from 'react-emotion';

const shade1 = '#8b94a9';
const shade2 = '#bac0ce';
const shade3 = '#8b94a9';

function getHeightAndWidth(width) {
  let iconWidth;

  if (width) {
    // eslint-disable-next-line no-restricted-globals
    iconWidth = !isNaN(width) ? `${width}px` : width;
  }

  return css`
    width: ${iconWidth || 'auto'};
  `;
}

function containerStyle(props) {
  return css`
    padding: ${props.padding ? props.padding : '0'};
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-transform: none;
    line-height: 1px;
    border-radius: 3px;
    background-color: ${props.isPlaceholder ? colors.border.default : 'transparent'};

    ${getHeightAndWidth(props.width)};

    svg {
      max-width: 100%;
      height: auto;
    }

    span {
      height: 100%;
      width: 100%;
      display: block;
    }

    .white {
      fill: #fff;
    }

    .dark {
      fill: ${shade3};
    }
    .light {
      fill: ${shade2};
    }

    .dark,
    .light,
    .dark,
    .grey {
      ${animation.ease};
    }

    & .icon-sidebar-transactions,
    & .icon-sidebar-dashboard {
      .dark {
        fill: ${shade1};
      }
      .light {
        fill: ${shade2};
      }
    }

    /* Plans */
    & .icon-sidebar-plans {
      .grey {
        fill: #fff;
      }
    }

    .icon-calendar * {
      fill: ${colors.icon.default};
    }

    .icon-right-arrow *,
    .icon-left-arrow *,
    .icon-down-arrow * {
      fill: #abb3c4;
    }

    .icon-paypal-full * {
      fill: #2464b4;
    }

    .flag-icon {
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0 2px 5px 0 rgba(121, 131, 149, 0.2);
    }
  `;
}

export const Container = styled('span')`
  ${containerStyle};
`;
