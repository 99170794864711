import isPlainObject from 'lodash/isPlainObject';
import { isUndefined } from 'utils/';
import { ENTITY_TYPE } from 'config';

/**
 * Check if the required params exist
 * @param {object} dataParams
 * @param {array} requiredParams
 * @param {number} currencyId
 * @returns {array}
 */
export function validateParams(
  dataParams: Object,
  requiredParams: Array<string>,
  currencyId: ?number | ?string
) {
  // Check if dataParams is undefined
  if (isUndefined(dataParams)) {
    showErrorInConsole(requiredParams);
    return requiredParams.toString();
  }

  const errorList = [...validateArray(dataParams, requiredParams)];

  // If currencyId exist
  if (currencyId && isUndefined(currencyId)) {
    errorList.push('currencyId');
  }

  // Show in console
  if (errorList.length > 0 && errorList[0]) {
    showErrorInConsole(errorList);
  }

  // Return list of missing params
  return errorList ? errorList.toString() : '';
}

function validateArray(dataParams, paramList) {
  const errors = [];
  paramList.forEach(propKey => {
    if (isPlainObject(propKey)) {
      errors.push(validateObject(propKey, dataParams));
    } else if (isUndefined(dataParams[propKey])) {
      errors.push(propKey);
    }
  });
  return errors;
}

function validateObject(data, dataParams): string {
  const objKey = Object.keys(data)[0];
  const paramValue = data[objKey];
  const reqParam = dataParams[objKey];
  if (isUndefined(reqParam)) {
    return objKey;
  }
  const errorList = validateArray(reqParam, paramValue);
  return errorList.toString();
}

function showErrorInConsole(requiredParams: Array<string>): void {
  // Show error in console log
  const list = [...requiredParams];
  const errorMsg = `{${list.toString()}} param(s) is missing`;
  if (process.env.NODE_ENV === 'development') console.error(errorMsg);
}

export function getEntityType(entityTypeId: string): string {
  if (!entityTypeId) {
    return 'accounts';
  }

  switch (entityTypeId) {
    case ENTITY_TYPE.CHANNEL.toString(10):
      return 'channels';
    case ENTITY_TYPE.BUSINESS.toString(10):
      return 'businesses';
    default:
      return 'accounts';
  }
}


const evidenceKeyToType = (key: string) => {
  const keyToType = {
    shipping: 'ProofOfDeliveryOrService',
    invoice: 'InvoiceOrReceipt',
    matchingPayments: 'InvoiceShowingDistinctTransactions',
    subscription: 'RecurringTransactionAgreement',
    terms: 'RefundOrCancellationPolicy',
    communication: 'CustomerCommunication',
    additional: 'AdditionalEvidence'
  };

  return keyToType[key];
};


const removeDataPrefix = str => str.includes('base64,') ? str.slice(str.indexOf('base64,') + 7) : str;

const evidenceTypeToKey = (type: string) => {
  const typeToKey = {
    ProofOfDeliveryOrService: 'shipping',
    InvoiceOrReceipt: 'invoice',
    InvoiceShowingDistinctTransactions: 'matchingPayments',
    RecurringTransactionAgreement: 'subscription',
    RefundOrCancellationPolicy: 'terms',
    CustomerCommunication: 'communication',
    AdditionalEvidence: 'additional'
  };

  return typeToKey[type];
};


export function marshallEvidence(rawEvidence: evidenceDuck.State): Evidence {
  const fieldKeys = Object.keys(rawEvidence);
  return fieldKeys.reduce((evidence, key) => {
    const rawField = rawEvidence[key];
    const hasContent = rawField.description.length || rawField.file.filename.length;
    if (hasContent) {
      const field = {
        type: evidenceKeyToType(key),
        fileId: rawField.file.id,
        filename: rawField.file.filename,
        fileContent: removeDataPrefix(rawField.file.content),
        description: rawField.description
      };
      return [...evidence, field];
    }
    return evidence;
  }, []);
}

export function unmarshallEvidence(evidence: Evidence): evidenceDuck.MaybeState {
  return evidence.reduce((rawEvidence, evidenceField) => {
    return {
      ...rawEvidence,
      [evidenceTypeToKey(evidenceField.type)]: {
        description: evidenceField.description || '',
        file: {
          loading: false,
          error: '',
          id: evidenceField.fileId || '',
          filename: evidenceField.fileName || '',
          content: evidenceField.fileContent || ''
        }
      }
    };
  }, {});
}
