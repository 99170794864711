// @flow
import { produce } from 'utils/tree';

import { getEntity } from 'utils/action.util';
import * as EvidenceAPI from 'services/resources/evidence.api';
import * as PaymentsAPI from 'services/resources/payments.api';
import * as paymentDetailsDuck from 'store/payment-details-duck';
import { type GetState } from 'store/reducers';

export type Request = {
  error: boolean,
  success: boolean,
  loading: boolean
};

type DisputeReason =
  | 'fraudulent'
  | 'unrecognized'
  | 'canceled_recurring'
  | 'product_service_not_received'
  | 'not_as_described'
  | 'credit_not_issued'
  | 'duplicate'
  | 'incorrect_amount'
  | 'general';

export type Dispute = {
  reasonCode: DisputeReason,
  payment: {
    id: string,
    amount: number,
    currency: string,
    method: string,
    arn: string,
    reference: string,
    processedOn: string
  },
  recommendedEvidence: Array<string>,
  relevantEvidence: Array<string>,
  id: string,
  category: string,
  currency: string,
  status: string,
  amount: number,
  lastUpdate: string,
  receivedOn: string,
  evidenceRequiredBy: string
};

export type State = {
  dispute: {
    request: Request,
    data: Dispute
  },
  payment: {
    request: Request,
    data: paymentDetailsDuck.PaymentPayload
  }
};

type GetDisputePendingAction = {
  type: 'GET_DISPUTE_PENDING'
};
function getDisputePending(): GetDisputePendingAction {
  return {
    type: 'GET_DISPUTE_PENDING'
  };
}

type GetDisputeSuccessAction = {
  type: 'GET_DISPUTE_SUCCESS',
  dispute: Dispute
};

function getDisputeSuccess(dispute: Dispute): GetDisputeSuccessAction {
  return {
    dispute,
    type: 'GET_DISPUTE_SUCCESS'
  };
}

type GetDisputeErrorAction = {
  type: 'GET_DISPUTE_ERROR',
  error: string
};

function getDisputeError(error: string): GetDisputeErrorAction {
  return {
    error,
    type: 'GET_DISPUTE_ERROR'
  };
}

type GetDisputePaymentDetailsErrorAction = {
  type: 'GET_DISPUTE_PAYMENT_DETAILS_ERROR',
  error: string
};
function getDisputePaymentDetailsError(
  error: string
): GetDisputePaymentDetailsErrorAction {
  return {
    error,
    type: 'GET_DISPUTE_PAYMENT_DETAILS_ERROR'
  };
}

type GetDisputePaymentDetailsPendingAction = {
  type: 'GET_DISPUTE_PAYMENT_DETAILS_PENDING'
};
function getDisputePaymentDetailsPending(): GetDisputePaymentDetailsPendingAction {
  return {
    type: 'GET_DISPUTE_PAYMENT_DETAILS_PENDING'
  };
}

type GetDisputePaymentDetailsSuccessAction = {
  type: 'GET_DISPUTE_PAYMENT_DETAILS_SUCCESS',
  payment: paymentDetailsDuck.PaymentPayload
};
function getDisputePaymentDetailsSuccess(
  payment: paymentDetailsDuck.PaymentPayload
): GetDisputePaymentDetailsSuccessAction {
  return {
    payment,
    type: 'GET_DISPUTE_PAYMENT_DETAILS_SUCCESS'
  };
}

export type GetDispute = (businessId: string, disputeId: string) => Function;
export function getDispute(businessId: string, disputeId: string): GetDispute {
  return async (dispatch: Function) => {
    dispatch(getDisputePending());
    const { data, error } = await EvidenceAPI.getDispute({
      businessId,
      disputeId
    });
    if (error) {
      dispatch(getDisputeError(error));
    } else {
      dispatch(getDisputeSuccess(data));
    }
  };
}

export type GetPaymentDetails = (businessId: string, paymentId: string) => void;
export function getPaymentDetails(
  businessId: string,
  paymentId: string
): GetPaymentDetails {
  return function getPaymentDetailsThunk(
    dispatch: Dispatch<any>,
    getState: GetState
  ) {
    const entityIds = {
      businessId
    };
    const entity = getEntity((entityIds: any));
    const request = {
      paymentId,
      entityId: entity.id,
      entityType: entity.type
    };
    dispatch(getDisputePaymentDetailsPending());
    PaymentsAPI.getPaymentDetails(request)
      .then(function(response) {
        const payment = response.data;
        dispatch(getDisputePaymentDetailsSuccess(payment));
      })
      .catch(function(error) {
        dispatch(getDisputePaymentDetailsError(error));
      });
  };
}

const DEFAULT_STATE: State = {
  dispute: {
    request: {
      error: false,
      loading: false,
      success: false
    },
    data: {
      reasonCode: '',
      payment: {
        id: '',
        amount: 0,
        currency: '',
        method: '',
        arn: '',
        reference: '',
        processedOn: ''
      },
      relevantEvidence: [],
      id: '',
      category: '',
      currency: '',
      status: '',
      amount: 0,
      lastUpdate: '',
      receivedOn: '',
      evidenceRequiredBy: ''
    }
  },
  payment: {
    request: {
      error: false,
      loading: false,
      success: false
    },
    data: {
      allowedActions: 0,
      details: {
        arn: '',
        id: '',
        chargeId: '',
        actionDisplayId: '',
        apiVersion: '',
        authorizationCode: '',
        authorizationDate: '',
        billingDescriptor: '',
        paymentDisplayId: '',
        longResponseCode: '',
        shortResponseCode: '',
        trackId: '',
        transactionIndicator: '',
        dispute: null
      },
      indicators: {
        currencySymbol: '',
        paymentMethodName: '',
        transactionDate: '',
        transactionStatus: '',
        transactionValue: 0
      },
      logs: [],
      paymentMethods: {
        avsCheck: '',
        avsDescription: '',
        bankCountry: '',
        cardHolder: '',
        cardId: '',
        cardProduct: '',
        cardWalletType: '',
        ccNumber: '',
        customerId: '',
        customerEmail: '',
        customerDisplayId: '',
        customerName: '',
        cvvCheck: '',
        cvvDescription: '',
        cvvPresent: false,
        expiryMonth: 0,
        expiryYear: 0,
        issuingBank: '',
        paymentMethod: '',
        paymentSource: {
          sourceId: '',
          responseData: null
        }
      },
      purchases: {
        captureTransactionId: '',
        currencyId: 0,
        currencySymbol: '',
        customerIp: '',
        customerIpCountryIso3: '',
        description: '',
        maxRefundAmount: 0,
        product: [],
        shippingAddress: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          country: '',
          phone: { number: '' },
          postCode: '',
          recipientName: '',
          state: '',
          userDefined: ''
        },
        userData: {}
      },
      dispute: {
        disputeId: '',
        initiatedOn: '',
        reason: '',
        reasonCode: '',
        status: '',
        submitEvidenceBy: ''
      }
    }
  }
};

type Action =
  | GetDisputePendingAction
  | GetDisputeErrorAction
  | GetDisputeSuccessAction
  | GetDisputePaymentDetailsPendingAction
  | GetDisputePaymentDetailsErrorAction
  | GetDisputePaymentDetailsSuccessAction;

export const reducer = (
  state: State = DEFAULT_STATE,
  action: Action
): State => {
  return produce(state, function(draft) {
    switch (action.type) {
      case 'GET_DISPUTE_PENDING':
        draft.dispute.request.loading = true;
        break;
      case 'GET_DISPUTE_ERROR':
        draft.dispute.request = {
          loading: false,
          error: true,
          success: false
        };
        break;
      case 'GET_DISPUTE_SUCCESS':
        draft.dispute = {
          request: {
            loading: false,
            error: false,
            success: true
          },
          data: action.dispute
        };
        break;
      case 'GET_DISPUTE_PAYMENT_DETAILS_PENDING':
        draft.payment.request.loading = true;
        break;
      case 'GET_DISPUTE_PAYMENT_DETAILS_ERROR':
        draft.payment.request = {
          loading: false,
          error: true,
          success: false
        };
        break;
      case 'GET_DISPUTE_PAYMENT_DETAILS_SUCCESS':
        draft.payment = {
          request: {
            loading: false,
            error: false,
            success: true
          },
          data: action.payment
        };
        break;
      default:
        break;
    }
  });
};
