// @flow
import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Analytics from 'services/analytics';
import Login from 'components/pages/login/';
import ResetPassword from 'components/pages/reset-password/';
import ForgotPassword from 'components/pages/forgot-password/';
import VerifyToken from 'components/pages/verify-token';
import {LoginCallback} from "@okta/okta-react";

const PublicRoutes = () => (
  <>
    <Route component={Analytics} />
    <Switch>
      <Route path="/implicit/callback" component={LoginCallback} />
      <Route path="/login" component={Login} />
      <Route exact path="/reset-password/:token?" component={ResetPassword} />
      <Route exact path="/verify/:token" component={VerifyToken} />
      <Route path="/forgot-password" component={ForgotPassword} />
      {/* 404 */}
      <Redirect to="/login" />
    </Switch>
  </>
);

export default PublicRoutes;
