// @flow
import * as CustomersAPI from 'services/resources/customers.api';
import * as PaymentPlanAPI from 'services/resources/payment-plans.api';
import * as PaymentsAPI from 'services/resources/payments.api';
import { dispatchResponse, getEntity } from 'utils/action.util';
import { type RequestStatus } from '../store.types';

export type Accounts = {
  accountId: number,
  businessId: number,
  channelId: number
};

export type Address = {
  addressLine1: string,
  addressLine2: string,
  postcode: string,
  country: string,
  city: string,
  state: string
};
export type Phone = {
  countryCode: string,
  number: string
};
export type AddressWithPhone = Address & {
  phone: Phone
};

type PaymentChargeData = {
  status: string,
  responseMessage: string
};

export type Card = {
  customerId: string,
  expiryMonth: string,
  expiryYear: string,
  defaultCard: boolean,
  displayId: string,
  id: string,
  last4: string,
  paymentMethod: string,
  fingerprint: string,
  name: string,
  cvvCheck: ?Object,
  avsCheck: string,
  responseCode: ?Object,
  billingDetails: AddressWithPhone
};

export type Cards = {
  count: number,
  data: Array<Card> // TODO rename data to list
};

export type PaymentPlan = {
  planId: string,
  customerPlanId: string,
  cardId: string,
  customerId: string,
  name: string,
  planTrackId: string,
  autoCapTime: number,
  value: number,
  currency: string,
  cycle: string,
  recurringCount: number,
  recurringCountLeft: number,
  totalCollectedValue: number,
  totalCollectedCount: number,
  status: number,
  startDate: string,
  previousRecurringDate: ?string,
  nextRecurringDate: string,
  channelId: string
};

export type Details = {
  name: string,
  email: string,
  phone: Phone,
  description: ?string,
  displayId: string,
  id: string,
  livemode: boolean,
  lTV: number,
  orderHistory: number,
  defaultCard: string,
  metadata: Object, // TODO what is metadata?
  createdDate: string,
  cards: Cards,
  isDeleted: boolean
};

export type Payment = {
  chargeTransactionDate: string,
  timeStamp: string,
  liveMode: boolean,
  totalSales: number,
  property: number,
  channel: number,
  scheme: number,
  actionId: ?string,
  businessName: string,
  channelName: string,
  currencySymbol: string,
  currencySymbolCode: string,
  displayActionId: ?string,
  displayPaymentId: ?string,
  id: string,
  paymentId: ?string,
  product: ?string,
  risk: ?string,
  status: string,
  tranType: string,
  type: string
};

/**
 * Update customer details types
 */
type UpdateDetailsPendingType = 'CUSTOMER_DETAILS_UPDATE_DETAILS_PENDING';
type UpdateDetailsSuccessType = 'CUSTOMER_DETAILS_UPDATE_DETAILS_SUCCESS';
type UpdateDetailsErrorType = 'CUSTOMER_DETAILS_UPDATE_DETAILS_ERROR';
export const UPDATE_DETAILS: {
  PENDING: UpdateDetailsPendingType,
  SUCCESS: UpdateDetailsSuccessType,
  ERROR: UpdateDetailsErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_UPDATE_DETAILS_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_UPDATE_DETAILS_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_UPDATE_DETAILS_ERROR'
};
type UpdateDetailsPendingActionType = {
  type: UpdateDetailsPendingType
};
type UpdateDetailsSuccessActionType = {
  type: UpdateDetailsSuccessType
};
type UpdateDetailsErrorActionType = {
  type: UpdateDetailsErrorType,
  payload: {
    data: string
  }
};

/**
 * Create payment types
 */
type CreatePaymentPendingType = 'CUSTOMER_DETAILS_CREATE_PAYMENT_PENDING';
type CreatePaymentSuccessType = 'CUSTOMER_DETAILS_CREATE_PAYMENT_SUCCESS';
type CreatePaymentErrorType = 'CUSTOMER_DETAILS_CREATE_PAYMENT_ERROR';
type CreatePaymentClearType = 'CUSTOMER_DETAILS_CREATE_PAYMENT_CLEAR';
export const CREATE_PAYMENT: {
  PENDING: CreatePaymentPendingType,
  SUCCESS: CreatePaymentSuccessType,
  ERROR: CreatePaymentErrorType,
  CLEAR: CreatePaymentClearType
} = {
  PENDING: 'CUSTOMER_DETAILS_CREATE_PAYMENT_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_CREATE_PAYMENT_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_CREATE_PAYMENT_ERROR',
  CLEAR: 'CUSTOMER_DETAILS_CREATE_PAYMENT_CLEAR'
};
type CreatePaymentPendingActionType = {
  type: CreatePaymentPendingType
};
type CreatePaymentSuccessActionType = {
  type: CreatePaymentSuccessType,
  payload: {
    data: PaymentChargeData
  }
};
type CreatePaymentErrorActionType = {
  type: CreatePaymentErrorType,
  payload: {
    data: string
  }
};
type CreatePaymentClearActionType = {
  type: CreatePaymentClearType
};

/**
 * Add creadit card types
 */
type AddCreditCardPendingType = 'CUSTOMER_DETAILS_ADD_CREDIT_CARD_PENDING';
type AddCreditCardSuccessType = 'CUSTOMER_DETAILS_ADD_CREDIT_CARD_SUCCESS';
type AddCreditCardErrorType = 'CUSTOMER_DETAILS_ADD_CREDIT_CARD_ERROR';
export const ADD_CREDIT_CARD: {
  PENDING: AddCreditCardPendingType,
  SUCCESS: AddCreditCardSuccessType,
  ERROR: AddCreditCardErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_ADD_CREDIT_CARD_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_ADD_CREDIT_CARD_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_ADD_CREDIT_CARD_ERROR'
};
type AddCreditCardPendingActionType = {
  type: AddCreditCardPendingType
};
type AddCreditCardSuccessActionType = {
  type: AddCreditCardSuccessType
};
type AddCreditCardErrorActionType = {
  type: AddCreditCardErrorType,
  payload: {
    data: string
  }
};

/**
 * Add payment plan types
 */
type AddPaymentPlanPendingType = 'CUSTOMER_DETAILS_ADD_PAYMENT_PLAN_PENDING';
type AddPaymentPlanSuccessType = 'CUSTOMER_DETAILS_ADD_PAYMENT_PLAN_SUCCESS';
type AddPaymentPlanErrorType = 'CUSTOMER_DETAILS_ADD_PAYMENT_PLAN_ERROR';
export const ADD_PAYMENT_PLAN: {
  PENDING: AddPaymentPlanPendingType,
  SUCCESS: AddPaymentPlanSuccessType,
  ERROR: AddPaymentPlanErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_ADD_PAYMENT_PLAN_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_ADD_PAYMENT_PLAN_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_ADD_PAYMENT_PLAN_ERROR'
};
type AddPaymentPlanPendingActionType = {
  type: AddPaymentPlanPendingType
};
type AddPaymentPlanSuccessActionType = {
  type: AddPaymentPlanSuccessType
};
type AddPaymentPlanErrorActionType = {
  type: AddPaymentPlanErrorType,
  payload: {
    data: string
  }
};

/**
 * Customer details types
 */
type CustomerDetailsPendingType = 'CUSTOMER_DETAILS_PENDING';
type CustomerDetailsSuccessType = 'CUSTOMER_DETAILS_SUCCESS';
type CustomerDetailsErrorType = 'CUSTOMER_DETAILS_ERROR';
export const CUSTOMER_DETAILS: {
  PENDING: CustomerDetailsPendingType,
  SUCCESS: CustomerDetailsSuccessType,
  ERROR: CustomerDetailsErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_ERROR'
};
type CustomerDetailsPendingActionType = {
  type: CustomerDetailsPendingType
};
type CustomerDetailsSuccessActionType = {
  type: CustomerDetailsSuccessType,
  payload: {
    data: Details
  }
};
type CustomerDetailsErrorActionType = {
  type: CustomerDetailsErrorType,
  payload: {
    data: string
  }
};

/**
 * Payments types
 */
type PaymentsPendingType = 'CUSTOMER_DETAILS_PAYMENTS_PENDING';
type PaymentsSuccessType = 'CUSTOMER_DETAILS_PAYMENTS_SUCCESS';
type PaymentsErrorType = 'CUSTOMER_DETAILS_PAYMENTS_ERROR';
type PaymentsResetType = 'CUSTOMER_DETAILS_PAYMENTS_RESET';
export const PAYMENTS: {
  PENDING: PaymentsPendingType,
  SUCCESS: PaymentsSuccessType,
  ERROR: PaymentsErrorType,
  RESET: PaymentsResetType
} = {
  PENDING: 'CUSTOMER_DETAILS_PAYMENTS_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_PAYMENTS_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_PAYMENTS_ERROR',
  RESET: 'CUSTOMER_DETAILS_PAYMENTS_RESET'
};
type PaymentsPendingActionType = {
  type: PaymentsPendingType,
  resetBeforeLoading: boolean
};
type PaymentsSuccessActionType = {
  type: PaymentsSuccessType,
  payload: {
    data: Array<Payment>
  }
};
type PaymentsErrorActionType = {
  type: PaymentsErrorType,
  payload: {
    data: string
  }
};
type PaymentsResetActionType = {
  type: PaymentsResetType
};

/**
 * Card default types
 */
type CardDefaultPendingType = 'CUSTOMER_DETAILS_CARD_DEFAULT_PENDING';
type CardDefaultSuccessType = 'CUSTOMER_DETAILS_CARD_DEFAULT_SUCCESS';
type CardDefaultErrorType = 'CUSTOMER_DETAILS_CARD_DEFAULT_ERROR';
export const CARD_DEFAULT: {
  PENDING: CardDefaultPendingType,
  SUCCESS: CardDefaultSuccessType,
  ERROR: CardDefaultErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_CARD_DEFAULT_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_CARD_DEFAULT_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_CARD_DEFAULT_ERROR'
};
type CardDefaultPendingActionType = {
  type: CardDefaultPendingType
};
type CardDefaultSuccessActionType = {
  type: CardDefaultSuccessType,
  cardId: string
};
type CardDefaultErrorActionType = {
  type: CardDefaultErrorType,
  payload: {
    data: string
  }
};

/**
 * Card update types
 */
type CardUpdatePendingType = 'CUSTOMER_DETAILS_CARD_UPDATE_PENDING';
type CardUpdateSuccessType = 'CUSTOMER_DETAILS_CARD_UPDATE_SUCCESS';
type CardUpdateErrorType = 'CUSTOMER_DETAILS_CARD_UPDATE_ERROR';
export const CARD_UPDATE: {
  PENDING: CardUpdatePendingType,
  SUCCESS: CardUpdateSuccessType,
  ERROR: CardUpdateErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_CARD_UPDATE_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_CARD_UPDATE_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_CARD_UPDATE_ERROR'
};
type CardUpdatePendingActionType = {
  type: CardUpdatePendingType
};
type CardUpdateSuccessActionType = {
  type: CardUpdateSuccessType,
  payload: {
    data: {
      message: string
    }
  }
};
type CardUpdateErrorActionType = {
  type: CardUpdateErrorType,
  payload: {
    data: string
  }
};

/**
 * Card delete types
 */
type CardDeletePendingType = 'CUSTOMER_DETAILS_CARD_DELETE_PENDING';
type CardDeleteSuccessType = 'CUSTOMER_DETAILS_CARD_DELETE_SUCCESS';
type CardDeleteErrorType = 'CUSTOMER_DETAILS_CARD_DELETE_ERROR';
export const CARD_DELETE: {
  PENDING: CardDeletePendingType,
  SUCCESS: CardDeleteSuccessType,
  ERROR: CardDeleteErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_CARD_DELETE_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_CARD_DELETE_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_CARD_DELETE_ERROR'
};
type CardDeletePendingActionType = {
  type: CardDeletePendingType
};
type CardDeleteSuccessActionType = {
  type: CardDeleteSuccessType,
  cardId: string
};
type CardDeleteErrorActionType = {
  type: CardDeleteErrorType,
  payload: {
    data: string
  }
};

/**
 * Payment plans types
 */
type PaymentPlansPendingType = 'CUSTOMER_DETAILS_PAYMENT_PLANS_PENDING';
type PaymentPlansSuccessType = 'CUSTOMER_DETAILS_PAYMENT_PLANS_SUCCESS';
type PaymentPlansErrorType = 'CUSTOMER_DETAILS_PAYMENT_PLANS_ERROR';
export const PAYMENT_PLANS: {
  PENDING: PaymentPlansPendingType,
  SUCCESS: PaymentPlansSuccessType,
  ERROR: PaymentPlansErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_PAYMENT_PLANS_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_PAYMENT_PLANS_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_PAYMENT_PLANS_ERROR'
};
type PaymentPlansPendingActionType = {
  type: PaymentPlansPendingType
};
type PaymentPlansSuccessActionType = {
  type: PaymentPlansSuccessType,
  payload: {
    data: Array<PaymentPlan>
  }
};
type PaymentPlansErrorActionType = {
  type: PaymentPlansErrorType,
  payload: {
    data: string
  }
};

/**
 * Payment plan change status types
 */
type PaymentPlanCnangeStatusPendingType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_CHANGE_STATUS_PENDING';
type PaymentPlanCnangeStatusSuccessType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_CHANGE_STATUS_SUCCESS';
type PaymentPlanCnangeStatusErrorType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_CHANGE_STATUS_ERROR';
export const PAYMENT_PLAN_CHANGE_STATUS: {
  PENDING: PaymentPlanCnangeStatusPendingType,
  SUCCESS: PaymentPlanCnangeStatusSuccessType,
  ERROR: PaymentPlanCnangeStatusErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_PAYMENT_PLAN_CHANGE_STATUS_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_PAYMENT_PLAN_CHANGE_STATUS_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_PAYMENT_PLAN_CHANGE_STATUS_ERROR'
};
type PaymentPlanCnangeStatusPendingActionType = {
  type: PaymentPlanCnangeStatusPendingType
};
type PaymentPlanCnangeStatusSuccessActionType = {
  type: PaymentPlanCnangeStatusSuccessType
};
type PaymentPlanCnangeStatusErrorActionType = {
  type: PaymentPlanCnangeStatusErrorType,
  payload: {
    data: string
  }
};

/**
 * Payment plan delete types
 */
type PaymentPlanDeletePendingType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_DELETE_PENDING';
type PaymentPlanDeleteSuccessType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_DELETE_SUCCESS';
type PaymentPlanDeleteErrorType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_DELETE_ERROR';
export const PAYMENT_PLAN_DELETE: {
  PENDING: PaymentPlanDeletePendingType,
  SUCCESS: PaymentPlanDeleteSuccessType,
  ERROR: PaymentPlanDeleteErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_PAYMENT_PLAN_DELETE_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_PAYMENT_PLAN_DELETE_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_PAYMENT_PLAN_DELETE_ERROR'
};
type PaymentPlanDeletePendingActionType = {
  type: PaymentPlanDeletePendingType
};
type PaymentPlanDeleteSuccessActionType = {
  type: PaymentPlanDeleteSuccessType
};
type PaymentPlanDeleteErrorActionType = {
  type: PaymentPlanDeleteErrorType,
  payload: {
    data: string
  }
};

/**
 * Payment plan edit types
 */
type PaymentPlanEditPendingType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_EDIT_PENDING';
type PaymentPlanEditSuccessType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_EDIT_SUCCESS';
type PaymentPlanEditErrorType = 'CUSTOMER_DETAILS_PAYMENT_PLAN_EDIT_ERROR';
export const PAYMENT_PLAN_EDIT: {
  PENDING: PaymentPlanEditPendingType,
  SUCCESS: PaymentPlanEditSuccessType,
  ERROR: PaymentPlanEditErrorType
} = {
  PENDING: 'CUSTOMER_DETAILS_PAYMENT_PLAN_EDIT_PENDING',
  SUCCESS: 'CUSTOMER_DETAILS_PAYMENT_PLAN_EDIT_SUCCESS',
  ERROR: 'CUSTOMER_DETAILS_PAYMENT_PLAN_EDIT_ERROR'
};
type PaymentPlanEditPendingActionType = {
  type: PaymentPlanEditPendingType
};
type PaymentPlanEditSuccessActionType = {
  type: PaymentPlanEditSuccessType
};
type PaymentPlanEditErrorActionType = {
  type: PaymentPlanEditErrorType,
  payload: {
    data: string
  }
};
type Action =
  | UpdateDetailsPendingActionType
  | UpdateDetailsSuccessActionType
  | UpdateDetailsErrorActionType
  | CreatePaymentPendingActionType
  | CreatePaymentSuccessActionType
  | CreatePaymentErrorActionType
  | CreatePaymentClearActionType
  | AddCreditCardPendingActionType
  | AddCreditCardSuccessActionType
  | AddCreditCardErrorActionType
  | AddPaymentPlanPendingActionType
  | AddPaymentPlanSuccessActionType
  | AddPaymentPlanErrorActionType
  | CustomerDetailsPendingActionType
  | CustomerDetailsSuccessActionType
  | CustomerDetailsErrorActionType
  | PaymentsPendingActionType
  | PaymentsSuccessActionType
  | PaymentsErrorActionType
  | PaymentsResetActionType
  | CardDefaultPendingActionType
  | CardDefaultSuccessActionType
  | CardDefaultErrorActionType
  | CardUpdatePendingActionType
  | CardUpdateSuccessActionType
  | CardUpdateErrorActionType
  | CardDeletePendingActionType
  | CardDeleteSuccessActionType
  | CardDeleteErrorActionType
  | PaymentPlansPendingActionType
  | PaymentPlansSuccessActionType
  | PaymentPlansErrorActionType
  | PaymentPlanCnangeStatusPendingActionType
  | PaymentPlanCnangeStatusSuccessActionType
  | PaymentPlanCnangeStatusErrorActionType
  | PaymentPlanDeletePendingActionType
  | PaymentPlanDeleteSuccessActionType
  | PaymentPlanDeleteErrorActionType
  | PaymentPlanEditPendingActionType
  | PaymentPlanEditSuccessActionType
  | PaymentPlanEditErrorActionType;

/**
 * Parts of the state type
 */
export type DetailsState = RequestStatus & {
  data: Details | Object
};
export type PaymentsState = RequestStatus & {
  list: Array<Object>
};
export type CardUpdateState = RequestStatus;
export type CardDeleteState = RequestStatus;
export type CardDefaultState = RequestStatus;
export type PaymentPlansState = RequestStatus & {
  list: Array<PaymentPlan>
};
export type PaymentPlanChangeStatusState = RequestStatus;
export type PaymentPlanDeleteState = RequestStatus;
export type PaymentPlanEditState = RequestStatus;
export type UpdateDetailsState = RequestStatus;
export type AddCreditCardState = RequestStatus;
export type AddPaymentPlanState = RequestStatus;
export type CreatePaymentState = RequestStatus & PaymentChargeData;

export type CustomerDetailsState = {
  details: DetailsState,
  payments: PaymentsState,
  cardUpdate: CardUpdateState,
  cardDelete: CardDeleteState,
  cardDefault: CardDefaultState,
  associatedPaymentPlans: PaymentPlansState,
  paymentPlanChangeStatus: PaymentPlanChangeStatusState,
  paymentPlanDelete: PaymentPlanDeleteState,
  paymentPlanEdit: PaymentPlanEditState,
  updateDetails: UpdateDetailsState,
  createPayment: CreatePaymentState,
  addCreditCard: AddCreditCardState,
  addPaymentPlan: AddPaymentPlanState
};

const DEFAULT_STATE: CustomerDetailsState = {
  details: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    data: {}
  },
  payments: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    list: []
  },
  cardUpdate: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  cardDelete: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  cardDefault: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  associatedPaymentPlans: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    list: []
  },
  paymentPlanChangeStatus: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  paymentPlanDelete: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  paymentPlanEdit: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  updateDetails: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  createPayment: {
    loading: false,
    success: false,
    error: false,
    errorMessage: '',
    status: '',
    responseMessage: ''
  },
  addCreditCard: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  },
  addPaymentPlan: {
    loading: false,
    success: false,
    error: false,
    errorMessage: ''
  }
};

export default function reducer(state: CustomerDetailsState = DEFAULT_STATE, action: Action) {
  switch (action.type) {
    /**
     * Single - Pending
     */
    case CUSTOMER_DETAILS.PENDING:
      return {
        ...state,
        details: {
          ...DEFAULT_STATE.details,
          loading: true
        }
      };
    /**
     * Single - Success
     */
    case CUSTOMER_DETAILS.SUCCESS:
      return {
        ...state,
        details: {
          ...DEFAULT_STATE.details,
          success: true,
          data: action.payload.data
        }
      };
    /**
     * Single - Error
     */
    case CUSTOMER_DETAILS.ERROR:
      return {
        ...state,
        details: {
          ...DEFAULT_STATE.details,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Payments - Reset
     */
    case PAYMENTS.RESET:
      return {
        ...state,
        payments: {
          ...DEFAULT_STATE.payments
        }
      };
    /**
     * Payments - Pending
     */
    case PAYMENTS.PENDING:
      return {
        ...state,
        payments: {
          ...DEFAULT_STATE.payments,
          loading: true,
          list: action.resetBeforeLoading ? [] : state.payments.list
        }
      };
    /**
     * Payments - Success
     */
    case PAYMENTS.SUCCESS:
      return {
        ...state,
        payments: {
          ...DEFAULT_STATE.payments,
          success: true,
          list: [...state.payments.list, ...action.payload.data]
        }
      };
    /**
     * Payments - Error
     */
    case PAYMENTS.ERROR:
      return {
        ...state,
        payments: {
          ...DEFAULT_STATE.payments,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Card Update - Pending
     */
    case CARD_UPDATE.PENDING:
      return {
        ...state,
        cardUpdate: {
          ...DEFAULT_STATE.cardUpdate,
          loading: true
        }
      };
    /**
     * Card Update - Success
     */
    case CARD_UPDATE.SUCCESS:
      return {
        ...state,
        cardUpdate: {
          ...DEFAULT_STATE.cardUpdate,
          success: true,
          message: action.payload.data
        }
      };
    /**
     * Card Update - Error
     */
    case CARD_UPDATE.ERROR:
      return {
        ...state,
        cardUpdate: {
          ...DEFAULT_STATE.cardUpdate,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Card Default - Pending
     */
    case CARD_DEFAULT.PENDING:
      return {
        ...state,
        cardDefault: {
          ...DEFAULT_STATE.cardDefault,
          loading: true
        }
      };
    /**
     * Card Default - Success
     */
    case CARD_DEFAULT.SUCCESS: {
      const newDefaultCardId = action.cardId;
      const customerCards = { ...state.details.data.cards }; // Flow is complaning. Fix it.
      // Loop through the cards and set the selected card
      // as defaultCard
      customerCards.data = customerCards.data.map(card => {
        if (card.id === newDefaultCardId) {
          card.defaultCard = true;
        } else {
          card.defaultCard = false;
        }
        return card;
      });

      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            cards: customerCards
          }
        },
        cardDefault: {
          ...DEFAULT_STATE.cardDefault,
          success: true
        }
      };
    }
    /**
     * Card Default - Error
     */
    case CARD_DEFAULT.ERROR:
      return {
        ...state,
        cardDefault: {
          ...DEFAULT_STATE.cardDefault,
          error: true,
          errorMsg: action.payload.data
        }
      };

    /**
     * Card Delete - Pending
     */
    case CARD_DELETE.PENDING:
      return {
        ...state,
        cardDelete: {
          ...DEFAULT_STATE.cardDelete,
          loading: true
        }
      };
    /**
     * Card Delete - Success
     */
    case CARD_DELETE.SUCCESS: {
      const prevCardDetails = state.details.data.cards;
      const { data } = prevCardDetails;
      const updatedCardList = removeCardFromList(data, action.cardId);

      return {
        ...state,
        cardDelete: {
          ...DEFAULT_STATE.cardDelete,
          success: true
        },
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            cards: {
              // todo rearrange cards data model
              ...prevCardDetails,
              data: updatedCardList
            }
          }
        }
      };
    }
    /**
     * Card Delete - Error
     */
    case CARD_DELETE.ERROR:
      return {
        ...state,
        cardDelete: {
          ...DEFAULT_STATE.cardDelete,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Payment Plans - Pending
     */
    case PAYMENT_PLANS.PENDING:
      return {
        ...state,
        associatedPaymentPlans: {
          ...DEFAULT_STATE.associatedPaymentPlans,
          loading: true
        }
      };
    /**
     * Payment Plans - Success
     */
    case PAYMENT_PLANS.SUCCESS:
      return {
        ...state,
        associatedPaymentPlans: {
          ...DEFAULT_STATE.associatedPaymentPlans,
          success: true,
          list: action.payload.data
        }
      };
    /**
     * Payment Plans - Error
     */
    case PAYMENT_PLANS.ERROR:
      return {
        ...state,
        associatedPaymentPlans: {
          ...DEFAULT_STATE.associatedPaymentPlans,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Change Status - Pending
     */
    case PAYMENT_PLAN_CHANGE_STATUS.PENDING:
      return {
        ...state,
        paymentPlanChangeStatus: {
          ...DEFAULT_STATE.paymentPlanChangeStatus,
          loading: true
        }
      };
    /**
     * Change Status - Success
     */
    case PAYMENT_PLAN_CHANGE_STATUS.SUCCESS:
      return {
        ...state,
        paymentPlanChangeStatus: {
          ...DEFAULT_STATE.paymentPlanChangeStatus,
          success: true
        }
      };
    /**
     * Change Status - Error
     */
    case PAYMENT_PLAN_CHANGE_STATUS.ERROR:
      return {
        ...state,
        paymentPlanChangeStatus: {
          ...DEFAULT_STATE.paymentPlanChangeStatus,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Delete - Pending
     */
    case PAYMENT_PLAN_DELETE.PENDING:
      return {
        ...state,
        paymentPlanDelete: {
          ...DEFAULT_STATE.paymentPlanDelete,
          loading: true
        }
      };
    /**
     * Delete - Success
     */
    case PAYMENT_PLAN_DELETE.SUCCESS:
      return {
        ...state,
        paymentPlanDelete: {
          ...DEFAULT_STATE.paymentPlanDelete,
          success: true
        }
      };
    /**
     * Delete - Error
     */
    case PAYMENT_PLAN_DELETE.ERROR:
      return {
        ...state,
        paymentPlanDelete: {
          ...DEFAULT_STATE.paymentPlanDelete,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Edit - Pending
     */
    case PAYMENT_PLAN_EDIT.PENDING:
      return {
        ...state,
        paymentPlanEdit: {
          ...DEFAULT_STATE.paymentPlanEdit,
          loading: true
        }
      };
    /**
     * Edit - Success
     */
    case PAYMENT_PLAN_EDIT.SUCCESS:
      return {
        ...state,
        paymentPlanEdit: {
          ...DEFAULT_STATE.paymentPlanEdit,
          success: true
        }
      };
    /**
     * Edit - Error
     */
    case PAYMENT_PLAN_EDIT.ERROR:
      return {
        ...state,
        paymentPlanEdit: {
          ...DEFAULT_STATE.paymentPlanEdit,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Update details - Pending
     */
    case UPDATE_DETAILS.PENDING:
      return {
        ...state,
        updateDetails: {
          ...DEFAULT_STATE.updateDetails,
          loading: true
        }
      };
    /**
     * Update details - Success
     */
    case UPDATE_DETAILS.SUCCESS:
      return {
        ...state,
        updateDetails: {
          ...DEFAULT_STATE.updateDetails,
          success: true
        }
      };
    /**
     * Update details - Error
     */
    case UPDATE_DETAILS.ERROR:
      return {
        ...state,
        updateDetails: {
          ...DEFAULT_STATE.updateDetails,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Create payment - Pending
     */
    case CREATE_PAYMENT.PENDING:
      return {
        ...state,
        createPayment: {
          ...DEFAULT_STATE.createPayment,
          loading: true
        }
      };
    /**
     * Create payment - Success
     */
    case CREATE_PAYMENT.SUCCESS:
      return {
        ...state,
        createPayment: {
          ...DEFAULT_STATE.createPayment,
          success: true,
          status: action.payload.data.status,
          responseMessage: action.payload.data.responseMessage
        }
      };
    /**
     * Create payment - Error
     */
    case CREATE_PAYMENT.ERROR:
      return {
        ...state,
        createPayment: {
          ...DEFAULT_STATE.createPayment,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Create payment - Clear
     */
    case CREATE_PAYMENT.CLEAR:
      return {
        ...state,
        createPayment: {
          ...DEFAULT_STATE.createPayment
        }
      };
    /**
     * Add Card - Pending
     */
    case ADD_CREDIT_CARD.PENDING:
      return {
        ...state,
        addCreditCard: {
          ...DEFAULT_STATE.addCreditCard,
          loading: true
        }
      };
    /**
     * Add Card - Success
     */
    case ADD_CREDIT_CARD.SUCCESS:
      return {
        ...state,
        addCreditCard: {
          ...DEFAULT_STATE.addCreditCard,
          success: true
        }
      };
    /**
     * Add Card - Error
     */
    case ADD_CREDIT_CARD.ERROR:
      return {
        ...state,
        addCreditCard: {
          ...DEFAULT_STATE.addCreditCard,
          error: true,
          errorMsg: action.payload.data
        }
      };
    /**
     * Add Payment Plan - Pending
     */
    case ADD_PAYMENT_PLAN.PENDING:
      return {
        ...state,
        addPaymentPlan: {
          ...DEFAULT_STATE.addPaymentPlan,
          loading: true
        }
      };
    /**
     * Add Payment Plan - Success
     */
    case ADD_PAYMENT_PLAN.SUCCESS:
      return {
        ...state,
        addPaymentPlan: {
          ...DEFAULT_STATE.addPaymentPlan,
          success: true
        }
      };
    /**
     * Add Payment Plan - Error
     */
    case ADD_PAYMENT_PLAN.ERROR:
      return {
        ...state,
        addPaymentPlan: {
          ...DEFAULT_STATE.addPaymentPlan,
          error: true,
          errorMsg: action.payload.data
        }
      };
    default:
      return state;
  }
}

/**
 * Remove card that matches cardId
 */
function removeCardFromList(cards: Array<Card>, cardId: string): Array<Card> {
  return cards.filter(card => card.id !== cardId);
}

/**
 * Action to get the customers details
 */
type CustomerDetailsParams = Accounts & {
  customerId: string
};
export type GetCustomerDetails = (customerDetailsParams: CustomerDetailsParams, isGWCore: Boolean) => Function;
export function getCustomerDetails(customerDetailsParams: CustomerDetailsParams, isGWCore: Boolean) {
  const { PENDING, SUCCESS, ERROR } = CUSTOMER_DETAILS;
  const entity = getEntity(customerDetailsParams);
  const detailsRequestParams = {
    entityId: entity.id,
    entityTypeId: entity.typeId,
    customerId: customerDetailsParams.customerId
  };
  const promiseReq = CustomersAPI.getCustomerDetails(detailsRequestParams, isGWCore);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to get the customers payments
 */
type CustomerPaymentsParams = Accounts & {
  customerId: string,
  limit: number,
  startIndex: number
};
export type GetCustomerPayments = (
  customerPaymentsParams: CustomerPaymentsParams,
  resetBeforeLoading?: boolean
) => Function;
export function getCustomerPayments(
  customerPaymentsParams: CustomerPaymentsParams,
  resetBeforeLoading?: boolean
) {
  const { PENDING, SUCCESS, ERROR } = PAYMENTS;
  const entity = getEntity(customerPaymentsParams);
  const detailsRequestParams = {
    entityId: entity.id,
    entityTypeId: entity.typeId,
    customerId: customerPaymentsParams.customerId,
    limit: customerPaymentsParams.limit,
    startIndex: customerPaymentsParams.startIndex
  };
  const promiseReq = CustomersAPI.getCustomerPayments(detailsRequestParams);
  return dispatchResponse(
    promiseReq,
    { type: PENDING, resetBeforeLoading },
    { type: SUCCESS },
    ERROR
  );
}

/**
 * Action to update the customers card details
 */
export type UpdateCardDetails = (
  cardId: string,
  customerId: string,
  accountId: number,
  billingDetails: AddressWithPhone
) => Function;
export function updateCardDetails(
  cardId: string,
  customerId: string,
  accountId: number,
  billingDetails: AddressWithPhone
): Function {
  const { PENDING, SUCCESS, ERROR } = CARD_UPDATE;
  const promiseReq = CustomersAPI.updateCardDetails(cardId, customerId, accountId, billingDetails);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to set a default associated card
 */
export type SetDefaultCard = (cardId: string, customerId: string, accountId: number) => Function;
export function setDefaultCard(cardId: string, customerId: string, accountId: number) {
  const { PENDING, SUCCESS, ERROR } = CARD_DEFAULT;
  const promiseReq = CustomersAPI.setDefaultCard(cardId, customerId, accountId);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS, cardId }, ERROR);
}

/**
 * Action to delete an associated card
 */
export type DeleteCard = (cardId: string, customerId: string) => Function;
export function deleteCard(cardId: string, customerId: string) {
  const { PENDING, SUCCESS, ERROR } = CARD_DELETE;
  const promiseReq = CustomersAPI.deleteCard(cardId, customerId);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS, cardId }, ERROR);
}

export type ResetCustomerPayments = () => PaymentsResetActionType;
export function resetCustomerPayments() {
  return {
    type: PAYMENTS.RESET
  };
}

/**
 * Action to get the associated payment plans of a customer
 */
export type GetAssociatedPaymentPlans = (customerId: string) => Function;
export function getAssociatedPaymentPlans(customerId: string) {
  const { PENDING, SUCCESS, ERROR } = PAYMENT_PLANS;
  const promiseReq = PaymentPlanAPI.getAssociatedPaymentPlans(customerId);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to change the status of a payment plan
 */
export type ChangePaymentPlanStatus = (
  customerPlanId: string,
  channelId: string,
  status: number
) => Function;
export function changePaymentPlanStatus(
  customerPlanId: string,
  channelId: string,
  status: number
): Function {
  const { PENDING, SUCCESS, ERROR } = PAYMENT_PLAN_CHANGE_STATUS;
  const promiseReq = PaymentPlanAPI.changePaymentPlanStatus(customerPlanId, channelId, status);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to delete a payment plan
 */
export type DeletePaymentPlan = (customerPlanId: string) => Function;
export function deletePaymentPlan(customerPlanId: string): Function {
  const { PENDING, SUCCESS, ERROR } = PAYMENT_PLAN_DELETE;
  const promiseReq = PaymentPlanAPI.deletePaymentPlan(customerPlanId);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to edit a payment plan (status and card)
 */
export type EditPaymentPlan = (
  customerPlanId: string,
  channelId: string,
  status: number,
  cardId: string
) => Function;
export function editPaymentPlan(
  customerPlanId: string,
  channelId: string,
  status: number,
  cardId: string
): Function {
  const { PENDING, SUCCESS, ERROR } = PAYMENT_PLAN_EDIT;
  const promiseReq = PaymentPlanAPI.editPaymentPlan(customerPlanId, channelId, status, cardId);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to update the customer details
 */
export type UpdateCustomerDetails = (customerId: string, email: string, name: string) => Function;
export function updateCustomerDetails(customerId: string, email: string, name: string): Function {
  const { PENDING, SUCCESS, ERROR } = UPDATE_DETAILS;
  const promiseReq = CustomersAPI.updateCustomerDetails(customerId, email, name);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to create a charge
 */
type CreateChargesParams = {
  amount: number,
  autoCapTime: number,
  autoCapture: string,
  billingDetails: AddressWithPhone,
  cardId: string,
  channelId: string,
  currency: string,
  description: string,
  email: string,
  shippingDetails: AddressWithPhone,
  trackId: string,
  type: string,
  udf1: string,
  customerDisplayId?: string,
  cardDisplayId?: string,
};
export type CreateCharges = (createChargesParams: CreateChargesParams) => Function;
export function createCharges(createChargesParams: CreateChargesParams, channelAPIVersion: string) {
  const { PENDING, SUCCESS, ERROR } = CREATE_PAYMENT;
  const promiseReq = PaymentsAPI.createCharges(createChargesParams, channelAPIVersion);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to clear the create payment data
 */
export type ClearCharges = () => CreatePaymentClearActionType;
export const clearCharges = () => {
  return {
    type: CREATE_PAYMENT.CLEAR
  };
};

/**
 * Action to add a credit card to a customer
 */
type BillingDetails = Address & { phone: Phone };
type AddCreditCardParam = {
  billingDetails: BillingDetails,
  cvv: string,
  expiryMonth: string,
  expiryYear: string,
  name: string,
  number: string
};
export type AddCreditCard = (
  customerId: string,
  card: AddCreditCardParam,
  channelId: number
) => Function;
export function addCreditCard(customerId: string, card: AddCreditCardParam, channelId: number) {
  const { PENDING, SUCCESS, ERROR } = ADD_CREDIT_CARD;
  const promiseReq = CustomersAPI.addCreditCard(customerId, card, channelId);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}

/**
 * Action to add a payment plan to a customer
 */
export type AddPaymentPlan = (
  customerId: string,
  cardId: string,
  channelId: string,
  planId: string
) => Function;
export function addPaymentPlan(
  customerId: string,
  cardId: string,
  channelId: string,
  planId: string
) {
  const { PENDING, SUCCESS, ERROR } = ADD_PAYMENT_PLAN;
  const promiseReq = PaymentPlanAPI.addPaymentPlan(customerId, cardId, channelId, planId);
  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR);
}
