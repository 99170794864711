import config from 'App-config';
import { ajxPromise, getCurrencyId } from 'services/appRequest';
import { uid } from 'uid';
import { validateParams } from 'utils/api.util';
import { isFeatureFlagged } from 'utils/FeatureSwitch';
import { GetPaymentBlacklistParam } from 'store/payment-details-duck';

/**
 * Handle the payments table data list request
 * @param {object} dataParam
 * @returns {promise}
 */
type DataParam = {
  entityId: string,
  entityType: string,
  fromDate: string,
  toDate: string,
  sortColumn: string,
  sortOrder: string,
  pageSize: number,
  startIndex: number,
  search?: string,
  filter?: Array<Object>
};

export const getAll = (dataParam: DataParam): Promise<Object> => {
  const requiredParams = [
    'entityId',
    'entityType',
    'fromDate',
    'toDate',
    'sortColumn',
    'sortOrder',
    'pageSize',
    'startIndex'
  ];

  const errorList = validateParams(dataParam, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(`Payments.getAll() is missing {${errorList}} param(s)`);
  }

  let entityId = dataParam.entityId;
  let entityType = dataParam.entityType;
  let date = `fromDate=${dataParam.fromDate}&toDate=${dataParam.toDate}`;
  let pageSize = `pageSize=${dataParam.pageSize}`;
  let sortColumn = `sortColumn=${dataParam.sortColumn}`;
  let sortOrder = `sortOrder=${dataParam.sortOrder}`;
  let startIndex = `startIndex=${dataParam.startIndex}`;

  let urlPaths = `${date}&${pageSize}&${sortColumn}&${sortOrder}&${startIndex}`;

  // Optional Params
  if (dataParam.search) {
    urlPaths += `&search=${dataParam.search}`;
  }

  if (dataParam.filter) {
    const filter = JSON.stringify(dataParam.filter);
    urlPaths += `&filter=${filter}`;
  }

  let endpoint = `/${entityType}/${entityId}/transaction/overview?${urlPaths}`;

  return ajxPromise.get(endpoint);
};
type GetPaymentDetailParam = {
  entityId: string,
  entityType: string,
  paymentId: string
};

/**
 * Handle the payments details request
 * @param {string|number} chargeId
 * @returns {promise}
 */
export const getPaymentDetails = (dataParam: GetPaymentDetailParam) => {
  const requiredParams = ['entityId', 'entityType', 'paymentId'];

  const errorList = validateParams(dataParam, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(new Error(`getPaymentDetails missing {${errorList}}`));
  }

  const { entityType, entityId, paymentId } = dataParam;
  const currencyId = `currencyId=${getCurrencyId()}`;
  const url = `/${entityType}/${entityId}/transaction/detail/${paymentId}?${currencyId}`;
  return ajxPromise.get(url);
};

/**
 * Handle the payments create payment request
 * @param {object} dataParam
 * @returns {promise}
 */
export const createCharges = (dataParam: Object, channelAPIVersion: string) => {
  const cardProperties = {
    card: ['number', 'name', 'cvv2', 'expiryMonth', 'expiryYear']
  };
  const requiredParams = [
    'channelId',
    'autoCapTime',
    'email',
    'currency',
    'amount',
    'type',
    'autoCapture',
    'billingDetails',
    'shippingDetails',
    'udf1'
  ];
  if (dataParam.cardId === void 0) {
    requiredParams.push(cardProperties);
  } else {
    requiredParams.push('cardId');
  }
  const errorList = validateParams(dataParam, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(`Payments.createCharges() is missing {${errorList}} param(s)`);
  }

  // environment should be local, qa or sandbox (until further notice of NAS readiness) for UPAPI endpoints of hub-api
  // for production, we still need to use GW3 endpoints of hub-api no matter what
  let url = `/charges${isFeatureFlagged(['local', 'qa', 'sandbox']) && channelAPIVersion === '2.0' ? '/v2' : ''}`;

  return ajxPromise.post(url, dataParam);
};

/**
 * Handles the payments blacklist data request
 * @param {GetPaymentBlacklistParam} dataObj
 * @returns {promise}
 */
export const getPaymentBlacklist = ({ paymentId, accountId }: GetPaymentBlacklistParam) => {
  if (!paymentId || !accountId) {
    return Promise.reject('Payments.getPaymentBlacklist() is missing: paymentId or accountId');
  }

  return new Promise((resolve, reject) => {
    ajxPromise.get(`${config.prism.apiBaseUrl}/assessments/${paymentId}/blacklist-attributes`, {
      headers: {
        'cko-selected-merchant-id': accountId
      }
    }).then(res => {
      const {
        attributes
      } = res.data;
      const data = {
        email: attributes?.email?.enabled ?? false,
        emailValue: attributes?.email?.display_value ?? null,
        cardNumberValue: attributes?.card_number?.display_value ?? null,
        cardNumber: attributes?.card_number?.enabled ?? false,
        phoneNumberValue: attributes?.phone?.display_value ?? null,
        phoneNumber: attributes?.phone?.enabled ?? false,
        ipAddressValue: attributes?.payment_ip?.display_value ?? null,
        ipAddress: attributes?.payment_ip?.enabled ?? false,
        isLocalBlackList: false
      };
      const response = {
        ...res,
        data
      };
      resolve(response);
    }).catch(reject);
  });
};

/**
 * Handles the setting blacklist request
 */
type BlacklistPaymentParams = {
  payment_id: string;
  attributes: string[];
};
export const blacklistPayment = (accountId: number, params: BlacklistPaymentParams) => {
  const requiredParams = ['accountId', 'payment_id', 'attributes'];
  const errorList = validateParams({ ...params, accountId }, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    const errorMsg = `Payments.blacklistPayment() is missing {${errorList}} param(s)`;
    return Promise.reject(errorMsg);
  }
  return ajxPromise.put(`${config.prism.apiBaseUrl}/blacklists`, params, {
    headers: {
      'cko-selected-merchant-id': accountId
    }
  });
};

/**
 * Handles the void blacklist request
 * @param {string|number} id
 * @param {object} params
 * @returns {promise}
 */
export const actionPayment = (
  action: string,
  id: string,
  { apiVersion, channelId, ...params }: Object
) => {
  const validActions: string[] = ['void', 'refund', 'capture'];
  const requiredParams = ['id', 'amount'];
  const errorList = validateParams({ ...params, id }, requiredParams);
  if (!validActions.includes(action)) {
    return Promise.reject(new Error(`action is not valid, ${action} not in ${validActions}`));
  }
  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(new Error(`action payment is missing {${errorList}} param(s) for ${action}`));
  }
  let version = '';
  let body = params;
  if (apiVersion === '2.0') {
    version = 'v2/';
    body.channelId = channelId;
  }

  const headers = { 'Cko-Idempotency-Key': `${id}-${action}-${uid(16)}` };
  return ajxPromise.post(`/charges/${version}${id}/${action}`, body, { headers });
};

/**
 * Handles the transaction logs data request
 * @param {object} params
 * @returns {promise}
 */
export const getPaymentsLogs = (params: Object) => {
  const requiredParams = [
    'chargeId',
    'fromDate',
    'toDate',
    'sortField',
    'sortOrder',
    'pageSize',
    'startIndex'
  ];

  const errorList = validateParams(params, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(`Payments.getPaymentsLogs() is missing {${errorList}} param(s)`);
  }

  let chargeId = params.chargeId.toString();
  let date = `fromDate=${params.fromDate}&toDate=${params.toDate}`;
  let pageSize = `pageSize=${params.pageSize}`;
  let sortColumn = `sortColumn=${params.sortField}`;
  let sortOrder = `sortOrder=${params.sortOrder}`;
  let startIndex = `startIndex=${params.startIndex}`;

  let urlPaths = `${date}&${pageSize}&${sortColumn}&${sortOrder}&${startIndex}`;
  return ajxPromise.get(`/transactions/${chargeId}/logs?${urlPaths}`);
};

/**
 * Gets the transaction response codes
 * @param {object} params
 * @returns {promise}
 */
export const getResponseCodes = (
  accountId: string,
  businessId: string,
  fromDate: string,
  toDate: string
) => {
  const requiredParams = ['accountId', 'businessId', 'fromDate', 'toDate'];
  const errorList = validateParams(
    {
      accountId,
      businessId,
      fromDate,
      toDate
    },
    requiredParams
  );

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(`Payments.getResponseCodes() is missing {${errorList}} param(s)`);
  }

  let accountParams = `accountId=${accountId}&businessId=${businessId}`;
  let dateParams = `fromDate=${fromDate}&toDate=${toDate}`;
  return ajxPromise.get(`/transactions/gateway-response/codes?${accountParams}&${dateParams}`);
};
