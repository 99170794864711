//@flow
import { produce } from 'utils/tree';
import * as formTypes from 'types/form';

export type State = {
  description: string,
  file: {
    loading: boolean,
    error: string,
    id: string,
    filename: string,
    content: string,
  },
};

export type HydrateAction = {
  type: 'EVIDENCE_HYDRATE_MATCHING_PAYMENTS',
  state: State,
};
export function hydrate(state: State): HydrateAction {
  return {
    state,
    type: 'EVIDENCE_HYDRATE_MATCHING_PAYMENTS',
  };
}

export type ClearAction = {
  type: 'EVIDENCE_CLEAR_MATCHING_PAYMENTS',
};
export function clear(): ClearAction {
  return {
    type: 'EVIDENCE_CLEAR_MATCHING_PAYMENTS',
  };
}

export type StreamMatchingPaymentsDescriptionAction = formTypes.StreamText<
  'EVIDENCE_STREAM_MATCHING_PAYMENTS_DESCRIPTION'
>;
export function streamMatchingPaymentsDescription(
  value: string
): StreamMatchingPaymentsDescriptionAction {
  return {
    value,
    type: 'EVIDENCE_STREAM_MATCHING_PAYMENTS_DESCRIPTION',
  };
}

export type UploadMatchingPaymentsEvidenceStartAction = {
  type: 'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_START',
};
export function uploadMatchingPaymentsEvidenceStart(): UploadMatchingPaymentsEvidenceStartAction {
  return {
    type: 'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_START',
  };
}

export type ClearMatchingPaymentsEvidenceAction = {
  type: 'EVIDENCE_CLEAR_MATCHING_PAYMENTS_EVIDENCE',
};
export function clearMatchingPaymentsEvidence(): ClearMatchingPaymentsEvidenceAction {
  return {
    type: 'EVIDENCE_CLEAR_MATCHING_PAYMENTS_EVIDENCE',
  };
}

export type UploadMatchingPaymentsEvidenceSuccessAction = formTypes.UploadSuccess<
  'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_SUCCESS'
>;
export function uploadMatchingPaymentsEvidenceSuccess(
  filename: string,
  content: string
): UploadMatchingPaymentsEvidenceSuccessAction {
  return {
    filename,
    content,
    type: 'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_SUCCESS',
  };
}

type UploadMatchingPaymentsEvidenceFailureAction = formTypes.UploadFailure<
  'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_FAILURE'
>;
export function uploadMatchingPaymentsEvidenceFailure(
  error: string
): UploadMatchingPaymentsEvidenceFailureAction {
  return {
    error,
    type: 'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_FAILURE',
  };
}

type Action =
  | HydrateAction
  | ClearAction
  | StreamMatchingPaymentsDescriptionAction
  | UploadMatchingPaymentsEvidenceStartAction
  | ClearMatchingPaymentsEvidenceAction
  | UploadMatchingPaymentsEvidenceSuccessAction
  | UploadMatchingPaymentsEvidenceFailureAction;

const DEFAULT_STATE: State = {
  description: '',
  file: {
    loading: false,
    error: '',
    id: '',
    filename: '',
    content: '',
  },
};

export function reducer(state: State = DEFAULT_STATE, action: Action): State {
  return produce(state, function(draft) {
    switch (action.type) {
      case 'EVIDENCE_HYDRATE_MATCHING_PAYMENTS':
        return action.state;

      case 'EVIDENCE_CLEAR_MATCHING_PAYMENTS':
        return DEFAULT_STATE;

      case 'EVIDENCE_STREAM_MATCHING_PAYMENTS_DESCRIPTION':
        draft.description = action.value;
        break;

      case 'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_START':
        draft.file.loading = true;
        break;

      case 'EVIDENCE_CLEAR_MATCHING_PAYMENTS_EVIDENCE':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: '',
          content: '',
        };
        break;

      case 'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_SUCCESS':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: action.filename,
          content: action.content,
        };
        break;

      case 'EVIDENCE_UPLOAD_MATCHING_PAYMENTS_FAILURE':
        draft.file.loading = false;
        draft.file.error = action.error;
        break;
      default: break;
    }
  });
}
