// @flow
import { ajxPromise, getCurrencyId } from "services/appRequest";
import { validateParams } from "utils/api.util";

type ParamObj = {
  fromDate: string,
  toDate: string,
  entityTypeId: number,
  entityId: number,
};
/**
 * Gets the KPI data - /customers/overview/indicators
 * @param {any} dataParam - { fromDate, toDate, entityTypeId, entityId }
 * @returns {promise}
 */
export function getIndicators(dataParam: ParamObj): Promise<any> {
  let currencyId = getCurrencyId();
  const requiredParams = ["fromDate", "toDate", "entityTypeId", "entityId"];
  const errorList = validateParams(dataParam, requiredParams, currencyId);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(`Customers.getIndicators() is missing {${errorList}} param(s)`)
    );
  }

  currencyId = `currencyId=${currencyId}`;
  let entity = `entityId=${dataParam.entityId}&entityTypeId=${dataParam.entityTypeId}`;
  let date = `fromDate=${dataParam.fromDate}&toDate=${dataParam.toDate}`;
  const url = `/customers/overview/indicators?${currencyId}&${entity}&${date}`;
  return ajxPromise.get(url);
}

/**
 * Gets customers list
 */

type Filter = {
  action: string,
  changed: boolean,
  field: string,
  filterIndex: number,
  operator: string,
  type: string,
  value: string,
};

export type GetAllParams = {
  fromDate: string,
  toDate: string,
  entityTypeId: string,
  entityId: string,
  sortField: string,
  sortOrder: string,
  pageSize: number,
  startIndex: number,
  search?: string,
  filter?: Array<Filter>,
};

export function getAll(params: GetAllParams): Promise<any> {
  const requiredParams = [
    "entityId",
    "entityTypeId",
    "fromDate",
    "toDate",
    "sortField",
    "sortOrder",
    "pageSize",
    "startIndex",
  ];
  let currencyId: string = getCurrencyId();

  const errorList = validateParams(params, requiredParams, currencyId);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(`Customers.getAll() is missing {${errorList}} param(s)`)
    );
  }

  currencyId = `currencyId=${currencyId}`;
  let entity = `entityId=${params.entityId}&entityTypeId=${params.entityTypeId}`;
  let date = `fromDate=${params.fromDate}&toDate=${params.toDate}`;
  let pageSize = `pageSize=${params.pageSize}`;
  let sortColumn = `sortColumn=${params.sortField}`;
  let sortOrder = `sortOrder=${params.sortOrder}`;
  let startIndex = `startIndex=${params.startIndex}`;

  let urlPaths = `${currencyId}&${entity}&${date}&${pageSize}&${sortColumn}&${sortOrder}&${startIndex}`;

  // Optional Params
  if (params.search) {
    urlPaths += `&search=${params.search}`;
  }

  if (params.filter) {
    urlPaths += `&filter=${JSON.stringify(params.filter)}`;
  }
  const url = `/customers/overview/v2?${urlPaths}`;
  return ajxPromise.get(url);
}

/**
 * Gets customer's details
 * @param {any} dataParam
 * @returns {promise}
 */
type GetDetailsParams = {
  entityTypeId: string,
  entityId: string,
  customerId: string,
};
export function getCustomerDetails(
  dataParam: GetDetailsParams,
  isGWCore: Boolean
): Promise<any> {
  let currencyId = getCurrencyId();
  const requiredParams = ["entityId", "entityTypeId", "customerId"];
  const errorList = validateParams(dataParam, requiredParams, currencyId);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(
        `Customers.getCustomerDetails() is missing {${errorList}} param(s)`
      )
    );
  }

  let customerId = dataParam.customerId;
  currencyId = `currencyId=${currencyId}`;
  let entity = `entityId=${dataParam.entityId}&entityTypeId=${dataParam.entityTypeId}`;
  let url = `/customers/${customerId}/v2?${currencyId}&${entity}`;
  return ajxPromise.get(url);
}

/**
 * Gets customer's transaction details
 * @param {any} dataParam
 * @returns {promise}
 */
type GetCustTransParams = {
  entityTypeId: string,
  accountId: string,
  businessId: string,
  channelId: string,
  customerId: string,
  pageSize: number,
  startIndex: number,
};
export function getCustomerPayments(
  dataParam: GetCustTransParams
): Promise<any> {
  const requiredParams = [
    "accountId",
    "businessId",
    "channelId",
    "pageSize",
    "startIndex",
    "customerId",
  ];
  const errorList = validateParams(dataParam, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(
        `Customers.getCustomerPayments() is missing {${errorList}} param(s)`
      )
    );
  }
  const {
    customerId,
    pageSize,
    startIndex,
    accountId,
    businessId,
    channelId,
  } = dataParam;

  let type;
  if (channelId) {
    type = `/channels/${channelId}`;
  } else if (businessId) {
    type = `/businesses/${businessId}`;
  } else {
    type = `/accounts/${accountId}`;
  }
  const url = `${type}/customer/${customerId}/transactions?pageSize=${pageSize}&startIndex=${startIndex}`;
  return ajxPromise.get(url);
}

/**
 * Update customer's details
 * @param {number|string} customerId
 * @param {number|string} email
 * @param {number|string} name
 * @param {number|string} phone
 * @returns {promise}
 */
export function updateCustomerDetails(
  customerId: string,
  email: string,
  name: string
): Promise<Object> {
  const requiredParams = ["customerId", "email", "name"];
  const errorList = validateParams({ customerId, email, name }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(`Customers.updateCustomerDetails() is missing {${errorList}} param(s)`)
    );
  }
  return ajxPromise.put(`/customers/${customerId}`, {
    email,
    name
  });
}

/**
 * Add new card
 * @param {string} customerId
 * @param {Object} card
 * @param {number} channelId
 * @returns {promise}
 */
export type Address = {
  addressLine1: string,
  addressLine2: string,
  postcode: string,
  country: string,
  city: string,
  state: string,
};
export type Phone = {
  countryCode: string,
  number: string,
};
type BillingDetails = Address & { phone: Phone };
type Card = {
  billingDetails: BillingDetails,
  cvv: string,
  expiryMonth: string,
  expiryYear: string,
  name: string,
  number: string,
};
export function addCreditCard(
  customerId: string,
  card: Card,
  channelId: number
): Promise<Object> {
  const requiredParams = ["customerId", "card", "channelId"];
  const errorList = validateParams(
    { customerId, card, channelId },
    requiredParams
  );
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(`Customers.addCreditCard() is missing {${errorList}} param(s)`)
    );
  }
  const url = `/customers/${customerId}/cards`;
  return ajxPromise.post(url, {
    card,
    channelId,
  });
}

/**
 * Updates customers details - /customers/cust_${customerId}/cards/${cardId}
 * @param {string} cardId
 * @param {string} customerId
 * @param {number} accountId
 * @returns {promise}
 */
export function updateCardDetails(
  cardId: string,
  customerId: string,
  accountId: number,
  data: any
): Promise<any> {
  const requiredParams = ["cardId", "customerId", "accountId"];
  const checkParams = { cardId, customerId, accountId };
  const errorList = validateParams(checkParams, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(
        `Customers.updateCardDetails() is missing {${errorList}} param(s)`
      )
    );
  }
  const url = `/customers/${customerId}/cards/${cardId}`;
  return ajxPromise.put(url, {
    accountId,
    ...data,
  });
}

/**
 * Sets a credit card as the default customer card - /customers/cust_${customerId}/cards/${cardId}/default
 * @param {string} cardId
 * @param {string} customerId
 * @param {number} merchantAccountId
 * @returns {promise}
 */
export function setDefaultCard(
  cardId: string,
  customerId: string,
  merchantAccountId: number
): Promise<any> {
  const requiredParams = ["cardId", "customerId", "merchantAccountId"];
  const checkParams = { cardId, customerId, merchantAccountId };
  const errorList = validateParams(checkParams, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(`Customers.setDefaultCard() is missing {${errorList}} param(s)`)
    );
  }

  // Remove 'cust_' to avoid duplication
  customerId = customerId.replace("cust_", "");
  const url = `/customers/cust_${customerId}/cards/${cardId}/default`;
  return ajxPromise.put(url, { merchantAccountId });
}

/**
 * Delete customers credit card  - /customers/cust_${customerId}/cards/${cardId}
 * @param {string} cardId
 * @param {string} customerId
 * @returns {promise}
 */
export function deleteCard(cardId: string, customerId: string): Promise<any> {
  const requiredParams = ["cardId", "customerId"];
  const checkParams = { cardId, customerId };
  const errorList = validateParams(checkParams, requiredParams);

  // Check the required params are valid
  if (errorList.length > 0) {
    return Promise.reject(
      new Error(`Customers.deleteCard() is missing {${errorList}} param(s)`)
    );
  }

  // Remove 'cust_' to avoid duplication
  customerId = customerId.replace("cust_", "");
  const url = `/customers/cust_${customerId}/cards/${cardId}`;
  return ajxPromise.delete(url);
}
