// @flow
import * as React from 'react';
import * as Styled from './Flex.sc';

type Props = {
  children: React.Node,
  className?: string,
};
export const FlexColumn = (props: Props) => (
  <Styled.Column
    {...props}
    className={`cko-flex-column ${props.className || ''}`}>
    {props.children}
  </Styled.Column>
);

/**
 * Flex Row
 */

export const FlexRow = (props: Props) => (
  <Styled.Row {...props} className={`cko-flex-row ${props.className || ''}`}>
    {props.children}
  </Styled.Row>
);

/**
 * Flex Item
 */
export const FlexItem = (props: Props) => (
  <Styled.Item {...props} className={`cko-flex-item ${props.className || ''}`}>
    {props.children}
  </Styled.Item>
);
