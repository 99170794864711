// @flow
import * as localApi from 'services/localDataApi';
import queryString from 'query-string';
import {
  LOGIN_ACTION_TYPES,
  LOOKUPS_ACTION_TYPES,
  USER_ACTION_TYPES,
  PROFILE_UPDATE_ACTION_TYPES
} from 'store/user-duck';
import * as globalDuck from 'store/global-duck';
import { GET_BUSINESSES_ACTION_TYPES } from 'store/global-duck';

const LOGIN = [
  LOGIN_ACTION_TYPES.SUCCESS,
  LOOKUPS_ACTION_TYPES.SUCCESS,
  PROFILE_UPDATE_ACTION_TYPES.SUCCESS,
];
const GLOBAL_ACTION_TYPES = [
  globalDuck.GET_ACCOUNTS_ACTION_TYPES.SUCCESS,
  globalDuck.GET_BUSINESSES_ACTION_TYPES.SUCCESS,
  globalDuck.GLOBAL_ACTION_TYPES.SET_SELECTION,
  globalDuck.GLOBAL_ACTION_TYPES.SET_DATES,
];

/**
 * Clear cache and state
 */
export const handleLogout = (store: Object) => (next: Function) => (
  action: Object
) => {
  if (action.type === USER_ACTION_TYPES.SIGN_OUT) {
    localApi.clearUserData();

    const { pathname } = window.location;
    // Check if current view is not /login
    if (!pathname.includes('/login')) {
      const showSessionMsg = action.sessionEnd === true ? '?s=1' : '';

      // If v2 is the root path add it to the redirection
      window.location.href = pathname.includes('v2')
        ? `/v2/login${showSessionMsg}`
        : `/login${showSessionMsg}`;
    }

    return null;
  }
  return next(action);
};

/**
 * Cache States
 */
export const cacheStates = (store: Object) => (next: Function) => (
  action: Object
) => {
  if (LOGIN.includes(action.type)) {
    /**
     * Cache Login
     */
    localApi.login.passed();
    const result = next(action);
    localApi.user.save(store.getState().userState);
    return result;
  } else if (GLOBAL_ACTION_TYPES.includes(action.type)) {
    /**
     * Cache Accounts
     */
    const result = next(action);
    const globalState = store.getState().globalState;
    localApi.globalState.save(globalState);
    return result;
  }
  return next(action);
};


export const redirectToHubV1Middleware = (store: Object) => (next: Function) => (action: Object) => {
  const res = next(action);

  if (LOGIN_ACTION_TYPES.SUCCESS === action.type) {
    const { redirect } = queryString.parse(window.location.search);

    if (redirect) {
      window.location.href = window.location.origin + redirect;
    }
  }

  return res;
};
