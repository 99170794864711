// @flow
import * as PaymentLinksAPI from 'services/payment-links/payment-links.api';
import { dispatchResponse } from 'utils/action.util';
import type { RequestStatus } from "../store.types";
import * as GlobalDuck from 'store/global-duck';
import {GLOBAL_ACTION_TYPES} from 'store/global-duck';

type GetPaymentLinksListPendingType = 'PAYMENT_LINKS_LIST_PENDING';
type GetPaymentLinksListSuccessType = 'PAYMENT_LINKS_LIST_SUCCESS';
type GetPaymentLinksListErrorType = 'PAYMENT_LINKS_LIST_ERROR';

export const PAYMENT_LINKS_LIST: {
  PENDING: GetPaymentLinksListPendingType,
  SUCCESS: GetPaymentLinksListSuccessType,
  ERROR: GetPaymentLinksListErrorType,
} = {
  PENDING: "PAYMENT_LINKS_LIST_PENDING",
  SUCCESS: "PAYMENT_LINKS_LIST_SUCCESS",
  ERROR: "PAYMENT_LINKS_LIST_ERROR",
};

type GetPaymentLinksListPending = {
  type: GetPaymentLinksListPendingType,
}

type GetPaymentLinksListSuccess = {
  type: GetPaymentLinksListSuccessType,
  payload: {
    data: Object,
  }
}

type GetPaymentLinksListError = {
  type: GetPaymentLinksListErrorType,
}

type Action = GetPaymentLinksListPending | GetPaymentLinksListSuccess | GetPaymentLinksListError;

export type PaymentLinksState = {
  list: RequestStatus & { data: Array<Object> },
}

const DEFAULT_STATE: PaymentLinksState = {
  list: {
    loading: false,
    success: false,
    error: false,
    msg: '',
    data: {},
  }
};

export default function reducer(
  state: PaymentLinksState = DEFAULT_STATE,
  action: Action
) {
  switch(action.type) {
    case GlobalDuck.GLOBAL_ACTION_TYPES.SET_SELECTION:
      if (!action.selection.channel.id) {
        return DEFAULT_STATE;
      }

      return state;
    case PAYMENT_LINKS_LIST.PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        }
      }
    case PAYMENT_LINKS_LIST.SUCCESS:
      return {
        ...state,
        list: {
          ...DEFAULT_STATE.list,
          success: true,
          data: {
            ...action.payload.data,
            data: action.payload.data.data.map((pl) => ({
              amount: pl.amount,
              billing: pl.billing,
              createdOn: pl.created_on,
              currency: pl.currency,
              customer: pl.customer,
              description: pl.description,
              expiresOn: pl.expires_on,
              id: pl.id,
              idForDetailsView: pl.id,
              paymentId: pl.payment_id,
              products: pl.products,
              reference: pl.reference,
              status: pl.status,
              links: pl._links,
              type: 'payment-link',
            }))
          },
        }
      }
    case PAYMENT_LINKS_LIST.ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          success: false,
          error: true,
          msg: 'Error getting payment links',
        }
      }
    default:
      return state;
  }
}
export type GetPaymentLinksList = (number, number, string, string) => Function;

export function getPaymentLinksList(
  limit,
  skip,
  statuses,
  channelId
) {
  const promiseReq = PaymentLinksAPI.getPaymentLinksList(limit, skip, statuses, channelId);
  const { PENDING, SUCCESS, ERROR } = PAYMENT_LINKS_LIST;

  return dispatchResponse(promiseReq, PENDING, { type: SUCCESS }, ERROR, false);
}
