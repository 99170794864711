import styled, { css } from 'react-emotion';

import { colors } from 'styles/common.sc';

/**
 * Common theme styles
 */
const commonStyles = {
  padding(size) {
    if (size === 'small') {
      return `0 10px`;
    } else if (size === 'large') {
      return `0 20px`;
    }
    return `0 15px`;
  },
  fontSize(size) {
    if (size === 'small') {
      return '12px';
    }
    return '13px';
  },
  lineHeight(size) {
    if (size === 'small') {
      return '24px';
    } else if (size === 'large') {
      return '40px';
    }
    return '30px';
  },
  iconMargin(size) {
    if (size === 'large') {
      return '15px';
    }
    return '10px';
  },
  width(props) {
    // specified width takes precedence
    if (props.width) {
      return props.width;
    }

    return props.block ? '100%' : 'auto';
  },
};

/**
 * Styled Button Component
 */
export const Container = styled('button')`
  ${containerStyle};
`;
export function containerStyle(props) {
  return css`
    margin: 0;
    font-weight: 400;
    user-select: none;
    appearance: button;
    border-radius: 3px;
    text-align: center;
    border: solid 1px transparent;
    padding: ${commonStyles.padding(props.size)};
    font-size: ${commonStyles.fontSize(props.size)};
    line-height: ${commonStyles.lineHeight(props.size)};
    width: ${commonStyles.width(props)};
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    ${createTheme(props)};

    .ckoIcon, .cko-icon {
      margin-right: ${props.iconRight || props.iconButton ? 0 : commonStyles.iconMargin(props)};
      margin-left: ${props.iconRight ? commonStyles.iconMargin(props) : 0};
    }
    
    span {
      &.cko-button-value {
        vertical-align: middle;
      }
    }

    svg {
      margin-right: 5px;
      vertical-align: middle;
    }
  `;
}

/**
 * Disabled theme
 */
function disabledTheme() {
  return `
    color: ${colors.white};
    background: ${colors.button.disable.bg};
    box-shadow: none;

    .ckoIcon *, .cko-icon svg * {
      fill: ${colors.white};
    }
  `;
}

/**
 * Default theme
 */
function defaultTheme() {
  return `
    border-color: ${colors.button.additional.default.border};
    background: ${colors.button.additional.default.bg};
    box-shadow: ${colors.button.additional.default.shadow};
    color: ${colors.button.additional.default.color};
    .ckoIcon *, .cko-icon svg * {
      fill: ${colors.icon.default};
    }

    &:hover {
      border-color: ${colors.button.additional.hover.border};
      background: ${colors.button.additional.hover.bg};
      box-shadow: ${colors.button.additional.hover.shadow};
      color: ${colors.button.additional.hover.color};
      .ckoIcon *, .cko-icon svg * {
        fill: ${colors.icon.hover};
      }
    }

    &:active {
      border-color: ${colors.button.additional.active.border};
      background: ${colors.button.additional.active.bg};
      box-shadow: ${colors.button.additional.active.shadow};
      color: ${colors.button.additional.active.color};
    }
  `;
}

/**
 * Primary theme
 */
function primaryTheme() {
  return `
    color: ${colors.white};
    background: linear-gradient(
      to top,
      ${colors.button.primary.default.bg.from},
      ${colors.button.primary.default.bg.to}
    );
    box-shadow: ${colors.button.primary.default.shadow};
    border-color: ${colors.button.primary.default.border};

    .ckoIcon *, .cko-icon svg * {
      fill: ${colors.button.primary.default.color};
    }

    &:hover {
      background: linear-gradient(
        to top,
        ${colors.button.primary.hover.bg.from},
        ${colors.button.primary.hover.bg.to}
      );
      box-shadow: ${colors.button.primary.hover.shadow};
      border-color: ${colors.button.primary.hover.border};
      .ckoIcon *, .cko-icon svg * {
        fill: ${colors.button.primary.hover.color};
      }
    }

    &:active {
      background: linear-gradient(
        to bottom,
        ${colors.button.primary.active.bg.to},
        ${colors.button.primary.active.bg.to}
      );
      box-shadow: ${colors.button.primary.active.shadow};
      border-color: ${colors.button.primary.active.border};
      .ckoIcon *, .cko-icon svg * {
        fill: ${colors.button.primary.active.color};
      }
    }
  `;
}

/**
 * Success theme
 */
function secondaryTheme() {
  return `
    color: ${colors.button.secondary.default.color};
    background: ${colors.button.secondary.default.bg};
    box-shadow: ${colors.button.secondary.default.shadow};
    border-color: ${colors.button.secondary.default.border};

    .ckoIcon *, .cko-icon svg * {
      fill: ${colors.icon.active};
    }

    &:hover {
      border-color: ${colors.button.secondary.hover.border};
      background: ${colors.button.secondary.hover.bg};
      box-shadow: ${colors.button.secondary.hover.shadow};
      color: ${colors.button.secondary.hover.color};
    }

    &:active {
      border-color: ${colors.button.secondary.active.border};
      background: ${colors.button.secondary.active.bg};
      box-shadow: ${colors.button.secondary.active.shadow};
      color: ${colors.button.secondary.active.color};
    }
  `;
}

/**
 * Danger theme
 */
function dangerTheme() {
  return `
		border-color: ${colors.border.default};
		background: ${colors.button.danger.default.bg};
		box-shadow: ${colors.button.danger.default.shadow};
		color: ${colors.button.danger.default.color};
    .ckoIcon *, .cko-icon svg * {
      fill: ${colors.button.danger.default.icon};
    }

    &:hover {
			border-color: ${colors.button.danger.hover.border};
      background: ${colors.button.danger.hover.bg};
      box-shadow: ${colors.button.danger.hover.shadow};
      color: ${colors.button.danger.hover.color};
    }

    &:active {
			border-color: ${colors.button.danger.active.border};
      background: ${colors.button.danger.active.bg};
      box-shadow: ${colors.button.danger.active.shadow};
      color: ${colors.button.danger.active.color};
    }

    &:focus {
      outline: none;
    }
  `;
}

/**
 * Link theme
 */
function linkTheme() {
  return `
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
    color: ${colors.text.links};
  `;
}


/**
 * Creates the button themes
 * based on the prop.type
 */
function createTheme(props) {
  // Disabled
  if (props.disabled) {
    return disabledTheme();
  }

  // Primary
  if (props.type === 'primary') {
    return primaryTheme();
  }

  // Secondary
  if (props.type === 'secondary') {
    return secondaryTheme();
  }

  // Danger
  if (props.type === 'danger') {
    return dangerTheme();
  }

  // Link
  if (props.type === 'link') {
    return linkTheme();
  }

  // Default
  return defaultTheme();
}
