import * as React from 'react';
import * as ReactRouterDOM from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { isUndefined, urlParamsToJson } from 'utils';
import feedback from 'components/ui/feedback/';
import { ERROR_CODE_TITLE } from 'config';
import LoginForm from './login-form/';
import NewsUpdate from './news-update/';
import CkoIcon from 'components/ui/icon/';
import CkoSignInLayout from 'components/ui/layout/CkoSignInLayout';
import { Column, Title } from 'components/ui/layout/CkoSignInLayout';
import type { StoreState } from 'store/reducers';
import * as userDuck from 'store/user-duck';
import * as passwordDuck from 'store/password-duck';
import * as globalDuck from 'store/global-duck';
import { formatLongDate } from 'utils/format';
import { withOktaAuth } from "@okta/okta-react";
import { loginFailure, mapOktaPayload } from "store/user-duck";
import { clearUserData } from 'services/localDataApi';

interface IAlert {
  type?: string;
  message?: string | React.Node;
  description?: string | React.Node;
}

type OwnProps = {|
  location: ReactRouterDOM.Location,
  history: ReactRouterDOM.RouterHistory
|};

type StateProps = {|
  user: userDuck.User,
  newPassword: passwordDuck.NewPasswordState,
  requestPassword: passwordDuck.RequestPasswordState,
  verifyTokenState: passwordDuck.VerifyTokenState
|};

type DispatchProps = {|
  login: userDuck.Login,
  logout: userDuck.Logout,
  verifyToken: passwordDuck.VerifyToken,
  clearPassword: passwordDuck.ClearPassword,
  clearUser: userDuck.Clear,
  setWelcomeBanner: globalDuck.SetWelcomeBanner
|};

type Props = OwnProps & StateProps & DispatchProps;

export class Login extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    this.checkStatus();
    this.reset();
    const token = sessionStorage.getItem('token');
    const { dispatch, login, oktaAuth } = this.props;

    if (token) {
      sessionStorage.removeItem('token');
      dispatch(login(false, token, oktaAuth));
    }

    this.setState({ isLoading: true });
    const oktaSessionExists = await oktaAuth.session.exists();
    if (oktaSessionExists) {
      this.readSsoUrlParamsFromSession();
      await this.getTokenFromOkta();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.redirectTo();
  }

  reset() {
    this.props.clearPassword();
    this.props.clearUser();
    clearUserData();
    this.props.logout(this.props.oktaAuth, false);
  }

  getUrlParams() {
    return urlParamsToJson(this.props.location.search);
  }

  readSsoUrlParamsFromSession() {
    const urlParams = JSON.parse(sessionStorage.getItem('ssoRedirectUrlParams'));
    if (urlParams) {
      sessionStorage.removeItem('ssoRedirectUrlParams');
      const searchParams = new URLSearchParams(window.location.search);
      Object.keys(urlParams).forEach((key) => {
        searchParams.set(key, urlParams[key]);
      });
      window.location.search = searchParams.toString();
    }
  }

  checkStatus() {
    const urlParams = this.getUrlParams();
    const { history } = this.props;

    // Presence of the of query paramter "s" with value "1" signifies
    // an expired session
    if (urlParams.s === '1') {
      feedback.error('Session expired');
      history.push({
        search: '',
        pathname: history.location.pathname
      });
    } else if (this.props.requestPassword.success) {
      feedback.success('New password sent!');
    } else if (this.props.verifyTokenState.error) {
      feedback.error('Reset password token expired');
    } else if (this.props.requestPassword.success) {
      feedback.success('New password sent!');
    }

    if (urlParams.verify) {
      this.props.verifyToken(urlParams.verify);
    }
  }

  // Check if User is Logged In
  redirectTo() {
    const userComingFromV1 = sessionStorage.getItem('userComingFromV1');
    const { history, user } = this.props;
    const urlParams = this.getUrlParams();

    if (user.success) {
      if (!userComingFromV1 && user.account.hubVersion === 1) {
        // redirect url to hubv1 application
        // redirect-user page will make call to hub api
        // to get user information and get token from cookie
        window.location.href = `${window.location.origin}/v1/redirect-user/`;
        return;
      }

      // Only show welcome message for merchant admin/readonly
      if (!user.account.isGodUser && !user.account.isSuperAdmin && !user.account.isSuperUser) {
        this.props.setWelcomeBanner(true);
      }

      history.push('/dashboard');

      // XXX: we shouldn't deal with password reset tokens
      // token directly in login
    } else if (urlParams.verify && this.props.verifyTokenState.success) {
      history.push(`/reset-password/${urlParams.verify}`);
      // we cache the user's email only if the login failed
      // because it required a password reset
    } else if (this.props.newPassword.credentials.email) {
      history.push('/reset-password');
    }
  }

  // Return string - The login error title
  getErrorTitle = (error: Object): string => {
    return ERROR_CODE_TITLE[error.errorCode] || 'Internal error, please try again later';
  };

  // Login error body message
  // Return JSX
  getErrorDesc = (error: Object): React.Node => {
    let attemptsLeft;
    let formattedLoginDate;
    let hideLastLogin;
    const { lastLoginDate, remainingLoginAttempts } = error;

    // Check if remainingLoginAttempts & lastLoginDate props exist
    if (!isUndefined(lastLoginDate) && !isUndefined(remainingLoginAttempts)) {
      attemptsLeft = remainingLoginAttempts;
      formattedLoginDate = moment(lastLoginDate).format(formatLongDate);
    } else {
      hideLastLogin = true;
    }

    // ErrorMsgBody Component
    const ErrorMsgBody = () => {
      let msg = error.message;

      // Onyl show the message - Hide Last successful login
      if (hideLastLogin) {
        return <p>{msg}</p>;
      }

      if (attemptsLeft > 0) {
        msg = `${attemptsLeft} more attempts remaining`;
      }

      return (
        <p>
          {msg} <br /> Last successful login: {formattedLoginDate}
        </p>
      );
    };

    return <ErrorMsgBody />;
  };

  // Show Alert Box
  getErrorMsgs(): IAlert {
    const { user } = this.props;

    const errorObj: IAlert = {
      type: 'error'
    };

    if (user.error) {
      errorObj.message = this.getErrorTitle(user.errorData);
      errorObj.description = this.getErrorDesc(user.errorData);
    } else if (this.props.newPassword.success) {
      errorObj.type = 'success';
      errorObj.message = 'Password update successful';
      errorObj.description = 'Please login with your new password';
    }

    return errorObj;
  }

  // Get okta token and parse contents to permissions data
  getTokenFromOkta = async (params): Promise<> => {
    const { oktaAuth, authorise, dispatch } = this.props;
    oktaAuth.token
      .getWithoutPrompt(params)
      .then((response) => {
        const tokenPayload = oktaAuth.token.decode(response.tokens.accessToken.accessToken).payload;
        dispatch(authorise(mapOktaPayload(tokenPayload)));
      })
      .catch((error) => {
        dispatch(loginFailure(error));
        this.setState({ isLoading: false });
      });
  };

  login = async (
    { email, password }: { email: string, password: string },
    isSso: boolean,
    ssoUrl?: string
  ) => {
    const { login, dispatch } = this.props;

    if (isSso) {
      sessionStorage.setItem('ssoRedirectUrlParams', JSON.stringify(this.getUrlParams()));
      window.location.assign(ssoUrl);
    } else {
      dispatch(login({ email, password }));
    }
  };

  // Render HTML
  render() {
    return (
      <CkoSignInLayout loading={this.props.user.loading || this.state.isLoading}>
        <Column align="center">
          <CkoIcon name="logo" className="logo" width="30px" />
          <Title>Log in to The Hub</Title>
          <LoginForm onSubmit={this.login} user={this.props.user} alert={this.getErrorMsgs()} />
        </Column>
        <Column align="center">
          <NewsUpdate />
        </Column>
      </CkoSignInLayout>
    );
  }
}

const mapActionsToProps: DispatchProps = dispatch => ({
  dispatch,
  verifyToken: passwordDuck.verifyToken,
  clearPassword: passwordDuck.clearPassword,
  login: userDuck.login,
  authorise: userDuck.authorise,
  logout: userDuck.logout,
  clearUser: userDuck.clear,
  setWelcomeBanner: globalDuck.setWelcomeBanner
});

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: state.userState.user,
    newPassword: state.passwordState.newPassword,
    requestPassword: state.passwordState.requestPassword,
    verifyTokenState: state.passwordState.verifyToken
  };
}

export default connect(mapStateToProps, mapActionsToProps)(withOktaAuth(Login));
