import axiosLib from 'axios';
import { ajxPromise } from 'services/appRequest';
import { validateParams } from 'utils/api.util';
import config from 'App-config';
import mockData from './mockDate';


const axios = axiosLib.create({
  headers: { 'x-hub-version': config.appVersion }
});

export const createPaymentLink = (dataParams: Object) => {
  return axios.post('https://sandbox.checkout.com/hub/channels/262276/payment-links', dataParams);
};

export const getPaymentLinksList = (
  limit,
  skip,
  statuses,
  channelId) => {
  const requiredParams = [
    'limit',
    'skip',
    'statuses',
    'channelId',
  ];
  const paramsList = { limit, skip, statuses, channelId };
  const errorList = validateParams(paramsList, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `PaymentLinks.getPaymentLinksList() is missing {${errorList}} param(s)`
    );
  }

  // const params = `limit=${limit}&skip=${skip}&statuses=${statuses}&id=${channelId}`;

  // return ajxPromise.get(`/businesses/239610/payment-links?${params}`, { ['x-authtoken']: 'sk_test_ee03de14-cf1c-49f8-aef4-9bb7c5516616'});

  // return axios.get(`https://sandbox.checkout.com/hub/channels/262276/payment-links/limit?${params}`);

  return Promise.resolve({ status: 200, data: mockData });
}
