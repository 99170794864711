// @flow
import { ajxPromise } from 'services/appRequest';
import { validateParams } from 'utils/api.util';

import * as payoutsDuck from 'store/payouts-duck';

/**
 * Get bank accounts details via an api call
 * @param {string|number} accountId account id
 * @returns {promise}
 */
export const getBankAccounts = (accountId: number): Promise<Object> => {
  const errorList = validateParams({ accountId }, ['accountId']);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getBankAccounts() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(`/accounts/${accountId}/bank-accounts?isDetailed=true`);
};

/**
 * Get account settings via an api call
 * @param {string|number} accountId account id
 * @returns {promise}
 */
export const getAccountSettings = (accountId: string): Promise<Object> => {
  const errorList = validateParams({ accountId }, ['accountId']);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getAccountSettings() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(`/accounts/${accountId}/account-settings-override`);
};

/**
 * Get the list of processing currencies associated to a business via an api call
 * @param {string|number} accountId account id
 * @param {string|number} businessId business id
 * @returns {promise}
 */
export const getProcessingCurrencies = (
  accountId: string,
  businessId: string
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId'];
  const errorList = validateParams({ accountId, businessId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getProcessingCurrencies() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(
    `/accounts/${accountId}/businesses/${businessId}/processingCurrencies`
  );
};

/**
 * Get apple pay signing request via an api call
 * @param {string|number} accountId account id
 * @param {string|number} businessId business id
 * @returns {promise}
 */
export const getApplePaySigningRequest = (
  accountId: string,
  businessId: string
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId'];
  const errorList = validateParams({ accountId, businessId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getApplePaySigningRequest() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(
    `/accounts/${accountId}/businesses/${businessId}/business-settings/apple-pay/signing-request`
  );
};

/**
 * Get apple pay certificates via an api call
 * @param {string|number} accountId account id
 * @param {string|number} businessId business id
 * @returns {promise}
 */
export const getApplePayCertificates = (
  accountId: string,
  businessId: string
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId'];
  const errorList = validateParams({ accountId, businessId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getApplePayCertificates() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(
    `/accounts/${accountId}/businesses/${businessId}/business-settings/apple-pay/certificates`
  );
};

/**
 * Get the processing settings via an api call
 * @param {string|number} accountId account id
 * @param {string|number} businessId business id
 * @returns {promise}
 */
export const getProcessingSettings = (
  accountId: string,
  businessId: string
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId'];
  const errorList = validateParams({ accountId, businessId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getProcessingSettings() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(
    `/accounts/${accountId}/properties/${businessId}/processor-management`
  );
};

/**
 * Get the payment methods via an api call
 * @param {string|number} accountId account id
 * @param {string|number} businessId business id
 * @returns {promise}
 */
export const getPaymentMethods = (
  accountId: string,
  businessId: string
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId'];
  const errorList = validateParams({ accountId, businessId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getPaymentMethods() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(
    `/accounts/${accountId}/businesses/${businessId}/payment-methods`
  );
};

/**
 * Get the service settings via an api call
 * @param {string|number} accountId account id
 * @returns {promise}
 */
export const getServiceSettings = (accountId: string): Promise<Object> => {
  const requiredParams = ['accountId'];
  const errorList = validateParams({ accountId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getServiceSettings() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(`/accounts/${accountId}/service-settings`);
};

export const updateBankAccount = (
  accountId: number,
  bankAccount: payoutsDuck.BankDescription
) => {
  const requiredParams = ['accountId', 'bankAccount'];
  const errorList = validateParams({ accountId, bankAccount }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.updateBankAccount() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.put(
    `/accounts/${accountId}/bank-accounts/${bankAccount.bankAccountId}`,
    bankAccount
  );
};

/**
 * Get the list of cities of the country via an api call
 * @param {string|number} countryId country id
 * @returns {promise}
 */
export const getCountryCities = (countryId: string): Promise<Object> => {
  const requiredParams = ['countryId'];
  const errorList = validateParams({ countryId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getCountryCities() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(`/countries/${countryId}/cities`);
};

/**
 * Get the list of timezones of the country via an api call
 * @param {string|number} countryId country id
 * @returns {promise}
 */
export const getCountryTimezones = (countryId: string): Promise<Object> => {
  const requiredParams = ['countryId'];
  const errorList = validateParams({ countryId }, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getCountryTimezones() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(`/lookups/countries/${countryId}/timezones`);
};

/**
 * Get payout cycle frequencies
 * @returns {promise}
 */
export const getFrequencies = (): Promise<Object> =>
  ajxPromise.get(`/lookups/frequencies`);

/**
 * Get business bank account details
 */
export const getBusinessBankDetails = (
  accountId: number,
  businessId: string
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId'];
  const params = { accountId, businessId };
  const errorList = validateParams(params, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getBusinessBankDetails() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(
    `/accounts/${accountId}/businesses/${businessId}/bank-accounts?isDetailed=true`
  );
};

/**
 * Get account bank fields
 * @param {string|number} accountId
 * @param {string|number} businessId
 * @returns {promise}
 */
export const getAccountBankFields = (
  countryId: number,
  currencyId: number
): Promise<Object> => {
  const requiredParams = ['countryId', 'currencyId'];
  const params = { countryId, currencyId };
  const errorList = validateParams(params, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getAccountBankFields() is missing {${errorList}} param(s)`
    );
  }
  return ajxPromise.get(
    `/bank-fields?countryId=${countryId}&currencyId=${currencyId}`
  );
};

/**
 * Get channel api keys
 */
type GetApiKeysParam = {
  accountId: number,
  businessId: number,
  channelId: number,
};
export const getApiKeys = (params: GetApiKeysParam): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId', 'channelId'];
  const errorList = validateParams(params, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getApiKeys() is missing {${errorList}} param(s)`
    );
  }

  return ajxPromise.get(
    `/accounts/${params.accountId}/businesses/${params.businessId}/channels/${
      params.channelId
    }/api-keys/`
  );
};

/**
 * Refresh channel api keys
 */
type RefreshApiKeyParam = {
  accountId: number,
  businessId: number,
  channelId: number,
  keyType: Object,
  password: string,
};
export const refreshApiKey = (params: RefreshApiKeyParam): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId', 'channelId', 'password'];
  const errorList = validateParams(params, requiredParams);
  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.refreshApiKey() is missing {${errorList}} param(s)`
    );
  }

  return ajxPromise.post(
    `/accounts/${params.accountId}/businesses/${params.businessId}/channels/${
      params.channelId
    }/api-keys/refresh`,
    params.keyType,
    {
      headers: { x_auth_credentials: params.password },
    }
  );
};

/**
 * Get all events
 */
type GetAllEventsParam = {
  accountId: number,
  businessId: number,
  channelId: number,
  apiVersion: string,
};
export const getAllEvents = (params: GetAllEventsParam): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId', 'channelId', 'apiVersion'];
  const errorList = validateParams(params, requiredParams);

  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getAllEvents() is missing {${errorList}} param(s)`
    );
  }

  return ajxPromise.get(
    `/accounts/${params.accountId}/businesses/${params.businessId}/channels/${
      params.channelId
    }/webhook/all-events?version=${params.apiVersion}`
  );
};

/**
 * Update channel URLs
 */
type Webhooks = {
  id?: string,
  isActive: boolean,
  isLive: boolean,
  events: Array<string>,
  url: string,
  authorisationKey: string,
};
type UpdateChannelUrlParam = {
  accountId: number,
  businessId: number,
  channelId: number,
  urlData: {
    channelUrl: string,
    channelUrlId: number,
    feedUrl: string,
    successfulTransactionUrl: string,
    unsuccessfulTransactionUrl: string,
    uwReviewed: boolean,
    webhooks: Array<Webhooks>,
  },
};
export const updateChannelUrl = (
  params: UpdateChannelUrlParam
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId', 'channelId'];
  const errorList = validateParams(params, requiredParams);

  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.updateChannelUrl() is missing {${errorList}} param(s)`
    );
  }

  return ajxPromise.put(
    `/accounts/${params.accountId}/businesses/${params.businessId}/channels/${
      params.channelId
    }/channelUrls`,
    params.urlData
  );
};

/**
 * Get channel URLs
 */
type GetChannelUrlParams = {
  accountId: number,
  businessId: number,
  channelId: number,
};
export const getChannelUrls = (
  params: GetChannelUrlParams
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId', 'channelId'];
  const errorList = validateParams(params, requiredParams);

  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.getChannelUrls() is missing {${errorList}} param(s)`
    );
  }

  return ajxPromise.get(
    `/accounts/${params.accountId}/businesses/${params.businessId}/channels/${
      params.channelId
    }/channelUrls`
  );
};

/**
 * Create channel URLs
 */
type CreateChannelUrlParam = {
  accountId: number,
  businessId: number,
  channelId: number,
  urlData: {
    channelName: string,
    channelUrl: string,
    successfulTransactionUrl: string,
    unsuccessfulTransactionUrl: string,
  },
};
export const createChannelUrl = (
  params: CreateChannelUrlParam
): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId', 'channelId'];
  const errorList = validateParams(params, requiredParams);

  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.createChannelUrl() is missing {${errorList}} param(s)`
    );
  }

  return ajxPromise.post(
    `/accounts/${params.accountId}/businesses/${params.businessId}/channels/${
      params.channelId
    }/channelUrls`,
    params.urlData
  );
};

/**
 * Create channel
 */
type CreateChannelParam = {
  accountId: number,
  businessId: number,
  data: {
    channelAPIVersion: string,
    channelName: string,
    channelType: number,
  },
};
export const createChannel = (params: CreateChannelParam): Promise<Object> => {
  const requiredParams = ['accountId', 'businessId'];
  const errorList = validateParams(params, requiredParams);

  if (errorList.length > 0) {
    return Promise.reject(
      `Settings.createChannel() is missing {${errorList}} param(s)`
    );
  }

  return ajxPromise.post(
    `/accounts/${params.accountId}/businesses/${params.businessId}/channels`,
    {
      accountId: params.accountId,
      businessId: params.businessId,
      ...params.data,
    }
  );
};
