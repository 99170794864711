// @flow
import { ajxPromise } from 'services/appRequest';
import type { Evidence } from 'store/ducks/submit-evidence';
import type { Dispute } from 'store/ducks/submit-evidence/dispute';
type GetRequestType = {
  businessId: string,
  disputeId: string
};

export function getDispute(
  request: GetRequestType
): Promise<{ data: Dispute }> {
  return ajxPromise.get(
    `/businesses/${request.businessId}/disputes/${request.disputeId}`
  );
}

export function getEvidence(
  request: GetRequestType
): Promise<{ data: Evidence }> {
  return ajxPromise.get(
    `/businesses/${request.businessId}/disputes/${request.disputeId}/evidence`
  );
}
