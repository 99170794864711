// @flow
import { produce } from 'utils/tree';
import * as formTypes from 'types/form';

export type State = {
  description: string,
  file: {
    loading: boolean,
    error: string,
    id: string,
    filename: string,
    content: string,
  },
};

export type HydrateAction = {
  type: 'EVIDENCE_HYDRATE_COMMUNICATION',
  state: State,
};
export function hydrate(state: State): HydrateAction {
  return {
    state,
    type: 'EVIDENCE_HYDRATE_COMMUNICATION',
  };
}

export type ClearAction = {
  type: 'EVIDENCE_CLEAR_COMMUNICATION',
};
export function clear(): ClearAction {
  return {
    type: 'EVIDENCE_CLEAR_COMMUNICATION',
  };
}

export type StreamCommunicationDescriptionAction = formTypes.StreamText<
  'EVIDENCE_STREAM_COMMUNICATION_DESCRIPTION'
>;
export function streamCommunicationDescription(
  value: string
): StreamCommunicationDescriptionAction {
  return {
    value,
    type: 'EVIDENCE_STREAM_COMMUNICATION_DESCRIPTION',
  };
}

export type UploadCommunicationEvidenceStartAction = {
  type: 'EVIDENCE_UPLOAD_COMMUNICATION_START',
};
export function uploadCommunicationEvidenceStart(): UploadCommunicationEvidenceStartAction {
  return {
    type: 'EVIDENCE_UPLOAD_COMMUNICATION_START',
  };
}

export type ClearCommunicationEvidenceAction = {
  type: 'EVIDENCE_CLEAR_COMMUNICATION_EVIDENCE',
};
export function clearCommunicationEvidence(): ClearCommunicationEvidenceAction {
  return {
    type: 'EVIDENCE_CLEAR_COMMUNICATION_EVIDENCE',
  };
}

export type UploadCommunicationEvidenceSuccessAction = formTypes.UploadSuccess<
  'EVIDENCE_UPLOAD_COMMUNICATION_SUCCESS'
>;
export function uploadCommunicationEvidenceSuccess(
  filename: string,
  content: string
): UploadCommunicationEvidenceSuccessAction {
  return {
    filename,
    content,
    type: 'EVIDENCE_UPLOAD_COMMUNICATION_SUCCESS',
  };
}

type UploadCommunicationEvidenceFailureAction = formTypes.UploadFailure<
  'EVIDENCE_UPLOAD_COMMUNICATION_FAILURE'
>;
export function uploadCommunicationEvidenceFailure(
  error: string
): UploadCommunicationEvidenceFailureAction {
  return {
    error,
    type: 'EVIDENCE_UPLOAD_COMMUNICATION_FAILURE',
  };
}
type Action =
  | HydrateAction
  | ClearAction
  | StreamCommunicationDescriptionAction
  | UploadCommunicationEvidenceStartAction
  | ClearCommunicationEvidenceAction
  | UploadCommunicationEvidenceSuccessAction
  | UploadCommunicationEvidenceFailureAction;

const DEFAULT_STATE: State = {
  description: '',
  file: {
    loading: false,
    error: '',
    id: '',
    filename: '',
    content: '',
  },
};

export function reducer(state: State = DEFAULT_STATE, action: Action): State {
  return produce(state, function(draft) {
    switch (action.type) {
      case 'EVIDENCE_HYDRATE_COMMUNICATION':
        return action.state;

      case 'EVIDENCE_CLEAR_COMMUNICATION':
        return DEFAULT_STATE;

      case 'EVIDENCE_STREAM_COMMUNICATION_DESCRIPTION':
        draft.description = action.value;
        break;

      case 'EVIDENCE_UPLOAD_COMMUNICATION_START':
        draft.file.loading = true;
        break;

      case 'EVIDENCE_CLEAR_COMMUNICATION_EVIDENCE':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: '',
          content: '',
        };
        break;

      case 'EVIDENCE_UPLOAD_COMMUNICATION_SUCCESS':
        draft.file = {
          loading: false,
          error: '',
          id: '',
          filename: action.filename,
          content: action.content,
        };
        break;

      case 'EVIDENCE_UPLOAD_COMMUNICATION_FAILURE':
        draft.file.loading = false;
        draft.file.error = action.error;
        break;
      default: break;
    }
  });
}
