import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { formatPathName } from './index';

const Analytics = ({ location: { pathname } }) => {
  const [path, setPath] = useState(window.location.pathname);
  useEffect(() => {
    if (path !== window.location.pathname) {
      const page = formatPathName(pathname);
      ReactGA.set({ page });
      ReactGA.pageview(page);
      setPath(window.location.pathname);
    }
  }, [path, pathname]);
  return null;
};

export default Analytics;
