export const BASE = 13;
export const LAPTOP_VIEW = 1500;

/**
 * GENERAL
 */
export const general = {
  gutter: 20,
};

/**
 * COLORS
 */
export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  text: {
    primary: '#1b2f37',
    secondary: '#354c55',
    tertiary: '#7b8d93',
    adiacent: '#a5b2b8',
    links: '#1da177',
    button: '#1da177',
    success: '#1da177',
    warning: '#d67f1c',
    alert: '#BE4343',
    cardTitle: '#6CD9B6'
  },
  icon: {
    default: '#acbabf',
    active: '#2db388',
    hover: '#7b8d93',
  },
  border: {
    default: '#dfe7eb',
    secondary: '#dfe1ea',
    hover: '#d0d8db',
    error: '#CF5858',
  },
  tag: {
    default: '#f7f9fa',
  },
  bg: {
    default: '#f2f4f5',
    green: '#2db388',
    alert: '#CF5858',
    dark: '#153947',
    blue: '#299fcb',
    tableHover: '#F7F8F9',
  },
  sidebarIcon: {
    divider: '#C0C9CC',
    stroke: '#5D7077',
    strokeActive: '#354C55',
    accent: '#C0C9CC',
    accentActive: '#6CD9B6',
  },
  form: {
    disabled: '#f7f9fa',
  },
  button: {
    primary: {
      default: {
        bg: {
          from: '#2aab82',
          to: '#2db388',
        },
        border: 'transparent',
        color: '#FFF',
        shadow: '0px 1px 3px 0 rgba(45, 179, 136, 0.45)',
      },
      hover: {
        bg: {
          from: '#2fbd90',
          to: '#2fbd90',
        },
        border: 'transparent',
        color: '#FFF',
        shadow: '0px 2px 5px 0 rgba(45, 179, 136, 0.45)',
      },
      active: {
        bg: {
          from: '#219b74',
          to: '#2db388',
        },
        border: 'transparent',
        color: '#FFF',
        shadow: 'inset 0px 2px 5px 0 rgba(30, 108, 87, 0.35)',
      },
    },
    secondary: {
      default: {
        bg: '#FFFFFF',
        border: '#dfe7eb',
        color: '#1da177',
        shadow: '0px 1px 3px 0 rgba(123, 141, 147, 0.15)',
      },
      hover: {
        bg: '#FFFFFF',
        border: '#2db388',
        color: '#1da177',
        shadow: '0px 2px 5px 0 rgba(121, 131, 149, 0.25)',
      },
      active: {
        bg: '#f5fffc',
        border: '#2db388',
        color: '#1da177',
        shadow: 'inset 0px 2px 5px 0 rgba(124, 148, 140, 0.2)',
      },
    },
    additional: {
      default: {
        bg: '#FFFFFF',
        border: '#dfe7eb',
        color: '#7b8d93',
        shadow: '0px 1px 3px 0 rgba(123, 141, 147, 0.15)',
      },
      hover: {
        bg: '#FFFFFF',
        border: '#d0d8db',
        color: '#354c55',
        shadow: '0px 2px 5px 0 rgba(123, 141, 147, 0.25)',
      },
      active: {
        bg: '#f5f8fa',
        border: '#d0d8db',
        color: '#798395',
        shadow: 'inset 0px 2px 5px 0 rgba(123, 141, 147, 0.2)',
      },
    },
    disable: {
      bg: '#DADEE5',
    },
    danger: {
      default: {
        bg: '#FFFFFF',
        border: '#CF5858',
        color: '#CF5858',
        icon: '#CF5858',
        shadow: '0px 1px 3px 0 rgba(121, 131, 149, 0.15)',
      },
      hover: {
        bg: '#FFFFFF',
        border: '#CF5858',
        color: '#CF5858',
        icon: '#CF5858',
        shadow: '0px 2px 5px 0 rgba(121, 131, 149, 0.25)',
      },
      active: {
        bg: '#fafafa',
        border: '#CF5858',
        color: '#CF5858',
        icon: '#CF5858',
        shadow: 'inset 0px 2px 5px 0 rgba(121, 131, 149, 0.2)',
      },
    },
  },
  select: {
    default: {
      bg: '#fff',
      color: '#7b8d93',
    },
    hover: {
      bg: '#fafbfc',
      color: '#1da177',
    },
    active: {
      bg: '#2db388',
      color: '#fff',
    },
  },

  riverBed: '#464F60',
  athensGray: '#DFE1EA',
  athensGrayS1: '#EFF0F4',
  athensGrayS2: '#F7F8FA',
  athensGrayS3: '#FBFBFC',
};

/**
 * FONT FAMILIES
 */
export const sourceSanFont = `
  font-family: 'Source Sans Pro', sans-serif;
`;

export const ocrFont = `
  font-family: 'OCR A Extended';
`;

/**
 * TYPOGRAPHY
 */
export const text = {
  h1: 24,
  h2: 21,
  h3: 16,
  h4: 15,
  base: BASE,
  '10': 10,
  '11': 11,
  '12': 12,
  '13': 13,
  '14': 14,
  '15': 15,
  '16': 16,
  '18': 18,
  '40': 40,
  medium: 500,
  semiBold: 600,
};

export const clearfix = `
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
`;

/**
 * Animations
 */
export const animation = {
  cubic: 'transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  ease: 'transition: all 0.3s ease-in-out',
  easeBg: 'transition: background .3s ease',
};

/**
 * Calendar
 */
export const calendarStyle = isRange => {
  return `
    .ant-calendar {
      font-size: 1em;
    }

    .ant-calendar-input-wrap,
    .ant-calendar-range-middle,
    .ant-calendar-prev-year-btn {
      display: none;
    }

    .ant-calendar-picker-container {
      left: auto !important;
      top: auto !important;
      right: auto;
      position: relative;
      width: 100%;
      height: 100%;
    }

    .ant-calendar-range {
      width: 100%;
      box-shadow: none;
      border: none;
    }

    .ant-calendar-panel {
      ${clearfix};
    }

    .ant-calendar-date-panel {
      width: ${isRange ? '80%' : '100%'};
      float: ${isRange ? 'right' : 'none'};
    }

    .ant-calendar-range-part {
      width: 47%;
    }

    .ant-calendar-range-left {
      margin-right: 30px;
    }

    /* Navs */
    .ant-calendar-prev-month-btn,
    .ant-calendar-next-month-btn,
    .ant-calendar-month-panel-prev-year-btn,
    .ant-calendar-month-panel-next-year-btn,
    .ant-calendar-year-panel-prev-decade-btn,
    .ant-calendar-year-panel-next-decade-btn,
    .ant-calendar-decade-panel-prev-century-btn,
    .ant-calendar-decade-panel-next-century-btn {
      position: absolute;
      top: 0;
      background-color: ${colors.white};
      border: solid 1px ${colors.border.default};
      padding: 0 8px;
      font-size: 12px;
      color: ${colors.icon.default};
      height: 30px;
      line-height: 29px;
      border-radius: 3px;
      box-shadow: 0 1px 3px 0 rgba(121, 131, 149, 0.15);

      &:before,
      &:before,
      &:after,
      &:after {
        font-family: Flaticon;
        font-style: normal;
      }

      &:hover {
        box-shadow: 0 1px 3px 0 rgba(121, 131, 149, 0.25);
        border-color: ${colors.border.hover};
        color: ${colors.icon.hover};
      }
    }

    .ant-calendar-prev-month-btn,
    .ant-calendar-month-panel-prev-year-btn,
    .ant-calendar-year-panel-prev-decade-btn,
    .ant-calendar-decade-panel-prev-century-btn {
      left: 0;

      &:after {
        content: '\f106';
      }
    }

    .ant-calendar-next-month-btn,
    .ant-calendar-month-panel-next-year-btn,
    .ant-calendar-year-panel-next-decade-btn,
    .ant-calendar-decade-panel-next-century-btn {
      right: 0;
      &:after {
        content: '\f105';
      }
    }

    /* Title */
    .ant-calendar-my-select,
    .ant-calendar-month-panel-year-select,
    .ant-calendar-year-panel-decade-select {
      color: ${colors.text.primary};
    }

    .ant-calendar-my-select {
      font-size: 15px;
    }

    .ant-calendar-month-select {
      font-weight: ${text['semiBold']};

      &:hover {
        color: ${colors.text.links};
      }
    }

    .ant-calendar-month-panel-year-select,
    .ant-calendar-year-panel-decade-select,
    .ant-calendar-decade-panel-century {
      font-size: 16px;
      &:hover {
        color: ${colors.text.links};
      }
    }

    /* Body */
    .ant-calendar-column-header {
      font-size: 12px;
      color: ${colors.text.adiacent};
    }

    .ant-calendar-cell .ant-calendar-date,
    .ant-calendar-month-panel-cell .ant-calendar-month-panel-month,
    .ant-calendar-decade-panel-cell .ant-calendar-decade-panel-decade {
      color: ${colors.text.secondary};
    }

    .ant-calendar-cell.ant-calendar-last-month-cell .ant-calendar-date,
    .ant-calendar-cell.ant-calendar-next-month-btn-day .ant-calendar-date {
      color: ${colors.text.adiacent};
    }

    .ant-calendar-cell.ant-calendar-disabled-cell {
      position: relative;

      &:before {
        content: '';
        display: block;
        background: transparent;
        border-radius: 0;
        border: 0;
        position: absolute;
        top: 2px;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 30px;
      }
      .ant-calendar-date {
        color: #adb0b880;
      }

      &:hover {
        &:before {
          background: rgba(207, 88, 88, 0.03);
        }
        .ant-calendar-date {
          color: ${colors.text.alert};
        }
      }
    }

    .ant-calendar-date,
    .ant-calendar-decade-panel-cell .ant-calendar-decade-panel-decade {
      &:hover {
        background: ${hexToRgba(colors.text.links, 0.1)};
        cursor: pointer;
        color: ${colors.text.links};
      }
    }

    .ant-calendar-selected-date .ant-calendar-date,
    .ant-calendar-range-part .ant-calendar-selected-day .ant-calendar-date,
    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
    .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
      background-color: ${colors.bg.green};
      color: ${colors.white};
    }

    .ant-calendar-today .ant-calendar-date {
      background: transparent;
      color: ${colors.text.links};
    }

    .ant-calendar-cell {
      .error-tip {
        position: absolute;
        border-radius: 2px;
        background-color: ${colors.bg.alert};
        box-shadow: 0 2px 5px 0 rgba(207, 88, 88, 0.45);
        color: ${colors.white};
        font-size: 12px;
        width: 160px;
        padding: 0;
        z-index: 1;
        text-align: center;
        display: none;
        top: 100%;
        left: -50%;
      }

      &:hover {
        .error-tip {
          display: block;
        }
      }
    }

    .ant-calendar-decade-panel-decade,
    .ant-calendar-month-panel-month,
    .ant-calendar-year-panel-year {
      font-size: 13px;
      color: ${colors.text.secondary};
      display: block;
      margin: 0 auto;
      width: auto;
      height: 32px;
      line-height: 32px;
      border: none;
      padding: 0;
      background: transparent;
      text-align: center;
      border-radius: 2px;

      &:hover {
        background: ${hexToRgba(colors.text.links, 0.1)};
        cursor: pointer;
        color: ${colors.text.links};
      }
    }

    .ant-calendar-year-panel > div {
      height: 100%;
      top: 0;
      position: absolute;
      background: white;
      width: 100%;
    }

    /* Side Links */
    .ant-calendar-footer {
      width: 20%;
      float: left;
      border: none;

      a {
        display: block;
        font-weight: 500;
        font-size: 13px;
        color: ${colors.text.tertiary};

        &:hover {
          color: ${colors.text.links};
        }
      }
    }
  `;
};

type FluidFixedWidthOptions = {
  width?: number,
  collapsed?: boolean,
};

export const fluidFixedWidth = (options?: FluidFixedWidthOptions = {}) => {
  /**
	sidebar width (270px) +
	sidebar margin left (20px) +
	contentArea padding (40px) +
	contentArea margin right (20px) = 350px
*/
  const optionalWidth = options.width ? options.width : 0;

  if (options.collapsed) {
    const width = `${optionalWidth + 140}px`;
    return `
      width: calc(100% - ${width});
      max-width: ${`${1780 - optionalWidth}px`};
    `;
  }

  const width = `${optionalWidth + 340}px`;
  return `
    width: calc(100% - ${width});
    max-width: ${`${1580 - optionalWidth}px`};
  `;
};

export function hexToRgba(hex: string, opacity: number): string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(
    result[2],
    16
  )}, ${parseInt(result[3], 16)},${opacity})`;
}
